
export class RoleDetails {
  Id: number;
  roleName: string;
  RoleDescription: string;
  PrivilegeID: string;
  PrivilegeName: string;
  typeOfPrivilege: string;
  countofPrivileges: number;
  Type: string;
  isDefault: string;
}

export class PriviligeDetails {
  PrivilegeID: string;
  /*TabID: string;*/
  PrivilegeName: string;
  typeOfPrivilege: string;
  /*TabName: string; */ 
}


export class Privilige {
  roleName: string;
  RoleDescription: string;
  RoleID: number;
  privileges: string;
  typeOfPrivilege: string;
  PrivilegeID: string;
  userID: number;
}


export class CountriesDetails
{
  Id: string;
  CountryCode: string;
  CountryName: string;
  CountryID: string;
}

export class DepartmentDetails
{

  ID: string;
  department: string;
  DepartmentCode: string;
  CompanyID: string;
  
}


export class UserGroupsDetails
{
  //GroupName: string;
 // ID: string;
  GroupDesc: string;
  //CompanyID: string;
  Groupname: string;
}

export class UserAndGroupsDetails {
  GroupName: string;
  GroupID: string;
  UserID: string;
  //CompanyID: string;
  UserName: string;
}
export class Office {
  Message: String;
  IsProxy: any;
  Proxy: any;
  State: any;
}
export class OfficeAudit {
  OldOutOfOffice: Office;
  NewOutOfOffice: Office;
  CurrentUserId: number;
}



export class UserFormData {
  userid: string;
  username: string;
  emailID: string;
  firstName: string;
  lastname: string;
  domain: string;
  timezone: string;
  roles: string;
  groupname: string;
  groupowner: string;
  department: string;
  countries: string;
  dynFormFieldData: string;
  lockoutEnabled: string;
  countryName: string;
  domainName: string;
  gropuName: string;
  roleName: string;
  departmentName: string;
  currentUserId: number;
  roleid: number;
  NoOfGroupsCount: string;
  timeFormat:Boolean;
  ssouser: Boolean;
  language: string;
  businessPhone: string;
  companyId: number;
  businessUnitId: number;
  company: string;
  businessUnit: string;
  status: string;


  Region: string;
  Market: string;
  enableEsign: boolean;
  //enableSSOUser: any;


}

export class UserDomains {
  domainName: string;
  DomainID: string;
  DomainCode: string;
  
}

export class TimeZonesData {
  timeZone: string;
  timeZoneID: string;
}

export class Languages {
  id: number;
  name: string;
}
export class Companies {
  companyId: number;
  companyName: string;
}
export class BusinessUnits {
 id: number;
  businessUnit: string;
}


export class AuditDashboardForm
{
  FieldName: string;
  valueBefore: string;
  valueAfter: string;
}

export class AduitForm
{
  userId: string;
  eventDate: string;
  actionType: string;
  changes: string;
  FieldName: string;
  ValueBefore: string;
  ValueAfter: string;
}


export class MasterData {
  MasterDataName: string;
  MasterDataID: string;
  MasterDataDescsiption: string;
  MasterDataCode: string;
  MasterDataFlag: string;
  MasterDataTypeID: string;
  MasterDataType: string;
}

export class MasterDataNames {
  MasterDataName: string;
  MasterDataID: string;
}

export class PlaceHolderData {
  ID: number;
  FileName: string;
  FilePath: string;
  PhysicalFilePath: string;
  ThumbnailFilePath: string;
  FileSize: string;
  CompanyID: number;
  FileExtention:string;
}

export class MasterDataInput {
  MasterDataName: string;
  MasterDataID: number;
  MasterDataDescsiption: string;
  MasterDataCode: string;
  MasterDataFlag: string;
  MasterDataTypeID: number;
  CompanyID: number;
  userID: number;
}
export class ExceptionDetail {
  Id: string;
  ExceptionMessage: string;
  StackTrace: string;
  InnerException: string;
  CreatedDate: string;
  UserId: string;
  ExceptionMethodArea: string;
  ModuleName: string;
  MethodName: string;
    }

export class GroupsData {
  Id: number;
  groupName: string;
  users: string;
  description: string;
  type: string;
  userIds: string;
  privTypeIDS: string;
  operation: number;
  rolescount: number;
  userscount: number;
  currentUserId: number;
  status: string;
  isDefault: string;
  masterModule: string;
}

export class UserGroupData {
  Id: number;
  UserName: string;
  Role: string;
  TypeID: string;
  users: string;
  CountryName: string;
}


export class PDfExpAuditSave {
  htmlcontent: string;
  offset: string;
  TableEnum: string;
  FilePath: string;
  Message: string;
  IsSuccess: boolean;
  AuditFile: string;
}

export class AllPrivileges{
  roleId : number;
  roleName : string;
  roleDescription : string;
  allActionAccessType : string;
  modulesPrivileges : PrivilegeMasterDTO [] = [];
}

export class PrivilegeMasterDTO{
  moduleId : number;
  privilegeId : number;
  moduleName : string;
  accessType : string;
  subModules : SubModulePrivilege [] = [];
}

export class ACMIDetails
{
         ID :number;
         ProjectID :number;
         ProductName:string 
         RequestorContactInfo:string;
         ChangeRequestNumber:string;
         FormCompletedonDate :Date;
         FinishGoodsCode :string;
         MaterialCodes :string;
         NewMaterialCodeRequired:boolean; 
         NewVersionRequired :boolean;
         NewMaterialCodeRequiredis:string; 
         NewVersionRequiredis :string;
         BottleSizeorBlisterConfiguration:string;
         Disposition :string;
         Description :string;
         Status :string;
         type:number;
}
export class UserPrivilege {
  role: string;
  roleID: string;
  privilege: string;
  moduleOrder: string;
  privilegeID: string;
  accessType: string;
  moduleID: string;
  levelID: string;
  templateTabID: string;
}

export class SubModulePrivilege{
  moduleId : number;
  privilegeId : number;
  parentId : number;
  moduleName : string;
  accessType : string;
  moduleActions : ActionPrivilege[]=[];
}

export class ActionPrivilege{
  moduleId : number;
  privilegeId : number;
  moduleName : string;
  actionName : string;
  accessType : string;
}


export class dropDownOptions {
  ID: string;
  Name: string;
}




export class AuditTrailsDTOData {
  AHID: number;
  KeyID: number;
  TableEnum: number;
  actionType: string;
  eventDate: string;
  modifiedBy: string;
  moduleName: string;
  MDID: number;
  date:Date;
  MDName: string;
  taskName:string;
  subprocessName:string;
  oldValue: string;
  newValue: string;
  changes: string;
  version:string;
}
export class UserSettings {
  Id: number;
  PwdExpiryDays: number;
  PwdFailedAttempts: number;
  PwdHistoryLimit: number;
  EsignPwdExpiryDays: number;
  MaxEsignPwdFailedAttempts: number;
  EsignPwdhistoryLimit: number;
  PwdMinCharacters: number;
  EmailNotiDays: number;
  ReqSpecialCharacters: boolean;
  ReqUppercaseCharacters: boolean;
  ReqNumericCharacters: boolean;
  ReqLowercaseCharacters: boolean;
  CreatedBy: number;
  CreatedDate: Date;
  ModifiedBy: number;
  ModifiedDate: Date;
}

