import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-workflow-tasks-node',
  templateUrl: './dynamic-workflow-tasks-node.component.html',
  styleUrls: ['./dynamic-workflow-tasks-node.component.css']
})
export class DynamicWorkflowTasksNodeComponent implements OnInit {


  @Input() node;
  @Input() treelist;
  selectedNodeID: string;
  nodeId: string;
  ticks = 0;
  public hideChildrenList;
  mouseDownX: string;
  mouseDownY: string;

  currentlyHoveringId: string;

  constructor() {
    // let timer1 = timer(0, 100);
    // timer1.subscribe(t => this.ticks = t);
    // this.treelistsvc.hideChilrenToggler$.subscribe((status) => {
    //   this.hideChildrenList = treelistsvc.hideChildrenOfIds;
    // });
    // this.treelistsvc.hoverNodeToggler$.subscribe((status) => {
    //   this.currentlyHoveringId = treelistsvc.currentlyHoveringId;
    // });
    this.hideChildrenList = [];
  }


  ngOnInit() {
  }

  shouldShowPopup(id) {
    return this.currentlyHoveringId === id;
  }
  
  hideChilren(id) {
    //this.treelistsvc.hideNodeChilren(id);
  }
  accordianFilteredRecords:any;
  GetAccordianData(dataItem: any): any {
    this.treelist.forEach(a => a.ToogleAccordian = 0);
    this.accordianFilteredRecords = this.treelist.filter(a => a.AccordianID == dataItem.IsAccordian);
    return this.accordianFilteredRecords;
  }
  accordianShowHide(dataItem, index) {
    if (dataItem.ToogleAccordian == 0) {
      dataItem.ToogleAccordian = 1;
    } else {
      dataItem.ToogleAccordian = 0;

    }
    // this.SelectedDataItem = dataItem.IsAccordian;
    //this.loadTemplate();

  }
  mouseDown(nodeID: string, selectedNode, event) {
    // console.log('mouse down: ' + this.ticks);
    if (event.target.id !== 'expandbutton') {
      this.mouseDownX = event.clientX;
      this.mouseDownY = event.clientY;
    }
  }

  mouseUp(nodeID: string, selectedNode, event) {
    // if (event.target.id !== 'expandbutton' && event.target.id !== 'addbutton') {
    //   if (this.mouseDownX === event.clientX && this.mouseDownY === event.clientY) {
    //     //this.sidenav.setFocusNode(nodeID, selectedNode);
    //   } else if (selectedNode !== this.sidenav.getFocusNode()) {
    //     //this.sidenav.setFocusNode(nodeID, selectedNode);
    //   }
    // }
  }

  getSelectedNodeID(dataItem:any) {
    //return this.sidenav.getFocusNodeId();
  }

  mouseEnter(id, event) {
    //console.log('enter');
    //this.treelistsvc.setCurrentlyHoveringId(id);
  }

  mouseLeave(id, event) {
    //console.log('leaving');
    //this.treelistsvc.setCurrentlyHoveringId('');
  }

  nodeClicked(nodeID: string, selectedNode, event) {
    alert(nodeID);
    // this.sidenav.setFocusNode(nodeID, selectedNode);
    // console.log(this.ticks);
  }

  shouldHideChildren(id) {
    //return this.treelistsvc.hideChildrenOfIds.includes(id);
  }

  addChild(id) {
    // console.log('add child to ' + id);
    //this.treelistsvc.addChild(id);
  }
  getColumnClassName(dataItem): string {
    var className = this.treelist.filter(a => a.ID == dataItem.ID)[0].WorkFlowStatus;
    return className.replace(/\s/g, "");
  }

}

