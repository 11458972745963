import { Component, OnInit } from '@angular/core';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from '../../../services/adminusermanagement.service';
import { Router } from '@angular/router';
import { UserFormData, CountriesDetails, AduitForm, AuditDashboardForm, PDfExpAuditSave, UserGroupsDetails} from '../../../services/AMFormsDataDetails';
import { NotificationAlertsService } from '../../../services/notification.service';
import { getBaseUrl } from '../../../../main';
import { GetAuditAllFieldName } from '../../../services/adminusermanagement.service';
import { DateConvertions } from '../../../services/adminusermanagement.service';
import { ModuleService } from '../../../services/module.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { GlobalVeriables } from '../../../Global/GlobalVaribales'
import { ActivatedRoute } from '@angular/router';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { LanguageService } from '../../../services/language.service';
import { debuglog } from 'util';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { CompositeFilterDescriptor, filterBy, process } from "@progress/kendo-data-query";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import {
  Workbook,
  WorkbookSheetColumn,
  WorkbookSheetRow,
  WorkbookSheetRowCell,
  WorkbookSheet,
} from "@progress/kendo-angular-excel-export";
import { saveAs } from "@progress/kendo-file-saver";
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css'],
  providers: [GetAuditAllFieldName, NotificationAlertsService, DateConvertions]
})
export class UsersListComponent implements OnInit {

  OperationType: number = 1;
  UserID: number = 0;
  CompanyID: number = 0;
  ResponseData: UserFormData[] = [];
  IsExportExcel: boolean = false;
  IsExportpdf: boolean = false;
  EnableSearchOption: boolean = false;
  columns: any;
  countryID: boolean = false;
  UsersId: boolean = true;
  UserCountriesdata: CountriesDetails[] = [];
  LockData = new LockOrunck();
  btnAddUser: boolean = true;
  btndisabledUSer: boolean = true;
  btnActive: boolean = false;
  btnActions: boolean = true;
  AuditResponseData: AuditDashboardForm[]=[];
  AuditLogId: boolean = false;
  Audituserdata: AduitForm[] = [];
  htmlToAdd: any;
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  btnEditAction: boolean = true;
  btnDeleteAction: boolean = true;
  AuditexcelBtn: boolean = false;
  showLoader: boolean = false;
  public opened = false;
  conformMessage: string;
  conformtype: number;
  conformMessageTitle: string;
  ObjAuditExpPdf = new PDfExpAuditSave();
  progress: number;
  message: string;
  FileName: string;
  UsersType: string;
  btnDisbleAction: boolean = true;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  CountriesLoad: boolean = false;
  roleidget: number;
  groupsDialogpopID: boolean = false;
  groupsLoader: boolean = false;
  GroupsHiddenData: UserGroupsDetails[] = [];
  EnableFilter: boolean = false;
  submitted = false;
  public filter: CompositeFilterDescriptor;
  filteredRecords: any;
  gridData: any;
  timeStamp : Date = new Date();
  excelRecords:any;
  excelColumns:any;
  Name:string;
  columnNamesUser:any=["User Name","Email","First Name","Department","Role","Time Zone","Domain","Groups","Countries"];
  IsDisable: boolean = false;
  //email
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  offSet: string = "+05:30";

  constructor(private router: Router, private dataservice: AdminUserManagementService, private commonfeaturesservice: CommonFeaturesService,
    private notifications: NotificationAlertsService, private DisplayAllFields: GetAuditAllFieldName, private DateTimeService: DateConvertions, private GV: GlobalVeriables, private av_router: ActivatedRoute, private languageService: LanguageService, private formbuilder: FormBuilder)
  {
    this.allData = this.allData.bind(this);
  }

  ngOnInit() {
    this.roleidget = this.av_router.snapshot.params["roleid"];
    this.GV.UserId = +localStorage.getItem("userId");
    this.CompanyID= +localStorage.getItem("usercompanyID");
    this.loadUsersDashBoard(this.OperationType, this.UserID, this.CompanyID);
    this.UsersType = "Active Users";
  }

  UserLockForm = this.formbuilder.group({
    comments: ['', [Validators.required]]
  });

  EnableDisableFilter() {
    if (this.EnableFilter)
      this.EnableFilter = false;
    else
      this.EnableFilter = true;
  }

  loadUsersDashBoard(OperationType: number, UserID: number, CompanyID: number) {
    // showLoader :true
    this.dataservice.getUMGetUsers(OperationType, UserID, CompanyID).subscribe(data => {
      this.ResponseData = data;
      this.gridData = this.ResponseData;
      this.IsExportExcel = true;
      this.IsExportpdf = true;
      this.btnEditAction = true;
      this.btnDeleteAction = true;
      this.btnDisbleAction = true;

      if (data.length != 0) 
        this.EnableSearchOption = true;

      if (this.gridData.length == 0) 
        this.IsDisable = true;
       else
         this.IsDisable = false;
    });
  }

  public closeDialog() {
    this.UserLockForm.controls['comments'].setValue('');
    this.UserLockForm.controls['comments'].setErrors({ required: false });
    this.submitted = false;
    this.opened = false;
  }

  public openDialog(userid, type) {
    this.UserLockForm.controls['comments'].setValue('');
    this.UserLockForm.controls['comments'].setErrors({ required: false });
    this.submitted = false;

    if (type == 2) {
      this.conformtype = 2;
      this.conformMessageTitle = "User Disable";
      this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "UDC", this.CompanyID).subscribe((dataUActionsMessages) => {
        this.conformMessage = dataUActionsMessages[0].displayMessage;//"Are you sure you want to Unlock this User?";
      });
    }
    else if (type == 1) {
      this.conformtype = 1;
      this.conformMessageTitle = "User Lock";
      this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "LUC",this.CompanyID).subscribe((dataUActionsMessages) => {
        this.conformMessage = dataUActionsMessages[0].displayMessage;//"Are you sure you want to Unlock this User?";
      });
      this.LockData.Userid = userid;
      this.LockData.LockID = "1";
    }
    else if (type == 0) {
      this.conformtype = 0;
      this.conformMessageTitle = "User Unlock";
      this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "UUC", this.CompanyID).subscribe((dataUActionsMessages) => {
        this.conformMessage = dataUActionsMessages[0].displayMessage;//"Are you sure you want to Unlock this User?";
      });
      this.LockData.Userid = userid;
      this.LockData.LockID = "0";
    }
    this.UserID = 0;
    this.opened = true;
    this.UserID = userid;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.ResponseData, filter);
    if (this.filteredRecords.length == 0) {
     this.gridData = this.filteredRecords;
     this.IsDisable = true;
    }
    else{
      this.gridData = this.filteredRecords;
      this.IsDisable = false;
    }
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {
      }).data,
    };
    return result;
  }

  lockOrUnlockUser() {

    if (this.UserLockForm.invalid && this.conformtype!=0) {
      this.submitted = true;
      return;
    }
    this.showLoader = true;
    let currDate = new Date();
    let addTimeZoneOffSet = currDate.getTimezoneOffset();
    let Offset = -(addTimeZoneOffSet);
    //negative offset for method
    let hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);
    if (hours > 0)
      hours = hours;
    let minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);
    let offset = '+' + hours + ':' + minutes;
    this.LockData.DateTimeOffset = offset;
    this.LockData.comments=this.UserLockForm.controls['comments'].value;
    
      this.dataservice.lockOrUnlockUser(this.LockData).subscribe((data) => {
        this.opened = false;

        if (data == "1") {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "ULS", this.CompanyID).subscribe((dataLActionsMessages) => {
            this.notifications.show(this.languageService.GetActionMessageByLanguage(dataLActionsMessages[0].aliasKey, dataLActionsMessages[0].displayMessage), dataLActionsMessages[0].messageType);
            this.dataservice.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForUserLockOrUnlock, 1, 0, 0, this.UserID, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
          });
        }
        else if (data == "0")
        {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "UULS", this.CompanyID).subscribe((dataUActionsMessages) => {
            this.notifications.show(this.languageService.GetActionMessageByLanguage(dataUActionsMessages[0].aliasKey, dataUActionsMessages[0].displayMessage), dataUActionsMessages[0].messageType);
            this.dataservice.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForUserLockOrUnlock, 0, 0, 0, this.UserID, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
          });
        }
        else
          this.notifications.show("Something went wrong", 4)
        this.UserLockForm.controls['comments'].setValue('');
        this.UserLockForm.controls['comments'].setErrors({ required: false });
        this.loadUsersDashBoard(1, this.UserID, this.CompanyID);
        this.showLoader = false;
      });
  }

  DeleteUserData() {

    this.dataservice.getUserExists(this.UserID, this.CompanyID).subscribe((data) => {

      if (data == true) {
        this.notifications.show(this.languageService.GetActionMessageByLanguage('AMSG_DUEAM', 'This user is already associated with one of record(s).You can\'t delete this user.Please assign all the work assigned to this user to other user.' ),4);
        this.opened = false;
      }
      else {
        this.dataservice.DeleteUser(this.UserID, this.GV.UserId).subscribe((data) => {
          this.opened = false;
          if (data == "1") {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "UDS", this.CompanyID).subscribe((dataDActionsMessages) => {
              this.notifications.show(this.languageService.GetActionMessageByLanguage(dataDActionsMessages[0].aliasKey, dataDActionsMessages[0].displayMessage), dataDActionsMessages[0].messageType);
            });
          }
          else if (data == "2")
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "CDUAT", this.CompanyID).subscribe((dataCDActionsMessages) => {
              this.notifications.show(this.languageService.GetActionMessageByLanguage(dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
            });
          this.dataservice.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoDisabledUser, 1, 0, 0, this.UserID, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
          this.loadUsersDashBoard(this.OperationType, this.UserID, this.CompanyID);
        }, error => {
          this.notifications.show(error.statusText, 4);
        }
        );
      }
    });
  }

  DisabledUsers() {
    this.showLoader = true;

    this.dataservice.getDisabledUsers(3, this.UserID, this.CompanyID).subscribe(data => {
      this.ResponseData = data;
      this.gridData = this.ResponseData;
      this.btnAddUser = false;
      this.btnActive = true;
      this.btndisabledUSer = false;
      this.btnActions = true;
      this.btnDisbleAction = false;
      this.IsExportExcel = true;
      this.IsExportpdf = true;
      this.btnEditAction = false;
      this.btnDeleteAction = false;
      this.UsersType = "Disabled Users";

      if (data.length != 0) 
        this.EnableSearchOption = true;
      this.showLoader = false;
    });
  }

  CountryData(userid: any) {
    var ib = document.getElementById('rejectReasonsInfoModal');
    this.countryID = true;
    this.CountriesLoad = true;

    this.dataservice.getCountriesData(4, userid, this.CompanyID).subscribe(data => {
      this.UsersId = true;
      this.UserCountriesdata = data;
      this.IsExportExcel = true;
      this.IsExportpdf = true;
      
      if (data.length != 0) 
        this.EnableSearchOption = true;
      this.CountriesLoad = false;
    });
  }

  GroupsData(userid:any)
  {
    this.groupsDialogpopID = true;
    this.groupsLoader = true;

    this.dataservice.getUMUserGroups(7, userid, this.CompanyID,'').subscribe(data => {
      this.UsersId = true;
      this.GroupsHiddenData = data;
      this.IsExportExcel = true;
      this.IsExportpdf = true;

      if (data.length != 0) 
        this.EnableSearchOption = true;
      this.groupsLoader = false;
    });
  }

  close() {
    this.countryID = false;
    this.UsersId = true;
    this.AuditLogId = false;
    this.btnEditAction = false;
    this.btnDeleteAction = false;
    this.router.navigate(['/UsersData']);
    this.btnDisbleAction = false;
  }

  countriesClose() {
    this.countryID = false;
  }

  auditclose() {
    this.countryID = false;
    this.UsersId = true;
    this.AuditLogId = false;
    if (this.btnActive) {
      this.btnEditAction = false;
      this.btnDeleteAction = false;
      this.btnDisbleAction = false;
    }
    else {
      this.btnEditAction = true;
      this.btnDeleteAction = true;
      this.btnDisbleAction = true;
    }
    this.router.navigate(['/UsersData']);
  }

  GroupsClose() {
    this.groupsDialogpopID = false;
  }

  ActiveUsers() {
    this.countryID = false;
    this.UsersId = true;
    this.btnAddUser = true;
    this.btnActive = false;
    this.btndisabledUSer = true;
    this.btnActions = true;
    this.OperationType = 1;
    this.btnDisbleAction = true;
    this.UsersType = "Active Users";
    this.loadUsersDashBoard(this.OperationType, this.UserID, this.CompanyID);
  }

  AuditLogData(keyID: any, TablenumID: any,  CompanyID: any)
  {
    this.AuditLogId = true;
    this.IsAuditExportExcel = true;

    this.dataservice.AuditLogData(keyID, TablenumID, this.GV.UserId, CompanyID).subscribe((data) => {
      this.divHisdata = true;
      let AuditDisplay = "";

      if (data.length > 0) {
        this.AuditexcelBtn = true;
        
        for (let i of data) {
          let seraizedata = JSON.parse(i["changes"]);
          AuditDisplay += "<div class=''>";
          AuditDisplay += "<table class='auditclass'>";
            AuditDisplay += "<tr class='auditclass margin-top-10' style='background: rgba(245, 247, 250, 0.5) !important;'>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>User: <b class='newtext-color'>" + i.userId + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Event date:<b class='newtext-color'> " + this.DateTimeService.convertdate(i.eventDate) + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Action type:<b class='newtext-color'> " + i.actionType + "</b></td>";
            AuditDisplay = AuditDisplay + "</tr>"
          AuditDisplay = AuditDisplay + "<tr class='auditclass'><td><b>Field name</b></td><td><b>Before change</b></td><td><b>After change</b></td></tr>";

          if (seraizedata.length > 0) {
            for (let j of seraizedata) {
                AuditDisplay = AuditDisplay + "<tr class='auditclass'>";
              AuditDisplay = AuditDisplay + "<td>" + this.DisplayAllFields.GetFieldName(j.FieldName,23) + "</div>";
              AuditDisplay = AuditDisplay + "<td>" + (j.ValueBefore == "(null)" ? "" : j.ValueBefore) + "</div>";
              AuditDisplay = AuditDisplay + "<td style='width:33%!important;'>" + (j.ValueAfter == "(null)" ? "" : j.ValueAfter) + "</div>";
                AuditDisplay = AuditDisplay + "</tr>";
              }
            }
            else 
            AuditDisplay = AuditDisplay + "<tr class='newtext-color'><td></td><td class='center'>No updates to show!</td><td></td></tr>";

          AuditDisplay = AuditDisplay + "</table><br/>";
          AuditDisplay = AuditDisplay + "</div>";
          }
        }
      else { 
        AuditDisplay = AuditDisplay + "No audit data for this record.";
        this.AuditexcelBtn = false;
        }
      this.htmlToAdd = AuditDisplay;
    });
  }

  ExportAuditToPdfFile (htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;;
    this.ObjAuditExpPdf.TableEnum = '23';
    this.showLoader = true;

    this.dataservice.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      this.showLoader = false;
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }

  DownLoadExportPdfFile(Filepath: string, FileName: string) {
    this.FileName = FileName;
    this.dataservice.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
      }
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText,4 );
      }
    );
  }

  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

   checkUserPrivilege(actionEventID, LevelID) {
     return this.dataservice.GetPrivilegeAccesType(actionEventID, LevelID);
  }
  onExcelExport()
    {
      this.excelRecords =  this.gridData
      let rows = [];
      //Application Name
      let headerRow = new HeaderRowClass();
      let headerRowcells: HeaderCellClass[] = [];
    let headerCell = new HeaderCellClass();
      headerCell.value = "Application Name"
      headerCell.bold = false;
    headerRowcells.push(headerCell);

    let headerCell1 = new HeaderCellClass();
      headerCell1.value = "Artwork360"
      headerCell1.bold = true;
      headerRowcells.push(headerCell1);
      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Module Name
    let headerRow1 = new HeaderRowClass();
    let headerRowcells1: HeaderCellClass[] = [];

    let headerCell2 = new HeaderCellClass();
      headerCell2.value = "Module Name"
      headerCell2.bold = false;
      headerRowcells1.push(headerCell2);

    let headerCell3 = new HeaderCellClass();
      headerCell3.value = "Admin Settings>>Users>>"+this.UsersType;
      headerCell3.bold = true;
      headerRowcells1.push(headerCell3);
      headerRow1.cells = headerRowcells1;
      rows.push(headerRow1);

      ///Downloaded by
    let headerRow2 = new HeaderRowClass();
    let headerRowcells2: HeaderCellClass[] = [];

    let headerCell4 = new HeaderCellClass();
      headerCell4.value ="Downloaded By" ;
      headerCell4.bold = false;
      headerRowcells2.push(headerCell4);

    let headerCell5 = new HeaderCellClass();
      headerCell5.value = this.GV.UserName;
      headerCell5.bold = true;
      headerRowcells2.push(headerCell5);
      headerRow2.cells = headerRowcells2;
      rows.push(headerRow2);

      //Downloaded Date
    let headerRow3 = new HeaderRowClass();
    let headerRowcells3: HeaderCellClass[] = [];

    let headerCell6 = new HeaderCellClass();
      headerCell6.value = "Downloaded Date";
      headerCell6.bold = false;
      headerRowcells3.push(headerCell6);

    let headerCell7 = new HeaderCellClass();
    let CurrentDate = new Date();
      headerCell7.value = CurrentDate.toString();;
      headerCell7.bold = true;
      headerRowcells3.push(headerCell7);
      headerRow3.cells = headerRowcells3;
      rows.push(headerRow3);

      //Binding Columns
    let headerRow4 = new HeaderRowClass();
    let headerRowcells4: HeaderCellClass[] = [];

      this.columnNamesUser.forEach(element => {
        let headerCell8 = new HeaderCellClass();
        headerCell8.value = element
        headerCell8.bold = true;
        headerRowcells4.push(headerCell8);
        headerCell8.background = "#227447"
        headerCell8.color = "#ffffff"
      });

      headerRow4.cells = headerRowcells4;
      rows.push(headerRow4);

      //Binding Grid Data
    let headerRow5 = new HeaderRowClass();
    let headerRowcells5: HeaderCellClass[] = [];

     for(let i=0;i<=this.excelRecords.length-1;i++)
     {
       let headerRow6 = new HeaderRowClass();
       let headerRowcells6: HeaderCellClass[] = [];

       let headerCell9 = new HeaderCellClass();
          headerCell9.value = this.excelRecords[i].username;
          headerCell9.bold = false;
          headerRowcells6.push(headerCell9);
          headerCell9.background = ""
          headerCell9.color = ""

       let headerCell10 = new HeaderCellClass();
          headerCell10.value = this.excelRecords[i].emailID;
          headerCell10.bold = false;
          headerRowcells6.push(headerCell10);
          headerCell10.background = ""
          headerCell10.color = ""

       let headerCell11 = new HeaderCellClass();
          headerCell11.value = this.excelRecords[i].firstName;
          headerCell11.bold = false;
          headerRowcells6.push(headerCell11);
          headerCell11.background = ""
          headerCell11.color = ""

       let headerCell12 = new HeaderCellClass();
          headerCell12.value = this.excelRecords[i].department;
          headerCell12.bold = false;
          headerRowcells6.push(headerCell12);
          headerCell12.background = ""
          headerCell12.color = ""

       let headerCell13 = new HeaderCellClass();
          headerCell13.value = this.excelRecords[i].groupname;
          headerCell13.bold = false;
          headerRowcells6.push(headerCell13);
          headerCell13.background = ""
          headerCell13.color = ""

       let headerCell14 = new HeaderCellClass();
          headerCell14.value = this.excelRecords[i].timeZone;
          headerCell14.bold = false;
          headerRowcells6.push(headerCell14);
          headerCell14.background = ""
          headerCell14.color = ""

       let headerCell15 = new HeaderCellClass();
          headerCell15.value = this.excelRecords[i].domain;
          headerCell15.bold = false;
          headerRowcells6.push(headerCell15);
          headerCell15.background = ""
          headerCell15.color = ""

       let headerCell16 = new HeaderCellClass();
          headerCell16.value = this.excelRecords[i].noOfGroupsCount;
          headerCell16.bold = false;
          headerRowcells6.push(headerCell16);
          headerCell16.background = ""
          headerCell16.color = ""

       let headerCell17 = new HeaderCellClass();
          headerCell17.value = this.excelRecords[i].countries;
          headerCell17.bold = false;
          headerRowcells6.push(headerCell17);
          headerCell17.background = ""
          headerCell17.color = ""
           headerRow6.cells = headerRowcells6;
           rows.push(headerRow6);
     }
      let excelName=this.UsersType;
      const workbook = new Workbook({
        sheets: <WorkbookSheet[]>[
          {
            // Column settings (width)
            columns: [
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },  
            ],
            
            // Title of the sheet            
            name:  excelName,
            // Rows of the sheet
            rows:rows   
          },
        ],
      });
      workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName+".xlsx"));
    }
  }

export class GridViewData {
  public pageSize: number = 10;
  public skipPage: number = 0;
  public viewData: any = {};
  public filter: any = null;
}

export class HeaderCellClass {
  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class HeaderRowClass {
  cells: any;
}

export class LockOrunck {
  Userid: string;
  LockID: string;
  DateTimeOffset: string;
  comments: string;
}




