import { Component, OnInit } from '@angular/core';
import { ModuleService } from '../services/module.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {  ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {


  modules: any;
  showMyContainer: boolean = true;
  masterModuleID: any;
  moduleID: any;

  constructor(private moduleService: ModuleService, private http: HttpClient, private router: Router, private _avRoute: ActivatedRoute) {

 

  


  }

  ngOnInit() {

     

    this.moduleService.masterModuleID.subscribe(masterID => {

    
      if (masterID == "0" || masterID == null ) {
        this.masterModuleID = 0;

      } else {
        this.masterModuleID = masterID;

      }

      this.moduleService.getModules(this.masterModuleID).subscribe(data => {
        
        this.modules = data;

        

      })
    });
   
    
    const $button  = document.querySelector('#sidebar-toggle');
    const $wrapper = document.querySelector('#wrapper');
    
    $button.addEventListener('click', (e) => {
      e.preventDefault();
      $wrapper.classList.toggle('toggled');
    });

  }

 




  filterSubModules(module) {
    
    return this.modules.filter(x => x.parentId == module);

  }

  onModuleClick(module) {



    this.moduleID = module.id;

   // this.moduleService.passModuleID(this.moduleID);

    this.router.navigate(['Project']);



  }
}
export class AppComponent {
  name = 'Angular Toggle Show Hide';
  

}
