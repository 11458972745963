import { HttpClient } from '@angular/common/http';
import { Output, EventEmitter, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { DialogService } from '@progress/kendo-angular-dialog';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FileInfo, FileRestrictions, RemoveEvent, SelectEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { getBaseUrl } from '../../../main';
import { GlobalVeriables } from '../../Global/GlobalVaribales';
import { templateData } from '../../interfaces/ItemplateFields';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from '../../services/adminusermanagement.service';
import { UserFormData } from '../../services/AMFormsDataDetails';
import { CommonFeaturesService } from '../../services/commonfeatures.service';
import { ModuleService } from '../../services/module.service';
import { NotificationAlertsService } from '../../services/notification.service';
import { DynamicFieldControlService } from '../dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../dynamic-fields-service/dynamic-field.service';
import { DynamicFormGroupBase } from '../dynamic-fields/dynamic-field-base';
import { DynamicTemplateData } from '../dynamic-fields/dynamic-field-list/dynamic-field-list.component';

@Component({
  selector: 'app-dynamic-control-view',
  templateUrl: './dynamic-control-view.component.html',
  styleUrls: ['./dynamic-control-view.component.css']
})
export class DynamicControlViewComponent implements OnInit {
  @Input() public SelectedProjectID: any;
  @Input() PassedmoduleID: number = 0;
  @Input() targetModuleID: number = 0;
  @Input() targetTemplateID: number = 0;
  @Input() inputFormFields: string = "";
  @Output() cancelEventreference = new EventEmitter<boolean>();
  @ViewChild('autocomplete', { static:false })
  public autocomplete: AutoCompleteComponent; 
  //tagitems: Array<string>;
  taggeditems: any[];
  ResponseData: UserFormData[] = [];
  data: string;
  displayViewID: any;
  windowState = 'default';
  resizeWindow = false;
  showArtWorkWindow = false;
  showPopUp: boolean = false;
  display = 'none';
  setProjectOwnerdisplay = 'none';
  FieldStatusobj = new FieldStatusDTO();
  actionTypeTemplateID: any;
  templateID: any = null;
 
  selectedTemplateID: any;
  RecordID: any;
  displayTemplateID: any;
  //templates: any;
  myFiles: Array<FileInfo> = [];
  allTemplates: any;
  controltemplates: any;
  controltemplatesforMenu: any;
  controlActionNotForMenuItems: any;
  controltemplatesforMenuCount: number = 4;//For showing number of action outside of menu
  displaytemplates: any;
  displaytemplatesControlIDs: any;
  displayFiltertemplatesControlIDs: any;
  IsdisplaytemplatesControlID: boolean = false;
  selectedGroupDisplayControlID: number = 0;
  template: any;
  subTemplates: any;
  templateParentID: number = 0;
  IsDisplayControls: boolean = false;
  IsDisplayView: boolean = false;
  IsDisplaySubFormView: boolean = false;
  IsDisplayNestedSubFormView: boolean = false;
  uploadedFiles = [];
  selectedItems = [];
  invalidSubmitAttempt: boolean = false;
  DynamicFields: DynamicFormGroupBase<any>;
  dynamicForm: FormGroup;
  DynamicFormFieldsActions: any = [];
  SelectedRecord: number = 0;
  tempSelectedRecord: number = 0;
  viewRecords: any;
  viewRelProjects: any;
  documentsPath: string;
  IsfromProject: boolean = false;
  DeleteRelProjectConfrim: boolean = false;
  bodyText: string;
  templateData = new DynamicTemplateData();
  tempData = new templateData()
  projectid: number = 0
  relatedProjectId: number = 0;
  submitted = false;
  offset: string = "+05:30";
  public proids = [];
  public listItems: Array<string>;
  public value = 0;
  public running: any;
  actionEvent: string;
  dynHeadingName: string;
  IpAddres:string;
  ActionEventID: number;
  workflowTasksActionID: number;
  CompanyID: number = 0;
  moduleID: number = 0;
  setSubProcessdisplay: boolean = true;

  public fileInfo: Array<FileInfo> = [];
  public FileType: number = 0;
  Dyntopiclistoption: any = [];
  dynamicFieldsTopic: any;

  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();

  dialogForm: FormGroup;
  dateFormats: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy'
  };
  showLoader: boolean = false;
  dynTemplateHeader: string = null;
  uploadSaveUrl = getBaseUrl() + 'api/dynamic/SaveReferenceDocument?loginUserId='+this.GV.UserId;
  //uploadRemoveUrl = getBaseUrl() + 'api/dynamic/RemoveDocument';
  uploadRemoveUrl = getBaseUrl() + 'api/dynamic/DynACUR?loginUserId=' + this.GV.UserId;
  dynPopUpHeading: string = null;
  public languageDataDefault:any;

  //bypassUnbypassOpen = 'none';


  dependencyTemplateSubscription: Subscription = new Subscription();
  CheckIsParentTemplateSubscription: Subscription = new Subscription();
  CheckHasDependentsSubscription: Subscription = new Subscription();
  templateRecordSubscription: Subscription = new Subscription();
  selectedReferenceFileIds:any;
  bypassUnbypassOpen = 'none';
  AuditHistory = 'none'

  myRestrictions: FileRestrictions = {
    allowedExtensions: [".PDF",".DOC",".DOCX",".XLS",".XLSX",".CSV",".TXT",".JPG",".JPEG",".PNG",".ZIP"]
  };

  chatDetailInformation: any = []
  mentionConfig: any;
  constructor(private moduleService: ModuleService, private http: HttpClient, public service: DynamicFieldService,
    private qcs: DynamicFieldControlService,
    private DFS: DynamicFieldService,
    private _router: Router
    , private notifyService: NotificationAlertsService,
    private amService: AdminUserManagementService,
    private cdr: ChangeDetectorRef, private fb: FormBuilder, private GV: GlobalVeriables,
    private dialogService: DialogService, private commonfeaturesservice: CommonFeaturesService,
    private dataservice: AdminUserManagementService, private languageService : LanguageService
  ) { }
  ngOnInit() {
    
    this.dynamicFieldsTopic = null;
    this.CompanyID= +localStorage.getItem("usercompanyID");
    this.languageDataDefault=this.languageService.dataByLanguage;
    //var templatedata = new templateData()
    //templatedata.moduleID = this.PassedmoduleID;
    //this.moduleService.GetTemplatesByModuleID(templatedata).subscribe(data => {

    //  this.displaytemplates = data.filter(a => a.hasParent == 0 && a.isDependent == 0 && a.displayControlsID != 0);
    //});
    this.dataservice.getUMGetUsers(10, this.GV.UserId, 0).subscribe(data => {
      this.ResponseData = data;
    });
    this.moduleService.ReferenceFileIds.subscribe(data => {
      this.selectedReferenceFileIds=data;
    })
    this.GV.UserName = localStorage.getItem("userName");
    this.GV.UserRoleID = parseInt(localStorage.getItem("userRoleID"));

    this.displayViewID = 0;
    this.templateParentID = 0;

      this.IpAddres = this.GV.UserIpAddress;
   
 
    this.loadTemplates();
    this.dialogForm = this.fb.group({
      ddlValue: [],
    });
    
    
  }

  ngOnDestroy() {
    this.CheckHasDependentsSubscription.unsubscribe();
  }
  controlActionForMenuItems: any = []
  loadTemplates() {
    this.onDisplayForm();

  }

  public removeEventHandler(e: RemoveEvent, fieldName: any): void {
    //console.log(e);
    if (e.files.length > 0) {
      let index = this.uploadedFiles[fieldName].indexOf(e.files[0].name);
      if (index != -1) {
        this.moduleService.removeUploadFiles(this.uploadedFiles[fieldName][index]).subscribe(data => {
        });
        this.uploadedFiles[fieldName].splice(index, 1);
      }
    }
  }

  public errorEventHandler(e) {
    //console.log(e);
    return false;
  }

  public selectEventHandler(e: SelectEvent): void {
    const that = this;
    e.files.forEach((file) => {
      //console.log(`File selected: ${file.name}`);
      if (!file.validationErrors) {
        //this.currentFileUpload = file;
      }
    });

  }

  uploadEventHandler(e: UploadEvent, fieldName: any) {
    this.showLoader = true;
    if (this.DFS.checkUndefinedNullEmpty(this.uploadedFiles[fieldName])) {
      this.uploadedFiles[fieldName] = [];
    }
    let files: any;
    files = e.files;
    if (files.length === 0) {
      return;
    }
    this.uploadedFiles[fieldName].push(files[0].name);
  }

  getFileRestrictions(objUpload: any) {
    this.myRestrictions.allowedExtensions = [];
    var DefaultType = ".pdf";
    if (objUpload.validationRuleRegExp == null || objUpload.validationRuleRegExp == undefined) {
      this.myRestrictions.allowedExtensions.push(DefaultType);
    } else {
      var fileTypes = objUpload.validationRuleRegExp.split(',');
      fileTypes.forEach((file) => {
        if (file != null && file != undefined && file != "") {
          this.myRestrictions.allowedExtensions.push(file);
        }
      });

    }
    return this.myRestrictions;
  }

  public completeEventHandler() {
    setTimeout(function () {
      var items = document.getElementsByClassName("k-text-error");
      for (var i = 0; i < items.length; i++) {
        document.getElementsByClassName("k-text-error")[i]["innerText"] = "Please upload a file without annotiations";
      }
    }, 500);

    this.showLoader = false;
  }
  //public completeEventHandler() {

  //  setTimeout(function () {
  //    var items = document.getElementsByClassName("k-text-error");
  //    //if (items.length > 0) {
  //    //  this.notifyService.show("One of the uploaded file have annotiations!", 4);
  //    //}
  //    //for (var i = 0; i < items.length; i++) {
  //    //  document.getElementsByClassName("k-text-error")[i]["innerText"] = "Please uploaded a file without annotiations";

  //    //  //this.notifyService.show("One of the uploaded file have annotiations!", 4);
  //    //}
  //  }, 500);

  //  this.showLoader = false;
  //}

  getDisplaycontrolRecords() {
    this.showLoader = true;
    this.templateParentID = 0;

    //this.moduleService.GetTemplatesByModuleID(this.moduleID, this.templateParentID).subscribe(data => {
    //  this.IsDisplayControls = true;
    //  this.showLoader = false;
    //  this.templates = data;

    //});
  }
  isrelatedProjectshow: boolean = false;
  DeleteConfrimopened: boolean = false;
 
  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }

  removeFile(file) {


    this.fileInfo = this.fileInfo.filter(a => a.name != file.name);

    this.GV.SetFileInfo(this.fileInfo);
    this.myFiles = this.fileInfo;

  }

  CloseCustomUpload() {



    this.PassedmoduleID = 0;
    this.setSubProcessdisplay = false;

    this.FileType = this.GV.FileType;

    this.fileInfo = this.GV.GetFileInfo();

    this.SelectedRecord = this.GV.GetProjectID();

    if (this.tempSelectedRecord != 0) {


      this.showPopUp = false;
      let selectedItem = new templateSelectedRecord();
      selectedItem.templateId = "15";
      selectedItem.selectedRecordId = this.SelectedRecord;
      selectedItem.highlightRecordID = this.SelectedRecord;
      selectedItem.moduleID = "1";
      selectedItem.dependentRecordID = "0";



      //check  has dependents
      this.CheckHasDependentsSubscription = this.moduleService.CheckHasDependents(selectedItem.moduleID, selectedItem.templateId).subscribe(dataDependent => {



        if (dataDependent.isDependent != null && dataDependent.isDependent != "0") {


          // this.IsDisplayControls = false;


          this.IsDisplayControls = false;
          var displaytemplates = this.displaytemplates.filter(a => a.moduleID == this.moduleID);
          this.displaytemplates = null;



          setTimeout(() => {
            this.SelectedRecord = selectedItem.selectedRecordId;


            var templateUpdate = displaytemplates.filter(a => a.templateID == dataDependent.templateID && a.moduleID == this.moduleID);
            var index = displaytemplates.indexOf(templateUpdate[0]);
            if (index != -1) {

              displaytemplates.splice(index, 1);
              dataDependent.recordID = selectedItem.selectedRecordId;
              dataDependent.selectedRecordID = selectedItem.selectedRecordId;
              dataDependent.selectedRecordIDs = selectedItem.selectedRecordIds;
              displaytemplates.push(dataDependent);



              displaytemplates.filter(a => a.templateID == selectedItem.templateId && a.moduleID == this.moduleID)[0].highlightRecordID = selectedItem.highlightRecordID;
              var displayControlsID = dataDependent.displayControlsID.split(",");
              if (displayControlsID != null && displayControlsID.length > 1) {




              }

              this.displaytemplates = displaytemplates;
              this.IsDisplayControls = true;

            }
          }, 100);

        }
      });

    }
  }

  DeleteConfrim() {
    //var ans = confirm("Do you want to delete this record?");
    //if (ans) {
    this.DeleteConfrimopened = false;
    this.showLoader = true;
    this.moduleService.DeleteDisplayFieldRecord(this.SelectedRecord,this.GV.UserId).subscribe(data => {
      this.IsDisplayControls = true;
      this.showLoader = false;
      this.loadTemplates();
      this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 1, "CDUAT", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
      });
      //this.notifyService.show("Project deleted successfully.", 1);
    });

    this.SelectedRecord = 0;
    // } 
    // else {
    //   this.IsDisplayControls = true;
    //   this.showLoader = false;

    // }
  }
  closeDialog() {
    this.DeleteConfrimopened = false;
    //this.IsDisplayControls = true;
    //this.showLoader = false;
  }

  getEditID(templateID, ID, inputFormFields, selectedTemplateID) {

    this.templateData.IsfromProject = true;
    this.templateData.IsfromAssigneUsers = false;
    this.templateID = templateID;
    this.RecordID = ID;
    this.inputFormFields = inputFormFields;
    this.selectedTemplateID = selectedTemplateID;
    this.onDisplayForm();
  }

  CloseSubDisplayForm() {
    this.templateParentID = 0;

    this.moduleService.passparentRecordID(0);


    //this.IsDisplaySubFormView = false;
  }
  closeModalDialog() {
    this.display = 'none'; //set none css after close dialog
  }
  closesetProjrectModalDialog() {
    this.setProjectOwnerdisplay = 'none'; //set none css after close dialog
  }
  onDisplayForm() {
   

    this.showLoader = true;
    this.uploadedFiles = [];
      this.RecordID = 0,
    this.templateID = this.targetTemplateID;
    this.selectedTemplateID = this.targetTemplateID;
    this.service.getDynamicFormFields(this.templateID, this.RecordID, 0, false, this.inputFormFields, this.selectedTemplateID, false).subscribe(response => {
      this.IsDisplayControls = false;
      this.dynTemplateHeader =  response.dynTemplateHeader;
      // this.dynTemplateHeader = response.dynTemplateHeader;
      this.DynamicFields = response.dynamicFormFieldList;
      this.languageService.TranslateDefaultItem(response.dynamicFormFieldList,this.languageDataDefault);
      this.DynamicFormFieldsActions = response.displayActions;
      this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
      this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[0].key].setValue(this.SelectedProjectID);
      if (localStorage.getItem("notificationSubProcessId")) 
        this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[1].key].setValue(Number(localStorage.getItem("notificationSubProcessId")));
      if(this.templateID!=61)
        this.handleParentTemplateChange(this.SelectedProjectID, this.DynamicFields[0].dynamicFields[0]);
      //for (var j = 0; j < response.dynamicFormFieldList.length; j++) {
      //  for (var i = 0; i < response.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
      //    if (response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 9) {
      //      this.myFiles = [];
      //      let uploadItems: any = [];
      //      this.uploadedFiles[response.dynamicFormFieldList[j]["dynamicFields"][i].key] = [];
      //      uploadItems = response.dynamicFormFieldList[j]["dynamicFields"][i].value;
      //      for (let a = 0; a < uploadItems.length; a++) {
      //        let obj: any = {};
      //        obj.name = uploadItems[a].fileName;
      //        obj.size = parseInt(uploadItems[a].fileSize);
      //        obj.extension = uploadItems[a].fileType;
      //        this.uploadedFiles[response.dynamicFormFieldList[j]["dynamicFields"][i].key].push(uploadItems[a].fileName);
      //        this.myFiles.push(obj);
      //      }

      //      this.fileInfo = this.myFiles;
      //    }
      //  }
      //}
      this.showLoader = false;
    });
  }

  DisplayChatInformation() {
    //this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDAE", this.CompanyID).subscribe((dataMEActionsMessages) => {
    //});
    //this.commonfeaturesservice.GetAllRequestFormGroups().subscribe(data => {
    //  this.allRqstFormGroups = data;
    //})
  }
  divLast(i) {
    var objDiv = document.getElementById("divChatMsgs");
    objDiv.scrollTop = objDiv.scrollHeight;
  }
  FromAction(action: any) {
    this.actionEvent = action.actionEvent;
    if (action.actionEvent == "Submit" || this.actionEvent == "btnSendChatMeassage") {
     this.onSubmit();
    }

    else if(this.actionEvent == "btnUsersSubmit")
    {
      if(this.selectedReferenceFileIds.length>0){
        this.templateData.DynFormFieldData=JSON.stringify(this.dynamicForm.value);
        this.templateData.selectedTakIds=this.selectedReferenceFileIds.join(",");
         this.DFS.saveReassigneUserData(this.templateData).subscribe(responce => {
           this.showLoader = false;
           if(responce=="success"){
           this.notifyService.show("Tasks Reassigned successfully",1);
           this.moduleService.passReferenceFileIds([]);
           this.cancelEventreference.emit(true);
           }
         })
        }
    }
    else {
      this.cancelEventreference.emit(false);
    }
  };
 

  onSubmit() {
    let uploadFields = Object.keys(this.uploadedFiles);
    if (uploadFields.length > 0) {
      for (var i = 0; i < uploadFields.length; i++) {
        this.dynamicForm.value[uploadFields[i]] = this.uploadedFiles[uploadFields[i]];
      }
    }
    if (this.dynamicForm.invalid) {
      this.invalidSubmitAttempt = true;
      return;
    }
    this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
    this.templateData.ID = parseInt(this.RecordID);
    this.templateData.TemplateID = parseInt(this.templateID);
    this.templateData.IsfromProject = true;
    this.templateData.InputFormFields = this.inputFormFields;
    this.templateData.UserID = this.GV.UserId
    this.showLoader = true;
    if (this.actionEvent == "btnSendChatMeassage") {
      let ids = this.dynamicForm.value.SendMessage.replace(/(\r\n|\n|\r)/gm, " ").split(" ").map(x => x.replace("@", "")).filter(t => t != "" && this.ResponseData.findIndex(u => u.username == t.trim()) > -1).map(name => this.ResponseData.find(s => s.username == name.trim()).userid)
      //console.log(ids);
      let currentTopic = this.dynamicForm.value.Topic;
      if (this.IpAddres == undefined) {
        this.GV.UserIpAddress = (this.moduleService.getIPAddress());
        this.IpAddres = this.GV.UserIpAddress;
      }
      if (ids.length > 0) {
        this.commonfeaturesservice.SaveChatDetails("WF", this.dynamicForm.value.Projects, this.dynamicForm.value.SubProcess, this.dynamicForm.value.Topic == undefined ? "" : this.dynamicForm.value.Topic, this.dynamicForm.value.SendMessage, 0, this.GV.UserId, this.IpAddres, this.dynamicForm.value.Priority).subscribe((dataresult) => {
          var chatlist = dataresult.Table;
          this.chatDetailInformation = chatlist;
          var LChatID = chatlist[Object.keys(chatlist).pop()].ID;
          //var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.key == "SendMessage");
          //this.DynamicFields[0].dynamicFields[index].value = "";
          this.dynamicForm.controls["SendMessage"].setValue("");
          this.showLoader = false;
          //if (ids.length > 0) {
          //console.log(ids);
          this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationChatMessage, 1, this.dynamicForm.value.Projects, this.dynamicForm.value.SubProcess, LChatID, 0, 1, 0, encodeURIComponent(this.offset)).subscribe();
          //}
          this.invalidSubmitAttempt = false;
          //this.handleParentTemplateChange(0, this.dynamicFieldsTopic);
          this.SelecteditenChange(currentTopic, this.dynamicFieldsTopic);
        });
      } else {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_ChatMesgErr', "Please enter valid details (User Name, Chat Message)."), 4);
      }
    }
    else if(this.actionEvent == "Submit") {
     this.templateData.ID = parseInt(this.SelectedProjectID);
     this.templateData.Ip=this.IpAddres;
     
      this.DFS.saveDynamicReferenceFilesData(this.templateData).subscribe(responce => {
        this.showLoader = false;
        if(responce=="success"){
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_RFAS',"Reference file added successfully.") ,1);
        this.cancelEventreference.emit(true);
        }
        else
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_RFAA', responce +" File(s) are already added") ,4);
      })
     }
     else if(this.actionEvent == "Assign") {
       
      }
     else
     {
       this.cancelEventreference.emit(false);
     }
    //else {

    //  this.templateData.UploadFileType = this.GV.GetFileType();
    //  this.DFS.saveDynamicFormFieldsdata(this.templateData).subscribe(responce => {
        
    //    this.showLoader = false;
    //    //this.loadTemplates();


    //    this.cancel();
    //  })
    //}


  }
  checkUserPrivilege(actionEventID, LevelID) {
    return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);

  }
  cancel() {

    this.IsDisplayControls = true;
    this.uploadedFiles = [];

    //this.loadTemplates();
    //this.asyncloadTemplates();

  }
  
  close() {
    /*this.showPopUp = false;*/
    this.IsDisplayControls = true;

    this.display = 'block';

  }

  closerel() {
    this.showPopUp = false;
  }
  handleFilter(value, dynamicField: any) {
    var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.key == dynamicField.key);
    if (dynamicField.fieldId == 2244) {
      this.Dyntopiclistoption=this.DynamicFields[0].dynamicFields[index].options;
    }
    this.DynamicFields[0].dynamicFields[index].options = this.Dyntopiclistoption;
    if (value.length >= 3) {
      // var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.key == dynamicField.key);
      //this.DynamicFields[0].dynamicFields[index].options = Dynoption;
      this.DynamicFields[0].dynamicFields[index].options = this.DynamicFields[0].dynamicFields[index].options.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }
    else if (value == "" || value == " ") {
      // var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.key == dynamicField.key);
      //this.DynamicFields[0].dynamicFields[index].options = Dynoption;
      this.DynamicFields[0].dynamicFields[index].options = this.Dyntopiclistoption;
      //this.DynamicFields[0].dynamicFields[index].options.filter((s => s));
    }
    else {
      // var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.key == dynamicField.key);
      this.DynamicFields[0].dynamicFields[index].options = this.Dyntopiclistoption;
      this.autocomplete.toggle(false);
    }
  }
  
  SelecteditenChange(value, dynamicField: any) {
    try {
      if (this.dynamicForm.value.SubProcess != undefined) {
        this.moduleService.UpdateSourceDataProjectSubProcessandUserId(Number(this.dynamicForm.value.Projects), Number(this.dynamicForm.value.SubProcess), this.GV.UserId, this.dynamicForm.value.Topic, Number(this.dynamicForm.value.Priority)).subscribe(data => { });
      }
      this.showLoader = true;
      if (this.dynamicForm.value.SubProcess == undefined)
        this.dynamicForm.value.SubProcess = 0;
      if (this.dynamicForm.value.Priority == undefined || this.dynamicForm.value.Priority == "" || this.dynamicForm.value.Priority == null)
        this.dynamicForm.value.Priority = 0;
      if (this.dynamicForm.value.Topic == undefined)
        this.dynamicForm.value.Topic = '';
      this.commonfeaturesservice.GetChatDetails("WF", this.dynamicForm.value.Projects, this.dynamicForm.value.SubProcess, this.dynamicForm.value.Topic, this.GV.UserId, this.dynamicForm.value.Priority).subscribe((dataresult) => {
        var chatlist = dataresult.Table;
        this.chatDetailInformation = chatlist;
        //var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.key == "SendMessage");
        //this.DynamicFields[0].dynamicFields[index].value = "";
        this.showLoader = false;
      });
    }
    catch{
      this.showLoader = false;
    }
  }

  handleParentTemplateChange(selectedTempID: any, dynamicField: any) {
    if (dynamicField.key == "SubProcess") {
      this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[2].key].setValue(null);
      this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[3].key].setValue('');
    }
    if (dynamicField.key == "Priority") {
      this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[3].key].setValue('');
    }
    //this.showLoader = true;
    if (selectedTempID == null) {
      this.SelecteditenChange(selectedTempID, dynamicField);
    }
    this.dynamicFieldsTopic = dynamicField;
    this.Dyntopiclistoption = [];
    if (this.templateID == 56) {
      var dependentdynamicField = this.DynamicFields[0].dynamicFields.find(p => p.dependentFieldId == dynamicField.fieldId);
        var Params: any = [];
      if (dependentdynamicField != null) {
        var sqlprocedure = dependentdynamicField.sqlSource;
        var ParamsIds = JSON.parse(dependentdynamicField.cascadeFieldIds);
        for (let i = 0; i < ParamsIds.length; i++) {  
          var pvalue = this.dynamicForm.controls[(this.DynamicFields[0].dynamicFields.find(p => p.fieldId == ParamsIds[i])).key].value;
          Params.push({ key: "param" + [i], value: pvalue});
        }
        var jresult = JSON.stringify(Params);
        this.moduleService.GetSourceData("WF", sqlprocedure, jresult, this.GV.UserId).subscribe(data => {
          var dsubprocess = data.Table;
          if (dynamicField.key == "Projects") {
            var subprocess = (data.Table).filter(function (d) {
              return d.IsProject == 2;
            });

            var Dynoption: any = [];
            for (let i = 0; i < subprocess.length; i++) {  //How to properly iterate here!!
              Dynoption.push({ key: subprocess[i].Name, value: subprocess[i].ID })
            }

            var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.dependentFieldId == dynamicField.fieldId);
            this.DynamicFields[0].dynamicFields[index].options = Dynoption;
            this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[index].key].setValue(undefined);
            this.chatDetailInformation = [];
            if (localStorage.getItem("notificationSubProcessId") == '' || localStorage.getItem("notificationSubProcessId") == undefined || localStorage.getItem("notificationSubProcessId") ==null)
            this.moduleService.UpdateSourceDataProjectSubProcessandUserId(Number(this.SelectedProjectID), Number(selectedTempID), this.GV.UserId, '', 0).subscribe(data => { });
          }
          if (dynamicField.key == "SubProcess") {
            var topiclist = data.Table;
            
            for (let i = 0; i < topiclist.length; i++) {  //How to properly iterate here!!
              this.Dyntopiclistoption.push(topiclist[i].Topic);
            }

            var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.dependentFieldId == dynamicField.fieldId);
            this.DynamicFields[0].dynamicFields[index].options = this.Dyntopiclistoption;
            this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[index].key].setValue(undefined);

            //this.DynamicFields[0].dynamicFields[index].setValue("");
            var chatlist = data.Table1;
            this.chatDetailInformation = chatlist;
            this.moduleService.UpdateSourceDataProjectSubProcessandUserId(Number(this.SelectedProjectID), Number(selectedTempID), this.GV.UserId,'',0).subscribe(data => {  });
          }
          if (localStorage.getItem("notificationSubProcessId")) {
            this.selectSubprocess();
            localStorage.removeItem('notificationSubProcessId');
          } else 
            this.SelecteditenChange(selectedTempID, dynamicField);
        });
      }

      //this.moduleService.GetProjectandSubprocess(selectedTempID.toString()).subscribe(data => {
      //  var d = data;
      //  var subprocess = data.filter(function (d) {
      //    return d.isProject == 2;
      //  });
      //  var Dynoption: any = [];
      //  for (let i = 0; i < subprocess.length; i++) {  //How to properly iterate here!!
      //    Dynoption.push({ key: subprocess[i].name , value: subprocess[i].id })
      //  }
        
      //  var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.dependentFieldId == dynamicField.fieldId);
      //  this.DynamicFields[0].dynamicFields[index].options = Dynoption;
      //});

      //this.dynamicForm.controls[dynamicField.key].value;
    }
    else {
      this.service.getDynamicFormFields(this.templateID, this.RecordID, selectedTempID, false, this.inputFormFields, "", false).subscribe(response => {
        //this.dynTemplateHeader = response.dynTemplateHeader;
        this.DynamicFields = response.dynamicFormFieldList;
        this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
        this.showLoader = false;

      });
      
    }
    if (dynamicField.key == "Priority") {
      this.SelecteditenChange(selectedTempID, dynamicField);
    }
    //var e = (document.getElementById("Projects")) as HTMLSelectElement;
    //this.cdr.detectChanges();
    //var CurValue = (<HTMLSelectElement>opt).value;
    //var CurText = (<HTMLSelectElement>opt).text;
  
  }

  selectSubprocess() {
    let subprocessId = localStorage.getItem("notificationSubProcessId");
    let projectId = localStorage.getItem("notificationProjectId");
    let subProcessName = localStorage.getItem("notificationSubProcessName");
    let priority = localStorage.getItem("notificationPriority");
    let topic = localStorage.getItem("notificationTopic");
    this.dynamicForm.value.SubProcess = Number(subprocessId);
    this.dynamicForm.value.Topic = topic;
    this.dynamicForm.value.Priority = priority;
    localStorage.removeItem('notificationSubProcessId');
    localStorage.removeItem('notificationProjectId');
    localStorage.removeItem('notificationSubProcessName');
    localStorage.removeItem('notificationTopic');
    localStorage.removeItem('notificationPriority');
    let selDynamicField = this.DynamicFields[0].dynamicFields[1];
    localStorage.removeItem('notificationSubProcessId');
    this.SelecteditenChange(topic, selDynamicField);
    this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[1].key].setValue(Number(subprocessId));
    this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[3].key].setValue(topic);
    this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[2].key].setValue(priority);
  }

  IsMandatoryStatuschanged(filedId: number) {
    this.FieldStatusobj.FieldId = filedId;
    var ans = confirm("Do you want to change the mandatory status for this field?");
    if (ans) {
      this.moduleService.FieldMandatoryStatuschanged(this.FieldStatusobj).subscribe(data => {
        if (data) {
          this.notifyService.show("Mandatory status has been changed successfully ", 1);
          this.invalidSubmitAttempt = false;
          this.onDisplayForm();
        }
      })
    }
  }
  handleParentdatepickerChange(value: any, dynamicField: any) {

    if (value != null) {
      this.showLoader = true;
      Array.prototype.forEach.call(this.DynamicFields, DynamicFieldGroup => {
        Array.prototype.forEach.call(DynamicFieldGroup.dynamicFields, DynamicField => {
          if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == dynamicField.fieldId) {
            //console.log(this.dynamicForm);
            let day = value.getDate();
            let month = value.getMonth();
            let year = value.getFullYear();
            DynamicField.minimumDate = new Date(year, month, day);
            DynamicField.miximumDate = new Date(year + 10, month, day);
            if (this.dynamicForm.controls[DynamicField.key].value < DynamicField.minimumDate || this.dynamicForm.controls[DynamicField.key].value > DynamicField.miximumDate) {
              this.dynamicForm.controls[DynamicField.key].setValue(null);
            }
          }
          else if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == null) {
            let date = new Date();
            let day = date.getDate();
            let month = date.getMonth();
            let year = date.getFullYear();
            DynamicField.minimumDate = new Date(year - 10, month, day);
            DynamicField.miximumDate = new Date(year + 10, month, day);
            if (this.dynamicForm.controls[DynamicField.key].value < DynamicField.minimumDate || this.dynamicForm.controls[DynamicField.key].value > DynamicField.miximumDate) {
              this.dynamicForm.controls[DynamicField.key].setValue(null);
              this.dynamicForm.controls["CompleteProjectByDate"].setValue(null);
            }
            //let Fieldvalue = this.dynamicForm.controls[DynamicField.key].value;
            //console.log(Fieldvalue);
            //DynamicField.dateValidationMesssge=undefined;
            //if(Fieldvalue !=null && Fieldvalue !="" && Fieldvalue !=undefined )
            //{
            // let valuefiled= new Date(Fieldvalue);
            // if((valuefiled>=DynamicField.minimumDate) && (DynamicField.miximumDate>=valuefiled))
            //   {
            //    DynamicField.dateValidationMesssge="Invalid Date";
            //   } 
            //}
          }
        });
      });
      this.showLoader = false;

    }
  }
  filevalidation(event: boolean) {
    this.dynamicForm.controls["DisplayName"].errors.required = false;
  }
  closerelDialog() {
    /**/
  }

  DeleteRelProj() {
    /**/
  }
  public deleteRelatedProjectbyProjectId(projId: number, relProjId: number) {
    this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
      if (data != 473 && data != 474) {
        var ans = confirm("Are you sure you want to delete this related project?");
        if (ans) {
          let obj: any = {}
          obj.projectid = projId;
          obj.relatedProjectId = relProjId;
          this.moduleService.deleteRelatedProjectByProjectId(obj).subscribe(data => {
            // this.getRelatedProjectByProjectId(projId);
          })

          this.moduleService.DisplayView(this.SelectedRecord).subscribe(data => {
            this.viewRecords = data;
            this.display = 'block';
            this.showLoader = false;
            this.documentsPath = this.moduleService.getBaseUrl();
             
          })
        }
      }
      else {
        this.notifyService.show("Project was completed or Cancelled. you can't add/edit related projects .", 4);
      }
    })
  }

  public OpenWindow() {
    this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
      if (data != 473 && data != 474) {
        this.showPopUp = true;
         
      }
      else {
        this.notifyService.show("Project was completed or Cancelled. you can't delete related projects .", 4);
      }
    })
  }

  public CancelRel() {
    this.showPopUp = false;
  }

  public startProgress() {
    this.running = setInterval(() => {
      if (this.value <= 100) {
        this.value++;
      } else {
        this.stopProgress();
      }
    }, 50);
  }

  public stopProgress() {
    if (this.running) {
      clearInterval(this.running);
      this.running = null;
    }
  }

  public resetProgress() {
    this.value = 0;
    this.stopProgress();
  }
  

  
}
export class FieldStatusDTO {
  FieldId: number;
  Status: string;
}

export class templateSelectedRecord {

  moduleID: string = "0";
  templateId: string = "0";
  selectedRecordId: number = 0;
  selectedRecordIds: any = [];
  dependentRecordID: string = "0";
  highlightRecordID: number = 0;
  recordType: number = 0;
}
//class DynamicTemplateData {
//  ID: number;
//  DynFormFieldData: string;
//  TemplateID: number;
//}
export class DynOptions {
  key: string;
  value: string;
}
export class ChatDetailInformation {
  TOPIC_ID: number;
  PARENT_ID: number;
  COMMENT_ORDER: number;
  COMMENTS: string;
  USERNAME: string;
  CREATEDDATE: string;
  STATUS: string;
  Reasonforchange: boolean;
}

