import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoleDetails, UserFormData, DepartmentDetails, UserGroupsDetails, CountriesDetails } from '../../../services/AMFormsDataDetails';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  // UserDyForm: FormGroup;
  OperationType: number = 1;
  UserID: number = 0;
  CompanyID: number = 0;
  UserDialogbx: boolean;
  CountriesData: CountriesDetails[] = [];
  departmentsData: DepartmentDetails[] = [];
  RolesData: RoleDetails[] = [];
  GroupsData: UserGroupsDetails[] = [];
  userid: any ;
  constructor(private router: Router, private av_router: ActivatedRoute,private formbuilder:FormBuilder, private dataservice: AdminUserManagementService) { }
  UserDyForm = this.formbuilder.group({
    userName: ['', [Validators.required]],
    Email: ['', [Validators.email]],
    FirstName: ['', [Validators.required]],
    /*LastName: ['', [Validators.required]],*/
    Domain: ['', [Validators.required]],
    Groups: ['', [Validators.required]],
    Roles: ['', [Validators.required]],
    department: ['', [Validators.required]],
    /*GroupOwner: ['', [Validators.required]],*/
  });

  ngOnInit() {
    this.userid = this.av_router.snapshot.paramMap.get('id');
    this.CompanyID= +localStorage.getItem("usercompanyID");

    alert(this.userid);
    this.UserDialogbx = true;
    this.UserCountries();
    this.UserDepartments();
   /* this.UserRoles();*/
   // this.UserGroups();
    this.EditUser(this.userid);
  }

  //Getting Countries data//
  UserCountries() {
    debugger;
    this.dataservice.getUMGetCountries().subscribe(response => {
      //console.log("getUMGetCountries", response);
      this.CountriesData = response;
    });
  }

  //Getting Roles data//
  //UserRoles() {
  //  /* debugger;*/
  //  this.dataservice.getUMGetRoles().subscribe(response => {
  //    //console.log("getUMGetRoles", response);
  //    this.RolesData = response;
  //  });
  //}

  //Getting Departmenst data//
  UserDepartments() {
    /*debugger;*/
    this.dataservice.getUserDepartments().subscribe(response => {
      //console.log("getUserDepartments", response);
      this.departmentsData = response;
    });
  }

  //Getting Groups data//
  //UserGroups() {
  //  /*debugger;*/
  //  this.dataservice.getUserGroups().subscribe(response => {
  //    //console.log("getUserGroups", response);
  //    this.GroupsData = response;
  //  });
  //}

  EditUser(userid:number)
  {
    debugger;
    this.UserDialogbx = true;
    //this.dataservice.getUMGetUsers(this.UserID, this.OperationType, this.CompanyID).subscribe(data => {
    //  //userid: number, OperationType: number, CompanyID: number
      
    //  this.UserDyForm.controls['userName'].setValue(data.username);
    //  this.UserDyForm.controls['Email'].setValue(data.emailID);
    //  this.UserDyForm.controls['FirstName'].setValue(data.firstName);
    //  this.UserDyForm.controls['LastName'].setValue(data.lastname);
    //  this.UserDyForm.controls['Domain'].setValue(data.domain);
    //  //this.UserDyForm.controls['ddlmscountryName'].setValue();
    //  //this.UsersDt.departement = this.UserDyForm.controls['DepartmentId'].value;
    //  //this.UserDyForm.controls['Roles'].setValue();
    ////this.UsersDt.groupname = this.UserDyForm.controls['GropusId'].value;

    //  });
  }

  close() {
    this.UserDialogbx = false;
    this.router.navigate(['/UsersData']);
  }



  cancelUserData() {
    this.UserDyForm.reset();
    this.UserDialogbx = false;
    this.router.navigate(['/UsersData']);
  }
  onKeypressEvent(event: any){
    if (event.which === 32 && !event.target.value)
    event.preventDefault();
 }
}
