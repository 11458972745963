import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationAlertsService } from '../../../services/notification.service';
import { ModulesData, TemplatesByModuleData } from '../../../services/BPM/workflowtasksDetails';
import { WorkflowTasksManagementService } from '../../../services/BPM/workflowtasksmanagement.service';
import { parse } from 'path';

@Component({
  selector: 'app-modules-list',
  templateUrl: './modules-list.component.html',
  styleUrls: ['./modules-list.component.css']
})
export class ModulesListComponent implements OnInit {

  ResponseModulesData: ModulesData[] = [];
  ResponseTemplatesByModuleData: TemplatesByModuleData[] = [];
  
 
  moduleID: number = 0;
  moduleName: string;

  constructor(private http: HttpClient, private av_router: ActivatedRoute, private router: Router, private dataservice: WorkflowTasksManagementService
    , private notifications: NotificationAlertsService) {


  }

  ngOnInit() {
    this.loadWorkFlowData(1);
  }


  loadWorkFlowData(CompanyID: number) {
    // showLoader :true
    this.dataservice.GetAllModules(CompanyID).subscribe(data => {
      //console.log("data", data);
      this.ResponseModulesData = data;
      this.moduleName = this.ResponseModulesData[0].moduleName;
      this.moduleID = parseInt(data[0].moduleID);//dataItem.dataItem.id;
      this.dataservice.GetAllTemplatesByModule(1, this.moduleID).subscribe(data => {
        //console.log("data", data);
        this.ResponseTemplatesByModuleData = data;
        //this.WorkFlowName = data[0].WorkflowName;
      });
      //this.handleSelection(this.ResponseModulesData[0]);
    });
  }


  public handleSelection(dataItem): void {

    //this.WorkflowID = dataItem.dataItem.id;

    this.moduleID = dataItem.dataItem.moduleID;
    this.moduleName = dataItem.dataItem.moduleName;
    
    this.dataservice.GetAllTemplatesByModule(1, this.moduleID).subscribe(data => {
      //console.log("data", data);
      debugger;
      this.ResponseTemplatesByModuleData = data;
    });
  }
}
