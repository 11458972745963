import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../ArtworkManagement/dynamic-fields/mustmatch.validator';

import { DynamicFieldBase, DynamicFormGroupBase } from '../dynamic-fields/dynamic-field-base';

@Injectable({
  providedIn: 'root'
})

export class DynamicFieldControlService {
  constructor() { }
  value: any;


  validationRuleArray: any = [];
  toFormGroup(DynamicFormGroups: any) {

    const group: any = {};
    let fromGroupValue = DynamicFormGroups;
    let dyn: any = {};
    let validators: any = [];
    DynamicFormGroups.forEach(DynamicFormGroup => {
      DynamicFormGroup.dynamicFields.forEach(DynamicField => {
        if (DynamicField.controlTypeID == 1) {
          if (DynamicField.value == null || DynamicField.value == "") {
            this.value = null;
          } else {
            this.value = DynamicField.value;
          }
          this.validationRuleArray = [];

          if (DynamicField.required)
            this.validationRuleArray.push(Validators.required);
          if (DynamicField.maximunLength != null && DynamicField.maximunLength != 0 && DynamicField.maximunLength != undefined)
            this.validationRuleArray.push(Validators.maxLength(DynamicField.maximunLength));
          if (DynamicField.minimumLength != null && DynamicField.minimumLength != 0 && DynamicField.maximunLength != undefined)
            this.validationRuleArray.push(Validators.minLength(DynamicField.minimumLength));
          if (DynamicField.validationRule != null && DynamicField.validationRule != 0 && DynamicField.validationRule != undefined)
            this.validationRuleArray.push(Validators.pattern(DynamicField.validationRuleRegExp));
            
            group[DynamicField.key] = this.validationRuleArray.length > 0 ? new FormControl(this.value, this.validationRuleArray)
            : new FormControl(this.value);
          
         if (DynamicField.isDisplayField == true && DynamicField.dependentFieldId > 0) {
          if (DynamicField.dependentFieldId != null && DynamicField.dependentFieldId != 0 && DynamicField.dependentFieldId != undefined) {
            for (var i = 0; i < fromGroupValue.length; i++) {
              for (var j = 0; j < fromGroupValue[i].dynamicFields.length; j++) {
                if (fromGroupValue[i].dynamicFields[j].fieldId === DynamicField.dependentFieldId) {
                  validators.push(MustMatch(DynamicField.key, fromGroupValue[i].dynamicFields[j].key,fromGroupValue[i].dynamicFields[j].required, 1));
                  return false;
                }
              }
            }

          }
        }
        }
        else if (DynamicField.controlTypeID == 4) {
          if (DynamicField.value == null || DynamicField.value == "") {
            this.value = null;
          } else {
            this.value = DynamicField.value.includes("[") ? JSON.parse(DynamicField.value) : DynamicField.value;
          }
          group[DynamicField.key] = DynamicField.required ? new FormControl(this.value || '', Validators.required)
            : new FormControl(this.value || '');
        }
        else if (DynamicField.controlTypeID == 5) {

          if (DynamicField.value == null || DynamicField.value == "") {
            this.value = null;
          } else {
            this.value = new Date(DynamicField.value);
          }

          if (DynamicField.minimumDate != "" && DynamicField.minimumDate != null) {
            DynamicField.minimumDate = new Date(DynamicField.minimumDate);

          }

          if (DynamicField.miximumDate != "" && DynamicField.miximumDate != null) {
            DynamicField.miximumDate = new Date(DynamicField.miximumDate);

          }
          group[DynamicField.key] = DynamicField.required ? new FormControl(this.value || '', Validators.required)
            : new FormControl(this.value || '');
        }
        else if (DynamicField.controlTypeID == 2){
          if (DynamicField.value == null || DynamicField.value == "") {
            this.value = null;
          } else {
            this.value = DynamicField.value;
          }
          //this.validationRuleArray.push(Validators.required);
          group[DynamicField.key] = DynamicField.required ? new FormControl(DynamicField.value || '', Validators.required)
            : new FormControl(DynamicField.value || '');
        if( DynamicField.isDisplayField == true && DynamicField.dependentFieldId > 0) {
          if (DynamicField.dependentFieldId != null && DynamicField.dependentFieldId != 0 && DynamicField.dependentFieldId != undefined) {
            for (var i = 0; i < fromGroupValue.length; i++) {
              for (var j = 0; j < fromGroupValue[i].dynamicFields.length; j++) {
                if (fromGroupValue[i].dynamicFields[j].fieldId === DynamicField.dependentFieldId ) {
                   validators.push(MustMatch(DynamicField.key, fromGroupValue[i].dynamicFields[j].key,fromGroupValue[i].dynamicFields[j].required, 2));
                   return false;
                }
              }
            }

          }
        }
       }

        else {

          group[DynamicField.key] = (DynamicField.required &&(DynamicField.controlTypeID !=12)) ? new FormControl(DynamicField.value || '', Validators.required)
            : new FormControl(DynamicField.value || '');
        }

      });
    });
    let fBuilder = new FormBuilder();
    if (validators && validators.length > 0) {
      return fBuilder.group(group, { validator: validators });
    } else {
      return fBuilder.group(group);
    }
  }



}
