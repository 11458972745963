import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { RoleDetails, AduitForm, AuditDashboardForm, PDfExpAuditSave, Privilige, PriviligeDetails, AuditTrailsDTOData } from '../../../services/AMFormsDataDetails';
import { Router } from '@angular/router';
import { getBaseUrl } from '../../../../main';
import { GetAuditAllFieldName } from '../../../services/adminusermanagement.service';
import { ModuleService } from '../../../services/module.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { DateConvertions } from '../../../services/adminusermanagement.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicFormComponent } from '../../dynamic-forms/dynamic-form.component';
import { InputRule } from '@progress/kendo-angular-editor';
import { breadCrumbs } from '../../../interfaces/ItemplateFields';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from "@progress/kendo-data-query";
import { LanguageService } from 'src/app/services/language.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import {
  Workbook,
  WorkbookSheetColumn,
  WorkbookSheetRow,
  WorkbookSheetRowCell,
  WorkbookSheet,
} from "@progress/kendo-angular-excel-export";
import { saveAs } from "@progress/kendo-file-saver";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-dynamic-field-auditlog',
  templateUrl: './dynamic-field-auditlog.component.html',
  styleUrls: ['./dynamic-field-auditlog.component.css'],
  providers: [GetAuditAllFieldName, NotificationAlertsService, DateConvertions]
})

export class DynamicFieldAuditlogComponent implements OnInit {
  @Input() public template: any;
  @Input() public templateID: any;
  @Input() public fromTemplateMode:any
  @Output() closeAudithistorymodel = new EventEmitter();
  public ID: any;
  public RecordID: any;
  isFromDialog:boolean=false;
  isFromTab:boolean=false;
  PriviligesData: any = [];
  RolesData: RoleDetails[] = [];
  ArtworkReviewURLNavigation: any;
  PrivilegeBaseRole: PriviligeDetails[] = [];
  OperationType: number = 1;
  roleId: number = 0;
  btnAddRole: boolean = true;
  breadcrumbs: Array<breadCrumbs>;
  activ:string;
  btndisabledRole: boolean = true;
  btnActive: boolean = false;
  btnActions: boolean = true;
  IsDisable:boolean=false;
  AuditResponseData: AuditDashboardForm[] = [];
  AuditLogId: boolean = false;
  Audituserdata: AduitForm[] = [];
  dataSaving: any;
  ProjectDetails:any;
  public listItems: Array<string> = [
    "Custom values Changed",
    "Project Start",
    "Task Status Changes ",
    "Project Created",
    
  ];

  htmlToAdd: any;
  eventlist:Array<string>=[];
  sublist:Array<string>=[];
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  btnEditAction: boolean = true;
  btnDeleteAction: boolean = true;
  IsExportExcel: boolean = true;
  AuditexcelBtn: boolean = false;
  public opened = false;
  public languageDataDefault: any;
  ObjAuditExpPdf = new PDfExpAuditSave();
  progress: number;
  message: string;
  FileName: string;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  showLoader: boolean = false;
  PrivilegeDialogueID: boolean = false;
  PrivilegesLoad: boolean = false;
  ResponseData: AuditTrailsDTOData[] = [];
  Afterfilter: AuditTrailsDTOData[] = [];
  listdata: AuditTrailsDTOData[] = [];
  listdata1: AuditTrailsDTOData[] = [];
  showPopUp: boolean = false;
  test:DynamicFormComponent;
  filteredRecords:any;
  breadcrumb:any;
  EnableFilter: boolean = false;
  columnNames:any=["Date Time","Task", "Activity","User","Description","Workflow Context","IP Address"];
  ISFilters: boolean = false;
  enableClear: boolean = false;
  filter:any;
  excelRecords: any;

  dateFormats: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy'
  };

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  filterform = this.formBuilder.group({
    Activity: ['', Validators.required],
     subprocess: ['', Validators.required],
     from:['', Validators.required],
     to:['', Validators.required],
  })

  constructor(private router: Router, private formBuilder: FormBuilder, private amService: AdminUserManagementService, private notifications: NotificationAlertsService, private DisplayAllFields: GetAuditAllFieldName, private moduleService: ModuleService, private DateTimeService: DateConvertions,private languageService:LanguageService, private GV: GlobalVeriables) {
    this.allData = this.allData.bind(this);
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "startsWith",
  };

  public changeFilterOperator(operator: "startsWith" | "contains"): void {
    this.filterSettings.operator = operator;
  }

  ngOnInit() {
    this.languageDataDefault = this.languageService.dataByLanguage;

    if(this.fromTemplateMode==1)
       this.isFromDialog=true;
       else
       this.isFromTab=true;
    this.showLoader = true;
    this.AuditLogId = true;

    this.amService.AuditHistory(this.templateID, 28, this.GV.UserId, 1, this.TZoffset).subscribe((data) => {
      this.ResponseData = data;
      if (this.ResponseData.length > 0) {
        for (let i = 0; i < this.ResponseData.length; i++) {
          this.ResponseData[i].eventDate = this.DateTimeService.convertdate(this.ResponseData[i].eventDate);
        }
      }
      this.dataSaving=this.ResponseData;
      this.Afterfilter=data;
      this.divHisdata = true;
      let AuditDisplay = "";

      if (data.length > 0) 
        this.AuditexcelBtn = true;
      else {
        AuditDisplay = AuditDisplay + "No audit data for this record.";
        this.AuditexcelBtn = false;
      }
      this.htmlToAdd = AuditDisplay;
    });

    this.moduleService.breadCrumbs.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs.filter(a => a.templateId == this.template.isDependent || a.templateId == this.template.hasParent);//|| (a.templateId == templateID && a.displayControlsID == displayControlsID)
    });

    this.moduleService.breadCrumbName.subscribe(breadcrumb => {
      if (!this.GV.GetIsRedirection())
      if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {
        let displayData = this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID);
        if (displayData != null && displayData != undefined && displayData.length != 0) 
        this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;
      }
    });
    this.showLoader = false;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.ResponseData, {
      }).data,
    };
    return result;
  }

  RolesAuditLogData(keyID: any, TablenumID: any,  CompanyID: any) {
    this.AuditLogId = true;
    this.IsAuditExportExcel = true;
    this.amService.AuditLogData(56, 22, 56, 1).subscribe((data) => {
      this.ResponseData = data;
      this.divHisdata = true;
      let AuditDisplay = "";
      if (data.length > 0) 
        this.AuditexcelBtn = true;
      else {
        AuditDisplay = AuditDisplay + "No audit data for this record.";
        this.AuditexcelBtn = false;
      }
      this.htmlToAdd = AuditDisplay;
    });
  }

  ExportAuditToPdfFile(htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;;
    this.ObjAuditExpPdf.TableEnum = '28';
    this.showLoader = true;
    this.amService.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      this.showLoader = false;
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter=[]
   this.filter=filter;
    this.filteredRecords = filterBy(this.dataSaving, this.filter);
    if (filter.filters.length > 0) {
     this.ResponseData = this.filteredRecords;
     if(this.filteredRecords.length ==0)
     this.IsDisable = true;
    }
    else{
      this.ResponseData = this.dataSaving;
      this.IsDisable = false;
      this.filteredRecords = [];
    }
  }

  EnableDisableFilter() {

    if (this.EnableFilter == true)
      {
        this.filter=[];
        this.ResponseData = this.dataSaving;
        this.EnableFilter = false;
      }
    else
      this.EnableFilter = true;
  }

  Clear()
  {
    this.enableClear = false;
    this.ResponseData=this.Afterfilter;
    this.FromReset();
  }

  GetMessageByLanguage(aliasKey, orgMsg) {
    let tData = this.languageDataDefault.find(x => x.LanguageKey == aliasKey);
    if (tData)
      return tData.Message;
    else
      return orgMsg;
  }

  FromAction()
  {
    try {
      let Activityfilter=this.filterform.controls['Activity'].value;
      let subprocfilter=this.filterform.controls['subprocess'].value;
      let from= this.filterform.controls["from"].value;
      let to=this.filterform.controls["to"].value;
      let fdate1 = new Date(from);
      let todate1=new Date(to);
      if(fdate1>todate1)
      {
        this.notifications.show(this.GetMessageByLanguage('STC_TGTFD', "Please select 'To' date greater than 'From' date"), 4);
        return false;
      }
      if((from!=''&&to=='')||(from==''&&to!='') || from==null || from==undefined)
      {
        this.notifications.show(this.GetMessageByLanguage('STC_PSBFT', "Please select both From date and To date"), 4);
        return false;
      }  
      this.listdata.length = 0;
      
       if(Activityfilter!=''&&subprocfilter!=''&&from!=''&&to!=''&&from!=null&&to!=null)
       {
         this.enableClear = true;
         for (let a in Activityfilter)
        {
           let variable = Activityfilter[a]
           let data=this.Afterfilter.filter(x=>x.actionType==variable);
            data.forEach(element => {
              this.listdata.push(element);
            });
        }
         for (let a in subprocfilter)
          {
           let variable = subprocfilter[a]
           let data=this.listdata.filter(x=>x.subprocessName==variable);
              this.listdata.length=0;
              data.forEach(element => {
                this.listdata.push(element);
              });
          }
        if(from!=''&&to!='')
        {
          let fdate = new Date(from);
          let todate=new Date(to);
          
          let data = this.listdata.filter(x => new Date(new Date(x.eventDate).toLocaleDateString().replace('/', '-')) >= new Date(fdate.toLocaleDateString().replace('/', '-')) && new Date(new Date(x.eventDate).toLocaleDateString().replace('/', '-')) <= new Date(todate.toLocaleDateString().replace('/', '-')));
          this.listdata.length=0;
          data.forEach(element => {
              this.listdata.push(element);
          });
        }
          this.ResponseData=this.listdata;
      }
      else if(Activityfilter!=''&&subprocfilter!=''&&(from==''&&to=='')||(from==null&&to==null))
       {
         this.enableClear = true;
        this.listdata.length=0;
         for (let a in Activityfilter)
        {
           let variable = Activityfilter[a]
           let data=this.Afterfilter.filter(x=>x.actionType==variable);
            data.forEach(element => {
              this.listdata.push(element);
            });
        }
         for (let a in subprocfilter)
        {
           let variable = subprocfilter[a]
           let data=this.listdata.filter(x=>x.subprocessName==variable);
            this.listdata.length=0;
            data.forEach(element => {
              this.listdata.push(element);
            });
        }
        this.ResponseData=this.listdata;
      }
      else if(Activityfilter!=''&&subprocfilter==''&&from!=''&&to!=''&& from!=null&&to!=null)
       {
         this.enableClear = true;
        this.listdata.length=0;
         for (let a in Activityfilter)
        {
           let variable = Activityfilter[a]
           let data=this.Afterfilter.filter(x=>x.actionType==variable);
            data.forEach(element => {
              this.listdata.push(element);
            });
        }
        if(from!=''&&to!='')
        {
          let fdate = new Date(from);
          let todate=new Date(to);
          let data = this.listdata.filter(x => new Date(new Date(x.eventDate).toLocaleDateString().replace('/', '-')) >= new Date(fdate.toLocaleDateString().replace('/', '-')) && new Date(new Date(x.eventDate).toLocaleDateString().replace('/', '-')) <= new Date(todate.toLocaleDateString().replace('/', '-')));
          this.listdata.length=0;
          data.forEach(element => {
              this.listdata.push(element);
          });
        }
          this.ResponseData=this.listdata;
      }
      else if(Activityfilter==''&&subprocfilter!=''&&from!=''&&to!=''&&from!=null&&to!=null)
       {
         this.enableClear = true;
        this.listdata.length=0;
         for (let a in subprocfilter)
        {
            let variable = subprocfilter[a]
           let data=this.Afterfilter.filter(x=>x.subprocessName==variable);
            this.listdata.length=0;
            data.forEach(element => {
              this.listdata.push(element);
            });
        }
        if(from!=''&&to!='')
        {
          let fdate = new Date(from);
          let todate=new Date(to);
          let data = this.listdata.filter(x => new Date(new Date(x.eventDate).toLocaleDateString().replace('/', '-')) >= new Date(fdate.toLocaleDateString().replace('/', '-')) && new Date(new Date(x.eventDate).toLocaleDateString().replace('/', '-')) <= new Date(todate.toLocaleDateString().replace('/', '-')));
          data.forEach(element => {
              this.listdata.push(element);
          });
        }
          this.ResponseData=this.listdata;
      }
        else if(Activityfilter==''&&subprocfilter==''&&(from==''&&to==''&&from!=null&&to!=null))
          this.ResponseData=this.Afterfilter;
        else{
         this.enableClear = true;
         for (let a in Activityfilter)
          {
              let variable = Activityfilter[a]
           let data=this.Afterfilter.filter(x=>x.actionType==variable);
              data.forEach(element => {
                this.listdata.push(element);
              });
          }
      
         for (let a in subprocfilter)
          {
              let variable = subprocfilter[a]
           let data=this.Afterfilter.filter(x=>x.subprocessName==variable);
              data.forEach(element => {
                this.listdata.push(element);
              });
          }
          if(from!=''&&to!='')
          {
            let fdate = new Date(from);
            let todate=new Date(to);
            let data = this.Afterfilter.filter(x => new Date(new Date(x.eventDate).toLocaleDateString().replace('/', '-')) >= new Date(fdate.toLocaleDateString().replace('/', '-')) && new Date(new Date(x.eventDate).toLocaleDateString().replace('/', '-')) <= new Date(todate.toLocaleDateString().replace('/', '-')));
               data.forEach(element => {
                this.listdata.push(element);
            });
          }
          this.ResponseData=this.listdata;
      }
      this.refresh();
       this.ISFilters=false;
    } catch (error) {
    }
  }

  Reset()
  {
    this.filterform.reset({
      'Activity': '',
      'subprocess': '',
      'from': '',
      'to': '',
     });
  }

  refresh()
  {  
    this.ResponseData = this.ResponseData.slice(0, 20);
    this.dataBinding.skip = 0;
  }

  FromReset()
  {
    this.ISFilters=false;
    this.filterform.reset({
      'Activity': '',
      'subprocess': '',
      'from': '',
      'to': '',
    });
  }

  groupBy(collection, property) {
    let i = 0, val, index,
         values = [], result = [];
     for (; i < collection.length; i++) {
         val = collection[i][property];
         index = values.indexOf(val);
         if (index > -1)
             result[val].push(collection[i]);
         else {
             values.push(val);
             result[val] = [];
             result[val].push([collection[i]]);
         }
     }
     return result;
  }

  OpenFilters()
  {
    this.ISFilters=true;
    try {
      let obj = this.groupBy(this.ResponseData, "actionType");
    this.ResponseData.forEach(item=>
      {
        this.eventlist.push(item.actionType);
      });
      this.ResponseData.forEach(item=>
        {
          if(item.subprocessName){
            this.sublist.push(item.subprocessName);
          }
        });
      this.eventlist = this.eventlist.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
    })
    this.sublist = this.sublist.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
  })
    } 
    catch (error) {
      this.showLoader=false;
    }
  }

  filtersclose()
  {
    this.ISFilters=false;
  }

auditclose() {
  this.AuditLogId = false;
  this.showLoader = true;
  this.closeAudithistorymodel.emit(false);
}

DownLoadExportPdfFile(Filepath: string, FileName: string) {
  this.FileName = FileName;
  this.amService.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
    if (event.type === HttpEventType.UploadProgress)
      this.progress = Math.round((100 * event.loaded) / event.total);
    else if (event.type === HttpEventType.Response) {
      this.message = 'Download success.';
      this.downloadFile(event);
    }
  },
    error => {
      this.showLoader = false;
      this.notifications.show("Download  Failed !! " + error.statusText, 4);
    }
  );
}

private downloadFile(data: HttpResponse<Blob>) {
  const downloadedFile = new Blob([data.body], { type: data.body.type });
  const a = document.createElement('a');
  a.setAttribute('style', 'display:none;');
  document.body.appendChild(a);
  a.download = this.FileName;
  a.href = URL.createObjectURL(downloadedFile);
  a.target = '_blank';
  a.click();
  document.body.removeChild(a);
  }

  getShortName(strText) {
    let strTxt = "";
    if (strText.length >= 50)
      strTxt = strText.slice(0, 50).concat('...');
    else
      strTxt = strText;
      this.breadcrumb=strTxt;
    return strTxt;
  }

  onExporttoExcel() {
    this.moduleService.DisplayView(this.template.recordID).subscribe(response => {
     
 
  
    debugger
    let dateToday = new Date();
    if (this.listdata.length != 0)
      this.excelRecords = this.listdata

    this.excelRecords = this.ResponseData
    let rows = [];

    //Description
    let headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];
    let headerCell = new headerCellClass();
    headerCell.value = "Project History Report as of " + dateToday;
    headerCell.bold = true;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Project Name
    let projName =  response.find(x => x.fieldName == "Name");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];
    headerCell = new headerCellClass();
    headerCell.value = "Project Name:"
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = projName.displayValue
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);


    ///Project path
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Project Path:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = projName.displayValue
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);


    //Project Owner
    let procesTempl =  response.find(x => x.fieldName == "ProjectOwner");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Project Owner:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);
    headerCell = new headerCellClass();
    headerCell.value = procesTempl?.displayValue !== undefined ? procesTempl.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Project Status
    let projStatus =  response.find(x => x.fieldName == "ProjectStatus");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Project Status:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = projStatus.displayValue
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Project Start Date
    let projStartDate =  response.find(x => x.fieldName == "ForecastStartDate");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Project Start Date:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = projStartDate?.displayValue !== undefined ? projStartDate.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Project End Date
    let completeDate =  response.find(x => x.fieldName == "CompleteProjectByDate");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Project End Date:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = completeDate?.displayValue !== undefined ? completeDate.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Commercial Sub category 
    let reqSubCatogory =  response.find(x => x.fieldName == "RequestSubCategory");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Request Sub Category:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = reqSubCatogory?.displayValue !== undefined ? reqSubCatogory.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Reference CCR Number

    let refereCCRNum = response.find(x => x.fieldName == "ReferenceCCRNumber");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Reference CCR Number:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = refereCCRNum?.displayValue !== undefined ? refereCCRNum.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Brand Name -Project
    let brandName =  response.find(x => x.fieldName == "BrandName");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Brand Name-Project:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = brandName?.displayValue !== undefined ? brandName.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);


    //Molecule Name-Project
    let moleculeName =  response.find(x => x.fieldName == "MoleculeName");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Molecule Name-Project:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = moleculeName?.displayValue !== undefined ? moleculeName.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Packaging Site
    let packageSite =  response.find(x => x.fieldName == "PackagingSite");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Packaging Site";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = packageSite?.displayValue !== undefined ? packageSite.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Market-Project
    let marketName =  response.find(x => x.fieldName == "Market");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Market-Project:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = marketName?.displayValue !== undefined ? marketName.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Priority
    let priority =  response.find(x => x.fieldName == "Priority");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Priority:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = priority?.displayValue !== undefined ? priority.displayValue : "NA"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Complete Project by date path
    let complDate =  response.find(x => x.fieldName == "CompleteProjectByDate");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "Complete Project By Date:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = complDate.displayValue
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //First Review By Date
    let firstDate =  response.find(x => x.fieldName == "FirstReviewByDate");
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    headerCell = new headerCellClass();
    headerCell.value = "First Review By Date:";
    headerCell.bold = true;
    headerRowcells.push(headerCell);

    headerCell = new headerCellClass();
    headerCell.value = firstDate?.displayValue !== undefined ? firstDate.displayValue : "NA";
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Binding Columns
    headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    this.columnNames.forEach(element => {
      headerCell = new headerCellClass();
      headerCell.value = element
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      headerCell.background = "#227447"
      headerCell.color = "#ffffff"
    });
    headerRow.cells = headerRowcells;
    rows.push(headerRow);
    //Binding Grid Data

    for (let i = 0; i <= this.excelRecords.length - 1; i++) {
      let headerRow = new headerRowClass();
      let headerRowcells: headerCellClass[] = [];

      let headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].eventDate;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].taskName;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].actionType;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].userId.split(',')[0];
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].description;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""


      let projName =  response.find(x => x.fieldName == "Name");
      let subProcName = this.excelRecords.find(x=>x.subprocessName != '').subprocessName;
      headerCell = new headerCellClass();
      headerCell.value =   projName.displayValue + "   " + (subProcName != null ? subProcName : " ") + " " + (this.excelRecords[i].isCycle != null? this.excelRecords[i].isCycle : " ")
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].userId.split(':')[1];
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""


      headerRow.cells = headerRowcells;
      rows.push(headerRow);
    }
    let excelName = "Project_History_Report_" + dateToday;
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: [
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
          ],
          // Title of the sheet            
          //name: template.templateName,
          name: excelName,
          // Rows of the sheet
          rows: rows
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName + ".xlsx"));
  });
  }
}

class Droplist
{
actiontype:string;
}

export class headerCellClass {
  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class headerRowClass {
  cells: any;
}

