import { HttpEventType, HttpResponse } from '@angular/common/http';
import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicFieldControlService } from '../../dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { DynamicFormGroupBase } from '../../dynamic-fields/dynamic-field-base';
import { ModuleService } from '../../../services/module.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { getBaseUrl } from '../../../../main';
import { FileInfo, FileRestrictions, SelectEvent, UploadEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { DynamicTemplateData, TaskSubDetails, TaskOwnermodal } from '../../dynamic-fields/dynamic-field-list/dynamic-field-list.component';
import { templateData } from '../../../interfaces/ItemplateFields';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { NotificationEmailTriggerEvent } from '../../../services/adminusermanagement.service'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogWidthService } from '../../../services/dialog-width.service';
/*import { GlobalVeriables } from 'src/app/Global/GlobalVaribales';*/
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { debug, log } from 'console';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { TitleBarLocalizationService } from '@progress/kendo-angular-dialog';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-dynamic-field-multileveltasksbypass',
  templateUrl: './dynamic-filed-multileveltasksbypass.html',
  styleUrls: ['./dynamic-field-multileveltasksbypass.css'],

})
export class DynamicFieldMultiLevelTaskBypass implements OnInit{
  @Input() public template: any;
  @Input() public templateID: any;
  @Output() changeLangOutput= new EventEmitter();
  @Output() cancelMultileveltaskBypassmodal= new EventEmitter();
  public ID: any;
  public RecordID: any;
  public RecordIDs: any;

  public ParentID: any;

  public selectableSettings: SelectableSettings = {
    enabled: false
  };

  byPassItemsSelected: any = [];
  projectSelectedItem: any;
  public source: Array<{ text: string; value: number }> = [
    { text: "Project level bypass", value: 1 },
    { text: "Sub process level bypass", value: 2 },
  ];

  public data: Array<{ text: string; value: number }>;
  showgriderror: boolean = false;
  records: any;
  selectedItems: any;
  uploadedFiles = [];
  documentsPath: any;
  PageOnLoad: boolean = false;
  showPopUp: boolean = false;
  display: string = "none";

  bypassUnbypassOpen: boolean = false;

  showLoader: boolean = false;
  dynTemplateHeader: string = null;
  DynamicFields: DynamicFormGroupBase<any>;
  DynamicFormFieldsActions: any = [];
  dynamicForm: FormGroup;

  invalidSubmitAttempt: boolean = false;
  templateData = new DynamicTemplateData();
  taskSubDetails = new TaskSubDetails();
  taskOwnermodal = new TaskOwnermodal();
  submitted = false;
  TaskAction: string = "";
  taskID: number = 0;
  Bypassunbypass = false;
  wrkflowdisplayfileds: any;
  wrkflowtaskActions: any
  WorkFlowProcessData: any;
  WorkFlowTaskStatus: any;
  TaskName: any;
  workFlowStatusID: any;
 
  ProjectStartConfrimopened: boolean = false;
  DocDeleteConfirmation: boolean = false;
  TaskBypassConfrimopened: boolean = false;
  TaskUnbypassBypassConfrimopened: boolean = false;
  multiTaskBypassConfrimopened: boolean = false;
  multiTaskUnBypassConfrimopened: boolean = false;

  artWorkReviewURL: SafeResourceUrl;
  showArtWorkWindow: boolean = false;
  BypassedItems: any = [];
  BypassItems: any = [];
  /* public listItems: Array<string>;*/
  showBypassedList: boolean = false;
  showBypassTasks: boolean = false;
  offSet: string = "+05:30";
  windowState = 'default';
  resizeWindow = false;
  showBypassUnbypassPopUp: boolean = false;
  showTaskOwnerPopUp: boolean = false;
  taskOwnerGroupSelectedValue: any;
  taskOwnerSelectedValue: any;
  dstaskOwnerGroupList: any;
  dstaskOwnerList: any;
  ClaimTaskConfrimopened: boolean = false;
  btnShowBypassUnByPass: boolean = false;
  showradioerror: boolean = false;
  public isResizable = false;

  GroupDyForm = this.formbuilder.group({
    Groups: ['', Validators.required],
    Users: ['']
    // Users: ['', Validators.required]
  });


  byPassFormopen1 : FormGroup;

  dateFormats: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy'
  };
  myRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf",],
  };

  value: any;
  currentWorkFlowData: any;
  wrkFlowTaskActions: any;
  wrkFlowCurrentTaskdata: any;
  wrkFlowCurrentProjectdata: any;
  wrkFlowCurrentsubProcessData: any;
  wrkFlowCurrentcustomData: any;
  wrkFlowReferenceFilesData: any;
  workFlowTaskAction: any;
  compnayID: number = 1;
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  progress: number;
  message: string;
  FileName: string;
  workflowId: number = 1;
  constructor(private moduleService: ModuleService, private dataService: DynamicFieldService, private router: Router,
    private qcs: DynamicFieldControlService, private amService: AdminUserManagementService,
    private DFS: DynamicFieldService, private notifyService: NotificationAlertsService, public sanitizer: DomSanitizer,
    public dws: DialogWidthService, private GV: GlobalVeriables, private formbuilder: FormBuilder
    ,private languageService: LanguageService) { }

  ngOnInit() {//debugger
   
    
    this.GV.UserRoleID = +localStorage.getItem("userRoleID");
    this.Bypassunbypass=true;
    //this.bypassUnbypassOpen = true;
   // this.showBypassUnbypassPopUp=true;
   let languageDataDefault=this.languageService.dataByLanguage;
    this.moduleService.GetTaskByPassFields(54, 54).subscribe(data => {//debugger
      if(data.dynamicFormFieldList && data.dynamicFormFieldList[0].dynamicFields.length>0){
        data.dynamicFormFieldList[0].dynamicFields.forEach(function(part,index){
          if(part.defaultItem)
              part.defaultItem.key= languageDataDefault.find(x=>x.FormFieldID==part.fieldId).DefaultValue;
        })
      }
      this.DynamicFields = data.dynamicFormFieldList;
      this.byPassFormopen1 = this.qcs.toFormGroup(this.DynamicFields);
      this.BypassItems = [];
      this.byPassItemsSelected = [];
     
      this.byPassFormopen1.addControl('processId', new FormControl(this.source[0], Validators.required));
      this.byPassFormopen1.addControl('bypassItem', new FormControl('', Validators.required))
      this.showLoader = false;
     // this.showBypassUnbypassPopUp = true;

    });

    this.data = this.source;

    if (this.GV.UserRoleID == 3)
      this.btnShowBypassUnByPass = true;
    else
      this.btnShowBypassUnByPass = false;

    this.GV.UserId = +localStorage.getItem("userId");
    //console.log(this.GV.UserId);
    this.template;
    this.showPopUp = false;
    this.display = "none";
    this.documentsPath = this.moduleService.getBaseUrl();


    this.moduleService.dependencyTemplate.subscribe(data => {


      this.RecordID = data.selectedRecordId;
      this.RecordIDs = data.selectedRecordIds;

      this.ParentID = data.dependentRecordID;

      if (this.ParentID != 0)
        this.bindTaskManagementView();

    });
  }
  public zeroPad = (num, places) => String(num).padStart(places, '0');
  public getOffset() {
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + this.zeroPad(hours, 2) + ':' + this.zeroPad(minutes, 2);
    this.offSet = offset;
  }

  //public handleParentTemplateChange(value: any): void {
  //  console.log("selectionChange", value);
  //}

  UsersOnChange(selectedId: any) {
    this.selectedItems = '';
    if (selectedId != undefined) {
      this.moduleService.getUsersForNotification(parseInt(this.ParentID), parseInt(this.RecordID), selectedId).subscribe(response => {
       // console.log(response);
        this.DynamicFields[0].dynamicFields[1].options = response['status'];
       // console.log(this.DynamicFields[0].dynamicFields[1].options);
        //this.DynamicFields
        //this.UsersData = response;
        //if (response.length > 0)
        //  this.isDisabledUsers = false;
        //else {
        //  this.selectedItems = '';
        ////this.isDisabledUsers = true;
        //}

      });
    }
    else {
      this.selectedItems = '';
      // this.isDisabledUsers = true;
    }
  }

  public disabledDates = (date: Date): boolean => {
    this.value = new Date();
    return date <= new Date(this.value.setDate(this.value.getDate() - 1));


  }



  public removeEventHandler(e: RemoveEvent, fieldName: any): void {
   // console.log(e);
    if (e.files.length > 0) {
      // service call to remove file from temp folder this.uploadedFiles[fieldName][0]
      let index = this.uploadedFiles[fieldName].indexOf(e.files[0].name);
      if (index != -1) {
        this.moduleService.removeUploadFiles(this.uploadedFiles[fieldName][index]).subscribe(data => {
        });
        this.uploadedFiles[fieldName].splice(index, 0);
      }
    }
  }

  public selectEventHandler(e: SelectEvent): void {
    const that = this;
    e.files.forEach((file) => {
      if (!file.validationErrors) {

      }
    });
  }

  uploadEventHandler(e: UploadEvent, fieldName: any, isMultiple: any) {
    //setTimeout(() => { }, 5000);

    this.showLoader = true;
    if (this.DFS.checkUndefinedNullEmpty(this.uploadedFiles[fieldName]) || !isMultiple) {
      if (!isMultiple && this.uploadedFiles[fieldName] !== undefined && this.uploadedFiles[fieldName].length > 0)
        this.moduleService.removeUploadFiles(this.uploadedFiles[fieldName][0]).subscribe(data => {
          this.showgriderror = true;
        });
      this.uploadedFiles[fieldName] = [];
    }
    let files: any;
    files = e.files;
    if (files.length === 0) {
      return;
    }
    let filesToUpload: File[] = files;
    let filelist = [];
    this.uploadedFiles[fieldName].push(files[0].name);

  }
  public completeEventHandler() {
    this.showLoader = false;
  }
  onFormSubmit() {
    let uploadFields = Object.keys(this.uploadedFiles);
    if (uploadFields.length > 0) {
      for (var i = 0; i < uploadFields.length; i++) {
        this.dynamicForm.value[uploadFields[i]] = this.uploadedFiles[uploadFields[i]];
      }
    }


    if (this.dynamicForm.invalid) {
      this.invalidSubmitAttempt = true;
      return;
    }
    if (this.isTaskBypassAction) {
      this.ID !== undefined ? parseInt(this.ID) : this.ID;
      this.templateData.ProjectId = parseInt(this.ParentID);
      this.templateData.ProcessDataId = parseInt(this.RecordID);
      if (this.wrkFlowCurrentTaskdata.workFlowStatus == "In Progress") {//inprogress
        this.templateData.TaskAction = "Bypass";
        this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
        this.moduleService.saveTaskStatusComments(this.templateData).subscribe(data => {
          this.showLoader = false;
          this.notifyService.show("Task bypassed successfully !!", 1);
          this.bindTaskManagementView();
          this.reloadParentDependentTemplate();
        })

      }
      else {
        this.templateData.TaskAction = "UnBypass";
        this.isTaskBypassAction = false;
        this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
        this.moduleService.saveTaskStatusComments(this.templateData).subscribe(data => {
          this.showLoader = false;
          this.notifyService.show("Task unbypassed successfully !!", 1);
          this.bindTaskManagementView();
          this.reloadParentDependentTemplate();
        })
      }
 
    }
    else if (this.wrkFlowCurrentTaskdata.taskId == 23) {
      this.showLoader = true;
      this.showPopUp = false;
      this.display = "none";
      this.ID = this.ParentID;
      this.templateID = 11;
      this.templateData.ID = this.ID !== undefined ? parseInt(this.ID) : this.ID;
      this.templateData.TaskID = this.RecordID
      this.templateData.TemplateID = parseInt(this.templateID);
      this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
      this.templateData.Isfromsubprocess = true;
      this.templateData.UserID = this.GV.UserId;
      this.templateData.UploadFileType = this.GV.GetFileType();
      this.dataService.saveDynamicFormFieldsdata(this.templateData).subscribe(responce => {
        this.showLoader = false;
        this.notifyService.show("Sub process details updated successfully !!", 1);
        this.bindTaskManagementView();
        this.reloadParentDependentTemplate();

      })
    }
    //else if (this.wrkFlowCurrentTaskdata.isTaskByPass == true) {

    //  this.ID !== undefined ? parseInt(this.ID) : this.ID;
    //  this.templateData.ProjectId = parseInt(this.ParentID);
    //  this.templateData.ProcessDataId = parseInt(this.RecordID);
    //  this.templateData.TaskAction = "Bypass";

    //  this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
    //  this.moduleService.saveTaskStatusComments(this.templateData).subscribe(data => {
    //      this.showLoader = false;
    //    this.notifyService.show("Task bypassed successfully !!", 1);
    //    this.bindTaskManagementView();
    //    this.reloadParentDependentTemplate();

    //  })
    //}
    //else if (this.wrkFlowCurrentTaskdata.isTaskByPass == false ) {
    //  this.ID !== undefined ? parseInt(this.ID) : this.ID;
    //  this.templateData.ProjectId = parseInt(this.ParentID);
    //  this.templateData.ProcessDataId = parseInt(this.RecordID);
    //  this.templateData.TaskAction = "UnBypass";

    //  this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
    //  this.moduleService.saveTaskStatusComments(this.templateData).subscribe(data => {
    //    this.showLoader = false;
    //    this.notifyService.show("Task unbypassed successfully !!", 1);
    //    this.bindTaskManagementView();
    //    this.reloadParentDependentTemplate();

    //  })
    //}
    else {
      this.showLoader = true;
      this.showPopUp = false;
      this.display = "none"
      this.taskSubDetails.projectID = parseInt(this.ParentID);
      this.taskSubDetails.processDataId = parseInt(this.RecordID);
      this.taskSubDetails.offset = "";
      this.taskSubDetails.actionType = "FormSubmit";
      this.taskSubDetails.formDataValues = JSON.stringify(this.dynamicForm.value);
      this.taskSubDetails.UserID = this.GV.UserId;
      this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
        this.showLoader = false;
        this.dynamicForm.reset();
        this.notifyService.show("Task details updated successfully !!", 1);
        this.bindTaskManagementView();
        this.reloadParentDependentTemplate();

      },
        error => {
          this.showLoader = false;
          this.notifyService.show("Task details updated failed !!. " + error.statusText, 4);
        }
      );
    }
  }

  cancel() {

    this.display= "none";
    this.showPopUp = false;
    this.uploadedFiles = [];
  }

  close() {
    this.showPopUp = false;
    this.display= "none";
    
  }
  // ProjectStartConfrim() {
  //   //var ans = confirm("Are you sure you want to start this project?");
  //   //if (ans) {
  //   //debugger;
  //   this.ProjectStartConfrimopened = false;
  //   this.showLoader = true;
  //   this.taskSubDetails.projectID = parseInt(this.ParentID);
  //   this.taskSubDetails.processDataId = parseInt(this.RecordID);
  //   this.taskSubDetails.offset = "";
  //   //this.taskSubDetails.formDataValues="";
  //   this.taskSubDetails.actionType = this.TaskAction;
  //   this.taskSubDetails.UserID = this.GV.UserId;
  //   this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
  //     this.showLoader = false;
  //     // this.notifyService.show("Project has been started successfully !!", 1);
  //     this.bindTaskManagementView();
  //     this.reloadParentDependentTemplate();
  //     // Calling Email Notifications
  //     this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.ProjectStarted, 0, this.ParentID, 0, 0, 1, this.compnayID, 1, encodeURIComponent(this.offSet)).subscribe();
  //   },
  //     error => {
  //       this.showLoader = false;
  //       this.notifyService.show("Task details updated failed !!. " + error.statusText, 4);
  //     }
  //   );
  //   // }
  // }
  closeDialog() {
    this.ProjectStartConfrimopened = false;
    this.TaskUnbypassBypassConfrimopened = false;
    this.TaskBypassConfrimopened = false;
  }
  reloadParentDependentTemplate() {

    this.moduleService.CheckIsDependents(this.template.moduleID, this.template.templateID).subscribe(dataDependent => {

      if (dataDependent.isDependent != null && dataDependent.isDependent == "0" && dataDependent.templateID != "0") {

        this.moduleService.passDependentRecord(dataDependent);
      }
    });

  }
  isTaskBypassAction: boolean = false;

  // onTaskFormActionClick(actionName: any, workFlowTaskId: any, TaskAction: any) {
       
  //   if (actionName == "Bypass") {
  //     this.TaskBypassConfrimopened = true;
  //   }

  //   else if (actionName == "UnBypass") {
  //     this.TaskUnbypassBypassConfrimopened = true;
  //   }

  //   else if (actionName == "Bypassed TaskList") {
  //     this.moduleService.getbypassedTasksList(parseInt(this.RecordID)).subscribe(data => {
  //       this.BypassedItems = [];
  //       if (data != null && data != "" && data != undefined) {
  //         this.BypassedItems = data;
  //         this.showBypassedList = true;
  //       }
  //     })
  //     if (this.BypassedItems.length == 0) {
  //       this.notifyService.show("There is no bypassed tasks");
  //     }
  //   }
  //   else {
  //     this.showLoader = true;
  //     this.moduleService.GetSubProcessTaskFileds(this.ParentID, this.RecordID,0).subscribe(data => {
  //       this.DynamicFields = data.dynamicFormFieldList;
  //       this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
  //       this.showLoader = false;
  //       this.showPopUp = true;
  //       this.display= "block";
  //     });
  //     this.reloadParentDependentTemplate();

  //   }
  // }

  UpdatemultiTaskbypass() {


    let selectedItems: any = this.byPassItemsSelected.length > 0 ? String(this.byPassItemsSelected) : "";
    let processId: any = this.byPassFormopen1.controls.processId.value.value !== undefined ? this.byPassFormopen1.controls.processId.value.value : 0;
    let bypassItems: any = this.byPassFormopen1.controls.bypassItem.value !== undefined ? this.byPassFormopen1.controls.bypassItem.value : 0;
    this.templateData.DynFormFieldData = JSON.stringify(this.byPassFormopen1.value);
    this.templateData.ProcessIds = selectedItems;
    this.templateData.TaskAction = bypassItems;
    this.templateData.Level = processId;
    //this.templateData.ProjectId=this.RecordID;
    
    this.templateData.UserID = this.GV.UserId
    let NotificationPreferences = this.byPassFormopen1.controls["NotificationPreferences"].value;
    let NTriggerEvent: any
    let NTriggerType: any = bypassItems == "Bypass" ? 1 : 2
    if (processId == 1)
      NTriggerEvent = NTriggerType == 1 ? this._NotificationEmailTriggerEvent.NotificationtoProjectBypassed : this._NotificationEmailTriggerEvent.NotificationtoProjectUnBypassed;
    else
      NTriggerEvent = NTriggerType == 1 ? this._NotificationEmailTriggerEvent.NotificationtoSubprocessBypassed : this._NotificationEmailTriggerEvent.NotificationtoSubProcessUnBypassed;
    this.moduleService.getBypassUnbypassWorkFlowTaskList(this.templateID, selectedItems, bypassItems, processId, this.templateData).subscribe(data => {
      this.BypassedItems = [];
      if (data != null && data != "" && data != undefined) {
        this.BypassedItems = data;
        this.showBypassedList = true;
       // this.showBypassUnbypassPopUp = true;
        this.multiTaskBypassConfrimopened=false;
        this.Bypassunbypass=false;
        
        this.notifyService.show("Tasks '" + bypassItems+"' successfully");
        this.moduleService.saveMultiTaskBypassComments(this.templateData).subscribe(data => {
          if (NotificationPreferences != 467)
            this.amService.GetActionEmailNotification(NTriggerEvent, NTriggerType, this.templateID, 0, selectedItems, this.workflowId, this.compnayID,0, encodeURIComponent(this.offSet)).subscribe();
        })
        this.cancelMultileveltaskBypassmodal.emit(false);
        this.router.navigate(['Projects']);
       // window.location.reload();

       // this.reloadParentDependentTemplate();
       

      }
    })
    //this.templateData.DynFormFieldData = JSON.stringify(this.byPassForm.value);
    //this.moduleService.saveTaskStatusComments(this.templateData).subscribe(data => {
    //})
  }


  
  multiTaskbypass() {
    this.showgriderror = false;
    this.showradioerror = false;
    // if (this.byPassFormopen1.invalid) {
    //   this.invalidSubmitAttempt = true;
    //   return;
    // }
    this.byPassFormopen1.addControl('processId', new FormControl({}, Validators.required));
    this.byPassFormopen1.addControl('bypassItem', new FormControl('', Validators.required))
    let bypassItems: any = this.byPassFormopen1.controls.bypassItem.value !== undefined ? this.byPassFormopen1.controls.bypassItem.value : 0;
    let selectedItems: any = this.byPassItemsSelected.length > 0 ? String(this.byPassItemsSelected) : "";
   if(selectedItems.length == 0||this.byPassFormopen1.invalid||bypassItems.length == "")
   {
  
    if (bypassItems.length == "") {
      this.showradioerror = true;
     
    }
    if (selectedItems.length == 0) {
      this.showgriderror = true;
     
    }

    if (this.byPassFormopen1.invalid) {
      this.invalidSubmitAttempt = true;
     
       }
       return;

   }
   
    if (bypassItems == "Bypass") {
      this.multiTaskBypassConfrimopened = true;
    }
    else {
      this.multiTaskUnBypassConfrimopened = true;
    }

    
  }
  ChangeBypassType(selectedId: any) {
    

    // this.moduleService.GetTaskByPassFields(3641, 13254).subscribe(data => {
    //   this.DynamicFields = data.dynamicFormFieldList;
    //   this.byPassForm = this.qcs.toFormGroup(this.DynamicFields);
    //   this.BypassItems = [];
    //   this.byPassItemsSelected = [];
    //   //if (this.GV.UserRoleID == 4)
    //   //  this.byPassForm.addControl('processId', new FormControl(this.source[0], Validators.required));
    //   //else if (this.GV.UserRoleID == 3)
    //   //  this.byPassForm.addControl('processId', new FormControl(this.source[0], Validators.required));
    //   //selse
    //   this.byPassForm.addControl('processId', new FormControl(this.source[0], Validators.required));

    //   this.byPassForm.addControl('bypassItem', new FormControl('', Validators.required))
    //   this.showLoader = false;
    //   this.showBypassUnbypassPopUp = true;

    // });
   // let rblbypassItems="UnBypass";
   let rblbypassItems: any = this.byPassFormopen1.controls.bypassItem.value !== undefined ? this.byPassFormopen1.controls.bypassItem.value : 0;
    if (selectedId.value !== undefined && rblbypassItems !== undefined) {
      this.moduleService.getBypassTasksByWorkFlow(this.templateID, rblbypassItems, selectedId.value).subscribe(data => {
        this.BypassItems = [];
        this.byPassItemsSelected = [];
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          if (rblbypassItems == "Bypass") 
            this.showBypassUnbypassPopUp = true;
          else
            this.showBypassTasks = true;
        }
      })
    }
  }

  bypassChange(actionName: string) {
    
    //this.byPassForm.controls.processId.value.value=1;

    //let level: any = this.byPassForm.controls.processId.value.value !== undefined ? this.byPassForm.controls.processId.value.value : 0;
    if (actionName == "Bypass") {
      
     
     
     // this.moduleService.getBypassTasksByWorkFlow(this.ParentID, actionName, 1).subscribe(data => {
      this.moduleService.getBypassTasksByWorkFlow(this.templateID, actionName, 1).subscribe(data => {
        this.BypassItems = [];
        this.byPassItemsSelected = [];
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          this.showBypassUnbypassPopUp = true;

        }
      })
    }
    else {
    
     // this.ParentID=3794;
      this.moduleService.getBypassTasksByWorkFlow(this.templateID, actionName,1).subscribe(data => {
        this.BypassItems = [];
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          this.showBypassTasks = true;
        }
      })


    }
  }
  // taskUnbypass() {
  //   this.TaskUnbypassBypassConfrimopened = false;
  //   this.taskSubDetails.projectID = parseInt(this.ParentID);
  //   this.taskSubDetails.processDataId = parseInt(this.RecordID);
  //   this.taskSubDetails.offset = "";
  //   this.taskSubDetails.formDataValues = ""
  //   this.taskSubDetails.actionType = "UnBypass";
  //   this.moduleService.GetTaskByPassFields(this.taskSubDetails.projectID, this.taskSubDetails.processDataId).subscribe(data => {
  //     this.isTaskBypassAction = true;
  //     this.DynamicFields = data.dynamicFormFieldList;
  //     this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
  //     this.showLoader = false;
  //     this.showPopUp = true;
  //     this.display = "block";
  //   });
  // }

  // taskBypass() {
  //   this.TaskBypassConfrimopened = false;
  //   this.taskSubDetails.projectID = parseInt(this.ParentID);
  //   this.taskSubDetails.processDataId = parseInt(this.RecordID);
  //   this.taskSubDetails.offset = "";
  //   this.taskSubDetails.formDataValues = ""
  //   this.taskSubDetails.actionType = "Bypass";
  //   this.moduleService.GetTaskByPassFields(this.taskSubDetails.projectID, this.taskSubDetails.processDataId).subscribe(data => {
  //     this.isTaskBypassAction = true;
  //     this.DynamicFields = data.dynamicFormFieldList;
  //     this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
  //     this.showLoader = false;
  //     this.showPopUp = true;
  //     this.display = "block";
  //   });
  // }

  closebypassDialog() {

    
    this.TaskBypassConfrimopened = false;
    this.showBypassUnbypassPopUp = false;
    this.multiTaskBypassConfrimopened = false;
    this.multiTaskUnBypassConfrimopened = false;
    this.cancelMultileveltaskBypassmodal.emit(false);
    //this.router.navigate(['/Projects'])
  // .then(() => {
  //   window.location.reload();
  // });
  }

  public OpenWindow() {
    this.showPopUp = true;
    this.display = "block";

  }

  public Cancel() {
    this.showPopUp = false;
    this.display = "none";
    
  }

  public CancelBypassList() {

    this.cancelMultileveltaskBypassmodal.emit(false);
    this.showBypassedList = false;
    this.showBypassTasks = false;
    this.showBypassUnbypassPopUp = false;
  }
  bindTaskManagementView() {

    this.showLoader = true;
    this.moduleService.DisplayTaskManagementView(this.ParentID, this.RecordID, this.GV.UserId).subscribe(data => {
      if (data != null && data != "" && data != undefined) {
        this.wrkFlowTaskActions = data.currenttaskActions;
        this.wrkFlowCurrentTaskdata = data.taskView[0];
        this.wrkFlowCurrentProjectdata = data.projectView[0];
        this.wrkFlowCurrentsubProcessData = data.subtaskView[0];
        this.wrkFlowCurrentcustomData = data.customTaskview[0];
        this.wrkFlowReferenceFilesData = data.refrenceFilesView[0];

        this.showLoader = false;
        this.PageOnLoad = true;

      }
    });



  }

  //ArtworkReviewgoToLink() {
  //  let TempId = 1;
  //  if (this.wrkFlowCurrentTaskdata.workFlowTaskId=8)
  //      TempId = 18;
  //  this.amService.getGetProjectArtworkComponents(TempId, this.template.recordID, this.wrkFlowCurrentTaskdata.subProcessId, 1, 1, 1, this.wrkFlowCurrentTaskdata.taskId).subscribe(data => {
  //    window.open(data.artworkReviewURLNavigation, "_blank");
  //  });
  //}

  // ArtworkReviewgoToLink() {
  //   this.showLoader = true;
  //   let TempId = 1;
  //   if (this.wrkFlowCurrentTaskdata.taskId == 7 || this.wrkFlowCurrentTaskdata.taskId == 18 || this.wrkFlowCurrentTaskdata.taskId == 21
  //     || this.wrkFlowCurrentTaskdata.taskId == 14 || this.wrkFlowCurrentTaskdata.taskId == 15 || this.wrkFlowCurrentTaskdata.taskId == 3
  //     || this.wrkFlowCurrentTaskdata.taskId == 20 || this.wrkFlowCurrentTaskdata.taskId == 19 || this.wrkFlowCurrentTaskdata.taskId == 4
  //     || this.wrkFlowCurrentTaskdata.taskId == 16) {
  //     //this.wrkFlowCurrentTaskdata.taskId = 7;
  //     TempId = 18
  //   }

  //   this.amService.getGetProjectArtworkComponents(TempId, this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.GV.UserId, 1, 1, this.wrkFlowCurrentTaskdata.taskId, this.wrkFlowCurrentTaskdata.cycleId).subscribe(data => {
  //     //var url = data.artworkReviewURLNavigation + "&embedded=true";
  //     var url = data.artworkReviewURLNavigation;

  //     //Assigning the URL to Artwork Iframe
  //     this.windowState = 'maximized';
  //     this.resizeWindow = false;
  //     this.showArtWorkWindow = true;
  //     this.artWorkReviewURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);


  //    // console.log(this.artWorkReviewURL);
  //     //const iframe = document.getElementById('embeddedPage') as HTMLIFrameElement;
  //     //iframe.contentWindow.location.replace(url);

  //     /*"http://localhost:53035/home/index?DocPath=ProjectComponets&1=899&2=900&3=1&4=1&5=1&6=9&7=1");*/
  //     this.showLoader = false;
  //   });
  // }

  // //Close Event for artwork review window
  // artWorkReviewClose() {
  //   this.moduleService.GetTaskStatus(this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.wrkFlowCurrentTaskdata.taskId, this.GV.UserId).subscribe(data => {
  //     if (this.wrkFlowCurrentTaskdata.workFlowStatusId != data)
  //       this.reloadParentDependentTemplate();
  //   });
  //   this.showArtWorkWindow = false;
  // }

  //dblClickEvent(e) {
  //  e.preventDefault();
  //  e.stopPropagation();
  //  console.log(e);

  //  return false;
  //}
  // DownloadArtWorkPdfFile(Filepath: string, FileName: string) {
  //   this.FileName = FileName;
  //   this.moduleService.DownloadPdfFile(Filepath, FileName).subscribe((event) => {
  //     if (event.type === HttpEventType.UploadProgress)
  //       this.progress = Math.round((100 * event.loaded) / event.total);
  //     else if (event.type === HttpEventType.Response) {
  //       this.message = 'Download success.';
  //       this.downloadFile(event);
  //     }
  //   });
  // }
  // private downloadFile(data: HttpResponse<Blob>) {
  //   const downloadedFile = new Blob([data.body], { type: data.body.type });
  //   const a = document.createElement('a');
  //   a.setAttribute('style', 'display:none;');
  //   document.body.appendChild(a);
  //   a.download = this.FileName;//.substring(1).slice(0,-1);
  //   a.href = URL.createObjectURL(downloadedFile);
  //   a.target = '_blank';
  //   a.click();
  //   document.body.removeChild(a);
  // }
  // DeleteArtWorkPdfFile() {
  //   this.DocDeleteConfirmation = true;
  // }
  // DocDelConfrim() {
  //   this.DocDeleteConfirmation = false;
  //   this.showLoader = true;
  //   var templData = new DynamicTemplateData();
  //   templData.TaskID = this.wrkFlowCurrentTaskdata.taskId;
  //   templData.SubprocessID = this.wrkFlowCurrentTaskdata.subProcessId;
  //   this.moduleService.DeleteArtWorkPdfFile(templData).subscribe(data => {
  //     if (data == "success") {
  //       this.showLoader = false;
  //       this.notifyService.show("File has been deleted successfully");
  //       this.reloadParentDependentTemplate();

  //     }
  //   });
  // }
  // closeDocDelDialog() {
  //   this.DocDeleteConfirmation = false;
  // }
  // DownloadAllArtWorkReferenceFile() {
  //   this.FileName = "Base_Artwork.zip";
  //   this.moduleService.DownloadALLArtworkPdfFile(this.RecordID, this.wrkFlowCurrentTaskdata.subProcessId).subscribe((event) => {
  //     if (event.type === HttpEventType.UploadProgress)
  //       this.progress = Math.round((100 * event.loaded) / event.total);
  //     else if (event.type === HttpEventType.Response) {
  //       this.message = 'Download success.';
  //       this.downloadFile(event);
  //     }
  //   });
  // }
  GetTaskFromFields(ActionType: any, TaskId: any) {
    this.invalidSubmitAttempt = false;
    this.showLoader = true;
    this.moduleService.GetSubProcessTaskFileds(this.ParentID, this.RecordID,0).subscribe(data => {
      this.DynamicFields = data.dynamicFormFieldList;
      this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
      this.showLoader = false;
      this.showPopUp = true;
      this.display = "block";
      //this.dynamicForm.reset();
    });
  }
  // onTemplateActionClick(ActionType: any, TaskId: any) {
  //   if (ActionType == "EditSubTask") {

  //     this.GetTaskFromFields(ActionType, TaskId);
  //   }
  //   else if (ActionType == "EditProject") {
  //     //Edit project code here

  //   }
  //   else if (ActionType == "Edit") {
  //     this.GetTaskFromFields(ActionType, TaskId);
  //   }

  // }

  getClassName(name): string {

    var ClassName = "";


    ClassName = name.replace(/\s/g, "");
    return ClassName;



  }


  // onBackClick() {
  //   var templData = new templateData();
  //   templData.moduleID = this.template.moduleID;
  //   this.moduleService.passTemplateData(templData);
  //   this.router.navigate(['Projects']);
  // }
  // onKeypressEvent(event: any) {
  //   if (event.which === 32 && !event.target.value)
  //     event.preventDefault();
  // }
  //set Task Owner 
  // claimTaskStatus:boolean=false;
  // bindTaskOwnerInputData()
  // {
  //     this.moduleService.GetTaskOwnerInputData(this.RecordID,this.GV.UserId,"","",0).subscribe(data => {
  //     this.dstaskOwnerGroupList=data.groupList;
  //     this.dstaskOwnerList=data.groupUsersList;
  //     setTimeout(() => { 
  //     if(data.groupId !=null && data.groupId !="" && data.groupId !=undefined){
  //     this.GroupDyForm.controls['Groups'].setValue(data.groupId.toString());
  //     this.taskOwnerGroupSelectedValue=data.groupId.toString();
  //     }
  //     else{ this.GroupDyForm.controls['Groups'].setValue(""); this.taskOwnerGroupSelectedValue=""}
  //     if(data.userId !=null && data.userId !="" && data.userId !=undefined){
  //     this.GroupDyForm.controls['Users'].setValue(data.userId.toString());
  //     this.taskOwnerSelectedValue=data.userId.toString();
  //     }
  //     else{this.GroupDyForm.controls['Users'].setValue("");this.taskOwnerSelectedValue="";}
  //     this.showLoader=false;
  //     this.showTaskOwnerPopUp=true;
  //     this.claimTaskStatus=data.claimTaskStatus;
  //   }, 1000);
      
  //   },
  //     error => {
  //       this.showLoader = false;
  //       this.notifyService.show("Something went wrong. Try again !!. " + error.statusText, 4);
  //       console.log(error);
  //     }
  //   )
  // }
  // bindTaskOwnerGroupUsers(value: any) {
  //   this.submitted = false;
  //   this.dstaskOwnerList = [];
  //   this.taskOwnerSelectedValue = "";
  //   if (value != null && value != undefined && value != "") {
  //     this.moduleService.GetTaskOwnerGroupUsers(2, value, this.GV.UserId).subscribe(data => {
  //       this.dstaskOwnerList = data;
  //     },
  //       error => {
  //         this.showLoader = false;
  //         this.notifyService.show("Something went wrong. Try again !!. " + error.statusText, 4);
  //         console.log(error);
  //       })
  //   }
  // }
  // SaveTaskOwner() {
  //   if (this.GroupDyForm.invalid) {
  //     this.submitted = true;
  //     return;
  //   }
  //   this.taskOwnermodal = new TaskOwnermodal();
  //   this.taskOwnermodal.ProcessDataID = this.RecordID;
  //   this.taskOwnermodal.GroupId = +this.GroupDyForm.controls['Groups'].value;;
  //   this.taskOwnermodal.UserId = +this.GroupDyForm.controls['Users'].value;;
  //   this.showTaskOwnerPopUp = false;
  //   this.showLoader = true;
  //   this.moduleService.SaveProjectTaskOwnerGroup(this.taskOwnermodal).subscribe(data => {
  //     if (data == "success") {
  //       this.showLoader = false;
  //       this.notifyService.show("Task Owner has been Updated successfully");
  //       this.reloadParentDependentTemplate();
  //     }
  //   },
  //     error => {
  //       this.showLoader = false;
  //       this.notifyService.show("Set Task Owner updated failed !!. " + error.statusText, 4);
  //       console.log(error);
  //     })
  // }
  // CancelTaskOwner() {
  //   this.showTaskOwnerPopUp = false;
  // }
  // closeClaimTaskDialog() {
  //   this.ClaimTaskConfrimopened = false;
  // }
  // ClaimTask() {
  //   this.ClaimTaskConfrimopened = false;
  //   this.taskOwnermodal = new TaskOwnermodal();
  //   this.taskOwnermodal.ProcessDataID = this.RecordID;
  //   this.taskOwnermodal.ClaimTaskStatus = true;
  //   this.taskOwnermodal.UserId = +this.GV.UserId;
  //   this.showLoader = true;
  //   this.moduleService.SaveProjectTaskOwnerGroup(this.taskOwnermodal).subscribe(data => {
  //     if (data == "success") {
  //       this.showLoader = false;
  //       this.notifyService.show("Task is claimed successfully");
  //       this.reloadParentDependentTemplate();
  //     }
  //   },
  //     error => {
  //       this.showLoader = false;
  //       this.notifyService.show("Task claimed updated failed !!. " + error.statusText, 4);
  //       console.log(error);
  //     })
  // }
}
