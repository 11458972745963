import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getBaseUrl } from '../../main';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private ogetAllActiveProjectDetails = getBaseUrl() + "api/reports/GetAllActiveProjects";
  private ogetUTCDate = getBaseUrl() + "api/reports/GetUTCDate";

  constructor(private httpClient: HttpClient) { }

  public AllActiveProjectDetails(ReportType: string, Params: string, UserID: number) {
    let paramslist = new HttpParams();
    paramslist = paramslist.set('ReportType', ReportType.toString());
    paramslist = paramslist.set('Params', Params.toString());
    paramslist = paramslist.set('UserID', UserID.toString());
    return this.httpClient.get<any>(this.ogetAllActiveProjectDetails, { params: paramslist });
  }
  public GetUTCDate() {
    return this.httpClient.get<any>(this.ogetUTCDate);
  }
  private updateUTC = new BehaviorSubject<boolean>(false);

  GMTReportCreatedTime = this.updateUTC.asObservable();
  setUTCTime(value: boolean) {
    this.updateUTC.next(value)
  }
}
