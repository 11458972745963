import { Component, Input, OnInit } from '@angular/core';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'app-dynamic-field-thumbnail',
  templateUrl: './dynamic-field-thumbnail.component.html',
  styleUrls: ['./dynamic-field-thumbnail.component.css']
})
export class DynamicFieldThumbnailComponent implements OnInit {

  @Input() physicalPath: string = '';
  @Input() physicalFilePath: string = '';
  @Input() template: any;

  documentsPath: string;
  docPath: string;
  openDocPath: string;
 
  thumbnailFilePath: string;
  thumbnailFilePath1: string;

  fileName: string;
 
  thumbnailFileName: string = '';

  groupsDialogpopID: boolean = false;

  title: string;
  myThumbnail: string;
  myFullresImage: string;
  date: any;

  constructor(private moduleService: ModuleService,) { }

  ngOnInit() {
    this.date = new Date().getTime();
    //this.physicalPath = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
    this.documentsPath = this.moduleService.getBaseUrl() + "documents/";
    this.docPath = this.moduleService.getBaseUrl();
    //this.physicalFilePath = this.documentsPath+ this.physicalPath.PhysicalFilePath

    //this.thumbnailFilePath = this.documentsPath+this.physicalPath.ThumbnailFilePath

    this.thumbnailFilePath = this.documentsPath + this.physicalPath + '?' + this.date;
    // this.thumbnailFilePath1 = this.documentsPath + this.physicalPath + '?' + this.date;s

    this.fileName = this.thumbnailFilePath.replace(/^.*[\\\/]/, '')
  }
  GroupsClose() {
    this.groupsDialogpopID = false;
  }

  openImage() {
   // this.groupsDialogpopID = true;
  
    this.documentsPath = this.moduleService.getBaseUrl() + "documents/";

    this.thumbnailFilePath1 = this.documentsPath + this.physicalPath;

    this.fileName = this.thumbnailFilePath1.replace(/^.*[\\\/]/, '');

    if(this.physicalFilePath.includes('Documents')){
      this.openDocPath = this.docPath + this.physicalFilePath + '?' + this.date;
    }else{
      this.openDocPath = this.documentsPath + this.physicalFilePath + '?' + this.date;
    }

    this.thumbnailFileName = this.fileName;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.openDocPath);
    link.setAttribute('download', this.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();

    //title = 'angular-img-hover';
    //this.thumbnailFileName = this.thumbnailFileName;//"https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
    //this.thumbnailFileName = this.thumbnailFileName;//"https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg";

  }
}
