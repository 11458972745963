import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { templateData } from '../../interfaces/ItemplateFields';
import { ModuleService } from '../../services/module.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamic-template-view',
  templateUrl: './dynamic-template-view.component.html',
  styleUrls: ['./dynamic-template-view.component.css']
})
export class DynamicTemplateViewComponent implements OnInit,OnChanges {
  displaytemplates: any;
    SelectedRecord: number=0;
  @Input() PassedmoduleID: number = 0;
  @Input() componentType: number = 0;
  @Input() RefreshGrid: number=0;
  CheckHasDependentsSubscription: Subscription = new Subscription();
  toogleActtions: any;

  constructor(private moduleService: ModuleService,) { }
  ngOnChanges() {
    this.RefreshGrid++;
  }
  ngOnDestroy() {
    this.CheckHasDependentsSubscription.unsubscribe();
  }
  ngOnInit() {
    this.moduleService.searchTemplateViewFilters.subscribe(searchData => {
      
      if (searchData.templateId == 0 || searchData.templateId == undefined || searchData.templateId == null ) {

      } else {
       

        //alert(searchData.searchFields);
        var tempDisplayTemplates = this.displaytemplates;
        this.displaytemplates = null;
        setTimeout(() => {
          if (tempDisplayTemplates !== null && tempDisplayTemplates !== "" && tempDisplayTemplates.length > 0) {
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilters = searchData.searchFields;
          tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].defaultSearch = searchData.defaultSearch;
          tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilterValues = searchData.searchFieldValues;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchName = searchData.searchName;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].highlightRecordID = null;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].selectedRecordID = null;

          }

          this.displaytemplates = tempDisplayTemplates;//.filter(a => a.hasParent == 0 && a.isDependent == 0 && a.displayControlsID != 0 && a.moduleID == this.moduleID);;
        }, 100);
      }

    });
    var templatedata  =new  templateData()
    templatedata.moduleID = this.PassedmoduleID;
    this.moduleService.GetTemplatesByModuleID(templatedata).subscribe(data => {

      this.displaytemplates = data.filter(a => a.hasParent == 0 && a.isDependent == 0 && a.displayControlsID != 0);
    });


    this.moduleService.dependencyTemplate.subscribe(data => {
   


     // if (data.componentType == "2") {


        //this.showLoader = true;
        this.SelectedRecord = data.selectedRecordId;


      if (!(data.moduleID == "0" && data.templateId == "0" && data.selectedRecordId == 0)) {

      if (data.componentType == "2") {

        //check for parent template
        this.moduleService.CheckIsParentTemplate(data.moduleID, data.templateId, data.targetTemplateId).subscribe(dataParent => {


          if (dataParent != null && dataParent.length > 0) {


            this.displaytemplates = null;
           


            // this.controltemplates = [];
            this.SelectedRecord = data.selectedRecordId;

            dataParent.forEach(function (part, index) {
              this[index].recordID = data.dependentRecordID;
              this[index].selectedRecordID = data.selectedRecordId;
            }, dataParent);


            this.displaytemplates = dataParent.filter(a => a.isDependent == 0);

        






          } else {
            //check  has dependents
            this.CheckHasDependentsSubscription = this.moduleService.CheckHasDependents(data.moduleID, data.templateId).subscribe(dataDependent => {

          

              if (dataDependent.isDependent != null && dataDependent.isDependent != "0") {

                // this.IsDisplayControls = false;


                setTimeout(() => {
                  this.SelectedRecord = data.selectedRecordId;
              


                  var templateUpdate = this.displaytemplates.filter(a => a.templateID == dataDependent.templateID);
                  var index = this.displaytemplates.indexOf(templateUpdate[0]);
                  if (index != -1) {

                    this.displaytemplates.splice(index, 1);
                    dataDependent.recordID = data.selectedRecordId;
                    dataDependent.selectedRecordID = data.selectedRecordId;
                    dataDependent.selectedRecordIDs = data.selectedRecordIds;
                    dataDependent.filteredRecordsSelected = data.filteredRecordsSelected;
                    this.displaytemplates.push(dataDependent);



                    this.displaytemplates.filter(a => a.templateID == data.templateId)[0].highlightRecordID = data.highlightRecordID;


                    var tempDisplayTemplates = this.displaytemplates;


                    tempDisplayTemplates.forEach(function (part, index) {

                      tempDisplayTemplates[index].recordType = data.recordType;
                      tempDisplayTemplates[index].collectionId = data.collectionId;
                    }, tempDisplayTemplates);



                    this.displaytemplates = tempDisplayTemplates;

                    //this.IsDisplayControls = true;

                  }
                }, 100);

              }
            });
          }
        });



        // this.SelectedRecord = data.selectedRecordId;
      }

        }



      //}


        //this.showLoader = false;
    

    });

     

    //get passed dependent template and push to existing template list


    this.moduleService.templateRecord.subscribe(data => {

      if (this.displaytemplates != null && this.displaytemplates != undefined && data.templateID != null) {
        var templateID = data.templateID;
        this.SelectedRecord = data.selectedRecordID;
        var templateUpdate = this.displaytemplates.filter(a => a.templateID == data.templateID);
        var index = this.displaytemplates.indexOf(templateUpdate[0]);
        if (index != -1) {
          this.displaytemplates.splice(index, 1);
        }

        //check template exist if not push the template
        var checkTempalte = this.displaytemplates.filter(a => a.templateID == templateID);

        if (checkTempalte.length == 0) {
          this.displaytemplates.push(data);

        }



        if (this.displaytemplates != null && this.displaytemplates != undefined) {
         
          this.displaytemplates.sort((a, b) => {
            return a.templateOrder - b.templateOrder;
          });
        }
      }





    });

    //get passed dependent template and push to remove template list

    this.moduleService.templateRecordRemove.subscribe(data => {


     


      if (this.displaytemplates != null && this.displaytemplates != undefined && data.templateID != null) {

        var templateID = data.templateID;

        this.SelectedRecord = data.selectedRecordID;

      


        var templateUpdate = this.displaytemplates.filter(a => a.templateID == data.templateID);
        var index = this.displaytemplates.indexOf(templateUpdate[0]);
        if (index != -1) {
          this.displaytemplates.splice(index, 1);


        }

      }
     

      if (this.displaytemplates != null && this.displaytemplates != undefined) {

        this.displaytemplates.sort((a, b) => {
          return a.templateOrder - b.templateOrder;
        });
      }



    });

    // check is dependent and refresh the parent dependent
    this.moduleService.dependentRecord.subscribe(dataDependent => {




      if (dataDependent.templateID != 0) {
       // this.IsDisplayControls = false;

        setTimeout(() => {


         

          var templateUpdate = this.displaytemplates.filter(a => a.templateID == dataDependent.templateID);
          var index = this.displaytemplates.indexOf(templateUpdate[0]);
          if (index != -1) {
            this.displaytemplates.splice(index, 1);
            templateUpdate[0].id = 0;
            this.displaytemplates.unshift(templateUpdate[0]);
          }

         // this.IsDisplayControls = true;


        }, 100);

      }

    });

    this.moduleService.getToogleActionsByModule(this.PassedmoduleID).subscribe(data => {

      this.toogleActtions = data;
    });
  }

  onToggleActionClick(template: any) {


    debugger;
    //geting aciton for toogle button
    let toogleActionClasss = this.toogleActtions.filter(a => a.templateId == template.templateID && a.targetTemplateId == template.templateID)[0].actionClass;

    if (document.getElementById("toogleAction" + template.templateID).classList.contains(toogleActionClasss)) {

      document.getElementById("toogleAction" + template.templateID).classList.remove(toogleActionClasss);

    }
    else {
      document.getElementById("toogleAction" + template.templateID).classList.add(toogleActionClasss);

    }


    let className = this.toogleActtions.filter(a => a.templateId == template.templateID && a.targetTemplateId == template.templateID)[0].className;

    if (document.getElementById("template" + template.templateID).classList.contains(className)) {
      this.toogleActtions.filter(a => a.templateId == template.templateID)
        .forEach(function (part) {
          document.getElementById("template" + part.targetTemplateId).classList.remove(part.className);
        });
    }
    else {
      this.toogleActtions.filter(a => a.templateId == template.templateID)
        .forEach(function (part) {
          document.getElementById("template" + part.targetTemplateId).classList.add(part.className);
        });

    }
  }

}
