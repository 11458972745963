import { Component, Input, OnInit } from '@angular/core';
import { GlobalVeriables } from '../../Global/GlobalVaribales';
import { breadCrumbs } from '../../interfaces/ItemplateFields';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'app-dynamic-summary-view',
  templateUrl: './dynamic-summary-view.component.html',
  styleUrls: ['./dynamic-summary-view.component.css']
})
export class DynamicSummaryViewComponent implements OnInit {
  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  breadcrumbs: Array<breadCrumbs>;

  constructor(private moduleService: ModuleService, private GV: GlobalVeriables,) { }

  ngOnInit(): void {

    this.moduleService.breadCrumbs.subscribe(breadcrumbs => {



      var templateID = this.template.templateID;
      var displayControlsID = this.template.displayControlsID;
      var depeendentTemplateID = this.template.isDependent;
      var parentTemplateID = this.template.hasParent;


      this.breadcrumbs = breadcrumbs.filter(a => a.templateId == depeendentTemplateID || a.templateId == parentTemplateID);//|| (a.templateId == templateID && a.displayControlsID == displayControlsID)

      //this.breadcrumbs.filter(a => a.templateId == templateID && a.displayControlsID == displayControlsID)[0].displayName = "darshan";


    });


    this.moduleService.breadCrumbName.subscribe(breadcrumb => {
      if (!this.GV.GetIsRedirection())
      if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {



        var displayData = this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID);

        if (displayData == null || displayData == undefined || displayData.length == 0) {

        } else {

          this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;
        }
      }


    });

  }

  getShortName(strText) {
    var strTxt = "";
    if (strText.length >= 50)
      strTxt = strText.slice(0, 50).concat('...');
    else
      strTxt = strText;
    return strTxt;
  }

}
