import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { ACMIDetails, AuditTrailsDTOData } from 'src/app/services/AMFormsDataDetails';
import { ModuleService } from 'src/app/services/module.service';
import { NotificationAlertsService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-acmlist',
  templateUrl: './acmlist.component.html',
  styleUrls: ['./acmlist.component.css']
})
export class ACMListComponent implements OnInit {
  @Input() public template: any;
  @Input() public templateID: any;
  @Input() public fromTemplateMode:any;
  @Output() public closemodel= new EventEmitter();
  Message: string;
  dynOperationHeader: string;
  IsExportExcel:boolean=false;
  EnableFilter: boolean = false;
  projectName:string;
  IsOpen:boolean=false;
  opened:boolean=false;
  btnActions:boolean=false;
  btnEditAction:boolean=false;
  btnDeleteAction:boolean=false;
  submitted:boolean=false;
  IsAdd:boolean=false;
  IsNewMaterialCodeRequired:boolean;
  IsNewVersionRequired:boolean;
  Recordid=0;
  savebtn:boolean=false;
  updatebtn:boolean=false;
  resmodal=new ACMIDetails();
  Projectdetails=new ACMIDetails();
  ResponseData: ACMIDetails[] = [];
  constructor(private moduleService: ModuleService,private formbuilder:FormBuilder,private notifyService: NotificationAlertsService,) {
    this.allData = this.allData.bind(this);
   }
  submitform=this.formbuilder.group(
    {
      Requestcontactinfo:[''],
      submitdate:[new Date()]
    }
  )
  UserDyForm = this.formbuilder.group({
    MaterialCodes: ['', [Validators.required, ]],
    BottleSizeorBlisterConfiguration: ['', [Validators.required]],
    Disposition: ['', [Validators.required]],
    FinishGoodsCode: ['', [Validators.required]],
    RequestorContactInfo: [''],
    FormCompletedonDate: [''],
    Description: ['', [Validators.required]],
    NewMaterialCodeRequired: [false],
    NewVersionRequired: [false]
   

  });

  ngOnInit(): void {
    this.IsOpen=true;
    this.Recordid=this.templateID;
    this.moduleService.GetMaterialDetails(this.Recordid).subscribe(
      (res: any)=>{
        debugger;
        this.IsExportExcel=true;
        this.EnableFilter=false;
        this.ResponseData=res.listprojects;
        this.Projectdetails=res.projectDetails;
        this.projectName=res.projectName;
        if(res.projectDetails.requestorContactInfo!=undefined)
        this.submitform.controls['Requestcontactinfo'].setValue(res.projectDetails.requestorContactInfo);
        if(res.projectDetails.formCompletedonDate!=null && res.projectDetails.formCompletedonDate!=undefined)
        {
          this.value=new Date(res.projectDetails.formCompletedonDate);
          this.submitform=this.formbuilder.group(
            {
              Requestcontactinfo:[res.projectDetails.requestorContactInfo],
              submitdate:[this.value]
            });
        }
        if(res.projectDetails.formCompletedonDate=='0001-01-01T00:00:00')
        {
          this.value=new Date();
          this.submitform=this.formbuilder.group(
            {
              Requestcontactinfo:[res.projectDetails.requestorContactInfo],
              submitdate:[this.value]
            });
        }
        
        if(this.txtvalue==undefined)
        {
          this.txtvalue='';
        }
        this.btnActions=true;
        this.btnEditAction = true;
        this.btnDeleteAction = true;
      }
    );
    
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.ResponseData, {
      }).data,

    };

    return result;

  }
  EnableDisableFilter() {
    debugger;
    if (this.EnableFilter == true)
      this.EnableFilter = false;
    else
      this.EnableFilter = true;
  }
  onload()
  {
  
    this.moduleService.GetMaterialDetails(this.Recordid).subscribe(
      (res: any)=>{
        debugger;
        this.ResponseData=res.listprojects;
        this.Projectdetails=res.projectDetails;
        this.projectName=res.projectName;
        if(res.projectDetails.requestorContactInfo!=undefined && res.projectDetails.requestorContactInfo!=null)
        this.submitform.controls['Requestcontactinfo'].setValue(res.projectDetails.requestorContactInfo);
        this.IsExportExcel=true;
       // this.EnableFilter=true;
       this.EnableFilter=false;
        if(res.projectDetails.formCompletedonDate!=null && res.projectDetails.formCompletedonDate!=undefined)
        {
          this.value=new Date(res.projectDetails.formCompletedonDate);
          
          this.submitform=this.formbuilder.group(
            {
              Requestcontactinfo:[res.projectDetails.requestorContactInfo],
              submitdate:[this.value]
            });

        }
        if(res.projectDetails.formCompletedonDate=='0001-01-01T00:00:00')
        {
          this.value=new Date();
          this.submitform=this.formbuilder.group(
            {
              Requestcontactinfo:[res.projectDetails.requestorContactInfo],
              submitdate:[this.value]
            });
        }
        else
        {
          this.submitform=this.formbuilder.group(
            {
              Requestcontactinfo:[res.projectDetails.requestorContactInfo],
              submitdate:[this.value]
            });
        }
        
        if(this.txtvalue==undefined)
        {
          this.txtvalue='';
        }
        this.IsOpen=true;
        this.btnActions=true;
        this.btnEditAction = true;
        this.btnDeleteAction = true;
        this.closemodel.emit(true);
      }
    );
  }
  acmiaddclose()
  {
    this.IsAdd=false;
    this.closemodel.emit(true);
    this.onload();
  }
  acmiclose() {
 
   // this.AuditLogId = false;
   this.IsOpen=false;
    this.closemodel.emit(false);
  }
  public value: Date = new Date();
  txtvalue:string;
  public format = "dd-MMM-yyyy";
  addrecord()
  {
    debugger;
    this.dynOperationHeader = "Add ACMI Info.";
    this.UserDyForm.reset();
    
    this.Message="";
   
    this.IsAdd=true;
    this.IsOpen=false;
    this.savebtn=true;
    this.updatebtn=false;
    // this.resmodal.RequestorContactInfo=this.submitform.controls['Requestcontactinfo'].value;
    // this.resmodal.FormCompletedonDate=this.value;
  }

  remove(dataitem)
  {
    this.opened=true;
    this.resmodal.ID=dataitem.id;
  }

  public close(status: string): void {
    
    this.opened = false;
    if(status=="yes")
    {
      this.resmodal.type=+3;
      this.resmodal.BottleSizeorBlisterConfiguration='';
    this.resmodal.Description='';
    this.resmodal.Disposition='';
    this.resmodal.MaterialCodes='';
    this.resmodal.FinishGoodsCode='';
    this.resmodal.NewMaterialCodeRequired=false;
    this.resmodal.NewVersionRequired=false;
    this.resmodal.ProjectID=+this.Recordid;
      this.moduleService.saveorupdate(this.resmodal).subscribe(
        result=>{
          this.UserDyForm.reset();
          if(status=='yes')
        this.notifyService.show("ACMI Info are  Removed successfully!", 1);

          this.moduleService.GetMaterialDetails(this.Recordid).subscribe(
            (res: any)=>{
              this.IsExportExcel=true;
              this.EnableFilter=false;
              this.ResponseData=res.listprojects;
              this.Projectdetails=res.projectDetails;
              this.projectName=res.projectName;
              if(res.projectDetails.requestorContactInfo!=undefined)
        this.submitform.controls['Requestcontactinfo'].setValue(res.projectDetails.requestorContactInfo);

        if(res.projectDetails.formCompletedonDate!=null && res.projectDetails.formCompletedonDate!=undefined)
        {
          this.value=new Date(res.projectDetails.formCompletedonDate);
          this.submitform=this.formbuilder.group(
            {
              Requestcontactinfo:[res.projectDetails.requestorContactInfo],
              submitdate:[this.value]
            });
        }
        
        if(this.txtvalue==undefined)
        {
          this.txtvalue='';
        }
              this.btnActions=true;
              this.btnEditAction = true;
              this.btnDeleteAction = true;
            });
        }
      )
    }
    if(status=="submit")
    {
      this.resmodal.type=+4;
      this.resmodal.BottleSizeorBlisterConfiguration='';
    this.resmodal.Description='';
    this.resmodal.Disposition='';
    this.resmodal.MaterialCodes='';
    this.resmodal.FinishGoodsCode='';
    this.resmodal.NewMaterialCodeRequired=false;
    this.resmodal.NewVersionRequired=false;
    this.resmodal.ProjectID=+this.Recordid;
    this.resmodal.RequestorContactInfo=this.submitform.controls['Requestcontactinfo'].value;
    this.resmodal.FormCompletedonDate=this.submitform.controls['submitdate'].value;
    debugger;
    if(this.resmodal.RequestorContactInfo!=null && this.resmodal.FormCompletedonDate!=null)
    {
      this.moduleService.saveorupdate(this.resmodal).subscribe(
        result=>{
          this.UserDyForm.reset();
          if(status=='submit')
          this.notifyService.show("ACMI Info are  submiteed successfully!", 1);
          this.moduleService.GetMaterialDetails(this.Recordid).subscribe(
            (res: any)=>{
              this.IsExportExcel=true;
              this.EnableFilter=false;
              this.ResponseData=res.listprojects;
              this.Projectdetails=res.projectDetails;
              this.projectName=res.projectName;
              if(res.projectDetails.requestorContactInfo!=undefined)
        this.submitform.controls['Requestcontactinfo'].setValue(res.projectDetails.requestorContactInfo);

        if(res.projectDetails.formCompletedonDate!=null && res.projectDetails.formCompletedonDate!=undefined)
        {
          this.value=new Date(res.projectDetails.formCompletedonDate);
          this.submitform=this.formbuilder.group(
            {
              Requestcontactinfo:[res.projectDetails.requestorContactInfo],
              submitdate:[this.value]
            });
        }
        if(res.projectDetails.formCompletedonDate=='0001-01-01T00:00:00')
        {
          this.value=new Date();
          this.submitform=this.formbuilder.group(
            {
              Requestcontactinfo:[res.projectDetails.requestorContactInfo],
              submitdate:[this.value]
            });
        }
        
        if(this.txtvalue==undefined)
        {
          this.txtvalue='';
        }
              this.btnActions=true;
              this.btnEditAction = true;
              this.btnDeleteAction = true;
            });
        }
      )

    }
      
    }
  }
  edit(dataItem,type)
  {
    this.dynOperationHeader = "Edit ACMI Info.";

    debugger;
    this.UserDyForm.controls['BottleSizeorBlisterConfiguration'].setValue(dataItem.bottleSizeorBlisterConfiguration);;
    this.UserDyForm.controls['Description'].setValue(dataItem.description);
    this.UserDyForm.controls['Disposition'].setValue(dataItem.disposition);
    this.UserDyForm.controls['MaterialCodes'].setValue(dataItem.materialCodes);
    this.UserDyForm.controls['FinishGoodsCode'].setValue(dataItem.finishGoodsCode);
    this.UserDyForm.controls['NewMaterialCodeRequired'].setValue(dataItem.newMaterialCodeRequired);
    this.UserDyForm.controls['NewVersionRequired'].setValue(dataItem.newVersionRequired);
    this.resmodal.ID=+dataItem.id;
    this.IsAdd=true;
    this.IsOpen=false;
    this.savebtn=false;
    this.updatebtn=true;
   // this.resmodal.ProjectID=+this.Recordid;
  }
  submit()
  {
    this.close("submit");
  }
  saveOrUpdateUserData(data:any)
  {
    debugger;
    if (this.UserDyForm.invalid) {
      /* debugger;*/
      this.submitted = true;
      return;
    }
    this.resmodal.BottleSizeorBlisterConfiguration=this.UserDyForm.controls['BottleSizeorBlisterConfiguration'].value;
    this.resmodal.Description=this.UserDyForm.controls['Description'].value;
    this.resmodal.Disposition=this.UserDyForm.controls['Disposition'].value;
    this.resmodal.MaterialCodes=this.UserDyForm.controls['MaterialCodes'].value;
    this.resmodal.FinishGoodsCode=this.UserDyForm.controls['FinishGoodsCode'].value;
    this.resmodal.NewMaterialCodeRequired=this.UserDyForm.controls['NewMaterialCodeRequired'].value;
    this.resmodal.NewVersionRequired=this.UserDyForm.controls['NewVersionRequired'].value;
    if(this.resmodal.Description==null)
    this.resmodal.Description='';

    if(this.resmodal.Disposition==null)
    this.resmodal.Disposition='';

    if(this.resmodal.BottleSizeorBlisterConfiguration==null)
    this.resmodal.BottleSizeorBlisterConfiguration='';

    if(this.resmodal.NewMaterialCodeRequired==null)
    this.resmodal.NewMaterialCodeRequired=false;


    if(this.resmodal.NewVersionRequired==null)
    this.resmodal.NewVersionRequired=false;

    this.resmodal.RequestorContactInfo=this.submitform.controls['Requestcontactinfo'].value;

    if(this.resmodal.RequestorContactInfo==null)
    this.resmodal.RequestorContactInfo='';

    this.resmodal.FormCompletedonDate=this.submitform.controls['submitdate'].value;

    this.resmodal.ProjectID=+this.Recordid;
    if(data=='save')
    {
      this.resmodal.type=+1;
    }
    else if(data=='update')
    {
      this.resmodal.type=+2;
    }
    
    
    this.moduleService.saveorupdate(this.resmodal).subscribe(
      result=>{
        this.UserDyForm.reset();
        if(data=='save')
    
        this.notifyService.show("ACMI Info are added successfully!", 1);
        if(data=='update')
        {
          this.notifyService.show("ACMI Info are  update successfully!", 1);
          this.acmiaddclose();
        }
        
      }
    )
 
      
    
  }


}
