 import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { HttpClient } from '@angular/common/http';
import { ModuleService } from '../../../services/module.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicFieldControlService } from '../../dynamic-fields-service/dynamic-field-control.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { DialogService } from '@progress/kendo-angular-dialog';
/*import { LanguageService } from '../../../services/language.service';*/
import { LanguageService } from 'src/app/services/language.service';
import { ChartComponent, LegendLabelsContentArgs, LegendTitle } from '@progress/kendo-angular-charts';
import { ReportsService } from '../../../services/reports.service';
import { saveAs } from "@progress/kendo-file-saver";
import { Router } from '@angular/router';
@Component({
  selector: 'app-dynamic-field-column-chart',
  templateUrl: './dynamic-field-column-chart.component.html',
  styleUrls: ['./dynamic-field-column-chart.component.css']
})
export class DynamicFieldColumnChartComponent implements OnInit {

  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  showLoader: boolean = false;
  public dynamicForm: FormGroup;
  columns: any;
  SearchName: string = "";
  IsSearchedApplied: string = "";
  records: any;
  filteredRecords: any;
  @ViewChild("chart1")
  chart1: ChartComponent;

  charttype: any = { type: 'column' };
  chartStakedtype: string = 'group';
  public chartTypeItems: any[] = [
    { key: "Column", value: "column" },
    { key: "Bar", value: "bar" },
  ];
  public GroupStackedItems: any[] = [
    { key: "Group", value: "group" },
    { key: "Stack", value: "stack" },
  ];
  public Stacked: boolean = false;
  public categories: any;
  public showSeries: boolean = false;
  public model = [];
  public modelres: any;// = [];
  public TChartPiedata: any;
  public TChartdata: any;
  GMTLoadDateTime: string = '';
  GMTReportCreatedTime: string = '';
  showdatetime: boolean = false;
  NoData: boolean = false;
  loadChart: boolean = false;
  IsExportExcel: any;
  public mySelection: number[] = [0];
  public fData: any;
  loadfilesave: boolean = false;
  loadPieChartfilesave: boolean = false;
  txtFilenamevalue: string = "chart";
  txtPieChartFilenamevalue: string = "pie chart";
  TotalPrj: number = 0;
  chartannotateshow = false;
  searchfiltershow = false;
  anchornewshow = false;
  anchorpiechartshow = false;
  columnsWithAlias = [];
  chatHaederName: string = 'Projects Selected';
  chatHeaderkey: string = 'STC_PRJSEL';
  public xCategory: any;
  @ViewChild("annotateanchor") public annotateanchor: ElementRef;
  @ViewChild("annotatepopup", { read: ElementRef }) public annotatepopup: ElementRef;
  @ViewChild('anchornew') public anchornew: ElementRef;
  @ViewChild('popupnew', { read: ElementRef }) public popupnew: ElementRef;
  
  constructor(private dataService: DynamicFieldService,
    private notifyService: NotificationAlertsService,
    private commonfeaturesservice: CommonFeaturesService,
    private qcs: DynamicFieldControlService,
    private http: HttpClient,
    private moduleService: ModuleService,
    public intl: IntlService, private dialogService: DialogService, private reportservice: ReportsService,
    private GV: GlobalVeriables,
    private router: Router,
    private languageService: LanguageService) {
    this.labelContent = this.labelContent.bind(this);
  }

  ngOnInit(): void {
    this.NoData = false;
    var temp = this.template;
    this.dynamicForm = new FormGroup({
      ddlcharttypevalue: new FormControl(''),
      ddlchartgrptypevalue: new FormControl(''),
      txtFilename: new FormControl(''),
      txtPieChartFilename: new FormControl(''),
      RbChartType: new FormControl(''),
      RbChartGroupType: new FormControl('')
    });
    this.reportservice.GetUTCDate().subscribe((dateresult) => {
      this.GMTLoadDateTime = dateresult
    });


    this.charttype = "column";
    this.chartStakedtype = "group";
    this.dynamicForm.controls['ddlcharttypevalue'].setValue(this.charttype);
    this.dynamicForm.controls['ddlchartgrptypevalue'].setValue(this.chartStakedtype);
    this.dynamicForm.controls['RbChartType'].setValue(this.charttype);
    this.dynamicForm.controls['RbChartGroupType'].setValue(this.chartStakedtype);
    this.filteredRecords = [];

    this.loadTemplate();
  }
  loadTemplate() {
    this.showLoader = true;

    this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 0, this.template.searchFilters, 'Default', 0, 0, "", this.template.chkFields, null).subscribe(data => {

        if (data.record == null && data.columns == null && data.displayProperties == null) {
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW',"Something went wrong. Please try again "), 4);
          this.showLoader = false;

          return;
        }

        this.SearchName = this.template.searchName;

      if (this.template.searchFilters == "" || this.template.searchFilters == [] || this.template.searchFilters == undefined) {
          this.IsSearchedApplied = "";
        } else {
        this.IsSearchedApplied = this.template.moduleID == "30" ? this.template.searchFilters : this.template.searchFilterValues;

      }
      localStorage.setItem("selectedSearchReportValues", this.IsSearchedApplied);
        this.records = JSON.parse(data.record);
        this.filteredRecords = this.records;
        this.columns = data.columns;
        //this.displayActions = data.displayActions;
        this.LoadData(this.filteredRecords);
        this.showLoader = false;


      });

  }
  LoadData(tChartdata: any = []) {
    this.xCategory = [];
    this.filteredRecords = tChartdata;
    this.modelres = [];
    this.categories = [];
    this.showdatetime = false;
    this.TChartPiedata = [];
    this.TChartdata = [];
    if (this.filteredRecords.length > 0) {
      this.columns = Object.keys(this.filteredRecords[0]);
      var chartdata = tChartdata;
      this.TChartdata = tChartdata;
      var StatusList: any;
      var model12 = [];
      this.chatHeaderkey = this.templateID == 87 ? 'STC_REJREP' : this.chatHeaderkey;
      this.chatHaederName = this.templateID == 87 ? 'Rejection Reason Reports' : this.chatHaederName;
        this.TotalPrj = this.TChartdata.filter(x => x).reduce((sum, item) => sum + item.Yaxis, 0);
        StatusList = chartdata.map(v => v.Status).filter((item, i, ar) => ar.indexOf(item) === i) //another method to get distinct values from array object
      this.categories = StatusList;
      this.xCategory = chartdata.map(v => v.Yaxis).filter((item, i, ar) => ar.indexOf(item) === i);
        StatusList.forEach(function (value) {
          let fData1 = chartdata.filter(
            x => x.Status === value);
          let nameL = '';
          let colorCode = '';
          if ((fData1[0].RejectReason) != undefined) {
            nameL = fData1[0].RejectReason;
            colorCode = fData1[0].ReasonColor != null ? fData1[0].ReasonColor : '#' + Math.floor(Math.random() * 16777215).toString(16);
          }
          else {
            nameL = value;
            if (value == 'InTime' || value == 'Early')
              colorCode = '#6ad49b';
            else colorCode = '#f1416c';
          }
          model12.push({ name: String(nameL), data: fData1, color: colorCode });
        });

      if (this.templateID == 87 && this.TotalPrj == 0) {
        this.modelres = [];
        this.NoData = true;
      }
      else {
        this.modelres = model12;
        this.showdatetime = true;
        this.loadChart = true;
        this.loadPieChartfilesave = false;
        this.loadfilesave = false;
        this.NoData = false;
        this.reportservice.GetUTCDate().subscribe((dateresult) => {
          this.GMTReportCreatedTime = dateresult
        });
      }
    }
    else {
      this.NoData = true;
    }
    this.showLoader = false;
  }

  public onTogglechartannotate(chartannotateshow?: boolean): void {
    //this.chartannotateshow = !this.chartannotateshow;
    this.chartannotateshow = chartannotateshow != undefined ? chartannotateshow : !this.chartannotateshow;
  }
  public onTogglesearchfilter(searchfiltershow?: boolean): void {
    //this.chartannotateshow = !this.chartannotateshow;
    this.searchfiltershow = searchfiltershow != undefined ? searchfiltershow : !this.searchfiltershow;
  }

  public onToggleanchornew(anchornewshow?: boolean): void {

    //this.chartannotateshow = !this.chartannotateshow;
    this.anchornewshow = anchornewshow != undefined ? anchornewshow : !this.anchornewshow;
    if (this.anchornewshow) {
      this.exportChart('chart');
    }

  }
  public onToggleanchorpiechart(anchorpiechartshow?: boolean): void {

    //this.chartannotateshow = !this.chartannotateshow;
    this.anchorpiechartshow = anchorpiechartshow != undefined ? anchorpiechartshow : !this.anchorpiechartshow;
    if (this.anchorpiechartshow) {
      this.exportChart('piechart');
    }

  }
  public closechartannotate(): void {
    this.chartannotateshow = false;
  }
  public closesearchfilter(): void {
    this.searchfiltershow = false;
  }

  private contains(target: any): boolean {
    return (
      this.annotateanchor.nativeElement.contains(target) ||
      (this.annotatepopup ? this.annotatepopup.nativeElement.contains(target) : false)
    );
  }
  GetTranslatedStatus(status: string) {
    return this.languageService.GetActionMessageByLanguage('STC_' + status.toUpperCase(), status);
  }
  public labelContent(args: LegendLabelsContentArgs): string {
      let status= this.GetTranslatedStatus(args.dataItem.Status);
      if (this.templateID == "87") {
        return `${status}: ${this.intl.formatNumber(
          args.dataItem.RejectReason, "0"
        )}`;
      }
      else {
        return `${status}: ${this.intl.formatNumber(
          args.dataItem.Status, "0"
        )}`;
      }
    
  }
  public legendTitle: LegendTitle = {
    text: "Status",
  };
  exportChart(selecvalue: any) {
    if (selecvalue == 'piechart')
      this.loadPieChartfilesave = true;
    else
      this.loadfilesave = true;
  }
  exportfileChart(selecvalue: any) {
    this.chart1.exportImage().then((dataURI) => {
      saveAs(dataURI, this.txtFilenamevalue != '' ? this.txtFilenamevalue : 'chart' + ".png");

      this.loadfilesave = false;
      this.txtFilenamevalue = "chart";
    });

  }
  exportfilecancelChart(selecvalue: any) {
    if (selecvalue == 'piechart') {
      this.loadPieChartfilesave = false;
      this.txtPieChartFilenamevalue = "pie chart";
      this.anchorpiechartshow = false;
    }
    else {
      this.loadfilesave = false;
      this.txtFilenamevalue = "chart";
      this.anchornewshow = false;
    }
  }
  colorFn(e) {
    //console.log(e);
    if (e.category == 'InTime' || e.dataItem.Status == 'InTime' || e.category == 'Early' || e.dataItem.Status == 'Early' || e.dataItem.Status == 'Active')
      return '#6ad49b';
    else if (e.category == 'Late' || e.dataItem.Status == 'Late' || e.dataItem.Status == 'Inactive')
      return '#f1416c';
    else {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
  }
  LegendNames(e) {
  if (e.category == 'InTime' || e.dataItem.Status == 'InTime' || e.category == 'Early' || e.dataItem.Status == 'Early' || e.dataItem.Status == 'Active')
    return '#6ad49b';
  else if (e.category == 'Late' || e.dataItem.Status == 'Late' || e.dataItem.Status == 'Inactive')
    return '#f1416c';
  else {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
       }
  }
  changeChartType(e) {
    //console.log(e.target.value);
    this.chartChange(e.target.value);
  }
  changeChartGroupType(e) {
    //console.log(e.target.value);
    this.chartGrpChange(e.target.value);
  }
  chartChange(selectedvalue: any) {
    this.charttype = selectedvalue;
    this.loadChart = false;
    this.LoadData(this.filteredRecords);
    //this.LoadFilterData('AAPR', this.SearchFilterData);
  }
  chartGrpChange(selectedvalue: any) {
    this.chartStakedtype = selectedvalue;
    if (selectedvalue == 'stack')
      this.Stacked = true;
    else
      this.Stacked = false;

    this.loadChart = false;
    this.LoadData(this.filteredRecords);
    //this.LoadFilterData('AAPR', this.SearchFilterData);
  }
  BackAction() {


    //this.router.navigate([module.routingName]);
    this.router.navigate(["Reports"], { replaceUrl: true });
  }
  /*--Start Chart zoom js code--*/
  ZoominAction() {
    var zoomdiv = $(".div-area-zoom");
    zoomdiv.addClass("zoomdivone");
    var hide = $(".zoom_in");
    hide.addClass("znone");

    var disp = $(".zoom_out");
    disp.addClass("zdplay");
  }

  ZoomoutAction() {
    var zoomdiv = $(".div-area-zoom");
    zoomdiv.removeClass("zoomdivone");
    var hide = $(".zoom_in");
    hide.removeClass("znone");

    var disp = $(".zoom_out");
    disp.removeClass("zdplay");
  }

  ZoominAction2() {
    var zoomdiv = $(".div-area-zoom2");
    zoomdiv.addClass("zoomdivone2");
    var hide = $(".zoom_in");
    hide.addClass("znone");

    var disp = $(".zoom_out");
    disp.addClass("zdplay");
  }

  ZoomoutAction2() {
    var zoomdiv = $(".div-area-zoom2");
    zoomdiv.removeClass("zoomdivone2");
    var hide = $(".zoom_in");
    hide.removeClass("znone");

    var disp = $(".zoom_out");
    disp.removeClass("zdplay");
  }
  
  /*--End Chart zoom js code--*/

}
export class OpenReportType {
  type: string;
  dataItem: string;
}


