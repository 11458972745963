import { Component, Input, OnInit } from '@angular/core';
import { debug } from 'console';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';
import { templateSelectedRecord } from '../../../dynamic-form-view/dynamic-form-view.component';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { ModuleService } from '../../../services/module.service';

@Component({
  selector: 'app-dynamic-field-tree',
  templateUrl: './dynamic-field-tree.component.html',
  styleUrls: ['./dynamic-field-tree.component.css']
})
export class DynamicFieldTreeComponent implements OnInit {
  public records: any[];
  public selectedKeys: any[] = ["-1"];
  public isDisableKeys:any[]=[];

  @Input() public template: any;
  @Input() public recordID: any;
  @Input() public templateID: any;
  @Input() public componentType: any;
  dalFileCollections: any;
  selectedFileCollection: number;
  ChkViewAllFiles: boolean = true;
  nodeDisable:boolean=false;

  public defaultItem: { name: string; id: number } = {
    name: "--Select--",
    id: 0,
  };

  public disabledKeys: any[] = [];
  constructor(private moduleService: ModuleService, private languageService: LanguageService, private GV: GlobalVeriables) { }

  ngOnInit() {
    this.loadDalCollection();

  }


  loadDalCollection() {
    //refresh DAL COllections

    this.moduleService.dalCollection.subscribe(dalData => {


      this.moduleService.getDalFileCollections(this.GV.UserId).subscribe(data => {

        this.dalFileCollections = data;
      });
    });

    // refresh DAL File collection list after removing from list collection

    this.moduleService.dalFileCollection.subscribe(dalFileDat => {

      if (dalFileDat != 0 && dalFileDat != null && dalFileDat != undefined) {
        let selectedItem = new templateSelectedRecord();
        selectedItem.templateId = this.template.templateID;

        selectedItem.selectedRecordId = dalFileDat;
        selectedItem.moduleID = this.template.moduleID;
        selectedItem.dependentRecordID = this.template.recordID;
        selectedItem.highlightRecordID = dalFileDat;
        selectedItem.recordType = 2;//from collection
        selectedItem.componentType = this.componentType;
        selectedItem.collectionId = dalFileDat.toString();


        this.moduleService.passDependencyTemplate(selectedItem);

      }
    });

    this.moduleService.GetTreeData(this.template.moduleID, this.template.templateID, this.GV.UserId).subscribe(dataParent => {



      this.records = dataParent;



      //#region check for dependent template and get add to template list for loading dependent template

      this.moduleService.CheckHasDependents(this.template.moduleID, this.template.templateID).subscribe(data => {



        if (data.isDependent != null && data.isDependent != "0") {
          //this.selectedRecordID = this.records[0].ID;
          data.recordID = this.records[0].id;
          data.selectedRecordID = this.records[0].id;
          data.selectedTemplateId = this.templateID;
          //this.showLoader = false;

          this.moduleService.passTemplateRecord(data);

        } else {

        }



        setTimeout(() => {


          if (this.ChkViewAllFiles) {
            let selectedItem = new templateSelectedRecord();
            selectedItem.templateId = this.template.templateID;

            selectedItem.selectedRecordId = 0
            selectedItem.moduleID = this.template.moduleID;
            selectedItem.dependentRecordID = this.template.recordID;
            selectedItem.highlightRecordID = 0
            selectedItem.recordType = 1;//from tree select
            selectedItem.componentType = this.componentType;

            this.moduleService.passDependencyTemplate(selectedItem);
          } else {
            let selectedItem = new templateSelectedRecord();
            selectedItem.templateId = this.template.templateID;

            selectedItem.selectedRecordId = -4;//by deault selecting place hoder files
            //selectedItem.selectedRecordId = this.records[0].id;
            selectedItem.moduleID = this.template.moduleID;
            selectedItem.highlightRecordID = 0

            selectedItem.dependentRecordID = this.template.recordID;
            selectedItem.componentType = this.componentType;
            this.moduleService.passDependencyTemplate(selectedItem);
          }


        }, 100);

      });

      //#endregion

    });


    this.selectedFileCollection = 0;

  }

  public children = (dataitem: any): Observable<any[]> => of(dataitem.items);

  public isExpanded = (dataItem: any, index: string) => {

    return dataItem.expanded;
  };
  public hasChildren = (dataitem: any): boolean => !!dataitem.items;
  public handleSelection(dataItem): void {
    if (dataItem.dataItem.accessType == "1") {


      let selectedItem = new templateSelectedRecord();
      selectedItem.templateId = this.template.templateID;

      selectedItem.selectedRecordId = dataItem.dataItem.id;
      selectedItem.moduleID = this.template.moduleID;
      selectedItem.dependentRecordID = this.template.recordID;
      selectedItem.highlightRecordID = dataItem.dataItem.id;
      selectedItem.recordType = 1;//from tree select
      selectedItem.componentType = this.componentType;

      this.moduleService.passDependencyTemplate(selectedItem);
    }



  }

  public selectionChange(value: any): void {
    this.selectedFileCollection = value.id;
    if (value.id != 0) {


      let selectedItem = new templateSelectedRecord();
      selectedItem.templateId = this.template.templateID;

      selectedItem.selectedRecordId = value.id;
      selectedItem.moduleID = this.template.moduleID;
      selectedItem.dependentRecordID = this.template.recordID;
      selectedItem.highlightRecordID = value.id;
      selectedItem.recordType = 2;//from collection
      selectedItem.componentType = this.componentType;
      selectedItem.collectionId = value.id;


      this.moduleService.passDependencyTemplate(selectedItem);
    } else {



      let selectedItem = new templateSelectedRecord();
      selectedItem.templateId = this.template.templateID;

      selectedItem.selectedRecordId = -1;
      selectedItem.moduleID = this.template.moduleID;
      selectedItem.dependentRecordID = this.template.recordID;
      selectedItem.highlightRecordID = -1;
      selectedItem.recordType = 1;//from tree select
      selectedItem.componentType = this.componentType;
      selectedItem.collectionId = "0";

      this.selectedKeys = ["-1"];


      this.moduleService.passDependencyTemplate(selectedItem);
    }

  }

  getDisableIds(node: any) {
    if (node.accessType == "3") {
      this.disabledKeys.push(node.id)
    }

    if (node.hasChildren) {
      node.children.forEach(childNode => {
        this.getDisableIds(childNode);
      });
    }
  }

  onChhkChange(e) {

    this.nodeDisable= this.GV.processTemplateID =="Cancellation" ? true : false;

    if (this.ChkViewAllFiles) {
      this.selectedFileCollection = 0;
      this.selectedKeys = ["-1"];

      this.loadAllDAlFiles();
    } 
    else if (this.nodeDisable)
    {
    
      this.selectedKeys = ["0_0_1"];
      this.isDisableKeys=["0_0_0"]
       let selectedItem = new templateSelectedRecord();
      selectedItem.templateId = this.template.templateID;
      selectedItem.selectedRecordId = -3
      selectedItem.moduleID = this.template.moduleID;
      selectedItem.dependentRecordID = this.template.recordID;
      selectedItem.highlightRecordID = -3;
      selectedItem.recordType = 1;//from tree select
      selectedItem.componentType = this.componentType;
      this.moduleService.passDependencyTemplate(selectedItem);
      
       this.moduleService.passdalFileCollection(-3);  
      this.nodeDisable=false;
    }
    else {
        this.selectedKeys = ["0_0_0"];
        this.isDisableKeys=[]
      this.loadDalCollection();
    }
  }

  onPrivilegeCheck(accessType: any) {
    var returnClass = "";

    if (accessType == '1') {
      // returnClass = "display-block";
    } else if (accessType == '2') {
      returnClass = "disable-treenode";

    } else if (accessType == '3') {
      returnClass = "display-none";

    }
    return returnClass;
  }

  loadAllDAlFiles() {

    let selectedItem = new templateSelectedRecord();
    selectedItem.templateId = this.template.templateID;

    selectedItem.selectedRecordId = 0
    selectedItem.moduleID = this.template.moduleID;
    selectedItem.dependentRecordID = this.template.recordID;
    selectedItem.highlightRecordID = 0
    selectedItem.recordType = 1;//from tree select
    selectedItem.componentType = this.componentType;

    this.moduleService.passDependencyTemplate(selectedItem);

  }

  LoadDisositionFiles()
  {
    let selectedItem = new templateSelectedRecord();
    selectedItem.templateId = this.template.templateID;

    selectedItem.selectedRecordId = -3
    selectedItem.moduleID = this.template.moduleID;
    selectedItem.dependentRecordID = this.template.recordID;
    selectedItem.highlightRecordID = -3
    selectedItem.recordType = 1;//from tree select
    selectedItem.componentType = this.componentType;

    this.moduleService.passDependencyTemplate(selectedItem);
  }

  public GetDefaultItem() {
    if (this.languageService.dataByLanguage.find(x => x.LanguageKey == 'STC_SLCT')) {
      this.defaultItem.name = '---' + this.languageService.dataByLanguage.find(x => x.LanguageKey == 'STC_SLCT').Message + '---';
    }
    return this.defaultItem;
  }
}
