import { DynamicFieldBase } from './dynamic-field-base';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileInfo, FileRestrictions, SelectEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { DynamicFieldService } from '../dynamic-fields-service/dynamic-field.service';
import { getBaseUrl } from '../../../main';

@Component({
  selector: 'app-file-upload',
  template: `
   <kendo-upload
[autoUpload]="false"
[saveUrl]="uploadSaveUrl"
 [removeUrl]="uploadRemoveUrl"
(select)="selectEventHandler($event)"
(upload)="uploadEventHandler($event)"
 [withCredentials]="false"
[restrictions]="myRestrictions"
>
</kendo-upload>
   `
})

export class FileUploadDynamicField {
  currentFileUpload: FileInfo = null;
  file: File = null;
  progress: any;
  @Output() newItemEvent = new EventEmitter<boolean>();
  @Input() DynamicField: DynamicFieldBase<string>;
  @Input() FormFields: any;
  controlType = 'fileupload';
  message: any;
  //uploadSaveUrl = getBaseUrl() + 'api/dynamic/uploadDocument';
  uploadSaveUrl = getBaseUrl() + 'api/dynamic/DynACU';
  //uploadRemoveUrl = 'https://localhost:44324/api/dynamic/uploadDocument';
  uploadRemoveUrl = 'https://localhost:44324/api/dynamic/DynACU';
  constructor(private DFS: DynamicFieldService, private httpclient: HttpClient) { }


  myRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf",],
  };

  public selectEventHandler(e: SelectEvent): void {
    const that = this;
    e.files.forEach((file) => {
      //console.log(`File selected: ${file.name}`);
      if (!file.validationErrors) {
        this.currentFileUpload = file;
      }
    });

  }
  errorEventHandler(e: ErrorEvent) {
    //console.log(e+'An error occurred');
  }
  


  uploadEventHandler(e: UploadEvent) {
    //console.log(this.FormFields);
    if (this.FormFields !== undefined && this.FormFields.value !== undefined) {
      if (this.DFS.checkUndefinedNullEmpty(this.FormFields.value[this.DynamicField.key])) {
        this.FormFields.value[this.DynamicField.key] = [];
      }
    }
    let files: any;
    files = e.files;
    if (files.length === 0) {
      return;
    }
    let filesToUpload: File[] = files;
    let filelist = [];
    this.FormFields.value[this.DynamicField.key].push(files[0].name);
    this.newItemEvent.emit(false);
  
  }


}
