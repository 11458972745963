import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataByLanguage, LanguageService } from '../services/language.service';
import { LoginService } from '../services/login.service';
import { GlobalVeriables } from '../Global/GlobalVaribales';
import { ModuleService } from '../services/module.service';
import { AdminUserManagementService } from '../services/adminusermanagement.service';
import { ActivatedRoute } from '@angular/router';
import { Subscriber } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { NotificationAlertsService } from '../services/notification.service';

@Component({
  selector: 'app-download-files',
  templateUrl: './download-files.component.html',
  styleUrls: ['./download-files.component.css']
})
export class DownloadFilesComponent implements OnInit {
  passwordType: any = "password";
  //public languagesList = [];
  public languagesList = [];
  submitted: boolean = false;
  currentYear: any;
  progress: number;
  message: string;
  FileName: string;
  public languageData: DataByLanguage[] = [];
  selectedLanguage;
  lblErrorMsg: string;
  showElement: boolean = false;
  showLoader: boolean = false;
  IsLoginSuccess: boolean = false;
  showHeader: boolean = false;
  Loginobj = new Login();
  Showlogin: boolean = true;
  id: any;
  constructor(private formBuilder: FormBuilder, private notifyService: NotificationAlertsService, private languageService: LanguageService, private _loginService: LoginService, private GV: GlobalVeriables, private moduleService: ModuleService, private amService: AdminUserManagementService, private router: ActivatedRoute,) { }

  loginForm = this.formBuilder.group({
    userName: ['', Validators.required],
    password: ['', Validators.required],
  })

  ngOnInit(): void {

    this.id = this.router.snapshot.paramMap.get('FileID');
    this.FileName = this.router.snapshot.paramMap.get('FileName');
    let dateObj = new Date();
    this.currentYear = dateObj.getUTCFullYear();
    this.GetAllLanguages();
  }
  GetAllLanguages() {
    this.languageService.GetAllLanguages().subscribe(res => {
      this.languagesList = res;
      if (!localStorage.getItem("SelectedLanguageId")) {
        this.selectedLanguage = this.languagesList.find(x => x.isDefault == true);
        localStorage.setItem("SelectedLanguageId", this.selectedLanguage.id);
      } else {
        this.selectedLanguage = this.languagesList.find(x => x.id == parseInt(localStorage.getItem("SelectedLanguageId")));
      }
      this.GetDataByLanguageId(this.selectedLanguage.id);
    });
  }
  changeLanguage(value: any): void {
    if (this.selectedLanguage.id != value.id) {
      this.selectedLanguage = value;
      localStorage.setItem("SelectedLanguageId", this.selectedLanguage.id);
      this.GetDataByLanguageId(this.selectedLanguage.id);
      window.location.reload();
    }
  }
  GetDataByLanguageId(languageId: number) {
    this.languageService.GetDataByLanguageID(languageId).subscribe(res => {
      this.languageData = res;
      //this.isDataLoaded = true;
    });
  }

  Login() {

    if (this.loginForm.invalid) {
      this.submitted = true;
      return;
    }
    this.showLoader = true;
    this.Loginobj.userName = this.loginForm.controls['userName'].value;
    this.Loginobj.password = this.loginForm.controls['password'].value;
    this._loginService.userverify(this.Loginobj, this.id).subscribe(response => {
      this.lblErrorMsg = "";

      if (response.loginFailType == null) {
        localStorage.setItem("userLogin", "true");
        localStorage.setItem("userName", response.userName);
        localStorage.setItem("userId", response.userId);
        localStorage.setItem("userRoleID", response.roleID);
        localStorage.setItem("userRoleName", response.roleName);
        localStorage.setItem("userFirstName", response.firstName);
        localStorage.setItem("userLastName", response.lastName);
        localStorage.setItem("usercompanyID", response.companyID);
        localStorage.setItem("usertimeZone", response.timeZone);
        localStorage.setItem("OutofOfficeStatus", response.outofOfficeStatus); //to know the status is off or on
        this._loginService.isValid = true;
        localStorage.setItem("token", response.user_Token);
        localStorage.setItem("IsColumnDragged", "0");
        this.GV.setoutOfofficeState(response.outofOfficeStatus);
        this.showHeader = true;
        this._loginService.setHeaderShow(true);
        // this.moduleService.reoladAppComponent(0);
        this.GV.UserName = localStorage.getItem("userName");
        this.GV.UserId = +localStorage.getItem("userId");
        this.GV.UserRole = localStorage.getItem("userRoleName");
        this.GV.UserRoleID = +localStorage.getItem("userRoleID");
        this.GV.CompanyID = +localStorage.getItem("usercompanyID");
        this.GV.TimezoneID = +localStorage.getItem("usertimeZone");
        // this.curCompanyID = this.GV.CompanyID;
        // this.cuserRoleID = this.GV.UserRoleID;
        this.FileName=response.fileName;
        this.amService.GetUserPrivileges(this.GV.UserId, 1).subscribe(result => {
          this.GV.setUserPrivileges(result);
        })
        this.moduleService.Publishfiledownload(this.id).subscribe((result) => {

          try {

            if (result.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * result.loaded) / result.total);
              // this.message = 'Download success.';
            }

            else if (result.type === HttpEventType.Response) {
              //  this.message = 'Download success.';
              // this.Showlogin=false;
              this.downloadFile(result);
            }

            // code that might throw an error
          } catch (error) {
            // this.message = 'Download Failed!.';
            // this.notifyService.show("Invalid link Please try Again!",4);
            this.Showlogin = true;
            //this.notifyService.show("Invalid link Please try Again!",4);
          } finally {
            // code that always executes, regardless of whether an error occurred or not
          }

        }
        );
        // this.router.navigate(["/Projects"]);

      }
      else {
        this.IsLoginSuccess = false;
        // if (response.loginFailType == "Locked" && response.loginFailAttempts <=0)
        //   this.functionInvalidNr1(response.loginFailType, response.loginFailAttempts);//User is locked
        // else if (response.loginFailType == "Locked")
        //   this.functionNr1("LOG_UIL");//User is locked
        // else if (response.loginFailType == "Invalid" && response.loginFailAttempts >= 0)
        //   this.functionInvalidNr1(response.loginFailType, response.loginFailAttempts);//Invalid username or password
        // else if (response.loginFailType == "Invalid")
        //   this.functionNr1("LOG_IUP");//Invalid username or password  
        //   else if (response.loginFailType == "NotSetPassword")
        //   this.functionNr1("LOG_NSP");
        // else if (response.loginFailType == "Disabled")
        //   this.functionNr1("LOG_UIDI");//User is disabled
        // else if (response.loginFailType == "Inactivated")
        //   this.functionNr1("LOG_UIDE");//User is deactivated
        if (response.loginFailType == "NA")
          this.notifyService.show("You are not authorised to download this file!", 4);
        else if (response.loginFailType == "FN")
          this.notifyService.show("File is not available!", 4);
        else if (response.loginFailType == "UAN")
          this.notifyService.show("User is not available!", 4);
        else if (response.loginFailType == "INC")
          this.notifyService.show("You have entered invalid credentials!", 4);


      }
      this.showLoader = false;
    })
  }
  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;//.substring(1).slice(0,-1);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
  functionNr1(msg) {
    this.showElement = true;
    this.lblErrorMsg = this.languageData.find(x => x.LanguageKey == msg).Message;
    setTimeout(() => {
      this.lblErrorMsg = "";
      this.showElement = false;
    }, 3000)
  }
  functionInvalidNr1(msg, attempts) {
    this.showElement = true;
    this.lblErrorMsg = '';
    if (msg == 'Locked')
      this.lblErrorMsg = "Your account has been locked out due to multiple failed login attempts. Please contact Admin.";
    else if (msg == 'Invalid')
      this.lblErrorMsg = "Invalid credentials. You have " + attempts + " more attempt(s) before your account gets locked out.";
    setTimeout(() => {
      this.lblErrorMsg = "";
      this.showElement = false;
    }, 5000);
  }
  showPassword() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text'
    }
    else {
      this.passwordType = 'password'
    }
  }
  closeTab() {
    //window.close();
    //  window.self.close();
    window.open();

  }

}
class Login {
  emailID: string;
  password: string;
  userName: string;
  roles: string;
}
