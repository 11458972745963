import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getBaseUrl } from '../../main';
import { Observable } from 'rxjs';
import { GlobalVeriables } from '../Global/GlobalVaribales';

@Injectable({
  providedIn: 'root'
})
export class CommonFeaturesService {
  
  private oGetAllActionsMessages = getBaseUrl() + "api/commonfeatures/GetAllActionsMessages";
  private oGetAllControlTypes = getBaseUrl() + "api/commonfeatures/GetAllControlTypes";
  private oGetAllRqstFormGroups = getBaseUrl() + "api/commonfeatures/GetAllRequestFormGroups";
  private oGetDefaultMasterModuleData = getBaseUrl() + "api/commonfeatures/GetDefaultMasterModuleData";
  private oUpdateResourceMessageData = getBaseUrl() + "api/workflowtasks/UpdateResourceMessageData";
  private oGetChatTopics = getBaseUrl() + "api/commonfeatures/GetChatTopicList";
  private oGetChatDetails = getBaseUrl() + "api/commonfeatures/GetChatDetails";
  private oSaveChatDetails = getBaseUrl() + "api/commonfeatures/SaveChatDetails";
  private oGetDataByLanguageID = getBaseUrl() + "api/commonfeatures/GetDataByLanguageID";
  private oGetLanguages = getBaseUrl() + "api/commonfeatures/GetLanguages";

  constructor(private httpClient: HttpClient,private GV:GlobalVeriables) { }

  public UpdateResourceMessageData(displayMessage: string, messageType: number, DefaultValue: string, HelpText: string, iD: number, LanguageId:number) {
    let params = new HttpParams();
    params = params.set('displayMessage', displayMessage.toString());
    params = params.set('messageType', messageType.toString());
    params = params.set('DefaultValue', DefaultValue.toString());
    params = params.set('HelpText', HelpText.toString());
    params = params.set('iD', iD.toString());
    params = params.set('LanguageId', LanguageId.toString());
    return this.httpClient.get<any>(this.oUpdateResourceMessageData, { params: params });
  }

  //public GetLanguages() {
  //  debugger;
  //  return this.httpClient.get<any>(this.oGetLanguages, {});
  //}

  public GetDataByLanguageID(LanguageId: number, msgType: number): Observable<any> {
    debugger;
    let params = new HttpParams();
    params = params.set('LanguageId', LanguageId.toString());
    params = params.set('msgType', msgType.toString());
    return this.httpClient.get<any>(this.oGetDataByLanguageID, { params: params });
  }

  //public GetDataByLanguageID() {
    
  //  return this.httpClient.get<any>(this.oGetDataByLanguageID, { params: params });
  //}

  public GetAllActionsMessages(ActionType: number, WorkflowTasksActionID: number, StandardActionID: number, ModuleType: number, AliasKey: string, CompanyID: number) {
    let params = new HttpParams();
    params = params.set('ActionType', ActionType.toString());
    params = params.set('WorkflowTasksActionID', (WorkflowTasksActionID == null || WorkflowTasksActionID == undefined || WorkflowTasksActionID == 0 || isNaN(WorkflowTasksActionID))?0 : WorkflowTasksActionID.toString());
    params = params.set('StandardActionID', StandardActionID.toString());
    params = params.set('ModuleType', ModuleType.toString());
    params = params.set('AliasKey', AliasKey.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<ActionsMessages[]>(this.oGetAllActionsMessages, { params: params });
  }

  public GetUTCOffset() {

    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes;

    //let params = new HttpParams();
    //params = params.append('offset', offset);

    return this.httpClient.get<any>(offset, {});
  }

  public GetDisplayFieldData(templateID: number, parentRecordID:number) {

    
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

   var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset= '+'+ hours + ':' + minutes
    

    let params = new HttpParams();
    params = params.set('templateID', templateID.toString());
    params = params.set('parentRecordID', parentRecordID.toString());
    
    params = params.append('offset', offset);

  }

  public  GetAllControlTypes(){
    return this.httpClient.get<ControlTypes[]>(this.oGetAllControlTypes);
  }
  public GetAllRequestFormGroups(){
    return this.httpClient.get<RqstFormGroups[]>(this.oGetAllRqstFormGroups);
  }

  public GetDefaultMasterModuleData() {
    
    return this.httpClient.get<DefaultMasterModuleData[]>(this.oGetDefaultMasterModuleData);
  }

 

  public GetChatTopics(ChatType: string, ProjectID: number, SubProcessID: number) {
    let params = new HttpParams();
    params = params.set('ChatType', ChatType.toString());
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('SubProcessID', SubProcessID.toString());
    return this.httpClient.get<any>(this.oGetChatTopics, { params: params });
  }
  public GetChatDetails(ChatType: string, ProjectID: number, SubProcessID: number, Topic: string, UserID: number, Priority: number) {
    let params = new HttpParams();
    params = params.set('ChatType', ChatType.toString());
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('SubProcessID', SubProcessID.toString());
    params = params.set('Topic', Topic.toString());
    params = params.set('UserID', UserID.toString());
params = params.set('Priority', Priority.toString());
    return this.httpClient.get<any>(this.oGetChatDetails, { params: params });
  }
  public SaveChatDetails(ChatType: string, ProjectID: number, SubProcessID: number, Topic: string, Comments: string, ParentID: number, UserID: number, Ip: string, Priority: string) {
    let params = new HttpParams();
    params = params.set('ChatType', ChatType.toString());
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('SubProcessID', SubProcessID.toString());
    params = params.set('Topic', Topic.toString());
    params = params.set('Comments', Comments.toString());
    params = params.set('ParentID', ParentID.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('Ip', this.GV.UserIpAddress.toString());
    params = params.set('Priority', Priority.toString());
    return this.httpClient.get<any>(this.oSaveChatDetails, { params: params });
  }
}

/////////////////////////////////////////////////////   Common features data ///////////////////////////////////

export class DefaultMasterModuleData {
  masterModuleName: string;
  defaultHeader: string;
  masterModuleDescription: string;
  logo: string;
}

export class GetDataLanguages {
  langID: number
  languageName: string
}

export class GetDataByLanguageIDData {
  iD: number
  language: string;
  formFieldID;
  message: string;
  languageKey: string;
  type: number;
  templateID: number;
  moduleID: number;
  helpText: string;
}

export class ActionsMessages {
  displayMessage: string;
  language: string;
  aliasKey : string;
  primaryClass: string;
  secondaryClass: string;
  messageType: number;
  messageTypeID: string;
  iD: number;
}

export class ControlTypes{
  Id: number;
  ControlName: string;
  ControlType: number;
  //IsDeleted:boolean;
}
export class RqstFormGroups{
  formsGroupId: number;
  //templateId: number;
  groupName : string;
  groupOrder : number;
  //companyId: number;
  //status: number;
}
