import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WorkflowTasksManagementService } from '../../../services/BPM/workflowtasksmanagement.service';
import { ActionsMessages, CommonFeaturesService, GetDataLanguages } from '../../../services/commonfeatures.service';
import { DataByLanguage, LanguageService } from '../../../services/language.service';
import { NotificationAlertsService } from '../../../services/notification.service';

@Component({
  selector: 'app-resourcemessages-list',
  templateUrl: './resourcemessages-list.component.html',
  styleUrls: ['./resourcemessages-list.component.css']
})
export class ResourcemessagesListComponent implements OnInit {

  CompanyID: number = 0;
  ResponseData: GetDataLanguages[] = [];
  LangResponseData: DataByLanguage[] = [];

  IsExportExcel: boolean = false;
  EnableSearchOption: boolean = false;

  columns: any;
  submitted = false;

  noOfRecords: number = 10;
  pageNo: number = 1;
  dataHeader: string;
  offset: string = "+05:30";
  showmsgEditTempWindow: boolean = false;
  updatebtn: boolean = false;

  FormMessage: string;
  DefaultValue: string;
  HelpText: string;
  FormMessageType: number;
  currentMsgID: number;
  currentLangID: number;
  public selectedKeys: any[] = ["0"];
  //string displayMessage, string messageType, string DefaultValue, string HelpText, int iD, int LanguageId

  public listItems = [{ text: "Success", id: 1 }, { text: "Warning", id: 2 }, { text: "Info", id: 3 }, { text: "Error", id: 4 }];

  constructor(private formbuilder: FormBuilder, private commonfeaturesservice: CommonFeaturesService, private notifications: NotificationAlertsService, private languageService: LanguageService) { }

  ngOnInit() {
    this.CompanyID= +localStorage.getItem("usercompanyID");

    this.GetAllLanguages();
  }

  GetAllLanguages() {
    this.languageService.GetAllLanguages().subscribe(res => {
      this.ResponseData = res;
      this.dataHeader = "English Messages";
      this.currentLangID=1;
      this.languageService.GetDataBySelectedLanguageId(1).subscribe(DataByLanguage => {
        this.LangResponseData = DataByLanguage;
      });
    });
  }

  UserDyForm = this.formbuilder.group({
    userFormMessage: ['', [Validators.required]],
    userFormMessageType: ['', []],
    userDefaultValue: ['', []],
    userHelpText: ['', []],
  });
  public handleSelection(dataItem): void {
    this.currentLangID = dataItem.dataItem.id;
    this.dataHeader = dataItem.dataItem.name + " Messages";

    this.languageService.GetDataBySelectedLanguageId(this.currentLangID).subscribe(DataByLanguage => {
      this.LangResponseData = DataByLanguage;
    //  debugger;
    });
  }


  public zeroPad = (num, places) => String(num).padStart(places, '0');
  public getOffset() {
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + this.zeroPad(hours, 2) + ':' + this.zeroPad(minutes, 2);
    this.offset = offset;
  }

  public ManageResoureMsgData(displayMessage: string, DefaultValue: string, HelpText: string, iD: number) {
    //debugger;
    this.showmsgEditTempWindow = true;
    this.updatebtn = true;

    this.currentMsgID = iD;
    this.UserDyForm.controls['userFormMessage'].setValue(displayMessage);
    if (DefaultValue != "" && DefaultValue != undefined)
      this.UserDyForm.controls['userDefaultValue'].setValue(DefaultValue);
    else
      this.UserDyForm.controls['userDefaultValue'].setValue("");
    if (HelpText != "" && HelpText != undefined)
      this.UserDyForm.controls['userHelpText'].setValue(HelpText);
    else
      this.UserDyForm.controls['userHelpText'].setValue("");
  }

  saveOrUpdateMsgData() {
    //debugger;

    if (this.UserDyForm.invalid) {
      /* debugger;*/
      this.submitted = true;
      return;
    }

    this.FormMessage = this.UserDyForm.controls['userFormMessage'].value;
    this.DefaultValue = this.UserDyForm.controls['userDefaultValue'].value;
    this.HelpText = this.UserDyForm.controls['userHelpText'].value;

    this.FormMessageType = 0;// (this.UserDyForm.controls['userFormMessageType'].value == undefined || this.UserDyForm.controls['userFormMessageType'].value == null) ? '' : this.UserDyForm.controls['userFormMessageType'].value.toString();

    this.commonfeaturesservice.UpdateResourceMessageData(this.FormMessage, this.FormMessageType, this.DefaultValue, this.HelpText, this.currentMsgID, this.currentLangID).subscribe(data => {
      if (data.result == "Error") {
        this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RMUS", this.CompanyID).subscribe((dataRDActionsMessages) => {
          this.notifications.show("Error", 4);//"Are you sure you want to Unlock this User?";
        });
      }
      else {
        this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RMUS", this.CompanyID).subscribe((dataRDActionsMessages) => {
          this.languageService.GetDataByLanguageID(parseInt(localStorage.getItem("SelectedLanguageId"))).subscribe(DataByLanguageRB => {
          
            this.languageService.GetDataBySelectedLanguageId(this.currentLangID).subscribe(DataByLanguage => {
              this.LangResponseData = DataByLanguage;
            });
          });
          this.notifications.show(dataRDActionsMessages[0].displayMessage, dataRDActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
        });
      }

      this.showmsgEditTempWindow = false;

      //this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 9999, "UCS", this.CompanyID).subscribe(data => {
      //  this.ResponseData = data;
      //  this.IsExportExcel = true;
      //  if (data.length != 0) {
      //    this.EnableSearchOption = true;
      //  }
      //  /*this.MasteDataType = data[0].masterDataType;*/
      //});

    });
  }

  rolesDialogClose() {
    this.showmsgEditTempWindow = false;
  }

}
