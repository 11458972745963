import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminUserManagementService, NotificationPrefernces } from '../../../services/adminusermanagement.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { RoleDetails, UserFormData, DepartmentDetails, UserGroupsDetails, CountriesDetails, UserDomains, TimeZonesData, Languages } from '../../../services/AMFormsDataDetails';
import { ActivatedRoute } from '@angular/router';
import { NotificationAlertsService } from '../../../services/notification.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { LanguageService } from '../../../services/language.service';
import { ModuleService } from '../../../services/module.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})

export class MyProfileComponent implements OnInit {
  isDisabled = true;
  UserDialogbx: boolean;
  userid: number;
  areaModel: any;
  OperationType: number = 1;
  UserID: number = 0;
  CompanyID: number = 0;
  submitted = false;
  validated = true;
  UsersDt = new UserFormData();
  selectedItems: any = [];
  public isChecked = false;

  MyProfileDlg: boolean = true;

  CountriesData: CountriesDetails[] = [];
  departmentsData: DepartmentDetails[] = [];
  RolesData: RoleDetails[] = [];
  GroupsData: UserGroupsDetails[] = [];
  DomainsData: UserDomains[] = [];
  usrTimeZonesData: TimeZonesData[] = [];
  languages: Languages[] = [];
  isTimeFormatChecked: boolean;

  savebtn: boolean = false;
  updatebtn: boolean = false;
  dynUserHeader: string = null;
  dataparsing: string;
  btnAddUser: boolean = true;
  btndisabledUSer: boolean = true;
  btnActive: boolean = false;
  countriesDataOpearation: number;
  contrydata: any;
  isTxtusrName = false;
  isTxtEmail = false;
  isTxtfirstname = false;
  isTimezoneNotEditable = false;
  isRoles = false;
  selectedGroupItems: any = [];
  public isGroupChecked = false;
  isDisabledGroups: boolean = true;
  selectedRoleid: any;
  roleidget: number;
  showDashboardLoader: boolean = false;
  notificationPrefernces: any;
  selectedPreferences:  NotificationPrefernces[] = [] ;
  form: FormGroup;
  businessUnit: string;
  company: string;
  phoneNumber: string;
  enableEsign: boolean;
  region: string;
  market: string;
   
  constructor(private router: Router, private moduleService: ModuleService,
    private av_router: ActivatedRoute, private formbuilder: FormBuilder, private dataservice: AdminUserManagementService, private commonfeaturesservice: CommonFeaturesService, private notifications: NotificationAlertsService, private GV: GlobalVeriables, private languageService: LanguageService, private notifyService: NotificationAlertsService) {
    this.usrTimeZonesData = this.usrTimeZonesData.slice();

    this.roleidget = parseInt(localStorage.getItem("userRoleID"));
    this.userid = this.GV.UserId;

    if (this.userid!=null) {
      this.updatebtn = true;
      this.savebtn = false;
    }
    else {
      this.userid = 0;
      this.savebtn = true;
      this.updatebtn = false;
    }
  };

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  UserDyForm = this.formbuilder.group({
    userFormUserName: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_-]*$")]],
    userFormEmail: ['', [Validators.required, Validators.email]],
    userFormFirstName: ['', [Validators.required, Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    userFormLastname: ['', [Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    userFormDomain: [''],
    userTimeZones: ['', [Validators.required]],
    userFormGroups: [''],
    userRolesFormData: [''],
    userFormDepartment: [''],
    userFormCountries: [''],
    userTimeFormat: [''],
    userlanguageData: ['', [Validators.required]],
    userFormGroupOwner: [''],

  });




  ngOnInit() {
    this.CompanyID= +localStorage.getItem("usercompanyID");

    this.GV.UserId = +localStorage.getItem("userId");
    this.UserDialogbx = true;
    this.UserCountries();
    this.UserDepartments();
    this.UserRoles();
    this.UserDomains();
    this.GetTimeZonesData();
    this.getLangauges();

    if (this.userid > 0) {
      this.dataservice.getUMUserGroups(5, this.userid, 1, this.roleidget).subscribe(response => {
        this.GroupsData = response;
        if (response.length > 0) {
          this.isDisabledGroups = false;
        }
        else {
          this.selectedGroupItems = [];
        }

        if (this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length) {
          this.isGroupChecked ? "Deselect All" : "Select All";
        }

        if (response != undefined && response != null && response.length > 0) {
          this.isDisabled = false;
          this.UserDyForm.controls['userFormGroupOwner'].setValue("");
        }
        else {
          this.UserDyForm.controls['userFormGroupOwner'].setValue("");
          this.isDisabled = true;
        }
      },
        error => {
          this.notifications.show(error.statusText, 4);
        }
      );
      this.EditUser(this.userid);

      this.dynUserHeader = 'Edit User';
    }
    else {
      this.dynUserHeader = 'Add User';
    }
  }


  public get isIndet() {
    return (
      this.selectedItems.length !== 0 && this.selectedItems.length !== this.CountriesData.length
    );
  }

  public get toggleAllText() {
    return this.isChecked ? "Deselect All" : "Select All";
  }

  public isItemSelected(item) {
    return this.selectedItems.some((x) => x.countryID === item.countryID);
  }

  public onClick() {
    this.isChecked = !this.isChecked;
    this.selectedItems = this.isChecked ? this.CountriesData.slice() : [];
  }

  public onValueChange() {
    this.isChecked = this.selectedItems.length === this.CountriesData.length;
  }

  saveOrUpdateUserData(userid: any) {
    if (this.UserDyForm.invalid) {
      this.submitted = true;
      return;
    }

    this.showDashboardLoader = true;
    this.UsersDt.userid = userid.toString();
    this.UsersDt.currentUserId = this.GV.UserId;
    this.UsersDt.username = this.UserDyForm.controls['userFormUserName'].value;
    this.UsersDt.emailID = this.UserDyForm.controls['userFormEmail'].value;
    this.UsersDt.firstName = this.UserDyForm.controls['userFormFirstName'].value;
    this.UsersDt.lastname = this.UserDyForm.controls['userFormLastname'].value;
    this.UsersDt.language = (this.UserDyForm.controls['userlanguageData'].value == undefined || this.UserDyForm.controls['userlanguageData'].value == null) ? '' : this.UserDyForm.controls['userlanguageData'].value.toString();
    this.UsersDt.timeFormat = this.UserDyForm.controls['userTimeFormat'].value;
    this.UsersDt.domain = (this.UserDyForm.controls['userFormDomain'].value == undefined || this.UserDyForm.controls['userFormDomain'].value == null) ? '' : this.UserDyForm.controls['userFormDomain'].value.toString();
    this.UsersDt.timezone = (this.UserDyForm.controls['userTimeZones'].value == undefined || this.UserDyForm.controls['userTimeZones'].value == null) ? '' : this.UserDyForm.controls['userTimeZones'].value.toString();
    this.UsersDt.department = (this.UserDyForm.controls['userFormDepartment'].value == undefined || this.UserDyForm.controls['userFormDepartment'].value == null) ? '' : this.UserDyForm.controls['userFormDepartment'].value.toString();
    this.UsersDt.roles = (this.UserDyForm.controls['userRolesFormData'].value == undefined || this.UserDyForm.controls['userRolesFormData'].value == null) ? '' : this.UserDyForm.controls['userRolesFormData'].value.toString();
    this.UsersDt.groupowner = this.UserDyForm.controls['userFormGroupOwner'].value.toString();
    this.UsersDt.businessPhone = this.phoneNumber;
    this.UsersDt.businessUnit = this.businessUnit;
    this.UsersDt.company = this.company;
    this.UsersDt.Market = this.market;
    this.UsersDt.Region = this.region;
    var grpdata = this.UserDyForm.controls['userFormGroups'].value;
    var getGroupDt = [];
    for (let grp of grpdata) {
      getGroupDt.push(grp.id);
    }
    this.UsersDt.groupname = getGroupDt.toString();

    var ele = this.UserDyForm.controls['userFormCountries'].value;
    var splitingdata = [];
    var splitingwithName = [];
    for (let i of ele) {
      var countdt = i.countryID;
      splitingdata.push(countdt);
      var countname = i.countryName;
      splitingwithName.push(countname)

    }
    this.UsersDt.countries = splitingdata.toString();
    this.UsersDt.countryName = splitingwithName.toString();
    if (userid.toString() == "0") {
      this.UsersDt.lockoutEnabled = "1";
    }

    this.dataservice.saveFormUsersFieldsdata(this.UsersDt).subscribe(
      (data) => {
        if (data != undefined) {
          if (data == "0") {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "UCS", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
          }
          else if (data == "1") {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "UUS", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
          }
          else if (data == "2") {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "UAE", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
          }
          else if (data == "3") {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "EAE", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
          }
          else if (data == "4") {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "PEVD", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
          }
          else
            this.notifications.show(data, 4)

        }
        this.showDashboardLoader = false;
        this.UserDialogbx = false;
        this.moduleService.passSelectedModuleIDForDashboard(1);

        this.router.navigate(['/Projects']);
      },
      error => {
        this.showDashboardLoader = false;
        this.notifications.show(error.statusText, 4);
      }
    );
  }

  //Getting Countries data//
  UserCountries() {
    this.dataservice.getUMGetCountries().subscribe(response => {
      this.CountriesData = response;
    });
  }

  //Getting Roles data//
  UserRoles() {
    this.dataservice.getUMGetRoles().subscribe(response => {
      this.RolesData = response;
    });
  }

  //Getting Departmenst data//
  UserDepartments() {
    this.dataservice.getUserDepartments().subscribe(response => {
      this.departmentsData = response;
    });
  }

  //Getting Groups data//
  RolesChange(roleid: any, userid: any) {
    this.selectedGroupItems = [];
    if (roleid == 0)
      this.isDisabledGroups = true;
    this.dataservice.getUMUserGroups(5, userid, 1, roleid).subscribe(response => {
      this.GroupsData = response;
      if (response.length > 0) {
        this.isDisabledGroups = false;
        if (this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length) {
          this.isGroupChecked ? "Deselect All" : "Select All";
        }
      }
      else {
        this.selectedGroupItems = [];
        this.isGroupChecked = false;

      }



      if (response != undefined && response != null && response.length > 0) {
        this.isDisabled = false;
        this.UserDyForm.controls['userFormGroupOwner'].setValue("");
      }
      else {
        this.UserDyForm.controls['userFormGroupOwner'].setValue("");
        this.isDisabled = true;
      }
    });
  }


  public get isGroupIndet() {
    return (
      this.selectedGroupItems.length !== 0 && this.selectedGroupItems.length !== this.GroupsData.length
    );
  }

  public get GrouptoggleAllText() {
    return this.isGroupChecked ? "Deselect All" : "Select All";
  }

  public GroupisItemSelected(item) {
    return this.selectedGroupItems.some((x) => x.id === item.id);
  }

  public GrouponClick() {
    this.isGroupChecked = !this.isGroupChecked;
    this.selectedGroupItems = this.isGroupChecked ? this.GroupsData.slice() : [];

    if (this.isGroupChecked) {
      this.isDisabled = false;
    }
    else {
      this.UserDyForm.controls['userFormGroupOwner'].setValue("");
      this.isDisabled = true;
    }
  }

  public GrouponValueChange() {
    this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length;
  }

  //Getting Domains Data//
  UserDomains() {
    this.dataservice.getUserDomains().subscribe(response => {
      this.DomainsData = response;
    });
  }

  GetTimeZonesData() {
    this.dataservice.LoadTimeZonesDahsboard().subscribe(response => {
      this.usrTimeZonesData = response;
      this.usrTimeZonesData = this.usrTimeZonesData.slice();

    });
  }
  getLangauges() {
    this.dataservice.getlanguages().subscribe(response => {
      this.languages = response;
      });
  }

  close() {
    this.UserDialogbx = false;
    this.moduleService.passSelectedModuleIDForDashboard(1);
    this.router.navigate(['/Projects']);
  }



  cancelUserData() {
    this.UserDyForm.reset();
    this.UserDialogbx = false;
    this.moduleService.passSelectedModuleIDForDashboard(1);

    this.router.navigate(['/Projects']);
  }

  EditUser(userid: number) {
    this.UserDialogbx = true;
    this.savebtn = false;
    this.updatebtn = true;
    this.OperationType = 2;
    this.countriesDataOpearation = 4;
    this.dataservice.getUMUserGroups(6, userid, 1, this.roleidget).subscribe(data => {
      if (data.length > 0) {
        this.selectedGroupItems = data;
        if (this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length) {
          this.isGroupChecked ? "Deselect All" : "Select All";
        }
      }
      else {
        this.selectedGroupItems = [];
      }

    });

    this.dataservice.getUMGetUsers(this.OperationType, userid, this.CompanyID).subscribe(data => {
      this.isTimeFormatChecked = data[0].timeFormat;
      this.UserDyForm.controls['userFormUserName'].setValue(data[0].username);
      this.UserDyForm.controls['userFormEmail'].setValue(data[0].emailID);
      this.UserDyForm.controls['userFormFirstName'].setValue(data[0].firstName);
      this.UserDyForm.controls['userFormLastname'].setValue(data[0].lastname);
      this.UserDyForm.controls['userFormDomain'].setValue(parseInt(data[0].domain));
      this.UserDyForm.controls['userTimeZones'].setValue(parseInt(data[0].timeZoneID));
      this.UserDyForm.controls['userFormDepartment'].setValue(parseInt(data[0].department));
      this.UserDyForm.controls['userRolesFormData'].setValue(parseInt(data[0].roles));
      this.UserDyForm.controls['userTimeFormat'].setValue(data[0].timeFormat);
      this.UserDyForm.controls['userlanguageData'].setValue(parseInt(data[0].language));
      this.company = data[0].companyId.toString();
      this.businessUnit = data[0].businessUnitId.toString();
      this.phoneNumber = data[0].businessPhone;
      this.market = data[0].market;
      this.region = data[0].region;
      this.enableEsign = data[0].enableEsign;
       
      this.isTxtusrName = true;
      this.isTxtEmail = true;
      this.isTxtfirstname = true;
      this.isTimezoneNotEditable = true;
      this.isRoles = true;
      this.selectedRoleid = data[0].groupname;
      if (data[0].groupowner == "True" || (data[0].groupname != '' && data[0].groupname != 0 && data[0].groupname.length > 0)) {
        if (data[0].groupowner == "True") {
          this.isDisabled = false;
          this.UserDyForm.controls['userFormGroupOwner'].setValue(data[0].groupowner);
        }

      }
      else {
        this.UserDyForm.controls['userFormGroupOwner'].setValue("");
        this.isDisabled = true;
      }

    });


    this.dataservice.getCountriesData(this.countriesDataOpearation, userid, this.CompanyID).subscribe(data => {
      if (data.length > 0) {
        this.selectedItems = data;
        if (this.isChecked = this.selectedItems.length === this.CountriesData.length) {
          this.isChecked ? "Deselect All" : "Select All";
        }
      }
      else {
        this.selectedItems = [];
      }

    });




  }


  //groups change event for gropuowner
  groupOnchange(event: any, data: any) {

    this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length;
    var selectedGroupsList = [];
    for (let i of event) {
      selectedGroupsList.push(i.id);
    }
    if (selectedGroupsList != undefined && selectedGroupsList != null && selectedGroupsList.length > 0) {
      if (event != null) {
        this.isDisabled = false;

      }
    }
    else {
      this.UserDyForm.controls['userFormGroupOwner'].setValue("");
      this.isDisabled = true;

    }

  }
  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }

  auditclose() {
    this.MyProfileDlg = false;
    this.moduleService.passSelectedModuleIDForDashboard(1);

    this.router.navigate(['/Projects']);
  }

  get preferncesFormArray() {
    return this.form.controls['prefernces'] as FormArray;
  }
  public onTabSelect(e: SelectEvent) {
    if (e.title == 'My Preferences') {
      this.showDashboardLoader = true;
      this.dataservice.getPrefernce(this.userid).subscribe(data => {
        this.notificationPrefernces = data;
        this.form = this.formbuilder.group({
          prefernces: new FormArray([])
        });
        this.showDashboardLoader = false;
        this.addCheckboxes();
      });
     
    }


  }
  submit() {
    this.selectedPreferences = [] ;
    const selectedIds = this.form.value.prefernces
      .map((checked: any, i: number) => checked ? this.notificationPrefernces[i].emailNotificationsMasterID : null)
      .filter((v: null) => v !== null);
    var user = this.userid.toString();
    if (selectedIds.length > 0) {
      selectedIds.forEach((value) => {
        let prefernces = this.notificationPrefernces.filter(function (prefernce) {
          prefernce.notifiedUsersType = 1;
          return prefernce.emailNotificationsMasterID === value;
        });
        this.selectedPreferences.push(prefernces[0]);
      });
    }
   
                 
    this.dataservice.savePrefernce(this.selectedPreferences,user).subscribe(data => {
      this.commonfeaturesservice.GetAllActionsMessages(4, 0, 0, 999, "ADMMP_NPSS", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
        this.MyProfileDlg = false;
        this.moduleService.passSelectedModuleIDForDashboard(1);

        this.router.navigate(['/Projects']);
      });

      });
    
  }
  cancel() {
    this.MyProfileDlg = false;
    this.moduleService.passSelectedModuleIDForDashboard(1);

    this.router.navigate(['/Projects']);
  }
  public addCheckboxes() {
    this.notificationPrefernces.forEach((value) => {
      var selectedValue = value.userCheckedNotification !="" ? true : false;
      this.preferncesFormArray.push(new FormControl(selectedValue))
    });
  }
  
}
