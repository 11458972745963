import { Injectable, Type } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
@Injectable({
  providedIn: 'root'
})
export class NotificationAlertsService {
 
  time: number = 0;
  interval;
  display:number=3

  constructor(private notificationService: NotificationService) { }
 
  // none" | 1 "success" | 2 "warning" |3  "error" |4  "info"
  public show(Message: string, Typeof: number = 3): void {
   if(Typeof===1 && this.display ==3){
     this.notificationService.show({
       content: Message,
       cssClass: 'notification-msg',
       animation: { type: "slide", duration: 400 },
       position: { horizontal: "right", vertical: "top" }, type: { style: "success", icon: true },
       hideAfter:3000, 
     });
     this.startTimer();

  }
  if(Typeof===2 && this.display ==3){
    this.notificationService.show({
        content: Message,
      cssClass: 'notification-msg',
        animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
        type: { style: "warning", icon: true },
      hideAfter: 3000
    });
    this.startTimer();
  }
  if(Typeof===3 && this.display ==3){
    this.notificationService.show({
        content: Message,
      cssClass: 'notification-msg',
        animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
        type: { style: "info", icon: true },
      hideAfter: 3000,
    });
    this.startTimer();
  }
    if (Typeof === 4 && this.display ==3) {
    this.notificationService.show({
        content: Message,
      cssClass: 'notification-msg',
        animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
        type: { style: "error", icon: true },
      hideAfter: 3000,
    });
    this.startTimer();
  }

  if(Typeof===0 && this.display ==3){
    this.notificationService.show({
        content: Message,
      cssClass: 'notification-msg',
        animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
        type: { style: "none", icon: true },
      hideAfter: 3000,
    });
    this.startTimer();
  }
}

startTimer() {
  this.interval = setInterval(() => {
    if(this.display > 0) {
      this.display--;
    } else {
      clearInterval(this.interval);
      this.display = 3;
    }
  },1000)
}

}
