import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { getBaseUrl } from '../../../main';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-verifyssouseraccess',
  templateUrl: './verifyssouseraccess.component.html',
  styleUrls: ['./verifyssouseraccess.component.css']
})
export class VerifyssouseraccessComponent implements OnInit {
  showEsignMessage: boolean = false;

  constructor(public router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    if (window.location.search != '' && window.location.search == '?email=esignFail') {
      this.showEsignMessage = true;
    }
  }
  loginEsignSSO() {
    this.http.get(getBaseUrl() + 'api/Account/SSOlogin?requestType=AppEsign&userID=' + localStorage.getItem("userId"), {}).subscribe(result => {
      window.location.href = result.toString();
    }, error => console.error(error));
  }

}
