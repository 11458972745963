import { Injectable, Component, ViewContainerRef  } from "@angular/core";
import { FileInfo } from "@progress/kendo-angular-upload";
import { UserPrivilege } from "../services/AMFormsDataDetails";
@Injectable()
 
 export  class GlobalVeriables {
    public  UserRole: string ;
    public UserName:string;
    public UserId:number;
    public Token:string;
    public  FullName: string ;
    public isAuthenticated:boolean=false;
    public Home:string="/Home/Index";
    public AlertComponent:ViewContainerRef=null;
    public Loading:boolean=false;
    public IsSP:boolean=false;
    public returnMessage="";
    public loginMessage = false;
    public UserRoleID: number;
    public CompanyID: number;
    public TimezoneID: number;
    public ProjectID: number;
    public FileType: number;
    public ActionEvent: string;
    public UserPrivileges: UserPrivilege[];
    public FileInfo: FileInfo[];
    public ParentRecordID: number;
    public RecordID: number;
    PwdMinimumCharacters:number;
    SpecialCharacters:boolean;
    UpperCaseCharacters:boolean;
    NumericCharacters:boolean;
    LowerCaseCharacters:boolean;
    UserIpAddress: any;
  ssoLogin: boolean;
    public processTemplateID : any;
  public outOfofficeState: boolean;

  public isRedirection: boolean = false;

  SetIsRedirection(val: boolean) {
    this.isRedirection = val;
  }

  GetIsRedirection() {
    return this.isRedirection;
  }

  SetProjectID(val: number) {
    this.ProjectID = val;
  }

  GetProjectID( ) {
    return this.ProjectID;
  }


  SetFileInfo(fileInfo: FileInfo[]) {
    this.FileInfo = fileInfo;
  }

  GetFileInfo() {
    return this.FileInfo;
  }
  SetRecordID(RecordID: number) {
    this.RecordID = RecordID;
  }

  GetRecordID() {
    return this.RecordID;
  }
  SetParentRecordID(RecordID: number) {
    this.ParentRecordID = RecordID;
  }

  GetParentRecordID() {
    return this.ParentRecordID;
  }

  SetFileType(fileType:number ) {
    this.FileType = fileType;
  }

  GetFileType() {
    return this.FileType;
  }


  SetActionEvent(actionEvent: string ) {
    this.ActionEvent = actionEvent;
  }

  GetActionEvent() {
    return this.ActionEvent;
  }


  setUserPrivileges(userPrivileges: UserPrivilege[]) {
   

    this.UserPrivileges = userPrivileges;
  }

  GetUserPrivileges() {

     

   return this.UserPrivileges;
  }
  setoutOfofficeState(outOfofficeState: boolean) {
    this.outOfofficeState = outOfofficeState;

  }
  getoutOfofficeState(){
  return this.outOfofficeState;
}


}

 
