import { Component, OnInit } from '@angular/core';
import { AdminUserManagementService, GetAuditAllFieldName, DateConvertions } from '../../../services/adminusermanagement.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { RoleDetails, AduitForm, AuditDashboardForm, PDfExpAuditSave, PriviligeDetails, PrivilegeMasterDTO } from '../../../services/AMFormsDataDetails';
import { Router } from '@angular/router';
import { ModuleService } from '../../../services/module.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { CompositeFilterDescriptor, filterBy, State, process } from '@progress/kendo-data-query';
import { ExcelExportData, Workbook, WorkbookSheet, } from '@progress/kendo-angular-excel-export';
import { saveAs } from "@progress/kendo-file-saver";

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.css'],
  providers: [GetAuditAllFieldName, NotificationAlertsService, DateConvertions]
})

export class RolesListComponent implements OnInit {
  PriviligesData: any = [];
  RolesData: RoleDetails[] = [];
  ArtworkReviewURLNavigation: any;
  PrivilegeBaseRole: PriviligeDetails[] = [];

  PriviligeData: PrivilegeMasterDTO[] = [];
  OperationType: number = 1;
  CompanyID: number = 0;
  roleId: number = 0;
  btnAddRole: boolean = true;
  btndisabledRole: boolean = true;
  btnActive: boolean = false;
  btnActions: boolean = true;
  AuditResponseData: AuditDashboardForm[] = [];
  AuditLogId: boolean = false;
  Audituserdata: AduitForm[] = [];
  htmlToAdd: any;
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  btnEditAction: boolean = true;
  btnDeleteAction: boolean = true;
  IsExportExcel: boolean = true;
  IsExportToPdf: boolean = true;
  AuditexcelBtn: boolean = false;
  public opened = false;
  ObjAuditExpPdf = new PDfExpAuditSave();
  progress: number;
  message: string;
  RoleType: string;
  FileName: string;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  showLoader: boolean = false;
  PrivilegeDialogueID: boolean = false;
  PrivilegesLoad: boolean = false;
  gridData: any;
  timeStamp: Date = new Date();
  excelRecords: any;
  columnNamesUser: any = ["Role", "Description", "Access", "Privileges"];
  IsDisable: boolean = false;
  filteredRecords: any;
  filter: CompositeFilterDescriptor;

  constructor(private router: Router, private amService: AdminUserManagementService, private notifications: NotificationAlertsService, private commonfeaturesservice: CommonFeaturesService, private DisplayAllFields: GetAuditAllFieldName, private moduleService: ModuleService, private DateTimeService: DateConvertions, private GV: GlobalVeriables) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit() {
    this.CompanyID = +localStorage.getItem("usercompanyID");
    this.GV.UserId = +localStorage.getItem("userId");
    this.LoadrolesDahsboard(this.OperationType, this.roleId);
    this.RoleType = "Active Roles";
  }

  public state: State = {
    skip: 0,
    take: 5,
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    }
  };

  EnableDisableFilter() {
    this.state = {
      skip: 0,
      take: 5,
      // Initial filter descriptor
      filter: {
        logic: 'and',
        filters: []
      }
    };
  }

  LoadrolesDahsboard(OperationType: number, roleId: number,) {
    this.amService.LoadrolesDahsboard(this.OperationType, this.roleId).subscribe(response => {
      this.RolesData = response;
      this.gridData = this.RolesData;
      this.IsExportExcel = true;
      this.IsExportToPdf = true;
      this.btnEditAction = true;
      this.btnDeleteAction = true;
      if (this.gridData.length == 0)
        this.IsDisable = true;
      else
        this.IsDisable = false;
    });
  }

  public closeDialog() {
    this.opened = false;
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {
      }).data,
    };
    return result;
  }

  public openDialog(roleId) {
    this.roleId = 0;
    this.opened = true;
    this.roleId = roleId;
  }

  DeleteRolesData() {

    this.amService.DeleteRoles(this.roleId, this.GV.UserId).subscribe((data) => {
      this.opened = false;
      if (data.result == "Exist") {
        this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RAADS", this.CompanyID).subscribe((dataRDActionsMessages) => {
          this.notifications.show(dataRDActionsMessages[0].displayMessage, dataRDActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
        });
      }
      else {
        this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RDS", this.CompanyID).subscribe((dataRDActionsMessages) => {
          this.notifications.show(dataRDActionsMessages[0].displayMessage, dataRDActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
        });
      }
      this.LoadrolesDahsboard(this.OperationType, this.roleId);
    });
  }

  DisabledUsers() {
    this.OperationType = 4;
    this.amService.getDisabledRoles(this.OperationType, this.roleId).subscribe(data => {
      this.RoleType = "Disabled Roles";
      this.RolesData = data;
      this.gridData = this.RolesData;
      this.btnAddRole = false;
      this.btnActive = true;
      this.btndisabledRole = false;
      this.btnActions = true;
      this.IsExportExcel = true;
      this.IsExportToPdf = true;
      this.btnEditAction = false;
      this.btnDeleteAction = false;
    });
  }

  ActiveUsers() {
    this.btnAddRole = true;
    this.btnActive = false;
    this.btndisabledRole = true;
    this.btnActions = true;
    this.OperationType = 1;
    this.RoleType = "Active Roles";
    this.LoadrolesDahsboard(this.OperationType, this.roleId);
  }

  auditclose() {
    this.AuditLogId = false;
    if (this.btnActive) {
      this.btnEditAction = false;
      this.btnDeleteAction = false;
    }
    else {
      this.btnEditAction = true;
      this.btnDeleteAction = true;
    }
    this.router.navigate(['/rolesData']);
  }

  ///get privilgese Data based on role///
  GetPrivilegesRoleData(roleID: number) {
    this.PrivilegeDialogueID = true;
    this.PrivilegesLoad = true;
    this.amService.GetSelectedPrivilages(3, roleID).subscribe(data => {
      this.PrivilegeBaseRole = data;
      this.PriviligeData = data;
      this.IsExportExcel = true;
      this.IsExportToPdf = true;
      this.PrivilegesLoad = false;
    });
  }

  PrivilegeClose() {
    this.PrivilegeDialogueID = false;
  }

  checkUserPrivilege(actionEventID, LevelID) {
    return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);
  }

  RolesAuditLogData(keyID: any, TablenumID: any, CompanyID: any) {
    this.AuditLogId = true;
    this.IsAuditExportExcel = true;
    this.amService.AuditLogData(keyID, TablenumID, this.GV.UserId, CompanyID).subscribe((data) => {
      this.divHisdata = true;
      let AuditDisplay = "";
      if (data.length > 0) {
        this.AuditexcelBtn = true;
        for (let i of data) {
          let seraizedata = JSON.parse(i["changes"]);
          AuditDisplay += "<div class=''>";
          AuditDisplay += "<table class='auditclass'>";
          AuditDisplay += "<tr class='auditclass margin-top-10' style='background: rgba(245, 247, 250, 0.5) !important;'>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>User: <b class='newtext-color'>" + i.userId + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Event date:<b class='newtext-color'> " + this.DateTimeService.convertdate(i.eventDate) + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Action type:<b class='newtext-color'> " + i.actionType + "</b></td>";
          AuditDisplay = AuditDisplay + "</tr>"
          AuditDisplay = AuditDisplay + "<tr class='auditclass'><td><b>Field name</b></td><td><b>Before change</b></td><td><b>After change</b></td></tr>";
          if (seraizedata.length > 0) {
            for (let j of seraizedata) {
              AuditDisplay = AuditDisplay + "<tr class='auditclass'>";
              AuditDisplay = AuditDisplay + "<td>" + this.DisplayAllFields.GetFieldName(j.FieldName, 24) + "</div>";
              AuditDisplay = AuditDisplay + "<td>" + (j.ValueBefore == "(null)" ? "" : j.ValueBefore) + "</div>";
              AuditDisplay = AuditDisplay + "<td style='width:33%!important;'>" + (j.ValueAfter == "(null)" ? "" : j.ValueAfter) + "</div>";
              AuditDisplay = AuditDisplay + "</tr>";
            }
          }
          else
            AuditDisplay = AuditDisplay + "<tr class='newtext-color'><td></td><td class='center'>No updates to show!</td><td></td></tr>";

          AuditDisplay = AuditDisplay + "</table><br/>";
          AuditDisplay = AuditDisplay + "</div>";
        }
      }
      else {
        AuditDisplay = AuditDisplay + "No audit data for this record.";
        this.AuditexcelBtn = false;
      }
      this.htmlToAdd = AuditDisplay;
    });
  }

  ExportAuditToPdfFile(htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;;
    this.ObjAuditExpPdf.TableEnum = '24';
    this.showLoader = true;
    this.amService.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      this.showLoader = false;
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }

  DownLoadExportPdfFile(Filepath: string, FileName: string) {
    this.FileName = FileName;
    this.amService.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
      }
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }

  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.RolesData, filter);
    if (this.filteredRecords.length == 0) {
      this.gridData = this.filteredRecords;
      this.IsDisable = true;
     }
     else{
       this.gridData = this.filteredRecords;
       this.IsDisable = false;
     }

  }
  onExcelExport() {
    this.excelRecords = this.gridData
    let rows = [];
    let headerRow = new HeaderRowClass();
    let headerRowcells: HeaderCellClass[] = [];

    let headerCell = new HeaderCellClass();
    headerCell.value = "Application Name"
    headerCell.bold = false;
    headerRowcells.push(headerCell);

    let headerCell1 = new HeaderCellClass();
    headerCell1.value = "Artwork360"
    headerCell1.bold = true;
    headerRowcells.push(headerCell1);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Module Name
    let headerRow1 = new HeaderRowClass();
    let headerRowcells1: HeaderCellClass[] = [];
    let headerCell2 = new HeaderCellClass();
    headerCell2.value = "Module Name"
    headerCell2.bold = false;
    headerRowcells1.push(headerCell2);
    let headerCell3 = new HeaderCellClass();
    headerCell3.value = "Admin Settings>>Roles>>" + this.RoleType
    headerCell3.bold = true;
    headerRowcells1.push(headerCell3);
    headerRow1.cells = headerRowcells1;
    rows.push(headerRow1);
    ///Downloaded by
    let headerRow2 = new HeaderRowClass();
    let headerRowcells2: HeaderCellClass[] = [];
    let headerCell4 = new HeaderCellClass();
    headerCell4.value = "Downloaded By";
    headerCell4.bold = false;
    headerRowcells2.push(headerCell4);
    let headerCell5 = new HeaderCellClass();
    headerCell5.value = this.GV.UserName;
    headerCell5.bold = true;
    headerRowcells2.push(headerCell5);
    headerRow2.cells = headerRowcells2;
    rows.push(headerRow2);
    //Downloaded Date
    let headerRow3 = new HeaderRowClass();
    let headerRowcells3: HeaderCellClass[] = [];
    let headerCell6 = new HeaderCellClass();
    headerCell6.value = "Downloaded Date";
    headerCell6.bold = false;
    headerRowcells3.push(headerCell6);
    let headerCell7 = new HeaderCellClass();
    let CurrentDate = new Date();
    headerCell7.value = CurrentDate.toString();;
    headerCell7.bold = true;
    headerRowcells3.push(headerCell7);
    headerRow3.cells = headerRowcells3;
    rows.push(headerRow3);
    //Binding Columns
    let headerRow4 = new HeaderRowClass();
    let headerRowcells4: HeaderCellClass[] = [];
    this.columnNamesUser.forEach(element => {
      let headerCell8 = new HeaderCellClass();
      headerCell8.value = element
      headerCell8.bold = true;
      headerRowcells4.push(headerCell8);
      headerCell8.background = "#227447"
      headerCell8.color = "#ffffff"
    });
    headerRow4.cells = headerRowcells4;
    rows.push(headerRow4);
    //Binding Grid Data
    let headerRowcells5: HeaderCellClass[] = [];

    for (let i = 0; i <= this.excelRecords.length - 1; i++) {
      let headerRow6 = new HeaderRowClass();
      let headerRowcells6: HeaderCellClass[] = [];

      let headerCell9 = new HeaderCellClass();
      headerCell9.value = this.excelRecords[i].roleName;
      headerCell9.bold = false;
      headerRowcells6.push(headerCell9);
      headerCell9.background = ""
      headerCell9.color = ""

      let headerCell10 = new HeaderCellClass();
      headerCell10.value = this.excelRecords[i].roleDescription;
      headerCell10.bold = false;
      headerRowcells6.push(headerCell10);
      headerCell10.background = ""
      headerCell10.color = ""

      let headerCell11 = new HeaderCellClass();
      headerCell11.value = this.excelRecords[i].typeOfPrivilege;
      headerCell11.bold = false;
      headerRowcells6.push(headerCell11);
      headerCell11.background = ""
      headerCell11.color = ""

      let headerCell12 = new HeaderCellClass();
      headerCell12.value = this.excelRecords[i].privilegeName;
      headerCell12.bold = false;
      headerRowcells6.push(headerCell12);
      headerCell12.background = ""
      headerCell12.color = ""

      headerRow6.cells = headerRowcells6;
      rows.push(headerRow6);
    }
    let excelName = this.RoleType
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          columns: [
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
          ],
          // Title of the sheet            
          name: excelName,
          rows: rows
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName + ".xlsx"));
  }
}

export class GridViewData {
  public pageSize: number = 10;
  public skipPage: number = 0;
  public viewData: any = {};
  public filter: any = null;
}

export class HeaderCellClass {
  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class HeaderRowClass {
  cells: any;
}


