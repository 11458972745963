import { Component, OnInit } from '@angular/core';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { AuditTrailsDTOData } from '../../../services/AMFormsDataDetails';
import {
  Workbook,
  WorkbookSheetColumn,
  WorkbookSheetRow,
  WorkbookSheetRowCell,
  WorkbookSheet,
} from "@progress/kendo-angular-excel-export";
import { saveAs } from "@progress/kendo-file-saver";
import { GlobalVeriables } from 'src/app/Global/GlobalVaribales';
import { CompositeFilterDescriptor , filterBy, process } from '@progress/kendo-data-query';

@Component({
  selector: 'app-audittrails-list',
  templateUrl: './audittrails-list.component.html',
  styleUrls: ['./audittrails-list.component.css']
})
export class AudittrailsListComponent implements OnInit {

  CompanyID: number = 0;
  ModuleID: number = 0;
  ResponseData: AuditTrailsDTOData[] = [];
  ResponseData1: AuditTrailsDTOData[] = [];
  ResponseData2: AuditTrailsDTOData[] = [];

  IsExportExcel: boolean = false;
  EnableSearchOption: boolean = false;
  showmsgEditTempWindow: boolean = false;
  moduleName: string;
  divHisdata: boolean = false;
  AuditexcelBtn: boolean = false;
  htmlToAdd: any;
  public range = { start: null, end: null };
  public selectedValue : any;

  public selectedValuestart: any;
  public selectedValueend: any;

  public fselectedValuestart: any;
  public fselectedValueend: any;
  excelRecords:any;
  gridData:any;
  columnNamesUser:any=["Module","Action Type","Modified By","Modified Date"];

  UsersType: string;
  pdfDataList : PDFDownloadData[] = [];
  pdfData : PDFDownloadData;
  timeStamp : Date = new Date();
  filteredRecords: any;
  filter: CompositeFilterDescriptor;
  IsDisable:boolean=false;

  constructor(private amService: AdminUserManagementService,private GV:GlobalVeriables) { }

  ngOnInit() {
    this.CompanyID= +localStorage.getItem("usercompanyID");
    this.amService.GetAllAuditTrails(this.CompanyID, this.ModuleID, "0", "0", "0").subscribe(data => {
      this.gridData=data;
      this.ResponseData = data;
      this.IsExportExcel = true;
      if (data.length != 0) {
        this.EnableSearchOption = true;
      }
    });
    this.amService.GetAllAuditTrails(this.CompanyID, this.ModuleID, "0", "0", "00").subscribe(data => {
      this.ResponseData2 = data;
    });
  }

  public ManageAuditHistory(auditType: string,Module:string) {
    this.amService.GetAllAuditTrails(this.CompanyID, this.ModuleID, "0", "0", auditType).subscribe(data => {
      this.moduleName = Module;
      this.ResponseData1 = data;

      this.divHisdata = true;
      var AuditDisplay = "";
      if (this.ResponseData1.length > 0) {
        for (var i = 0; i < this.ResponseData1.length; i++) {
          this.pdfData = new PDFDownloadData;
          this.pdfData.actionType = data[i].actionType;
          this.pdfData.eventDate = data[i].eventDate;
          this.pdfData.userId = data[i].modifiedBy;
          var seraizedata = JSON.parse(this.ResponseData1[i]["changes"]);
          this.pdfData.listdata = seraizedata;
          this.pdfDataList.push(this.pdfData);         
        }
      }
     
      this.showmsgEditTempWindow = true;
      

    });
  }

  public btnAuditSearch() {
    if (this.selectedValuestart != null && this.selectedValuestart != undefined)
      this.fselectedValuestart = this.selectedValuestart.toLocaleDateString();
    else
      this.fselectedValuestart = "0";
    if (this.selectedValueend != null && this.selectedValuestart != undefined)
      this.fselectedValueend = this.selectedValueend.toLocaleDateString();
    else
      this.fselectedValueend = "0";
    if (this.selectedValue == '' || this.selectedValue == null)
      this.selectedValue = 0;
    this.amService.GetAllAuditTrails(this.CompanyID, this.selectedValue, this.fselectedValuestart, this.fselectedValueend, this.ModuleID.toString()).subscribe(data => {
      this.gridData=data;
      this.ResponseData = data;
    });
  }

  public btnAuditClear() {
    this.amService.GetAllAuditTrails(this.CompanyID, this.ModuleID, "0", "0", "0").subscribe(data => {
      this.gridData=data;
      this.ResponseData = data;
      this.IsExportExcel = true;
      if (data.length != 0) {
        this.EnableSearchOption = true;
      }
      this.selectedValuestart = "";
      this.selectedValueend = "";
      this.selectedValue = null;
    });
  }
 
  ExportAuditToPdfFile(htmlcontent: string) {
  }

  public rolesDialogClose() {
    this.amService.GetAllAuditTrails(this.CompanyID, this.ModuleID, "0", "0", "0").subscribe(data => {
      this.gridData=data;
      this.ResponseData = data;
      this.IsExportExcel = true;
      if (data.length != 0) {
        this.EnableSearchOption = true;
      }
    });
    this.showmsgEditTempWindow = false;
    this.pdfDataList = [];

  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.ResponseData, filter);
    if (this.filteredRecords.length == 0) {
      this.gridData = this.filteredRecords;
      this.IsDisable = true;
     }
     else{
       this.gridData = this.filteredRecords;
       this.IsDisable = false;
     }

  }
  onExcelExport()
  {
    this.excelRecords =  this.gridData
    
      
      let rows = [];

      //Application Name
      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];

      var headerCell = new headerCellClass();
      headerCell.value = "Application Name"
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new headerCellClass();
      headerCell.value = "Artwork360"
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Module Name
      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];

      var headerCell = new headerCellClass();
      headerCell.value = "Module Name"
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new headerCellClass();
      headerCell.value = "Admin>>Audit Trails"
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      ///Downloaded by

      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];

      var headerCell = new headerCellClass();
      headerCell.value = "Downloaded By";
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new headerCellClass();
      headerCell.value = this.GV.UserName;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Downloaded Date

      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];


      var headerCell = new headerCellClass();
      headerCell.value = "Downloaded Date";
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      var headerCell = new headerCellClass();

      var CurrentDate = new Date();
      headerCell.value = CurrentDate.toString();;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

//Binding Columns

      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];

      this.columnNamesUser.forEach(element => {
        var headerCell = new headerCellClass();
        headerCell.value = element
        headerCell.bold = true;
        headerRowcells.push(headerCell);
        headerCell.background = "#227447"
        headerCell.color = "#ffffff"
      });

      headerRow.cells = headerRowcells;
      rows.push(headerRow);

//Binding Grid Data
     var headerRow = new headerRowClass();
     var headerRowcells: headerCellClass[] = [];

     for(var i=0;i<=this.excelRecords.length-1;i++)
     {
      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];

          var headerCell = new headerCellClass();
          headerCell.value = this.excelRecords[i].moduleName;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new headerCellClass();
          headerCell.value = this.excelRecords[i].actionType;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new headerCellClass();
          headerCell.value = this.excelRecords[i].modifiedBy;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new headerCellClass();
          headerCell.value = this.excelRecords[i].eventDate;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

           headerRow.cells = headerRowcells;
           rows.push(headerRow); 
     }
      let excelName='Audit Trails';
      const workbook = new Workbook({
        sheets: <WorkbookSheet[]>[
          {
            // Column settings (width)
            columns: [
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
            ],
            
            // Title of the sheet            
            //name: template.templateName,
            name:  excelName,
            // Rows of the sheet
            rows:rows   
          },
        ],
      });
      workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName+".xlsx"));
    }
   
  }


export class gridViewData {
  public pageSize: number = 10;
  public skipPage: number = 0;
  public viewData: any = {};
  public filter: any = null;
}
export class headerCellClass {

  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class headerRowClass {

  cells: any;
}

export class PDFDownloadData{
  userId : string;
  eventDate: string;
  actionType: string;
  listdata: PDFFieldNames[] = [];
  
  }
  export class PDFFieldNames{
    FieldName: string;
    ValueBefore: string;
    ValueAfter: string;
  }


