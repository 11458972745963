import { Component, Input } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { DynamicFieldBase } from '../dynamic-fields/dynamic-field-base';
@Component({
  selector: 'app-DynamicField',
  templateUrl: './dynamic-form-field.component.html'
})
export class DynamicFormDynamicFieldComponent {
  @Input() DynamicField: DynamicFieldBase<string>;
  @Input() dynamicForm: FormGroup;
  get isValid() { return this.dynamicForm.controls[this.DynamicField.key].valid;}
}
