import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ViewportScroller } from "@angular/common";
import { ModuleService } from '../../../services/module.service';
import { CompositeFilterDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { IntlService } from '@progress/kendo-angular-intl';
import { DynamicFormGroupBase } from '../dynamic-field-base';
import { FormGroup } from '@angular/forms';
import { getBaseUrl } from '../../../../main';
import { DynamicFieldControlService } from '../../dynamic-fields-service/dynamic-field-control.service';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { NotificationAlertsService } from '../../../services/notification.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { Workbook, WorkbookSheet } from "@progress/kendo-angular-excel-export";
import { saveAs } from "@progress/kendo-file-saver";
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { LanguageService } from 'src/app/services/language.service';
import { PagerPosition, PagerSettings } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { breadCrumbs, templateData } from '../../../interfaces/ItemplateFields';
import { ListViewDataResult, PageChangeEvent } from '@progress/kendo-angular-listview';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminUserManagementService } from 'src/app/services/adminusermanagement.service';
import { LabelFn, LabelSettings } from '@progress/kendo-angular-progressbar';
import { Subscription } from 'rxjs';
import { PDFDocument } from 'pdf-lib';
@Component({
  selector: 'app-dynamic-field-list',
  templateUrl: './dynamic-field-list.component.html',
  styleUrls: ['./dynamic-field-list.component.css']
})
export class DynamicFieldListComponent implements OnInit {
  @Output() passEditID = new EventEmitter();
  @Input() public componentType: any;
  @Input() fromReports: any;
  _listData = new gridViewData;
  uploadedFiles = [];
  // skip:number=0;
  take: number = 10;
  RecordID: any;
  moduleID: any;
  CompanyID: number = 0;
  searchFilters = new searchFilter();
  IsTaskCls: string;
  templates: any;
  IsDisplayControls: boolean = false;
  IsDisplayView: boolean = false;
  templateParentID: number = 0;
  IsDisplaySubFormView: boolean = false;
  public filter: CompositeFilterDescriptor;
  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;

  IsSearchedApplied: string = "";
  SearchName: string = "";
  ShowRefersh: boolean = false;
  Selectedcolumn: any;
  SelectedDataItemForTask: any;
  SelectedDataItem: any;
  selectedRecordID: number = 0;
  controlClass: string;
  Sort: boolean = false;
  @Input() public ID: any;
  public groups: GroupDescriptor[];
  Taskcount: Array<Tasksmodel> = [];
  TaskData: Array<Tasksmodel> = [];
  Taskmcount: Array<Tasksmodel> = [];
  Taskmdcount: Array<Tasksmodel> = [];
  displayViewID: any;
  documentsPath: string;
  thumbnaildocumentsPath: string;
  keyWordSearch: string = "";
  chkFields: string = "";
  chkFieldValues: string = "";
  columns: any;
  FileStatusColumnExists: boolean = false;
  defaultSearchActive:boolean=true;
  excelColumns: any;
  displayActions: any;
  displayCommonActions: any;
  records: any;
  templateFilters: any;
  checkedtemplateFilters: Array<string> = [];
  excelRecords: any;
  filteredRecords: any = {};
  recordsCount: number;
  public view: ListViewDataResult;
  searchfilteredRecords: any = {};
  accordianFilteredRecords: any;
  public loading = false;


  public pageSize = 10;
  EnableSearchOption: any;
  EnableFiltersSearch: any;
  ShowBreadcrumbs: any;
  ShowSearchPopUp: boolean = false;
  ShowSearchDirect: boolean = false;
  ShowFilterSearchInPopUp: boolean = false;
  EnableFilterSearchSaving: any;
  IsFilter: any;
  IsPaging: any;
  IsSorting: any;
  IsExportPDF: any;
  IsExportExcel: any;
  //ShowProgressBar: any;
  EnableMultiSelect: boolean = false;
  breadcrumbs: Array<breadCrumbs>;
  name: string;
  NoOfRecordsMsg: string = "";
  ShowMultiSelect: boolean = false;
  public skip = 0;
  // public pageSize = 20;
  showPopUp: boolean = false;
  viewRecords: any;
  filters: any;
  showLoader: boolean = false;
  parentRecordID: number;
  invalidSubmitAttempt: boolean = false;
  templateData = new DynamicTemplateData();
  showingDefault:any;
  DynamicFields: DynamicFormGroupBase<any>;
  dynamicForm: FormGroup;
  DynamicFormFieldsActions: any = [];
  taskCompletedCount: any;
  Name: string;
  dateFormats: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy'
  };

  dynTemplateHeader: string = null;
  //uploadSaveUrl = getBaseUrl() + 'api/dynamic/uploadDocument';
  uploadSaveUrl = getBaseUrl() + 'api/dynamic/DynACU';
  //uploadRemoveUrl = 'https://localhost:5001/api/dynamic/uploadDocument';
  uploadRemoveUrl = 'https://localhost:5001/api/dynamic/DynACU';

  checkBoxCheckIDs: any = [];
  CancelledProjects: boolean = false;
  showSearch: boolean = false;

  IpAddres:any;
  FileName:any;


  targetModuleID: any;
  targetTemplateID: any;
  inputFormFields: any;
  SelectedRecord: number = 0;
  parseData: any;
  filteredRecordsAll: any;
  checkDefault:boolean=true;
  displayActionsTemplateLevel: any;
  ShowReAssignWindow: boolean = false;
  dynHeadingName: string;
  reassignpopup: string = "none";
  disableFields: string = "";
  // public pageSize = 0;
  CheckHasDependentsSubscription: Subscription = new Subscription();
  public position: PagerPosition = "bottom";
  public pageSizes = true;
  public info = true;
  public prevNext = true;
  public type: PagerType = "numeric";
  message: any;
  progress: any;
  public get showPager(): boolean {
    return this.view && this.view.total > 0;
  }
  public id: string;
  public processDataId: string;

  public nonCommonClassess: any;
  public legends: any;
  public label: LabelSettings = {};
  public get pagerSettings(): PagerSettings {





    if (this.IsPaging) {

      //this.pageSize = 10;
      return {
        position: this.position,
        pageSizes: this.pageSizes,
        info: this.info,
        previousNext: this.prevNext,
        type: this.type,
        buttonCount: 30,

      };
    } else {
      if (this.records != null && this.records != undefined)
        this.pageSize = this.records.length;
      else
        this.pageSize = this.pageSize;

      return null;
    }

  }

  constructor(private scroller: ViewportScroller, private dataService: DynamicFieldService,
    private commonfeaturesservice: CommonFeaturesService,
    private qcs: DynamicFieldControlService,
    private http: HttpClient,
    private moduleService: ModuleService,
    private notifyService: NotificationAlertsService,
    public intl: IntlService,
    private GV: GlobalVeriables,
    private router: ActivatedRoute,
    private languageService: LanguageService,
    private amService: AdminUserManagementService,
    private router_navigate: Router,
      
  ) { }
  public handlePageChange(event: PageChangeEvent): void {


    this.skip = event.skip;
    this.pageSize = event.take;

    this.loadTemplate();
  }

  ngOnDestroy() {
    this.CheckHasDependentsSubscription.unsubscribe();
  }

  ngOnInit() {

    this.CompanyID = +localStorage.getItem("usercompanyID");

    this.id = this.router.snapshot.paramMap.get('ID') != null ? JSON.parse(localStorage.getItem("reportsProjectID")) : this.router.snapshot.paramMap.get('ID'); 
    this.IsTaskCls = "";
    this.processDataId = this.router.snapshot.paramMap.get('ProcessDataID');
    this.displayViewID = 0;
    this.templateParentID = 0;
    this.Name = localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName');

    this.documentsPath = this.moduleService.getBaseUrl();
    this.thumbnaildocumentsPath = this.moduleService.getBaseUrl() + "documents/";

    // this.fromReports

    this.showPopUp = false;
    this.parentRecordID = 0;
    this.loadTemplate();

    this.moduleService.GetFiltersByTemplateID(this.templateID, "0").subscribe(filterData => {
      if (filterData != null && filterData.length > 0) {
        this.templateFilters = filterData;

    

        this.checkedtemplateFilters = [];

        if (this.template.chkFieldValues != "") {
          var selectedFiltes = JSON.parse(this.template.chkFieldValues);

          selectedFiltes.forEach(element => {
            this.templateFilters.filter(a => a.searchDisplayName == element)[0].isChecked = true;
            this.checkedtemplateFilters.push(element);
          });
          if(this.template.filterConfigKey == 1){
            this.templateFilters.forEach(function (item: { searchConfig: number; isDisabled: boolean; isChecked: boolean; }) {
              if(item.searchConfig == 0){
                item.isChecked = false;
                item.isDisabled = true;
              }
            })
          }
          else{
            this.templateFilters.forEach(function (item: { searchConfig: number; isDisabled: boolean; }) {
              if(item.searchConfig == 0){
                item.isDisabled = false;
              }
            })
          }
        }
      }
    });

    this.moduleService.breadCrumbs.subscribe(breadcrumbs => {
      var templateID = this.template.templateID;
      var displayControlsID = this.template.displayControlsID;
      var depeendentTemplateID = this.template.isDependent;
      var parentTemplateID = this.template.hasParent;
      this.breadcrumbs = breadcrumbs.filter(a => a.templateId == depeendentTemplateID || a.templateId == parentTemplateID);//|| (a.templateId == templateID && a.displayControlsID == displayControlsID)

      //this.breadcrumbs.filter(a => a.templateId == templateID && a.displayControlsID == displayControlsID)[0].displayName = "darshan";
    });

    this.moduleService.breadCrumbName.subscribe(breadcrumb => {
      if (!this.GV.GetIsRedirection())
      if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {
        var displayData = this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID);
        if (displayData == null || displayData == undefined || displayData.length == 0) {
        } else {
          this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;
        }
      }
    });

    this.moduleService.GetLegends(this.templateID).subscribe(legendsData => {
      this.legends = legendsData;
    });

    this.label = {
      format: this.formatter,
      position: 'center'
    };
  }

  public formatter: LabelFn = (value: number): string => {
    return '';
  };

  loadTemplate() {

    this.showLoader = true;
    this.passEditID;


    


    if(this.id!=undefined && this.id!=null)
    {
      if (this.templateID == "15" )
      {
        let selectedItem = new templateSelectedRecord();
        selectedItem.templateId = "15"
        selectedItem.selectedRecordId = parseInt(this.id);
        selectedItem.selectedRecordIds = parseInt(this.id);

        selectedItem.moduleID = "1";
        selectedItem.highlightRecordID = parseInt(this.id);

        selectedItem.dependentRecordID = this.id
        selectedItem.recordType = null;
        selectedItem.collectionId = null;
        selectedItem.targetTemplateId = "0"
        selectedItem.componentType = "1";
        this.pageSize = 999999999;
        this.moduleService.passDependencyTemplate(selectedItem);
        if (this.processDataId != undefined && this.processDataId != null) {
          let selectedItem = new templateSelectedRecord();
          selectedItem.templateId = "16"
          selectedItem.selectedRecordId = parseInt(this.processDataId);
          selectedItem.selectedRecordIds = parseInt(this.processDataId);

          selectedItem.moduleID = "1";
          selectedItem.highlightRecordID = parseInt(this.processDataId);

          selectedItem.dependentRecordID = this.id
          selectedItem.recordType = null;
          selectedItem.collectionId = null;
          selectedItem.targetTemplateId = "0"
          selectedItem.componentType = "1";
          this.moduleService.passDependencyTemplate(selectedItem);
        }
      }
      else {
        this.recordID = this.id;
      }
    }
   
    //Binding data for list view for projects start region
    //this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 0, this.template.searchFilters,'Default',this.skip,this.pageSize).subscribe(data => {
    this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 0, this.template.searchFilters, 'Default', this.skip, this.pageSize, this.template.keyWordSearch, this.template.chkFields, null).subscribe(data => {

      this.SearchName = this.template.searchName;

      if (this.template.searchFilters == "" || this.template.searchFilters == [] || this.template.searchFilters == "[]" || this.template.searchFilters == undefined)
        this.IsSearchedApplied = "";
      else
        this.IsSearchedApplied = this.template.searchFilterValues;

      this.chkFields = this.template.chkFields;
      this.chkFieldValues = this.template.chkFieldValues;

      if (data.record == null && data.columns == null && data.displayActions == null && data.displayProperties == null) {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);
        this.showLoader = false;
        return;
      }


      this.records = JSON.parse(data.record);
      this.filteredRecords = this.records;
      this.view = null;
      if (this.id == null || this.id == undefined || this.templateID!="15") {
        if (this.records.length > 0) {
          // this.records.length=this.records[0].Total;

          // this.view.data= this.records.slice(this.skip, this.skip + this.pageSize).map((item) => ({ ...item })),
          // this.view.total= this.records[0].Total;
          if (data.displayProperties[0].isPaging == "True") {
            var list = <ListViewDataResult>{
              data: this.records,
              total: this.records[0].Total
            }
            this.view = list;
            this.selectedRecordID = this.records[0].ID;
            this.template.highlightRecordID = this.records[0].ID;
            this.recordsCount = this.records[0].Total;
          }
          else {
            this.view = this.records;
            this.recordsCount = this.records.length;
          }
        }
      }
      //this.onListFieldAction()
      //if reports template then translate reports name and description 
      if (this.templateID == "66" && this.records && this.records.length > 0) {
        this.records.forEach(element => {
          let tdata = this.languageService.dataByLanguage.find(x => x.LanguageKey == element.RepCode);
          if (tdata) {
            element.Name = tdata.Message;
            element.Description = tdata.DefaultValue;
          }
        });
      }
      //gaates task list, then disable navigation click on subprocesses(components)
      if ((this.templateID == "71" || this.templateID == 78) && this.records && this.records.length > 0) {
        this.records.forEach(element => {
          if (element.TaskID && element.TaskID == '37') {
            element.isAccordianClick = true;
          } else {
            element.isAccordianClick = false;
          }
        });
      }
      this.filteredRecords = this.records;

      this.recordsCount = this.records.filter(a => a.IsAccordian == 0).length;;

      this.controlClass = this.template.controlClass;
      this.columns = data.columns;
      this.FileStatusColumnExists = this.columns.filter(a => a.controlType == 43).length > 0 ? true : false;//checking for file status colunm

      this.displayCommonActions = data.displayActions.filter(a => a.dynamicActionType == 3);
      this.displayActions = data.displayActions.filter(a => a.dynamicActionType == 1);

      this.EnableSearchOption = data.displayProperties[0].enableSearchOption == "True" ? true : false;
      this.EnableFiltersSearch = this.template.enableFiltersSearch == "True" ? true : false;
      this.ShowBreadcrumbs = this.template.showBreadcrumbs == "True" ? true : false;
      this.keyWordSearch = this.template.keyWordSearch;
      this.displayActionsTemplateLevel = data.displayActions.filter(a => a.dynamicActionType == 4);

      this.ShowFilterSearchInPopUp = this.template.showFilterSearchInPopUp == "True" ? true : false;
      this.EnableFilterSearchSaving = this.template.enableFilterSearchSaving == "True" ? true : false;

      if (this.ShowFilterSearchInPopUp) {
        this.ShowSearchPopUp = true;
        this.ShowSearchDirect = false;
      } else {
        this.ShowSearchPopUp = false;
        this.ShowSearchDirect = true;
      }

      this.IsFilter = data.displayProperties[0].isFilter == "True" ? true : false;
      this.IsPaging = data.displayProperties[0].isPaging == "True" ? true : false;
      this.IsPaging = this.id != null && this.id != undefined && this.templateID=="15"? false : this.IsPaging

      this.IsSorting = data.displayProperties[0].isSorting == "True" ? true : false;
      this.IsExportPDF = data.displayProperties[0].isExportPDF == "True" ? true : false;

      this.EnableMultiSelect = data.displayProperties[0].enableMultiSelect == "True" ? true : false;
      if (this.EnableMultiSelect) {
     
        this.ShowMultiSelect = true;
      } else {
        
        this.ShowMultiSelect = false;

      }
      this.NoOfRecordsMsg = data.displayProperties[0].noOfRecordsMsg;
      if (this.templateID == 17 && this.id != null) {
        this.NoOfRecordsMsg = "No. of Tasks";
      }
      this.IsExportExcel = data.displayProperties[0].isExportExcel == "True" ? true : false;

      //this.ShowProgressBar = data.displayProperties[0].showProgressBar == "True" ? true : false;

      if (this.IsPaging == true) {
        if (this.records.length > 0)
          this.recordsCount = this.records[0].Total;
        else
          this.recordsCount = 0;

      }

      this.taskCompletedCount = this.filteredRecords.filter(a => a.WorkFlowStatus == "Completed" || a.WorkFlowStatus == "Approved" || a.WorkFlowStatus == "Rejected").filter(a => a.IsAccordian == 0).length;


      if (this.id !== undefined && this.id !== null && this.templateID =="15") {
        
        let rid = this.id;
        let projectID = this.id;
          let filteredData = this.filteredRecords.filter(x => x.ID == projectID);
          if (filteredData.length > 0) {
            const index: number = this.filteredRecords.indexOf(filteredData[0]);
            if (index !== -1) {
              this.filteredRecords.splice(index, 1);
              this.filteredRecords.splice(0, 0, filteredData[0]);
            }  
            var list = <ListViewDataResult>{
              data: filteredData,
              total: filteredData[0].Total
            }
            this.view = list;
            this.recordsCount = filteredData.length;
            this.filteredRecords.spl
            this.onListFieldAction("", filteredData[0])
          }
      }

      if (this.template.hasParent != 0 && this.template.selectedRecordID != 0) {
        this.selectedRecordID = this.template.selectedRecordID;
        this.ShowRefersh = true;
      } else {
        if (this.records != null && this.records.length > 0) {
          if (this.id != null)
            this.selectedRecordID = this.records[0].ProjectID;
          else
            this.selectedRecordID = this.records[0].ID;
        }
      }

      if (this.template.highlightRecordID == 0 || this.template.highlightRecordID == null)
        this.template.highlightRecordID = this.selectedRecordID;
      //#region check for dependent template and get add to template list for loading dependent template
      this.CheckHasDependentsSubscription = this.moduleService.CheckHasDependents(this.template.moduleID, this.template.templateID).subscribe(data => {

        if (data.isDependent != null && data.isDependent != "0" && this.records != null && this.records.length > 0) {

          //this.selectedRecordID = this.records[0].ID;
          data.recordID = this.template.highlightRecordID;
          data.selectedRecordID = this.template.highlightRecordID;
          data.selectedTemplateId = this.templateID;

          data.recordType = this.template.recordType;
          data.collectionId = this.template.collectionId;

          if (this.columns.length > 0) {
            if (this.columns[0].actionType == 6)
              data.targetTemplateId = data.templateID;
          }
          this.showLoader = false;

          this.moduleService.passTemplateRecord(data);

          if (this.template.highlightRecordID == 0 || this.template.highlightRecordID == null) {
            this.template.highlightRecordID = this.selectedRecordID;
          }
          this.moveScroll();

        } else {

          data.selectedTemplateId = this.templateID;

          data.recordType = this.template.recordType;
          this.showLoader = false;
          this.moduleService.passTemplateRecordRemove(data);
        }

        var breadcrumbs = new breadCrumbs()
        breadcrumbs.id = this.template.templateID;
        if (this.records == undefined || this.records == null || this.records.filter(a => a.ID == this.template.highlightRecordID).length == 0)
          breadcrumbs.displayName = "";
        else
          breadcrumbs.displayName = this.records.filter(a => a.ID == this.template.highlightRecordID)[0][this.columns.filter(a => a.isBreadCrumbField == "True")[0].fieldName];

        breadcrumbs.templateId = this.template.templateID;

        breadcrumbs.displayControlsID = this.template.displayControlsID;
        breadcrumbs.defaultHeader = (this.template.defaultHeader == null || this.template.defaultHeader == undefined || this.template.defaultHeader == "") ? this.template.tabHeaderName : this.template.defaultHeader;

        breadcrumbs.displayControlsTabID = this.template.displayControlsTabID;
        breadcrumbs.selectedRecordID = this.template.selectedRecordID;
        breadcrumbs.highlightRecordID = this.template.highlightRecordID;

        this.moduleService.passbreadCrumbName(breadcrumbs);
      });

      //#endregion

      //#region Accordion expand when reloading after selecting record
      if (this.template.hasParent != "0") {

        var dataItemAccord = this.filteredRecords.filter(a => a.IsAccordian != 0);

        if (dataItemAccord != null && dataItemAccord.length > 0) {

          for (var i = 0; i < dataItemAccord.length; i++) {
            var index = this.filteredRecords.indexOf(dataItemAccord[i]);
            this.accordianShowHide(dataItemAccord[i], index)
          }
          this.SelectedDataItem = 0;
        }
      } else {
        if (!isNaN(this.selectedRecordID)) {
          var dataItem = this.filteredRecords.filter(a => a.ID == this.selectedRecordID);

          if (dataItem != null && dataItem != undefined && dataItem.length != 0) {

            if (dataItem[0].AccordianID != 0) {
              var dataItemAccord = this.filteredRecords.filter(a => a.IsAccordian == dataItem[0].AccordianID && a.AccordianID == 0);
              var index = this.filteredRecords.indexOf(dataItemAccord[0]);
              this.accordianShowHide(dataItemAccord[0], index)
            }
          }

          if (this.template.highlightRecordID == 0 || this.template.highlightRecordID == null)
            this.template.highlightRecordID = this.selectedRecordID;

          this.moveScroll();
        }
      }
      this.showLoader = false;
    });

    //   #projects endregion



    //getting commom classes based on template id


    this.moduleService.GetClassName(this.templateID).subscribe(classessData => {


      this.nonCommonClassess = classessData;
      });


  }

  moveScroll() {

    //moving the scroll to the previous postion
    setTimeout(() => {

      var divelement = document.getElementById("div" + this.template.templateID + this.template.highlightRecordID);

      if (divelement != null) {
        //document.getElementById("div" + this.template.templateID + this.template.highlightRecordID).scrollIntoView({ block: "start" });
        document.getElementById("div" + this.template.templateID + this.template.highlightRecordID).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
    }, 1000);
  }

  close() {
    this.showPopUp = false;
    this.parentRecordID = 0;
  }


  gridActionView(dataItem) {

    this.moduleService.passtemplateParentID(dataItem.TemplateId);
    this.moduleService.passparentRecordID(dataItem.ID);

  }
  showFilter: boolean = false;
  toggleFilter() {
    this.showFilter = !this.showFilter;

  }
  accordianShowHide(dataItem, index) {



    if (this.filteredRecords[index].ToogleAccordian == 0) {
      this.filteredRecords[index].ToogleAccordian = 1;
    } else {
      this.filteredRecords[index].ToogleAccordian = 0;

    }

    this.SelectedDataItem = dataItem.IsAccordian;
    //this.loadTemplate();

  }


  onCheckAll(templateID) {


    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + templateID);
    var isChecked = elementAll.checked;

    var checkBoxCheckIDs = []

    var filteredRecords = [];
    filteredRecords = this.filteredRecords;

    filteredRecords.forEach(function (value) {

      var element = <HTMLInputElement>document.getElementById("chk" + templateID + value.ID);
      if (element != null) {
        if (isChecked) {
          element.checked = true;
          checkBoxCheckIDs.push(parseInt(value.ID));
        } else {
          element.checked = false;
        }
      }




    });



    this.checkBoxCheckIDs = checkBoxCheckIDs;


    let selectedItem = new templateSelectedRecord();
    selectedItem.templateId = this.template.templateID;
    selectedItem.selectedRecordId = this.filteredRecords[0].ID;
    selectedItem.selectedRecordIds = this.checkBoxCheckIDs;
    selectedItem.moduleID = this.template.moduleID;
    selectedItem.dependentRecordID = this.template.recordID;
    selectedItem.highlightRecordID = this.filteredRecords[0].ID;
    selectedItem.componentType = this.componentType;
    this.moduleService.passDependencyTemplate(selectedItem);


  }

  onCheck(dataItem, templateID) {



    var element = <HTMLInputElement>document.getElementById("chk" + templateID + dataItem.ID);
    var isChecked = element.checked;


    if (isChecked) {
      if (this.checkBoxCheckIDs.indexOf(dataItem.ID) > -1) {
      } else {
        this.checkBoxCheckIDs.push(dataItem.ID);

      }
    } else {
      const index: number = this.checkBoxCheckIDs.indexOf(dataItem.ID);
      if (index !== -1) {
        this.checkBoxCheckIDs.splice(index, 1);
      }
    }
    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + templateID);


    if (elementAll != null) {
      if (this.checkBoxCheckIDs.length == this.filteredRecords.length) {
        elementAll.checked = true;
      } else {
        elementAll.checked = false;
      }

    }




    if (this.template.isDependent != "0") {
      this.selectedRecordID = dataItem.ID;
    } else {
      this.selectedRecordID = dataItem.ID;
    }


    let selectedItem = new templateSelectedRecord();
    selectedItem.templateId = this.template.templateID;
    selectedItem.selectedRecordId = dataItem.ID;
    selectedItem.selectedRecordIds = this.checkBoxCheckIDs;
    selectedItem.moduleID = this.template.moduleID;
    selectedItem.dependentRecordID = this.template.recordID;
    selectedItem.highlightRecordID = dataItem.ID;
    selectedItem.componentType = this.componentType;
    this.moduleService.passDependencyTemplate(selectedItem);


  }
  onListFieldAction(selectedRow, dataItem) {
    this.GV.SetIsRedirection(false);

    if (this.template.isSplitter == "1") {

      this.moduleService.passSplitterTemplate(dataItem);

    } else {

    if (selectedRow.actionType == 7) {
      localStorage.setItem("moduleID", dataItem.ID);
      //this.moduleService.passSelectedModuleIDForDashboard(dataItem.ID);
      this.moduleID = dataItem.ID;
      var templData = new templateData();
      templData.moduleID = dataItem.ID;
      this.moduleService.passTemplateData(templData);
      let selectedItem = new templateSelectedRecord();
      selectedItem.selectedRecordId = 0;
      this.moduleService.passDependencyTemplate(selectedItem);
      this.router_navigate.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router_navigate.navigate([dataItem.RoutingName]));
    }

    if (selectedRow.actionType == 132) {
      this.searchFilters.templateId = selectedRow.actionTypeTemplateId;
      this.searchFilters.searchFields = (dataItem.Id == 5) ? "" : '[{"options":["' + dataItem.Id + '"],"key":"TaskStatus"}]';
      this.searchFilters.searchFieldValues = '{ "TaskStatus": ["' + dataItem.Id + '"] }'
      this.searchFilters.searchName = "Task Status"
      this.moduleService.passsearchFilters(this.searchFilters);

    } else {


      if (this.template.isDependent != "0") {
        this.selectedRecordID = dataItem.ID;
      } else {
        this.selectedRecordID = dataItem.ID;
      }

      let selectedItem = new templateSelectedRecord();
      selectedItem.templateId = this.template.templateID;
      selectedItem.selectedRecordId = dataItem.ID;
      selectedItem.selectedRecordIds = [dataItem.ID];

      selectedItem.moduleID = this.template.moduleID;
      selectedItem.highlightRecordID = dataItem.ID;

      selectedItem.dependentRecordID = this.template.recordID;
      selectedItem.recordType = this.template.recordType;
      selectedItem.collectionId = this.template.collectionId;


      if (selectedRow.actionType == 6) {
        selectedItem.targetTemplateId = dataItem.TargetTemplateID;
        //selectedItem.templateId = dataItem.TargetTemplateID;

      } else {
        selectedItem.targetTemplateId = "0";
      }

      selectedItem.componentType = this.componentType;
      this.template.highlightRecordID = dataItem.ID;
      this.moduleService.passDependencyTemplate(selectedItem);

    }


    var breadcrumbs = new breadCrumbs()
    breadcrumbs.id = this.template.templateID;
    if (this.columns.filter(a => a.isBreadCrumbField == "True") != null && this.columns.filter(a => a.isBreadCrumbField == "True") != undefined && this.columns.filter(a => a.isBreadCrumbField == "True").length > 0) {
      breadcrumbs.displayName = dataItem[this.columns.filter(a => a.isBreadCrumbField == "True")[0].fieldName];

    } else {
      breadcrumbs.displayName = "";

    }

    breadcrumbs.templateId = this.template.templateID;

    breadcrumbs.displayControlsID = this.template.displayControlsID;
    breadcrumbs.defaultHeader = (this.template.defaultHeader == null || this.template.defaultHeader == undefined || this.template.defaultHeader == "") ? this.template.tabHeaderName : this.template.defaultHeader;

    breadcrumbs.displayControlsTabID = this.template.displayControlsTabID;
    breadcrumbs.selectedRecordID = this.template.selectedRecordID;
    breadcrumbs.highlightRecordID = this.template.highlightRecordID;


    this.moduleService.passbreadCrumbName(breadcrumbs);
  }
  }

  onTemplateAction(action) {

    if (action.actionEvent == "btnReassign") {

      //debugger;
      this.dynHeadingName = action.templateName;
      this.dynHeadingName = this.languageService.GetActionMessageByLanguage(action.templateAliasName, action.templateName);
      this.inputFormFields = action.inputFormFields;
      this.disableFields = action.disableFields;


      //alert(this.inputFormFields);

      //this.dynHeadingName = this.dynHeadingName + " - " + data[data.length - 1].projectName;
      this.reassignpopup = "block";
      this.ShowReAssignWindow = true;

    }
  }
  closereassignModalDialog() {
    this.reassignpopup = 'none'; //set none css after close dialog
    this.ShowReAssignWindow = false;
  }






  gridAction(dataItem, action) {


    this.ID = dataItem.ID;
    if (action.actionEvent == "Edit") {

      this.passEditID.emit(dataItem.ID);

    } else if (action.actionEvent == "Audit") {

    } else if (action.actionEvent == "Delete") {

      var ans = confirm("Do you want to delete this record");
      if (ans) {
        this.showLoader = true;
        this.moduleService.DeleteDisplayFieldRecord(dataItem.ID, this.GV.UserId).subscribe(data => {
          this.loadTemplate();
        });
      }


    } else if (action.actionEvent == "SubProcess Edit") {

      this.showPopUp = true;
      this.RecordID = dataItem.ID;

      this.showLoader = true;
      this.dataService.getDynamicFormFields(11, dataItem.ID, 0, true, "", "", false).subscribe(response => {

        this.dynTemplateHeader = response.dynTemplateHeader;
        this.DynamicFields = response.dynamicFormFieldList;
        this.DynamicFormFieldsActions = response.displayActions;
        this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
        this.showLoader = false;
      });
    }

    else if (action.actionEvent == "View") {
      this.showPopUp = true;
      this.moduleService.DisplayView(dataItem.ID).subscribe(data => {
        this.viewRecords = data;
        this.documentsPath = this.moduleService.getBaseUrl();
      })
    } else if (action.actionEvent == "showDataByFilter") {


      this.searchFilters.templateId = action.targetTemplateID;
      this.searchFilters.searchFields = (dataItem.Id == 5) ? "" : '[{"options":["' + dataItem.Id + '"],"key":"TaskStatus"}]';
      this.searchFilters.searchFieldValues = '{ "TaskStatus": ["' + dataItem.Id + '"] }'
      this.searchFilters.searchName = "Task Status"
      this.moduleService.passsearchFilters(this.searchFilters);

    }
  }


  GetAccordianData(dataItem: any): any {



    this.accordianFilteredRecords = this.filteredRecords.filter(a => a.AccordianID == dataItem.IsAccordian)

    return this.accordianFilteredRecords;
  }

  FromAction(action: any) {
    if (action.actionEvent == "Submit")
      this.onSubmit();
    else
      this.cancel();
  };

  onSubmit() {
    if (this.dynamicForm.invalid) {
      this.invalidSubmitAttempt = true;
      return;
    }

    if (this.uploadedFiles.length > 0 && this.uploadedFiles != undefined) {
      let uploadFields = Object.keys(this.uploadedFiles);
      for (var i = 0; i < uploadFields.length; i++) {
        this.dynamicForm.value[uploadFields[i]] = this.uploadedFiles[uploadFields[i]];
      }
    }
    //console.log(this.dynamicForm.value);
    this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
    this.templateData.IsfromProject = false;
    this.templateData.ID = this.ID !== undefined ? parseInt(this.ID) : this.ID;
    this.templateData.TemplateID = parseInt(this.templateID);
    this.templateData.UserID = this.GV.UserId;
    this.showLoader = true;
    this.templateData.UploadFileType = this.GV.GetFileType();
    this.dataService.saveDynamicFormFieldsdata(this.templateData).subscribe(responce => {
      this.showLoader = true;
      this.showPopUp = false;
      this.loadTemplate();
      // this.cancel();
    })

  }

  cancel() {
    this.IsDisplayView = false;
    this.displayViewID = 0;
    //this.getDisplaycontrolRecords();

  }
  onSort() {

    //this.Sort = !this.Sort;

    //this.filteredRecords = this.filteredRecords.sort(function (a, b) {


    //  return a.ID-b.ID;
    //});


  }


  isCheckedCancelled: boolean = false;
  isCheckedSuspended: boolean = false;
  isCheckedMyProjects: boolean = false;
  isCheckedAllProjects: boolean = false;


  onCheckChange(dataObject, index) {
    if (this.templateFilters != null && this.templateFilters != undefined) {
      if(dataObject.searchConfig == 1){
        let element = <HTMLInputElement>document.getElementById("chkFilter" + index);
        if (element.checked) {
          this.templateFilters.forEach(function (filterItems: { searchConfig: number; isDisabled: boolean; isChecked: boolean; }) {
            if(filterItems.searchConfig == 0){
              filterItems.isChecked = false;
              filterItems.isDisabled = true;
            }
          })
        }
        else{
          this.templateFilters.forEach(function (item: { searchConfig: number; isDisabled: boolean; }) {
            if(item.searchConfig == 0){
              item.isDisabled = false;
            }
          })
        }
      }
    }
  }

  onCheckSubmit(){
    let searchOptions = [];
    let searchOptionValues = [];
    let searchKey = "";
    let filterKeyConfig = 0;
    let searchDisplayName = "";

    if (this.templateFilters != null && this.templateFilters != undefined) {
      this.templateFilters.forEach(function (value, index) {
        let element = <HTMLInputElement>document.getElementById("chkFilter" + index);

        if (element.checked) {
          searchOptions.push(value.searchOptions);

          searchKey = value.searchKey;
          if(value.searchConfig == 1){
              filterKeyConfig = 1
          }
          searchDisplayName = value.searchDisplayName;
          searchOptionValues.push(searchDisplayName)
        }
      });
    }

    if(searchOptionValues.length == 0){
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SELFLT', "Please select atleast one filter."), 4);
      return;
    }


    this.checkedtemplateFilters = searchOptionValues
    this.searchFilters.searchFields = this.template.searchFilters;
    this.searchFilters.searchFieldValues = this.template.searchFilterValues;
    this.searchFilters.chkFields = "[{\"options\":[" + searchOptions.join(",") + "],\"key\":\"" + searchKey + "\"}]";
    this.searchFilters.chkFieldValues = JSON.stringify(searchOptionValues);
    this.searchFilters.keyWordSearch = this.keyWordSearch;
    this.searchFilters.displayControlsID = this.template.displayControlsID;

    this.searchFilters.chkDisplayControl = "14";

    this.searchFilters.templateId = this.templateID;
    this.searchFilters.filterConfigKey = filterKeyConfig;
    this.moduleService.passsearchFilters(this.searchFilters);
  }

  closeDialog() {
    let searchOptions = [];
    let searchOptionValues = [];
    let searchKey = "";
    let filterKeyConfig = 0;
    let searchDisplayName = "";

    if (this.templateFilters != null && this.templateFilters != undefined) {
      this.templateFilters.forEach(function (value, index) {
        let element = <HTMLInputElement>document.getElementById("chkFilter" + index);

        if (element.checked) {
          searchOptions.push(value.searchOptions);

          searchKey = value.searchKey;
          if(value.searchConfig == 1){
              filterKeyConfig = 1
          }
          searchDisplayName = value.searchDisplayName;
          searchOptionValues.push(searchDisplayName)
        }
      });
    }

    this.checkedtemplateFilters = searchOptionValues
    this.searchFilters.searchFields = this.template.searchFilters;
    this.searchFilters.searchFieldValues = this.template.searchFilterValues;
    this.searchFilters.chkFields = "[{\"options\":[" + searchOptions.join(",") + "],\"key\":\"" + searchKey + "\"}]";
    this.searchFilters.chkFieldValues = JSON.stringify(searchOptionValues);
    this.searchFilters.keyWordSearch = this.keyWordSearch;
    this.searchFilters.displayControlsID = this.template.displayControlsID;

    this.searchFilters.chkDisplayControl = "13";

    this.searchFilters.templateId = this.templateID;
    this.searchFilters.filterConfigKey = filterKeyConfig;
    this.moduleService.passsearchFilters(this.searchFilters);
    this.showFilter = false;
  }
  getDisplaycontrolRecords() {
    this.showLoader = true;
    this.templateParentID = 12;

    //this.moduleService.GetTemplatesByModuleID(this.moduleID, this.templateParentID).subscribe(data => {
    //  this.IsDisplayControls = true;
    //  this.showLoader = false;
    //  this.showPopUp = false;
    //  this.templates = data;
    //});
  }

  CloseSubDisplayForm() {
    this.templateParentID = 0;
    this.moduleService.passparentRecordID(0);
    this.IsDisplaySubFormView = false;
  }


  getClassName(dataItem): string {
     
    var className = "";
    if (dataItem.ID == this.template.highlightRecordID) {

      className = "active";
    }

    var checkTileClassControl = this.columns.filter(a => a.controlType == "45");
    if (checkTileClassControl != null && checkTileClassControl.length>0) {

      className = className +" "+ dataItem.IndividualTileClass;
    }

    return className;

  }

  getColumnClassName(column, dataItem): string {
    var className = "";
    if (column.isCommonClass == "True") {
      className = column.classess;
    } else {      
      if (dataItem[column.fieldName] != null) {
        var fieldName = dataItem[column.fieldName];

        if (this.nonCommonClassess.filter(a => a.name == fieldName) != null
          && this.nonCommonClassess.filter(a => a.name == fieldName).length>0
        ) {
          className = this.nonCommonClassess.filter(a => a.name == fieldName)[0].classess;

        }

        if (this.template.templateID == 95) {
          className = className + this.template.templateID;
        }
      }

    }


    return className;

  }
  reloadParentDependentTemplate() {


    //this.onListFieldAction(selectedRow, dataItem);
    this.CheckHasDependentsSubscription = this.moduleService.CheckHasDependents(this.template.moduleID, this.template.templateID).subscribe(data => {

      if (data.isDependent != null && data.isDependent != "0") {



        this.moduleService.CheckIsDependents(this.template.moduleID, data.templateID).subscribe(dataDependent => {

          if (dataDependent.isDependent != null && dataDependent.isDependent == "0" && dataDependent.templateID != "0") {
            dataDependent.selectedRecordID = this.selectedRecordID;
            dataDependent.selectedRecordIDs = this.selectedRecordID;



            this.moduleService.passDependentRecord(dataDependent);

          }
        });

      }
    });

  }



  //public onSearch(inputValue: string): void {

  //  debugger
  //  this.keyWordSearch=inputValue;
  //  let allRecords = this.records;
  //  let filters = [];
  //  this.columns.forEach(function (value) {
  //    let objFilter = new filter();
  //    objFilter.field = value.fieldName;
  //    objFilter.operator = "contains";
  //    objFilter.value = inputValue;
  //    filters.push(objFilter);
  //  });
  //   this.filteredRecords=  process(this.records, {
  //    filter: {
  //      logic: "or",
  //      filters: filters
  //    }
  //  }).data;
  //  this.view=this.filteredRecords;
  //  let accordianIDs = [];
  //  this.filteredRecords.filter(function (record) {
  //    if (record.AccordianID != 0) {

  //      if (accordianIDs.indexOf(record.AccordianID) > -1) {
  //      } else {
  //        accordianIDs.push(record.AccordianID);
  //      }
  //    }
  //  });
  //   this.filteredRecordsAll = this.filteredRecords;
  //  let searchfilteredRecords = {};
  //  accordianIDs.forEach(function (value) {
  //    filters = [];
  //    let objFilter = new filter();
  //    objFilter.field = "IsAccordian";
  //    objFilter.operator = "eq";
  //    objFilter.value = value;
  //    filters.push(objFilter);

  //    searchfilteredRecords = process(allRecords, {
  //      filter: {
  //        logic: "or",
  //        filters: filters
  //      }
  //    }).data;
  //    this.view=this.searchfilteredRecords;
  //    if (searchfilteredRecords != null && searchfilteredRecords[0] != null) {

  //      if (this.filteredRecordsAll.indexOf(searchfilteredRecords[0]) > -1) {
  //      } else {
  //        this.filteredRecordsAll.push(searchfilteredRecords[0]);
  //      }
  //    }

  //  });

  //  this.filteredRecords =this.view = this.filteredRecordsAll;

  //  this.recordsCount = this.filteredRecords.filter(a => a.IsAccordian == 0).length;

  //  if(this.IsPaging==true)
  //  {


  //    if(inputValue=="")
  //    {
  //      this.recordsCount=this.records[0].Total;
  //        var list= <ListViewDataResult>{  
  //          data: this.records,  
  //          total: this.records[0].Total  
  //      }
  //      this.view=list;
  //    }
  //    else
  //    {
  //      this.recordsCount=this.filteredRecords.length;
  //    }
  //  }



  //}

  IsKeyWordSearched: boolean = false;

  public onKeyWordSearch() {
    this.IsKeyWordSearched = true;
 
    this.skip = 0;
    this.take = 10;

    this.template.keyWordSearch = this.keyWordSearch;

    this.loadTemplate();


    if (this.keyWordSearch == "" || this.keyWordSearch == null || this.keyWordSearch == undefined) {
      this.IsKeyWordSearched = false;


    }
 
  }



  public onClearKeyWordSearch() {
    this.IsKeyWordSearched = false;

    this.skip = 0;
    this.take = 10;

    this.template.keyWordSearch = "";
     this.loadTemplate();
  }

  getControlClass(): string {
    var className = "";
    if (this.template.templateID != '94' && this.template.templateID != '95') {
      className = this.template.controlClass;
    }
    return className;
  }
  checkUserPrivilege(actionEventID, LevelID) {
    return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);

  }

  onExcelExport(template) {

    this.showLoader = true;

    this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 1, this.template.searchFilters, 'Default', 0, 0, this.keyWordSearch, this.template.chkFields, null).subscribe(data => {


      if (data.record == null && data.columns == null && data.displayActions == null && data.displayProperties == null) {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);
        this.showLoader = false;

        return;
      }

      if (this.keyWordSearch == null || this.keyWordSearch == undefined || this.keyWordSearch == '') {
        if (this.checkBoxCheckIDs.length > 0) {

          this.parseData = JSON.parse(data.record);
          this.excelRecords = [];

          for (let i = 0; i <= this.checkBoxCheckIDs.length - 1; i++) {
            for (let j = 0; j <= this.parseData.length - 1; j++) {
              if (this.checkBoxCheckIDs[i] == this.parseData[j].ID) {

                this.excelRecords.push(this.parseData[j]);
              }
            }
          }
        }

        else {
          this.excelRecords = JSON.parse(data.record);
        }
      }
      else {
        this.excelRecords = JSON.parse(data.record);

      }
      this.excelColumns = data.columns;

      if (this.excelColumns == null || this.excelColumns.length == 0) {

        this.commonfeaturesservice.GetAllActionsMessages(4, 0, 0, 999, "NCCFEE", this.CompanyID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
        });
        this.showLoader = false;
        return;
      }


      var rows = [];
      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];


      var columnName = [];

      ///Downloaded by

      var headerCell = new headerCellClass();
      headerCell.value = this.languageService.GetActionMessageByLanguage('STC_DLDBY', "Downloaded By");
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      var headerCell = new headerCellClass();
      headerCell.value = this.Name + '(' + this.GV.UserName + ')';
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Downloaded Date

      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];
      var headerCell = new headerCellClass();
      headerCell.value = this.languageService.GetActionMessageByLanguage('STC_DLDDT', "Downloaded Date");
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      var headerCell = new headerCellClass();

      var CurrentDate = new Date();
      headerCell.value = CurrentDate.toString();;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);


      //Filter Name 

      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];
      var headerCell = new headerCellClass();
      headerCell.value = this.languageService.GetActionMessageByLanguage('STC_FLTNM', "Filter Name");
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      var headerCell = new headerCellClass();

      var CurrentDate = new Date();
      headerCell.value = "[" + this.languageService.GetActionMessageByLanguage('STC_KEYSCH', 'Keyword Search') + " : " + this.keyWordSearch + "] [" + this.languageService.GetActionMessageByLanguage('STC_SCHNM', "Search Name") + " : " + this.SearchName + "]";
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);


      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];

      //binding column name 
      this.excelColumns.filter(a => a.isExcelExportField == "True").forEach(function (value) {
        var headerCell = new headerCellClass();
        headerCell.value = this.GetTranslatedExcelColumnName(this.templateID, value.formFieldID, value.workFlowTaskId, value.displayName); //value.displayName;
        headerCell.bold = true;
        headerCell.background = "#227447"
        headerCell.color = "#fffff"
        headerRowcells.push(headerCell);

        columnName.push(value.fieldName);

      }.bind(this));

      headerRow.cells = headerRowcells;
      rows.push(headerRow);


      //binding data
      this.excelRecords.forEach(function (datavalue) {

        var headerRow = new headerRowClass();
        var headerRowcells: headerCellClass[] = [];
        columnName.forEach(function (columnvalue) {
          var headerCell = new headerCellClass();
          headerCell.value = datavalue[columnvalue];
          headerCell.bold = false;
          headerRowcells.push(headerCell);

        });

        headerRow.cells = headerRowcells;
        rows.push(headerRow);
      });
      let excelName = '';
      if (this.languageService.dataByLanguage.find(x => x.TemplateID == this.templateID && x.Type == 7)) {
        let excelNameObj = this.languageService.dataByLanguage.find(x => x.TemplateID == this.templateID && x.Type == 7);
        if (excelNameObj.HelpText)
          excelName = excelNameObj.HelpText +" "+ new Date();
        else
          excelName = template.templateName +" "+ new Date();
      } else {
        excelName = template.templateName +" "+ new Date();
      }
      const workbook = new Workbook({
        sheets: <WorkbookSheet[]>[
          {
            // Column settings (width)
            columns: [
              { autoWidth: true },

            ],
            // Title of the sheet
            //name: template.templateName,
            name: excelName,
            // Rows of the sheet
            rows: rows,
          },
        ],
      });

      this.commonfeaturesservice.GetAllActionsMessages(4, 0, 0, 999, "EEDS", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
      });

      workbook.toDataURL().then((dataUrl) => {
        saveAs(dataUrl, excelName + ".xlsx");
      });





      this.showLoader = false;


    });


  }

  onSearchClick() {



    var inputFormFields = [];



    this.columns.filter(a => a.isSearchFilterField == "True").forEach(function (value) {
      inputFormFields.push(parseInt(value.formFieldID));
    });

    this.SelectedRecord = 0
    this.targetModuleID = this.template.moduleID;
    this.targetTemplateID = this.template.templateID;
    this.inputFormFields = inputFormFields.join(',');
    this.showSearch = true;

  }
  closeSearch() {

    this.showSearch = false;

  }

  onClearSearh() {

    this.template.searchName=''
    this.template.defaultSearch=false;

    this.template.searchFilters = "";
    this.SearchName = "";

    this.loadTemplate();
  }
  GetTranslatedExcelColumnName(templateId, formFieldId, workFlowTaskId, OrgVal) {
    if (this.languageService.dataByLanguage.find(x => x.TemplateID == templateId && x.FormFieldID == formFieldId && x.WorkFlowTaskID == workFlowTaskId && x.Type == 2)) {
      return this.languageService.dataByLanguage.find(x => x.TemplateID == templateId && x.FormFieldID == formFieldId && x.WorkFlowTaskID == workFlowTaskId && x.Type == 2).Message;
    } else {
      return OrgVal;
    }
  }

  getShortName(strText) {
    var strTxt = "";
    if (strText.length >= 50)
      strTxt = strText.slice(0, 50).concat('...');
    else
      strTxt = strText;
    return strTxt;
  }
  DownloadArtWorkPdfFile(Filepath: string, FileName: string, ProcessDataID: number) {
    debugger
    this.IpAddres = this.GV.UserIpAddress;
    this.FileName = FileName;
    this.moduleService.DownloadPdfFile(Filepath, FileName, ProcessDataID, 0, this.GV.UserId, this.IpAddres).subscribe((event) => {

      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event,FileName);

      }

    });

 
}
async downloadPDF(data: HttpResponse<Blob>, FileName:any) {
  const downloadedFile = new Blob([data.body], { type: data.body.type });
  const URLSaving= URL.createObjectURL(downloadedFile);
  const pdfData = await this.http.get(URLSaving, { responseType: 'arraybuffer' }).toPromise();
  const pdfDoc = await PDFDocument.load(pdfData);
  pdfDoc.setTitle(FileName);
  const modifiedPdfData = await pdfDoc.save();
  saveAs(new Blob([modifiedPdfData], { type: 'application/pdf' }), FileName);
}
async downloadFile(data: HttpResponse<Blob>,FileName:any) {
  const downloadedFile = new Blob([data.body], { type: data.body.type });
  const a = document.createElement('a');
  a.setAttribute('style', 'display:none;');
  document.body.appendChild(a);
  a.download = FileName;//.substring(1).slice(0,-1);
  a.href = URL.createObjectURL(downloadedFile);
  a.target = '_blank';
  a.click();
  document.body.removeChild(a);
}
}




export class filter {

  field: string;
  operator: string;
  value: string;
}

export class DynamicTemplateData {
  ID: number;
  DynFormFieldData: string;
  TemplateID: number;
  TaskID: number;
  SubprocessID: number;
  IsfromProject: boolean;
  Isfromsubprocess: boolean;
  IsfromAssigneUsers: boolean;
  InputFormFields: string;
  ProjectId: number;
  ProcessDataId: number;
  TaskAction: string;
  UserID: number;
  ActionEvent: string;
  ProcessIds: string;
  Level: number;
  IsfromTaskEdit: false;
  UploadFileType: number = 0;
  WorkFlowId: number = 0;
  Ip: string;
  moduleID: number;
  selectedTakIds: string
}


export class DALPublish {
  ID: number;
  DynFormFieldData: string;
  UserID: number;
  Ip: string;
  ModifiedUser: string;
  DocumentsPath: string;


}




export class templateSelectedRecord {

  moduleID: string = "0";
  templateId: string = "0";
  //selectedTemplateId: string = "0";
  selectedRecordId: number = 0;
  selectedRecordIds: any = [];
  //parentTemplateID: string = "0";
  //parentRecordID: string = "0";
  //dependentTemplateID: string = "0";
  dependentRecordID: string = "0";

  highlightRecordID: number = 0;
  targetTemplateId: string = "0";
  componentType: string = "1";//1-dynamic form view  2-template view componet
  collectionId: string = "0";//For maintaining id for  remove collection 

  recordType: number = 1;//using for dal to identiry file colleciton selection or treview node selection---tree view=1
  filteredRecordsSelected?: any = '[]';
}

export class searchFilter {
  defaultSearch:boolean;
  templateId: number = 0;
  filterConfigKey: number = 0;
  searchFields: string = "";
  searchFieldValues: string = "";
  searchName: string = "";
  keyWordSearch: string = "";
  chkFields: string = "";
  chkFieldValues: string = "";
  displayControlsID: string = "";
  chkDisplayControl: string = "";//using for check filter for identifying in which control is selected like grid/list
  filter: any;
}

export class TaskSubDetails {
  projectID: number;
  processDataId: number;
  formDataValues: string;
  offset: string;
  actionType: string;
  actionStatus: string;
  UserID: number;
  workFlowId: number;
  Ip: string;
  UploadFileType: number = 0;
  offSet: string;
}
export class TaskOwnermodal {
  ProcessDataID: number;
  GroupId: number;
  UserId: number;
  lstUserIds: string;
  ClaimTaskStatus: boolean;
  moduleType: string;
  lstProcessDataIDs: string;
  actionType: number;
  userndGroupId: number;
  comment: string;
  CurrentLogin_UserID: number;
  Ip: string;
}
export class TaskOwnerAssignUsermodal {
  assignUsersModals: AssignUsersModal[];
  projectId: number;
  moduleType: string;
  CurrentLogin_UserID: number;
  Ip: string;
}
export class AssignUsersModal {
  DepartmentId: number;
  GroupId: string;
  UserId: string;
  lstProcessDataIDs: any = [];
}
export class TaskEditmodal {
  ProcessDataID: number;
  GroupId: number;
  UserId: number;
  ClaimTaskStatus: boolean;
  moduleType: string;
  lstProcessDataIDs: string;
  ActionType: number;
  UserndGroupId: number;
  TaskName: string;
  StepInstructions: string;
  Reasonforchange: string;
  Duration: number;
  Description: string;
}
export class headerCellClass {

  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class headerRowClass {

  cells: any;
}

export class backTemplate {
  templateID: string;
  displayTemplates: any;

}

class Tasksmodel {


  id: number;
  // Alias:string;
  TaskName: string;
  Taskcount: number;
  classname: string;
  actcls: string;
  icon: string;
  Alias: string;



}

export class gridViewData {
  public pageSize: number = 10;
  public skipPage: number = 0;
  public viewData: any = {};
  public filter: any = null;
}


