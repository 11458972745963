import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { AllPrivileges, PrivilegeMasterDTO, Privilige, PriviligeDetails } from '../../../services/AMFormsDataDetails';
import { NotificationAlertsService } from '../../../services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})

export class AddRoleComponent implements OnInit {
  roleDialog: boolean;
  //PriviligeData: PriviligeDetails[] = [];
  PriviligeData: PrivilegeMasterDTO[] = [];
  objPrivilige = new Privilige();
  showLoader = false;
  submitted = false;
  /*ddlmsprivilegeName = new FormControl([20]);*/
  OperationType: number = 1;
  selectedItems: any=[];
  privDataOpearation: number;
  savebtn: boolean = false;
  updatebtn: boolean = false;
  id: number;
  dynRoleHeader: string = null;
  UserID: number = 0;
  CompanyID: number = 0;
  public isChecked = false;
  showRoleDashboardLoader: boolean = false;
  allAccessValue='1';
public isRadioDisabled=false;
public isAllActionChecked=false;
  
  //displayedRows$: Observable<any>;
  //private tableData = new MatTableDataSource(messages);
  constructor(private router: Router, private av_router: ActivatedRoute, private formBuilder: FormBuilder, private amService: AdminUserManagementService, private commonfeaturesservice: CommonFeaturesService, private notifications: NotificationAlertsService, private GV: GlobalVeriables) {
    if (this.av_router.snapshot.params["id"]) {
      this.id = this.av_router.snapshot.params["id"];
      this.updatebtn = true;
      this.savebtn = false;
    }
    else {
      this.id = 0;
      this.savebtn = true;
      this.updatebtn = false;
    }
  };
  priviligeForm = this.formBuilder.group({
    txtRole: ['', [Validators.required, Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    txtDescription: ['', [Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    //ddlmsprivilegeName: ['', Validators.required],
  })

  ngOnInit() {
    // this.GV.UserId = +localStorage.getItem("userId");
    // this.roleDialog = true;
    // this.GetAllPrivleges();
    debugger;
    this.CompanyID= +localStorage.getItem("usercompanyID");

    if (this.id > 0) {
      this.EditRoleData(this.id);
      this.dynRoleHeader = 'Edit Role';
    }
    else {
      this.dynRoleHeader = 'Add Role';
      this.GV.UserId = +localStorage.getItem("userId");
      this.roleDialog = true;
      this.GetAllPrivleges();
    }
  }

  GetAllPrivleges() {//debugger
    // this.amService.getUMGetAllPrivleges(5, this.GV.UserId, this.CompanyID).subscribe(response => {
    //   //console.log("getUMGetAllPrivleges", response);
    //   this.PriviligeData = response;
    // });
    this.amService.GetAllPrivileges().subscribe(result=>{
      this.PriviligeData=result;
    })
  }

  public get toggleAllText() {
    return this.isChecked ? "Deselect All" : "Select All";
  }

  public isItemSelected(item) {
    return this.selectedItems.some((x) => x.privilegeID === item.privilegeID);
  }

  public onClick() {
    this.isChecked = !this.isChecked;
    this.selectedItems = this.isChecked ? this.PriviligeData.slice() : [];
  }

  public onValueChange() {
    this.isChecked = this.selectedItems.length === this.PriviligeData.length;
  }
  saveRoleData(id: any) {
   // debugger;
    if (this.priviligeForm.invalid) {
     this.submitted = true;
      return;
    }

    this.showRoleDashboardLoader = true;
    this.objPrivilige.RoleID = parseInt(id);
    this.objPrivilige.userID = this.GV.UserId;
    this.objPrivilige.roleName = this.priviligeForm.controls['txtRole'].value;
    this.objPrivilige.RoleDescription = this.priviligeForm.controls['txtDescription'].value;
    //this.objPrivilige.RoleID = 0;
   // this.objPrivilige.privileges = this.priviligeForm.controls['ddlmsprivilegeName'].value;
    var ele = this.priviligeForm.controls['ddlmsprivilegeName'].value;
    var splitingdata = [];
    var splitingwithName = [];
    var splitprivtype = [];
    for (var i = 0; i < ele.length; i++) {
      var countdt = ele[i].privilegeID; 
      splitingdata.push(countdt);
      var privname = ele[i].privilegeName;
      splitingwithName.push(privname);
      var privtype = ele[i].typeOfPrivilege;
      splitprivtype.push(privtype);
     

    }
    this.objPrivilige.typeOfPrivilege = splitprivtype.toString();
    this.objPrivilige.PrivilegeID = splitingdata.toString();
    this.objPrivilige.privileges = splitingwithName.toString();
    this.amService.saveRoleData(this.objPrivilige).subscribe(response => {
     // debugger;
      if (response != undefined) {
        if (response == "0") {
          //this.notifications.show("Role Created Sucessfully", 1)
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RCS", this.CompanyID).subscribe((dataRCActionsMessages) => {
            this.notifications.show(dataRCActionsMessages[0].displayMessage, dataRCActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        else if (response == "1") {
          //this.notifications.show("Role Updated Sucessfully", 1)
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RUS", this.CompanyID).subscribe((dataRUActionsMessages) => {
            this.notifications.show(dataRUActionsMessages[0].displayMessage, dataRUActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        else if (response == "2") {
          //this.notifications.show("Role Already Exists", 4)
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RAE", this.CompanyID).subscribe((dataREActionsMessages) => {
            this.notifications.show(dataREActionsMessages[0].displayMessage, dataREActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        //else {
        //  alert("invalid details");
        //}
      }
      //else {
      //  alert("invalid details");
      //}
      this.showRoleDashboardLoader = false;
      this.roleDialog = false;
      this.router.navigate(['/Roles']);
    },
      error => {
        //console.log(error);
        this.showRoleDashboardLoader = false;
        this.notifications.show(error.statusText, 4);
      }
    );
  }

  EditRoleData(roleid) {
    /*debugger;*/
    this.roleDialog = true;
    this.savebtn = false;
    this.updatebtn = true;
    this.OperationType = 2;
    this.privDataOpearation = 3;
    //this.amService.GetSelectedPrivilages(this.privDataOpearation, parseInt(roleid)).subscribe(data => {
      this.amService.GetPrivilagesByRoleId(parseInt(roleid)).subscribe(data => {
        setTimeout(() => {
          if(data){
            this.priviligeForm.controls['txtRole'].setValue(data.roleName);
            this.priviligeForm.controls['txtDescription'].setValue(data.roleDescription);
            this.allAccessValue=data.allActionAccessType;
            this.PriviligeData=data.modulesPrivileges;
            if(data.allActionAccessType != null && data.allActionAccessType>'0' && data.allActionAccessType != "")
            {
              this.isAllActionChecked=true;
              this.isRadioDisabled=true;
            }
        }
      },2000)
     
    });
  }
  onKeypressEvent(event: any){
    if (event.which === 32 && !event.target.value)
    event.preventDefault();
 }
  allActionRadClick(value: any){//debugger;
   this.allAccessValue=value;

   this.updateAllAccessTypes(value);
 }
 changeModuleAccessType(accessType:any , index:any){
   //debugger;
  this.PriviligeData[index].accessType=accessType;
//update all submodules access
  if(this.PriviligeData[index].subModules && this.PriviligeData[index].subModules.length>0){
    this.PriviligeData[index].subModules.map(function(y){
      y.accessType=accessType;
    });
    //update all actions access
    this.PriviligeData[index].subModules.forEach(function(parts,sindex,sArray){
      if(sArray[sindex].moduleActions && sArray[sindex].moduleActions.length>0){
         sArray[sindex].moduleActions.map(function(z){
           z.accessType=accessType;
         });
     }
    });
  }
 }
  changeSubModuleAccessType(accessType: any, index: any, sindex: any) {
    this.PriviligeData[index].subModules[sindex].accessType = accessType;
    if (this.PriviligeData[index].subModules[sindex].moduleActions != null) {
      if (this.PriviligeData[index].subModules[sindex].moduleActions.length > 0) {
        this.PriviligeData[index].subModules[sindex].moduleActions.map(function (z) {
          z.accessType = accessType;
        })
      }
    }
  //update all actions access

  //this.PriviligeData[index].subModules.forEach(function(parts,sindex,sArray){
  //  if(sArray[sindex].moduleActions && sArray[sindex].moduleActions.length>0){
  //     sArray[sindex].moduleActions.map(function(z){
  //       z.accessType=accessType;
  //     });
  // }
  //});
 }
 changeActionAccessType(accessType:any , index:any , sindex:any , aindex:any){
   this.PriviligeData[index].subModules[sindex].moduleActions[aindex].accessType=accessType;
 }
  allActionClick() {
   

   if(!this.isAllActionChecked){
     this.isRadioDisabled = true;
     this.allAccessValue = '1';
     this.updateAllAccessTypes(this.allAccessValue)
   }
   else{
     this.isRadioDisabled=false;
   }
 } 

 updateAllAccessTypes(value:any){
   //update all modules access
   this.PriviligeData.map(function(x){
    x.accessType=value;
  });
  //update all submodules access
  this.PriviligeData.forEach(function(part,index,pArray){
    if(pArray[index].subModules && pArray[index].subModules.length>0){
       pArray[index].subModules.map(function(y){
         y.accessType=value;
       });
       //update all actions access
       pArray[index].subModules.forEach(function(parts,sindex,sArray){
         if(sArray[sindex].moduleActions && sArray[sindex].moduleActions.length>0){
            sArray[sindex].moduleActions.map(function(z){
              z.accessType=value;
            });
        }
       });
   }
  });
 }

 addUpdateUserRole(roleId:any){
   //debugger;
   if (this.priviligeForm.invalid) {
    this.submitted = true;
     return;
   }

   this.showRoleDashboardLoader = true;
   let privilegeSaveObj= new AllPrivileges();
   if(this.isAllActionChecked){
     privilegeSaveObj.allActionAccessType=this.allAccessValue;
   }
   else{
     privilegeSaveObj.allActionAccessType=null;
   }
   privilegeSaveObj.modulesPrivileges = this.PriviligeData;
   if(roleId>0)
    privilegeSaveObj.roleId=parseInt(roleId);
  else
    privilegeSaveObj.roleId=0;
   privilegeSaveObj.roleName=this.priviligeForm.controls['txtRole'].value;
   privilegeSaveObj.roleDescription=this.priviligeForm.controls['txtDescription'].value;
   let list = privilegeSaveObj.modulesPrivileges.filter(a => a.accessType == '1');
   if (list.length > 0) {
     this.amService.addUpdateRolePrivileges(privilegeSaveObj).subscribe(response => {
       // this.amService.saveNewRolePrivileges(this.PriviligeData).subscribe(response=>{
       // debugger;
       if (response != undefined) {
         if (response == "0") {
           //this.notifications.show("Role Created Sucessfully", 1)
           this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RCS", this.CompanyID).subscribe((dataRCActionsMessages) => {
             this.notifications.show(dataRCActionsMessages[0].displayMessage, dataRCActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
           });
         }
         else if (response == "1") {
           //this.notifications.show("Role Updated Sucessfully", 1)
           this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RUS", this.CompanyID).subscribe((dataRUActionsMessages) => {
             this.notifications.show(dataRUActionsMessages[0].displayMessage, dataRUActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
           });
         }
         else if (response == "2") {
           //this.notifications.show("Role Already Exists", 4)
           this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RAE", this.CompanyID).subscribe((dataREActionsMessages) => {
             this.notifications.show(dataREActionsMessages[0].displayMessage, dataREActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
           });
         }
         //else {
         //  alert("invalid details");
         //}
       }
       //else {
       //  alert("invalid details");
       //}
       this.showRoleDashboardLoader = false;
       this.roleDialog = false;
       this.router.navigate(['/Roles']);
     },
       error => {
         //console.log(error);
         this.showRoleDashboardLoader = false;
         this.notifications.show(error.statusText, 4);
       }
     );
   } else {
     this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "RSOMA", this.CompanyID).subscribe((dataREActionsMessages) => {
       this.notifications.show(dataREActionsMessages[0].displayMessage, dataREActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
     });
     this.showRoleDashboardLoader = false;
   }
 }

}
