import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DynamicFieldBase } from '../dynamic-fields/dynamic-field-base';
import { DynamicFieldControlService } from '../dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../dynamic-fields-service/dynamic-field.service';
//import { ViewChild } from '@angular/core';
//import { ToasterService } from '../../toaster/toaster.service';
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [DynamicFieldControlService]
})
export class DynamicFormComponent implements OnInit {
  @Input() DynamicFields: DynamicFieldBase<string>[] = [];

  dynamicForm: FormGroup;
  filelist = [];
  payLoad = '';
  testdata = new TestData();

  constructor(private qcs: DynamicFieldControlService,
    private DFS: DynamicFieldService,
    private _router: Router,
    //private toaster: ToasterService,

  ) { }

  ngOnInit() {
    this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
  }
  onSubmit() {
    //console.log(this.filelist);
    this.testdata.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
    this.testdata.RecordID = 0;
    this.testdata.FileList = this.filelist;
    //this.DFS.saveDynamicFormFieldsdata(this.testdata).subscribe(responce => {
    //  //if (responce == "success")
    //  //  this.toaster.show('success', 'Well done!', 'data has been saved successfully');
    //  //else
    //  //  this.toaster.show('error', 'Check it out!', 'error! data not saved');
    //  this.cancel();
    //})
  }




  cancel() {
    this._router.navigate(['/projects']);
  }
  onKeypressEvent(event: any){
    if (event.which === 32 && !event.target.value)
    event.preventDefault();
 }
}
class TestData {
  RecordID: number;
  DynFormFieldData: string;
  FileList: any;
}
