import { Injectable } from '@angular/core';
import { LoaderService } from './loader.service';
import { finalize } from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from "rxjs/operators";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private loadingService: LoaderService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
     
    this.totalRequests++;
    this.loadingService.setLoading(true);
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false);
        }
       
      })
    );
    
  }
}

// intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
//   console.log('caught')
//     this.totalRequests++;
//     this.loadingService.show();
//     return next.handle(request).pipe(
//       finalize(() => {
//         this.totalRequests--;
//         if (this.totalRequests == 0) {
//           this.loadingService.hide();
//         }
//       })
//     );
//   }
//}

// intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
//   this.totalRequests++;
//   this.loadingService.setLoading(true);
//   return next.handle(request)
//         .pipe(
//           finalize(() => {
//                     this.totalRequests--;
//                     if (this.totalRequests == 0) {
//                       this.loadingService.setLoading(false);
//                     }
//               catchError((error: HttpErrorResponse) => {
//                 debugger
//                 this.loadingService.setLoading(false);
//                  return throwError(this.loadingService.setLoading(false));
//               })
//             })
//       );
// }
// }
