import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


@Injectable()
export class DialogWidthService {
  private mediumRatio = 0.6;

  public calculatedWidthMedium = new BehaviorSubject<number>(window.innerWidth * this.mediumRatio);
  public minWidth = 250;

  constructor() {
    window.onresize = (e) => {
      this.calculatedWidthMedium.next(Math.max((<Window>e.target).innerWidth * this.mediumRatio, this.minWidth));
    }
  }
}
