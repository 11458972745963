import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-field-aceoffix-editor',
  templateUrl: './dynamic-field-aceoffix-editor.component.html',
  styleUrls: ['./dynamic-field-aceoffix-editor.component.css']
})
export class DynamicFieldAceoffixEditorComponent implements OnInit {

  constructor() { /**/ }

  ngOnInit() { /**/
  }

}
