import { Component, Input, OnInit } from '@angular/core';
import { getBaseUrl } from '../../../../main';
import { FileInfo, FileRestrictions, SelectEvent, UploadEvent, RemoveEvent, ChunkSettings } from '@progress/kendo-angular-upload';
import { ModuleService } from '../../../services/module.service';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationAlertsService } from '../../../services/notification.service';
import { forEachChild } from 'typescript';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { EADDRINUSE } from 'constants';

@Component({
  selector: 'app-dynamic-field-custom-uploader',
  templateUrl: './dynamic-field-custom-uploader.component.html',
  styleUrls: ['./dynamic-field-custom-uploader.component.css']
})
export class DynamicFieldCustomUploaderComponent implements OnInit {


  @Input() public fileUploadDynamicField: any;
  dynamicForm = this.formbuilder.group({

    CustomUpload: ['', Validators.required],

  });
  CompanyID: number = 0;
  @Input() public customUploadType: any;


  public myFiles: Array<FileInfo> = [];

  @Input() public SelectedRecord: number;
  invalidSubmitAttempt: boolean = false;


  //uploadSaveUrl = getBaseUrl() + 'api/dynamic/uploadDocument';
  uploadSaveUrl = getBaseUrl() + 'api/dynamic/DynACU?loginUserId=' + this.GV.UserId;
  //uploadRemoveUrl = getBaseUrl() + 'api/dynamic/RemoveDocument';
  uploadRemoveUrl = getBaseUrl() + 'api/dynamic/DynACUR?loginUserId=' + this.GV.UserId;
  uploadedFiles = [];
  duplicateuploadedFiles = [];
  showLoader: boolean = false;

  eFiles: any = [];
  count: number = 0;
  temp: any = [];

  public fileInfo: Array<FileInfo> = [];
  myRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf",],
    maxFileSize: 2147483648,
  };
  showDALPopUP: boolean = false;
  LocalUploadDisabled: boolean = false;
  DALUploadDisabled: boolean = false;
  UploadType: string = "LocalUpload";
  FileType: number = 0;
  IpAddres:string;

  constructor(private moduleService: ModuleService, private commonfeaturesservice: CommonFeaturesService,
    private formbuilder: FormBuilder, private DFS: DynamicFieldService,
    private notifyService: NotificationAlertsService,
    private GV: GlobalVeriables) { }

  ngOnInit() {

    var addedFiles= this.GV.GetFileInfo();
    this.CompanyID= +localStorage.getItem("usercompanyID");

    this.LocalUploadDisabled = false;
        this.DALUploadDisabled = false;
    this.FileType = this.GV.FileType;

      if ((this.FileType == 1 || this.FileType == 0 || this.FileType == 3) && (this.customUploadType == 16 || this.customUploadType == 18)) {

      if (this.FileType == 3) {
        this.FileType = 1;
        this.GV.SetFileType(this.FileType);
      }
      this.UploadType = "LocalUpload";


      if (addedFiles != null && addedFiles.length>0) {
        this.LocalUploadDisabled = false;
        this.DALUploadDisabled  = true;


      } else {
        this.LocalUploadDisabled = false;
        this.DALUploadDisabled = false;
      }


    } else {
      this.UploadType = "DALUpload";


      if (addedFiles != null && addedFiles.length > 0) {
        this.LocalUploadDisabled = true;
        this.DALUploadDisabled = false;


      } else {
        this.LocalUploadDisabled = false;
        this.DALUploadDisabled = false;
      }

    }
    //this.IpAddres = localStorage.getItem("Ip");
    //if (this.IpAddres == undefined || this.IpAddres == "" || this.IpAddres == null) {

     
        this.IpAddres = this.GV.UserIpAddress;

      
    ///}

  }

  errorEventHandler(e: ErrorEvent) {
    debugger;
    console.log('An error occurred');
  }

  handleChange(event) {
    if (this.UploadType == "LocalUpload") {
      this.FileType = 1;


    } else if (this.UploadType == "DALUpload") {
      this.FileType = 2;
    }

    this.GV.SetFileType(this.FileType);
  }
  public removeEventHandler(e: RemoveEvent, fieldName: any): void {
    if (e.files.length > 0) {
      this.temp.forEach((filename) => {
      if(filename == e.files[0].name)
      {
        this.temp.splice((this.temp.indexOf(e.files[0].name)), 1);
      }
    });
      let index = this.uploadedFiles[fieldName].indexOf(e.files[0].name);
      if (index != -1) {
        this.moduleService.removeUploadFiles(this.uploadedFiles[fieldName][index]).subscribe(data => {
          this.uploadedFiles[fieldName].splice(index, 1);
          if (this.duplicateuploadedFiles.indexOf(e.files[0].name) != -1) {
            this.duplicateuploadedFiles.splice((this.duplicateuploadedFiles.indexOf(e.files[0].name)), 1);
          }
        });
        
      }
    }
  }

  public selectEventHandler(e: SelectEvent): void {
    const that = this;
    var duplicateFiles = [];
    var duplicateuploadedFiles = [];
    var tempFiles = [];
    tempFiles = this.temp.slice();
    var num = 0;
    var ExistingFiles = this.GV.GetFileInfo();
    var ExistingFile = [];
    this.eFiles = e.files.slice();
    ExistingFiles.forEach((f) => {
      ExistingFile[num] = f.name;
      num++;
    });

    this.eFiles.forEach((file) => {
      var a = ExistingFile.includes(file.name);
      if (a) {
        duplicateFiles.push(file.name);
      }

      duplicateFiles.forEach((filename) => {
        if (filename == file.name) {
          e.files.splice((e.files.indexOf(file)), 1);
        }
      });

      tempFiles.forEach((filename) => {
        var t = e.files.indexOf(file);
        if (t != -1){
        if (filename == file.name) {
          e.files.splice((e.files.indexOf(file)), 1);
          }
        }
      });
      if (!file.validationErrors) {
        //this.currentFileUpload = file;

        if (duplicateuploadedFiles.indexOf(file.name) == -1) {
            duplicateuploadedFiles.push(file.name);
            if(!this.temp.includes(file.name)){
            this.temp.push(file.name)}
        } else {
          this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(file.name + " " + dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          });
          e.preventDefault();
        }
      }
    });
    
    if (duplicateFiles != null && duplicateFiles.length > 0) {
      this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(duplicateFiles.join() + " " + dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
        
      });
      this.showLoader = false;
      return;
    }
  }

  //public chunkSettings: ChunkSettings = {
  //  size: 1024000,
  //};

  uploadEventHandler(e: UploadEvent, fieldName: any) {
    debugger;
    this.showLoader = true;
    if (this.DFS.checkUndefinedNullEmpty(this.uploadedFiles[fieldName])) {
      this.uploadedFiles[fieldName] = [];
    }
    let files: any;
    files = e.files;
    if (files.length === 0) {
      return;
    }
    let filesToUpload: File[] = files;
    let filelist = [];
    if (this.uploadedFiles[fieldName].indexOf(e.files[0].name)!="-1") {
      this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(e.files[0].name + " " + dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
      });
    } else {
    this.uploadedFiles[fieldName].push(files[0].name);
    }
    this.invalidSubmitAttempt = false;

  }
  public completeEventHandler() {


    //this.DFS.uploadDocument().subscribe((data) => {

    //  if (data != "1") {
    //  }
    //  else
    //    this.notifyService.show("One of the uploaded file have annotiations!", 4);
    //});
    this.showLoader = false;
  }

  ShowDAL() {


    this.showDALPopUP = true;
  }



  closeDAL() {
    this.showDALPopUP = false;
    this.duplicateuploadedFiles = [];
  }


  CreateSubProcess() {
   
      this.IpAddres =this.GV.UserIpAddress;
   
    var GetActionEvent = this.GV.GetActionEvent();

    var ExistingFiles = this.GV.GetFileInfo();

    this.fileInfo = [];
    this.showLoader = true;

     

    var fileIDs = "";



      var projectID = this.GV.GetProjectID() == undefined ? 0 : this.GV.GetProjectID();


      this.moduleService.UpdateModifiedDateForDynamicForms(projectID.toString()).subscribe(data => {
      });


    if (this.dynamicForm.invalid) {
      this.invalidSubmitAttempt = true;
      this.showLoader = false;
     // this.GV.SetFileInfo(this.fileInfo);
      return;
    }
  

    var fileData = JSON.stringify(this.dynamicForm.value);


    if (this.dynamicForm.value.CustomUpload.length > 0) {
       


      this.dynamicForm.value.CustomUpload.forEach((file) => {
       



        this.fileInfo.push(file);
      });


      //this.GV.SetFileInfo(this.fileInfo);

    }
  

    var components = [];


    this.fileInfo.forEach(function (part, index) {
      components.push(part.name);
    });

    fileData = components.join('~#~');

    
    //check file already added
    var duplicateFiles = [];
    var addedFiles = fileData.split(',');
    addedFiles.forEach(function (part, index) {
      if (ExistingFiles != null && ExistingFiles.length > 0) {
        var checkDuplicate = ExistingFiles.filter(a => a.name == part);
        if (checkDuplicate.length > 0) {
          duplicateFiles.push(ExistingFiles.filter(a => a.name == part)[0].name);
        }
      }

    });
    if (duplicateFiles != null && duplicateFiles.length > 0) {
      this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(duplicateFiles.join() + " " + dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);

      });
      this.showLoader = false;
      return;
    }

     
    var myFiles = [];
    if (ExistingFiles != null && ExistingFiles.length > 0) {
      ExistingFiles.forEach(function (part, index) {
        myFiles.push(part);
      });
      this.fileInfo.forEach(function (part, index) {
        myFiles.push(part);
      });

    } else {
      myFiles = this.fileInfo;
    }

    //check file already added end

    this.GV.SetFileInfo(myFiles);

    if (GetActionEvent == "CreateSubProcess") {



      this.moduleService.CreateSubProcess(projectID.toString(), "", fileData,this.GV.UserId,this.IpAddres).subscribe(data => {
         
        if (data.result != "") {
          this.showLoader = false;
         
          this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(data.result+" "+dataCDActionsMessages[0].displayMessage , dataCDActionsMessages[0].messageType);
          });

          return;

          
        } else {

          this.showLoader = false;

          this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "SPCS", this.CompanyID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          });

          //this.notifyService.show("Sub process created successfully", 1);
          this.moduleService.passCloseDAL("Close");
        }


      });

    } else {
      this.moduleService.passCloseDAL("Close");

    }
    this.duplicateuploadedFiles = [];
   

  }
}
