
import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "../services/language.service";

@Pipe({
    name: "translate"
})

export class TranslatePipe implements PipeTransform{
    constructor(private languageService:LanguageService){}
    transform(key:string , keyType:string, originalValue ,key2: string,key3 : string){
        if(this.languageService.dataByLanguage && this.languageService.dataByLanguage.length>0){
            let tData;
            if(keyType=='ModuleId')
                tData= this.languageService.dataByLanguage.find(x=>x.ModuleID== parseInt(key) && x.Type == 8);
            else if(keyType=='LanguageKey')
                tData= this.languageService.dataByLanguage.find(x=>x.LanguageKey== key);
            else if(keyType=='FormFieldId' || keyType=='HelpText' || keyType=='DefaultValue' || keyType=='CustomValMsg')
                //tData=this.languageService.dataByLanguage.find(x=>x.FormFieldID==parseInt(key) && x.Type == 1);
                tData=this.languageService.dataByLanguage.find(x=> x.FieldIdList.includes(parseInt(key)));
            else if(keyType=="GroupId")
                tData=this.languageService.dataByLanguage.find(x=>x.FormGroupId==parseInt(key))
            else if(keyType=="TempDefaultValue" || keyType=="TempDefHeader")
                tData=this.languageService.dataByLanguage.find(x=>x.TemplateID==parseInt(key) && x.DisplayControlID==parseInt(key2) && x.Type == 7)
            else if(keyType=="MasterDataID")
                tData=this.languageService.dataByLanguage.find(x=>x.MasterDataID==parseInt(key) && x.Type == 9)
            else if(keyType=="TemplateId")
                tData=this.languageService.dataByLanguage.find(x=>x.TemplateID==parseInt(key) && x.Type == 7)
            else if(keyType=="DisplayField")
                tData=this.languageService.dataByLanguage.find(x=>x.FormFieldID==parseInt(key) && x.TemplateID==parseInt(key2) && x.WorkFlowTaskID==parseInt(key3) && x.Type==2)
            if(tData){
                if(keyType=='HelpText'){
                    if(tData.HelpText != null && tData.HelpText != '')
                     return tData.HelpText;
                     else
                     return originalValue;
                }                   
                else if(keyType=='DefaultValue' || keyType=="TempDefaultValue"){
                    if(tData.DefaultValue != null && tData.DefaultValue != '')
                    return tData.DefaultValue;
                    else
                    return originalValue;
                }
                    
                else if(keyType=='CustomValMsg'){
                    if(tData.CustomValidation != null && tData.CustomValidation != '')
                    return tData.CustomValidation;
                    else
                    return originalValue;
                }                   
                else
                    return tData.Message;
            }else{
                return originalValue;
            }
          }else{
            return originalValue;
          }
        //   else{
        //       let languageId=parseInt(localStorage.getItem("SelectedLanguageId"));
        //       this.languageService.GetDataByLanguageID(languageId).subscribe((res)=>{
        //         this.languageService.dataByLanguage=res;
        //         if(res){
        //             let tData= res.find(x=>x.LanguageKey==key);
        //             if(tData)
        //                 return tData.Message;
        //         }
        //       });
        //   }
    }
}
