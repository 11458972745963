import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FileInfo, FileRestrictions, RemoveEvent } from '@progress/kendo-angular-upload';
import { DatePipe } from "@angular/common";
import { Subscription } from 'rxjs';
import { getBaseUrl } from '../../../main';
import { GlobalVeriables } from '../../Global/GlobalVaribales';
import { templateData } from '../../interfaces/ItemplateFields';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from '../../services/adminusermanagement.service';
import { UserFormData } from '../../services/AMFormsDataDetails';
import { CommonFeaturesService } from '../../services/commonfeatures.service';
import { LanguageService } from '../../services/language.service';
import { ModuleService } from '../../services/module.service';
import { NotificationAlertsService } from '../../services/notification.service';
import { DynamicFieldControlService } from '../dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../dynamic-fields-service/dynamic-field.service';
import { DynamicFormGroupBase } from '../dynamic-fields/dynamic-field-base';
import { DynamicTemplateData, searchFilter } from '../dynamic-fields/dynamic-field-list/dynamic-field-list.component';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'app-dynamic-search-view',
  templateUrl: './dynamic-search-view.component.html',
  styleUrls: ['./dynamic-search-view.component.css'],
})
export class DynamicSearchViewComponent implements OnInit {
  @Input() public SelectedProjectID: number = 0;
  @Input() PassedmoduleID: number = 0;
  @Input() targetModuleID: number = 0;
  @Input() targetTemplateID: number = 0;
  @Input() inputFormFields: string = "";
  @Input() keyWordSearch: string = "";
  @Input() chkFields: string = "";
  @Input() chkFieldValues: string = "";
  @Output() responseFromSearch = new EventEmitter<any>();
  @Output() resetForm = new EventEmitter<any>();
  searchFormFieldIds: any;
  @Input() filter : any;

  @Input() IsSearchedApplied: string = "";


  @Input() componentType: number = 0;
  @Input() public template: any;
  templateSavedSearch: any;

  @ViewChild('autocomplete', { static: false })
  public autocomplete: AutoCompleteComponent;
  taggeditems: any[];
  ResponseData: UserFormData[] = [];
  data: string;
  displayViewID: any;
  windowState = 'default';
  resizeWindow = false;
  showArtWorkWindow = false;
  showPopUp: boolean = false;
  display = 'none';
  setProjectOwnerdisplay = 'none';
  FieldStatusobj = new FieldStatusDTO();
  actionTypeTemplateID: any;
  templateID: any = null;

  selectedTemplateID: any;
  RecordID: any;
  displayTemplateID: any;
  myFiles: Array<FileInfo> = [];
  allTemplates: any;
  controltemplates: any;
  controltemplatesforMenu: any;
  controlActionNotForMenuItems: any;
  controltemplatesforMenuCount: number = 4;//For showing number of action outside of menu
  displaytemplates: any;
  displaytemplatesControlIDs: any;
  displayFiltertemplatesControlIDs: any;
  IsdisplaytemplatesControlID: boolean = false;
  selectedGroupDisplayControlID: number = 0;


  subTemplates: any;
  templateParentID: number = 0;
  IsDisplayControls: boolean = false;
  IsDisplayView: boolean = false;
  IsDisplaySubFormView: boolean = false;
  IsDisplayNestedSubFormView: boolean = false;
  uploadedFiles = [];
  selectedItems = [];
  invalidSubmitAttempt: boolean = false;
  DynamicFields: DynamicFormGroupBase<any>;
  DynamicFieldsForCascading: any;
  dynamicForm: FormGroup;
  DynamicFormFieldsActions: any = [];
  SelectedRecord: number = 0;
  tempSelectedRecord: number = 0;
  viewRecords: any;
  viewRelProjects: any;
  documentsPath: string;
  IsfromProject: boolean = false;
  DeleteRelProjectConfrim: boolean = false;
  bodyText: string;
  templateData = new DynamicTemplateData();
  tempData = new templateData()
  projectid: number = 0
  relatedProjectId: number = 0;
  submitted = false;
  offset: string = "+05:30";
  public proids = [];
  public listItems: Array<string>;
  public value = 0;
  public running: any;
  actionEvent: string;
  dynHeadingName: string;
  ActionEventID: number;
  workflowTasksActionID: number;
  CompanyID: number = 0;
  moduleID: number = 0;
  setSubProcessdisplay: boolean = true;
  searchFilters = new searchFilter();
  public fileInfo: Array<FileInfo> = [];
  public FileType: number = 0;
  public languageDataDefault: any;
  public defaultItem: { name: string; id: number } = {
    name: "--Select--",
    id: 0,
  };
  defualtSearch:boolean=false;
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();

  dialogForm: FormGroup;
  dateFormats: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy'
  };
  showLoader: boolean = false;
  dynTemplateHeader: string = null;
  uploadSaveUrl = getBaseUrl() + 'api/dynamic/DynACU';
  uploadRemoveUrl = getBaseUrl() + 'api/dynamic/DynACUR?loginUserId=' + this.GV.UserId;
  dynPopUpHeading: string = null;


  allFields: any;
  dependencyTemplateSubscription: Subscription = new Subscription();
  CheckIsParentTemplateSubscription: Subscription = new Subscription();
  CheckHasDependentsSubscription: Subscription = new Subscription();
  templateRecordSubscription: Subscription = new Subscription();

  bypassUnbypassOpen = 'none';
  AuditHistory = 'none'

  myRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf",],
  };

  chatDetailInformation: any = []
  mentionConfig: any;
  saveSearchName: string = "";

  SelectedValue: number = 0;
  constructor(private moduleService: ModuleService, private http: HttpClient, public service: DynamicFieldService,
    private qcs: DynamicFieldControlService,
    private DFS: DynamicFieldService,
    private _router: Router
    , private notifyService: NotificationAlertsService,
    private amService: AdminUserManagementService,
    private cdr: ChangeDetectorRef, private fb: FormBuilder, private GV: GlobalVeriables,
    private dialogService: DialogService, private commonfeaturesservice: CommonFeaturesService,
    private dataservice: AdminUserManagementService, private languageService: LanguageService, private reportservice: ReportsService
  ) { }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "startsWith",
  };
  public changeFilterOperator(operator: "startsWith" | "contains"): void {
    this.filterSettings.operator = operator;
  }
  public toggleAllText(data: any) {

    return data.isChecked ? "Deselect All" : "Select All";
  }
  public isIndet(data: any) {
    return (
      data.selectedItems.length !== 0 && data.selectedItems.length !== data.options.length
    );
  }
  public onClick(data: any) {
    data.selectedItems = [];
    data.isChecked = !data.isChecked;
    if (data.isChecked) {
      if (data.options.length > 0) {
        data.options.forEach(function (item) {
          data.selectedItems.push(item.value);

        });
      }
      else {
        data.selectedItems = [];
      }
    }
    else {
      data.selectedItems = [];
    }
    this.valueChange(data, data.selectedItems);
  }
  public isItemSelected(item: any, dynamicfiled: any) {
    return dynamicfiled.selectedItems.some((x) => x === item.value);
  }
  ngOnInit() {
  
    this.saveSearchName =this.template.searchName;
    this.defualtSearch=this.template.defaultSearch
     

    this.languageDataDefault = this.languageService.dataByLanguage;
    this.dataservice.getUMGetUsers(1, this.GV.UserId, 0).subscribe(data => {

      this.ResponseData = data;
    });
    this.GV.UserName = localStorage.getItem("userName");
    this.GV.UserRoleID = parseInt(localStorage.getItem("userRoleID"));
    this.CompanyID = +localStorage.getItem("usercompanyID");

    this.displayViewID = 0;
    this.templateParentID = 0;

    this.loadTemplates();
    this.dialogForm = this.fb.group({
      ddlValue: [],
    });

    this.getSavedSearch();

    if (this.IsSearchedApplied != "" && this.IsSearchedApplied != undefined && this.IsSearchedApplied != null) {
      var searchValues = JSON.parse(this.IsSearchedApplied);
      for (var key in searchValues) {
        if (searchValues.hasOwnProperty(key)) {
          this.DynamicFields[0].dynamicFields.forEach(function (item) {

            if (searchValues[key] == "" || searchValues[key] == undefined || searchValues[key] == null) {
              /**/
            } else {
              item.selectedItems = searchValues[key];
              item.isChecked = item.selectedItems.length === item.options.length;
            }
          });

          if (this.DynamicFields[0].dynamicFields.filter(a => a.key == key)[0].controlTypeID == 5) {
            if (searchValues[key] == "" || searchValues[key] == undefined || searchValues[key] == null) {
              /**/
            } else {
              this.dynamicForm.controls[key].setValue(new Date(searchValues[key]));
            }
          } else {
            this.dynamicForm.controls[key].setValue(searchValues[key]);
          }

          if (searchValues[key] != "") {
            var fieldId = this.DynamicFields[0].dynamicFields.filter(a => a.key == key)[0].fieldId;
            var selectedValues = JSON.stringify(searchValues[key]);
            this.moduleService.GetCascadingData(this.template.templateID, fieldId, selectedValues).subscribe(data => {
              if (data != null) {
                Array.prototype.forEach.call(data, dataField => {
                  var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
                  if (index != -1) {
                    this.DynamicFields[0].dynamicFields[index].options = dataField.options;
                    this.DynamicFields[0].dynamicFields[index].value = "";
                    this.DynamicFields[0].dynamicFields[index].selectedItems = [];
                    this.DynamicFields[0].dynamicFields[index].isChecked = false;
                    this.allFields[index].options = dataField.options;
                  }
                });
              }
            })
          }
        }
      }


    }
  }


  ngOnDestroy() {
    /**/
  }
  controlActionForMenuItems: any = []
  loadTemplates() {
    this.onDisplayForm();
  }

  public removeEventHandler(e: RemoveEvent, fieldName: any): void {
    if (e.files.length > 0) {
      let index = this.uploadedFiles[fieldName].indexOf(e.files[0].name);
      if (index != -1) {
        this.moduleService.removeUploadFiles(this.uploadedFiles[fieldName][index]).subscribe(data => {
        });
        this.uploadedFiles[fieldName].splice(index, 1);
      }
    }
  }

  public errorEventHandler(e) {
    return false;
  }



  getDisplaycontrolRecords() {
    this.showLoader = true;
    this.templateParentID = 0;
  }
  isrelatedProjectshow: boolean = false;
  DeleteConfrimopened: boolean = false;

  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }

  removeFile(file) {


    this.fileInfo = this.fileInfo.filter(a => a.name != file.name);

    this.GV.SetFileInfo(this.fileInfo);
    this.myFiles = this.fileInfo;

  }

  getSavedSearch() {

    
 
    this.moduleService.GetSavedSearch(this.templateID, this.GV.UserId).subscribe(data => {

      this.templateSavedSearch = data;
   var selectedDataFilter = this.templateSavedSearch.filter(a=>a.name ==this.template.searchName);

      if (selectedDataFilter.length > 0) {
        this.SelectedValue = selectedDataFilter[0].id
        this.defualtSearch = selectedDataFilter[0].defaultSearch;
      }
   
    });

  }


  DeleteSavedSearch() {
    const dialog: DialogRef = this.dialogService.open({
      title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
      content: this.languageService.GetActionMessageByLanguage('STC_CFDLSR', 'Are you sure you want to delete this saved search?'),
      actions: [
        { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
        { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
      ],
      width: 350,
      height: 150,
      minWidth: 200,
      cssClass: "custom-css-class",
    });
    dialog.result.subscribe((result) => {

      if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
        this.moduleService.DeleteSavedSearch(this.SelectedValue).subscribe(data => {
          this.getSavedSearch();

          this.FromReset();
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_DLDSRH', "Deleted Saved Search."), 1);


        });
      }
    });

  }

  SaveSearch() {
    this.template.searchFilters="";
    var savedValue = false;
    for (var key in this.dynamicForm.value) {
      if (this.dynamicForm.value.hasOwnProperty(key)) {
        if (this.dynamicForm.value[key] != "") {
          savedValue = true;
        }
      }
    }
    var dynamicFormvalue =this.dynamicForm.value
    var allNamesFields = [];
    this.allFields.forEach(function (eachfield, index) {

      var field = new searchkeywithNames();
      field.key = eachfield.key;
      var options = [];
      if (eachfield.controlType == 5) {

        var dateOption = ""
        if (dynamicFormvalue[eachfield.key] == undefined || dynamicFormvalue[eachfield.key] == '' || dynamicFormvalue[eachfield.key] == null) {
          /*options.push('');*/
        } else {
          // options.push(new Date(dynamicFormvalue[eachfield.key]).toUTCString());
          var datePipe = new DatePipe('en-US');
          dateOption = datePipe.transform(new Date(dynamicFormvalue[eachfield.key]).toUTCString(), 'yyyy-MM-dd');

        }
        field.options = dateOption;

      } else {

        if (dynamicFormvalue[eachfield.key] == '') {
        } else {
          dynamicFormvalue[eachfield.key].forEach(function (eachValue, index) {
            options.push(eachfield.options.filter(a => a.value == eachValue)[0].key);
          });

        }
        field.options = options;
      }

      if (field.options.length > 0) {
        allNamesFields.push(field);

      }
    });
    this.defualtSearch = this.defualtSearch == undefined ? false : this.defualtSearch;
    
    var searchFields=JSON.stringify(allNamesFields);
    if (savedValue == false) {
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SELFLT', "Please select atleast one filter."), 4);
    }
    else {
      this.moduleService.SaveSearchName(this.saveSearchName, this.templateID,this.defualtSearch, JSON.stringify(this.dynamicForm.value), this.SelectedValue, this.GV.UserId,searchFields).subscribe(data => {
        this.getSavedSearch();

        if (data.result == "") {

          if (this.SelectedValue == 0) {
            this.commonfeaturesservice.GetAllActionsMessages(4, 0, 0, 999, "SSS", this.CompanyID).subscribe((dataCDActionsMessages) => {
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
            });

          } else {
            this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SCHUPD', "Search Updated Successfully."), 1);

          }
          this.FromReset();
        } else if (data.result == "Exists") {
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SHEXST', "Search Name already Exists."), 4);

        }
      });
    }
  }


  onDisplayForm() {

    this.showLoader = true;
    this.uploadedFiles = [];
    this.RecordID = 0;

    if (this.template.targetTemplateId == null || this.template.targetTemplateId == "0" || this.template.targetTemplateId == undefined || this.template.targetTemplateId == "") {
      this.templateID = this.template.templateID;
      this.selectedTemplateID = this.template.templateID;
    } else {
      this.templateID = this.template.targetTemplateId;
      this.selectedTemplateID = this.template.targetTemplateId;
    }

    this.moduleService.GetSearchFormFieldIds(this.templateID).subscribe(data => {

      this.searchFormFieldIds = data["inputFormFields"];
      if (this.searchFormFieldIds != null)
        this.service.GetAllDynamicSearchFormFields(this.templateID, this.RecordID, 0, false, this.searchFormFieldIds, this.selectedTemplateID, false, this.template.displayControlsID).subscribe(response => {
          this.IsDisplayControls = false;
          this.dynTemplateHeader = response.dynTemplateHeader;
          this.DynamicFields = response.dynamicFormFieldList;
          this.DynamicFieldsForCascading = response.dynamicFormFieldList;
          this.DynamicFormFieldsActions = response.displayActions;
          this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);

          if (this.IsSearchedApplied == "" || this.IsSearchedApplied == undefined || this.IsSearchedApplied == null) {

          }
          else {
            var searchValues = JSON.parse(this.IsSearchedApplied);

            for (var key in searchValues) {
              if (searchValues.hasOwnProperty(key)) {
                if(this.DynamicFields[0].dynamicFields.filter(a => a.key == key)[0].controlTypeID == 4){
                  this.DynamicFields[0].dynamicFields.forEach(function (item) {
                    if (item.key == key) {
                      if (searchValues[key] == "" || searchValues[key] == undefined || searchValues[key] == null) {
                      }
                      else {
                        item.selectedItems = searchValues[key];
                        item.isChecked = item.selectedItems.length === item.options.length;
                      }
                    }
                  });
                }

                if (this.DynamicFields[0].dynamicFields.filter(a => a.key == key)[0].controlTypeID == 5) {
                  if (searchValues[key] == "" || searchValues[key] == undefined || searchValues[key] == null) {
                  } else {
                    this.dynamicForm.controls[key].setValue(new Date(searchValues[key]));
                  }
                } else {
                  this.dynamicForm.controls[key].setValue(searchValues[key]);
                }

                if (searchValues[key] != "") {
                  var fieldId = this.DynamicFields[0].dynamicFields.filter(a => a.key == key)[0].fieldId;
                  var selectedValues = JSON.stringify(searchValues[key]);
                  this.moduleService.GetCascadingData(this.template.templateID, fieldId, selectedValues).subscribe(data => {
                    if (data != null) {
                      Array.prototype.forEach.call(data, dataField => {
                        var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
                        if (index != -1) {
                          this.DynamicFields[0].dynamicFields[index].options = dataField.options;
                          this.DynamicFields[0].dynamicFields[index].value = "";
                          this.allFields[index].options = dataField.options;

                        }
                      });
                    }
                  })
                }
              }
            }
          }

          //set selected values when pop is closed
          if (this.template.showFilterSearchInPopUp == 'False' && this.template.searchFilterValues != "") {

            var searchValues = JSON.parse(this.template.searchFilterValues);

            for (var key in searchValues) {
              if (searchValues.hasOwnProperty(key)) {
                if (this.DynamicFields[0].dynamicFields.filter(a => a.key == key)[0].controlTypeID == 5) {
                  if (searchValues[key] == "" || searchValues[key] == undefined || searchValues[key] == null) {
                  } else {
                    this.dynamicForm.controls[key].setValue(new Date(searchValues[key]));
                  }
                }
                else {
                  this.dynamicForm.controls[key].setValue(searchValues[key]);
                }

                if (searchValues[key] != "") {
                  var fieldId = this.DynamicFields[0].dynamicFields.filter(a => a.key == key)[0].fieldId;
                  var selectedValues = JSON.stringify(searchValues[key]);
                  this.moduleService.GetCascadingData(this.template.templateID, fieldId, selectedValues).subscribe(data => {
                    if (data != null) {
                      Array.prototype.forEach.call(data, dataField => {
                        var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
                        if (index != -1) {
                          this.DynamicFields[0].dynamicFields[index].options = dataField.options;
                          this.DynamicFields[0].dynamicFields[index].value = "";
                          this.allFields[index].options = dataField.options;

                        }
                      });
                    }
                  })
                }
              }
            }
          }
          var allFields = [];
          response.dynamicFormFieldList.forEach(function (dynGroups, index) {
            dynGroups.dynamicFields.forEach(function (dynFields, index) {

              var field = new eachField();
              field.key = dynFields.key;
              field.controlType = dynFields.controlTypeID;
              field.options = dynFields.options;
              allFields.push(field);
            });
          });
          this.allFields = allFields;

          this.showLoader = false;
        }, error => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
        });

    });
  }

  GetMessageByLanguage(aliasKey, orgMsg) {
    let tData = this.languageDataDefault.find(x => x.LanguageKey == aliasKey);
    if (tData)
      return tData.Message;
    else
      return orgMsg;
  }
  selectionChange(event) {

    this.template.defaultSearchCheck="False"
    this.saveSearchName = event.id == 0 ? "" : event.name;

    this.SelectedValue = event.id;
    this.showLoader = true;
    this.defualtSearch= event.defaultSearch

    this.service.GetAllDynamicSearchFormFields(this.templateID, event.id, 0, false, this.searchFormFieldIds, this.selectedTemplateID, false, this.template.displayControlsID).subscribe(response => {
      this.IsDisplayControls = false;

      this.dynTemplateHeader = response.dynTemplateHeader;
      this.DynamicFields = response.dynamicFormFieldList;
      this.DynamicFormFieldsActions = response.displayActions;
      this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
      var allFields = [];

      response.dynamicFormFieldList.forEach(function (dynGroups, index) {
        dynGroups.dynamicFields.forEach(function (dynFields, index) {
          var field = new eachField();
          field.key = dynFields.key;
          field.controlType = dynFields.controlTypeID;
          field.options = dynFields.options;
          if (dynFields.value == "[]" || dynFields.value == "" || dynFields.value == undefined || dynFields.value == null) {

          }
          else {
            if (field.controlType == 5) {
              dynFields.selectedItems = dynFields.value;
            }
            else {
              dynFields.selectedItems = JSON.parse(dynFields.value);
              dynFields.isChecked = dynFields.selectedItems.length === dynFields.options.length;
            }
          }
          allFields.push(field);
        });
      });
      this.allFields = allFields;

      this.showLoader = false;
    });
  }


  FromReset() {

    this.moduleService.setSearchShow(false);
    localStorage.setItem("selectedSearchReportValues", "");

    this.SelectedValue = 0;
    this.saveSearchName = "";
    this.defualtSearch= false;
    Array.prototype.forEach.call(this.DynamicFields[0].dynamicFields, dataField => {


      this.dynamicForm.controls[dataField.key].setValue("");
      this.DynamicFields[0].dynamicFields.forEach(function (item) {


        item.selectedItems = []
        item.isChecked = false;

      });
      if (this.DynamicFields[0].dynamicFields.filter(a => a.key == dataField.key)[0].isDependent == true) {

        this.DynamicFields[0].dynamicFields.filter(a => a.key == dataField.key)[0].options = []


      }

    });


    if (this.templateID == "58") {//Reports search filter
      this.searchFilters.searchFields = "[]";
      this.responseFromSearch.emit(this.searchFilters);
    } else {
      /**/
    }


    if (this.template.displayControlsID == "32") {
      this.searchFilters.searchFields = "[]";
      this.searchFilters.searchFieldValues = "[]";
      this.searchFilters.templateId = this.templateID;
      this.moduleService.passsearchTabFilters(this.searchFilters);

    }
    else {
      if (this.template.showFilterSearchInPopUp == "False") {
        this.searchFilters.searchFields = "[]";
        this.searchFilters.searchFieldValues = "[]";
        this.searchFilters.templateId = this.templateID;
        this.searchFilters.displayControlsID = this.template.displayControlsID;

        this.searchFilters.keyWordSearch = '';
        this.searchFilters.chkFields = '';
        this.searchFilters.chkFieldValues = '';
        this.moduleService.passTemplateViewFilters(this.searchFilters);
      }
      else {
        this.moduleService.passsearchFilters(this.searchFilters);
      }

    }
  }

  FromAction() {

  //  if(this.defualtSearch==true)
  //   this.moduleService.passDefaultSearchValue(true);
  //  else
  //  this.moduleService.passDefaultSearchValue(false);

    this.moduleService.setSearchShow(false);
    
    this.saveSearchName = this.saveSearchName

    var savedValue = false;
    for (var key in this.dynamicForm.value) {
      if (this.dynamicForm.value.hasOwnProperty(key)) {
        if (this.dynamicForm.value[key] != "") {
          savedValue = true;
        }
      }
    }
    if (savedValue == false) {
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SELFLT', "Please select atleast one filter."), 4);

      return;
    }

    this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
    this.templateData.ID = parseInt(this.RecordID);
    this.templateData.TemplateID = parseInt(this.templateID);
    this.templateData.IsfromProject = true;
    this.templateData.InputFormFields = this.inputFormFields;
    this.templateData.UserID = this.GV.UserId
    this.showLoader = false;

    var dynamicFormvalue = this.dynamicForm.value;

    var allNamesFields = [];
    this.allFields.forEach(function (eachfield, index) {

      var field = new searchkeywithNames();
      field.key = eachfield.key;
      var options = [];
      if (eachfield.controlType == 5) {

        var dateOption = ""
        if (dynamicFormvalue[eachfield.key] == undefined || dynamicFormvalue[eachfield.key] == '' || dynamicFormvalue[eachfield.key] == null) {
        } else {
          var datePipe = new DatePipe('en-US');
          dateOption = datePipe.transform(new Date(dynamicFormvalue[eachfield.key]).toUTCString(), 'yyyy-MM-dd');

        }
        field.options = dateOption;

      } else {

        if (dynamicFormvalue[eachfield.key] == '') {
        } else {
          dynamicFormvalue[eachfield.key].forEach(function (eachValue, index) {
            options.push(eachfield.options.filter(a => a.value == eachValue)[0].key);
          });

        }
        field.options = options;
      }

      if (field.options.length > 0) {
        allNamesFields.push(field);

      }
    });

    this.searchFilters.templateId = this.targetTemplateID;
    this.searchFilters.searchFields = JSON.stringify(allNamesFields);
    this.searchFilters.searchFieldValues = JSON.stringify(dynamicFormvalue);
    this.searchFilters.searchName = this.saveSearchName;
    this.searchFilters.keyWordSearch = this.keyWordSearch;
    this.searchFilters.defaultSearch=this.defualtSearch;
    this.searchFilters.displayControlsID = this.template.displayControlsID;

    this.searchFilters.chkFields = this.chkFields;
    this.searchFilters.chkFieldValues = this.chkFieldValues;
    this.searchFilters.filter = this.filter;


    if (this.componentType == 1) {
      if (this.templateID == "58") {//Reports search filter
        this.searchFilters.templateId = this.targetTemplateID;
        this.searchFilters.searchFields = JSON.stringify(dynamicFormvalue);
        this.responseFromSearch.emit(this.searchFilters);
        this.searchFilters.searchFields = "[]";
        this.searchFilters.searchFieldValues = "[]";
      }
      else {

        if (this.template.displayControlsID == "32" || (this.template.displayControlsTabID != 0 && this.template.displayControlsTabID != null && this.template.displayControlsTabID != undefined)) {
 

          if (this.template.targetTemplateId == null || this.template.targetTemplateId == "0" || this.template.targetTemplateId == undefined || this.template.targetTemplateId == "") {
            this.templateID = this.template.templateID;
          } else {
            this.templateID = this.template.targetTemplateId;
          }

          this.searchFilters.templateId = this.templateID;
          this.moduleService.passsearchTabFilters(this.searchFilters);

        }
        else {
          this.moduleService.passsearchFilters(this.searchFilters);
        }
      }

    } else if (this.componentType == 2) {
      this.searchFilters.templateId = this.templateID;

      this.moduleService.passTemplateViewFilters(this.searchFilters);

    }
    this.reportservice.setUTCTime(true);
  }
  cancel() {

    this.IsDisplayControls = true;
    this.uploadedFiles = [];
  }

  close() {
    this.IsDisplayControls = true;
    this.display = 'block';

  }

  closerel() {
    this.showPopUp = false;
  }
  handleFilter(value, dynamicField: any) {

    if (value.length >= 3) {
      var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.key == dynamicField.key);
      this.DynamicFields[0].dynamicFields[index].options = this.DynamicFields[0].dynamicFields[index].options.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocomplete.toggle(false);
    }
  }
  SelecteditenChange(value, dynamicField: any) {

    this.showLoader = true;
    this.commonfeaturesservice.GetChatDetails("WF", this.dynamicForm.value.Projects, this.dynamicForm.value.SubProcess, this.dynamicForm.value.Topic, this.GV.UserId, this.dynamicForm.value.Priority).subscribe((dataresult) => {
      var chatlist = dataresult.Table;
      this.chatDetailInformation = chatlist;
      this.showLoader = false;
    });
  }

  handleParentdatepickerChange(value: any, dynamicField: any) {

    if (value != null) {
      this.showLoader = true;
      Array.prototype.forEach.call(this.DynamicFields, DynamicFieldGroup => {
        Array.prototype.forEach.call(DynamicFieldGroup.dynamicFields, DynamicField => {
          if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == dynamicField.fieldId) {
            let day = value.getDate();
            let month = value.getMonth();
            let year = value.getFullYear();
            DynamicField.minimumDate = new Date(year, month, day);
            DynamicField.miximumDate = new Date(year + 10, month, day);
            if (this.dynamicForm.controls[DynamicField.key].value < DynamicField.minimumDate || this.dynamicForm.controls[DynamicField.key].value > DynamicField.miximumDate) {
              this.dynamicForm.controls[DynamicField.key].setValue(null);
            }
          }
          else if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == null) {
            let date = new Date();
            let day = date.getDate();
            let month = date.getMonth();
            let year = date.getFullYear();
            DynamicField.minimumDate = new Date(year - 10, month, day);
            DynamicField.miximumDate = new Date(year + 10, month, day);
            if (this.dynamicForm.controls[DynamicField.key].value < DynamicField.minimumDate || this.dynamicForm.controls[DynamicField.key].value > DynamicField.miximumDate) {
              this.dynamicForm.controls[DynamicField.key].setValue(null);
              this.dynamicForm.controls["CompleteProjectByDate"].setValue(null);
            }
          }
        });
      });
      this.showLoader = false;

    }

  }


  filevalidation(event: boolean) {
    this.dynamicForm.controls["DisplayName"].errors.required = false;
  }

  public valueChange(dynamicField, event) {
    this.showLoader = true;
    dynamicField.isChecked = dynamicField.selectedItems.length === dynamicField.options.length;
    var selectedValues = JSON.stringify(event);
    var template = "";
    if (this.template.displayControlsID == 32) {//checking for custom chart control and assign targetTemplateID
      template = this.template.targetTemplateId;
    } else {
      template = this.template.templateID;
    }

    this.moduleService.GetCascadingData(template, dynamicField.fieldId, selectedValues).subscribe(data => {
      if (data != null) {
        Array.prototype.forEach.call(data, dataField => {
          var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
          if (index != -1) {
            this.DynamicFields[0].dynamicFields[index].options = dataField.options;
            this.DynamicFields[0].dynamicFields[index].value = "";
            this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[index].key].setValue("");
            this.DynamicFields[0].dynamicFields[index].selectedItems = [];

            this.allFields[index].options = dataField.options;
            this.allFields[index].selectedItems = [];
          }
        });
      }

      this.showLoader = false;

    })
  }

  closerelDialog() {
    /**/
  }

  DeleteRelProj() {
    /**/
  }
  public deleteRelatedProjectbyProjectId(projId: number, relProjId: number) {
    this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
      if (data != 473 && data != 474) {
        var ans = confirm(this.languageService.GetActionMessageByLanguage('STC_DELRPRJ', "Are you sure you want to delete this related project?"));
        if (ans) {
          let obj: any = {}
          obj.projectid = projId;
          obj.relatedProjectId = relProjId;
          this.moduleService.deleteRelatedProjectByProjectId(obj).subscribe(data => {
          })

          this.moduleService.DisplayView(this.SelectedRecord).subscribe(data => {
            this.viewRecords = data;
            this.display = 'block';
            this.showLoader = false;
            this.documentsPath = this.moduleService.getBaseUrl();

          })
        }
      }
      else {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_CPCAEP', "Project was completed or Cancelled. you can't add/edit related projects."), 4);
      }
    })
  }

  public OpenWindow() {
    this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
      if (data != 473 && data != 474) {
        this.showPopUp = true;

      }
      else {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_CPCDRP', "Project was completed or Cancelled. you can't delete related projects."), 4);
      }
    })
  }

  public CancelRel() {
    this.showPopUp = false;
  }

  public startProgress() {
    this.running = setInterval(() => {
      if (this.value <= 100) {
        this.value++;
      } else {
        this.stopProgress();
      }
    }, 50);
  }

  public stopProgress() {
    if (this.running) {
      clearInterval(this.running);
      this.running = null;
    }
  }

  public resetProgress() {
    this.value = 0;
    this.stopProgress();
  }

  public GetDefaultItem() {
    if (this.languageService.dataByLanguage.find(x => x.LanguageKey == 'STC_SLCT')) {
      this.defaultItem.name = '---' + this.languageService.dataByLanguage.find(x => x.LanguageKey == 'STC_SLCT').Message + '---';
    }
    return this.defaultItem;
  }
}
export class FieldStatusDTO {
  FieldId: number;
  Status: string;
}

export class eachField {
  key: string;
  options: any;
  controlType: number;
}

export class searchkeywithNames {
  key: string;
  options: any = [];
}

export class templateSelectedRecord {

  moduleID: string = "0";
  templateId: string = "0";
  selectedRecordId: number = 0;
  selectedRecordIds: any = [];
  dependentRecordID: string = "0";
  highlightRecordID: number = 0;
  recordType: number = 0;
}
export class DynOptions {
  key: string;
  value: string;
}
export class ChatDetailInformation {
  TOPIC_ID: number;
  PARENT_ID: number;
  COMMENT_ORDER: number;
  COMMENTS: string;
  USERNAME: string;
  CREATEDDATE: string;
  STATUS: string;
  Reasonforchange: boolean;
}

