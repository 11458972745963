import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplatesByModuleData, TemplatesFormFieldsData } from '../../../services/BPM/workflowtasksDetails';
import { WorkflowTasksManagementService } from '../../../services/BPM/workflowtasksmanagement.service';
import { NotificationAlertsService } from '../../../services/notification.service';

@Component({
  selector: 'app-dynamicforms-list',
  templateUrl: './dynamicforms-list.component.html',
  styleUrls: ['./dynamicforms-list.component.css']
})
export class DynamicformsListComponent implements OnInit {

  ResponseTemplatesFormFieldsData: TemplatesFormFieldsData[] = [];
  ResponseTemplatesByModuleData: TemplatesFormFieldsData[] = [];
  

  inputFormFields: any;
  actionID: any;
  templateName: string;
  public selectedKeys:any[]=[];

  constructor(private http: HttpClient, private av_router: ActivatedRoute, private router: Router, private dataservice: WorkflowTasksManagementService
    , private notifications: NotificationAlertsService) {

    this.inputFormFields = this.av_router.snapshot.params["TemplateID"];
  }

  ngOnInit() {
    this.loadWorkFlowData(1);
    
  }


  loadWorkFlowData(CompanyID: number) {
    //debugger;
    this.dataservice.GetAllTemplatesFormFields(CompanyID,0,0,0).subscribe(data => {
      //console.log("data", data);
      this.ResponseTemplatesByModuleData = data;

      this.actionID = this.ResponseTemplatesByModuleData[0].actionID;
      this.inputFormFields = this.ResponseTemplatesByModuleData[0].inputFormFields;
      this.templateName = this.ResponseTemplatesByModuleData[0].actionName;

      this.dataservice.GetAllTemplatesFormFields(1, this.inputFormFields, this.actionID,1).subscribe(data => {
        //console.log("data", data);
        this.ResponseTemplatesFormFieldsData = data;
        //this.WorkFlowName = data[0].WorkflowName;
      });
      //if (this.actionID){
      //  let selectedNode = this.ResponseTemplatesByModuleData.find(x => x.actionID == this.actionID);
      //  if (selectedNode) {
      //    this.selectedKeys = [selectedNode.actionName];
      //    this.dataservice.GetAllTemplatesFormFields(1, this.inputFormFields, this.actionID, 1).subscribe(data => {
      //    this.ResponseTemplatesFormFieldsData = data;
      //    this.dataservice.formOrderListByTemplate=data.map(a=>a.formOrder);
      //  });
      //}
      //}

    });
  }


  public handleSelection(dataItem): void {

    //this.WorkflowID = dataItem.dataItem.id;

    this.inputFormFields = dataItem.dataItem.inputFormFields;
    this.templateName = dataItem.dataItem.actionName;

    this.selectedKeys=[this.templateName];
    this.dataservice.GetAllTemplatesFormFields(1, this.inputFormFields,0,1).subscribe(data => {
      //console.log("data", data);
      //debugger;
      this.ResponseTemplatesFormFieldsData = data;
      this.dataservice.formOrderListByTemplate=data.map(a=>a.formOrder);
      //this.WorkFlowName = data[0].WorkflowName;
    });
  }

}
