import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../../../services/module.service';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from '../../../services/adminusermanagement.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { ExceptionDetail } from '../../../services/AMFormsDataDetails';
import {
  Workbook,
  WorkbookSheet,
} from "@progress/kendo-angular-excel-export";
import { saveAs } from "@progress/kendo-file-saver";
import { GlobalVeriables } from 'src/app/Global/GlobalVaribales';
import { CompositeFilterDescriptor , filterBy } from '@progress/kendo-data-query';
@Component({
  selector: 'app-errors-list',
  templateUrl: './errors-list.component.html',
  styleUrls: ['./errors-list.component.css']
})
export class ErrorsListComponent implements OnInit {
  OperationType: number = 1;
  MasteDataType: number = 1;
  MasteDataTypeID: number = 1;
  MasteDataTypeName: any;
  MasterDataID: number = 0;
  CompanyID: number = 0;
  ResponseData: ExceptionDetail[] = [];
  IsExportExcel: boolean = false;
  EnableSearchOption: boolean = false;
  columns: any;
  countryID: boolean = false;
  UsersId: boolean = true;
  btnAddUser: boolean = true;
  btndisabledUSer: boolean = true;
  btnActive: boolean = false;
  btnActions: boolean = true;
  htmlToAdd: any;
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  btnEditAction: boolean = true;
  btnDeleteAction: boolean = true;
  AuditexcelBtn: boolean = false;
  noOfRecords: number = 10;
  pageNo: number = 1;
  dataHeader: string = "Exception Details";
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  offset: string = "+05:30";
  EnableFilter: boolean = false;
  streamdata: any;

  excelRecords:any;
  public filter: CompositeFilterDescriptor;
  filteredRecords: any;
  gridData: any;
  messageChecking:any;
  errorDataHandle:boolean=true;
  internetMessageHandle:boolean=false;
  
  columnNamesUser:any=["Exception","Stack Trace","Inner Exception","Exception Method Area","Method Name","Module Name",
  "Created Date"];

  constructor(private moduleService: ModuleService, private http: HttpClient, private av_router: ActivatedRoute, private router: Router, private dataservice: AdminUserManagementService,
    private notifications: NotificationAlertsService,private GV:GlobalVeriables) {     }

  ngOnInit() {
    this.CompanyID= +localStorage.getItem("usercompanyID");
    this.loadExceptionDetailData(this.noOfRecords, this.pageNo, this.MasterDataID, this.CompanyID);
    this.getOffset()
  }

  EnableDisableFilter() {
    if (this.EnableFilter)
      this.EnableFilter = false;
    else
      this.EnableFilter = true;
  }

  loadExceptionDetailData(noOfRecords: number, pageNo: number, MasterDataID: number, CompanyID: number) {
    this.dataservice.AllExceptionDetailsData(noOfRecords, pageNo, MasterDataID, CompanyID).subscribe(data => {

      this.gridData=data;
      this.ResponseData = data;
      this.IsExportExcel = true;
      if (data.length != 0) {
        this.EnableSearchOption = true;
      }
    });
  }

  public zeroPad = (num, places) => String(num).padStart(places, '0');
  public getOffset() {
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + this.zeroPad(hours, 2) + ':' + this.zeroPad(minutes, 2);
    this.offset = offset;
  }
  async TestGVAPI() {
    const formData = new FormData();
    const apiUrl = 'https://verify.trial.globalvision.co/api/v1/files'
    const nodeFetch = require('node-fetch');
    formData.append('file', '[xfvfvvb]');
    const apiResponse = await nodeFetch(apiUrl, {
      method: 'POST',
      headers: {
        'x-gv-integration': 'test',
        'x-api-key': '16fe805794c51c856a6263e6bb0ef933.ae948da7ec0c547d0f9cb6ca59cdb50ab137eed435631e10876d994e47709f4d',
        'accept': '/'
      },
      body: formData
    });
    const json = await apiResponse.json;
  }

  async TestGVAPI123() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "16fe805794c51c856a6263e6bb0ef933.ae948da7ec0c547d0f9cb6ca59cdb50ab137eed435631e10876d994e47709f4d");
    myHeaders.append("x-gv-integration", "test");
    var formdata = new FormData();

    formdata.append("file", "[12345]");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    };
    fetch("https://verify.trial.globalvision.co/api/v1/files", requestOptions)

      .then(response => response.text())

      .then(result => console.log(result))

      .catch(error => console.log('error', error));
    //const json = await apiResponse.json;
    //console.log(json);
  }

  //////////////////////////////////////
  TestGVAPI111() {
    this.dataservice.SendSourcefileGVAPI("SampleFIle.pdf","test").subscribe(data => {
      console.log(data);
      this.dataservice.SendSourcefileGVAPI("SampleFile2.pdf","test2").subscribe(data1 => {
        console.log(data1);
        var fileid1 = data.id
        var fileid2 = data1.id
        this.dataservice.VerifyGVInspection(fileid1, fileid2).subscribe(datainsres => {
          console.log(datainsres);
          var inspectionid = datainsres.id
          this.dataservice.FetchReportInspection(inspectionid).subscribe(datareportres => {
            console.log(datareportres);

          });
        });

      });

    });
  }
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.ResponseData, filter);
    this.gridData = this.filteredRecords;

  }
  onExcelExport()
  {
    this.excelRecords =  this.gridData
    
      
    var rows = [];
      //Application Name
      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

      var headerCell = new HeaderCellClass();
      headerCell.value = "Application Name"
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new HeaderCellClass();
      headerCell.value = "Artwork360"
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);
      //Module Name
      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

      var headerCell = new HeaderCellClass();
      headerCell.value = "Module Name"
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new HeaderCellClass();
      headerCell.value = "Application Errors"
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Downloaded by

      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

      var headerCell = new HeaderCellClass();
      headerCell.value = "Downloaded By";
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new HeaderCellClass();
      headerCell.value = this.GV.UserName;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Downloaded Date

      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];


      var headerCell = new HeaderCellClass();
      headerCell.value = "Downloaded Date";
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      var headerCell = new HeaderCellClass();

    let CurrentDate = new Date();
      headerCell.value = CurrentDate.toString();;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

//Binding Columns

      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

      this.columnNamesUser.forEach(element => {
        var headerCell = new HeaderCellClass();
        headerCell.value = element
        headerCell.bold = true;
        headerRowcells.push(headerCell);
        headerCell.background = "#227447"
        headerCell.color = "#ffffff"
      });

      headerRow.cells = headerRowcells;
      rows.push(headerRow);
//Binding Grid Data
     var headerRow = new HeaderRowClass();
     var headerRowcells: HeaderCellClass[] = [];

     for(let i=0;i<=this.excelRecords.length-1;i++)
     {
      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

          var headerCell = new HeaderCellClass();
          headerCell.value = this.excelRecords[i].exceptionMessage;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = this.excelRecords[i].stackTrace;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = this.excelRecords[i].innerException;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = this.excelRecords[i].exceptionMethodArea;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = this.excelRecords[i].methodName;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = this.excelRecords[i].moduleName;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = this.excelRecords[i].createdDate;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""
       
           headerRow.cells = headerRowcells;
           rows.push(headerRow); 
     }
      let excelName='Error Log';
      const workbook = new Workbook({
        sheets: <WorkbookSheet[]>[
          {
            // Column settings (width)
            columns: [
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
            ],
            
            // Title of the sheet            
            name:  excelName,
            rows:rows   
          },
        ],
      });
      workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName+".xlsx"));
    }
   
  }


export class GridViewData {
  public pageSize: number = 10;
  public skipPage: number = 0;
  public viewData: any = {};
  public filter: any = null;
}
export class HeaderCellClass {

  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class HeaderRowClass {

  cells: any;
}
