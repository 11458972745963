import { Component, OnInit } from '@angular/core';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from '../../../services/adminusermanagement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { RoleDetails, UserFormData, DepartmentDetails, GroupsData, UserGroupData } from '../../../services/AMFormsDataDetails';
import { NotificationAlertsService } from '../../../services/notification.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {
  GroupDialog: boolean;
  groupid: any;
  id: number;
  areaModel: any;
  OperationType: number = 1;
  UserID: number = 0;
  CompanyID: number = 0;
  submitted = false;
  validated = true;
  UsersDt = new UserFormData();
  departmentsData: DepartmentDetails[] = [];

  selectedItems: any = [];
  objGroupDt = new GroupsData();
  savebtn: boolean = false;
  updatebtn: boolean = false;
  dynGroupHeader: string = null;
  dataparsing: string;
  btnAddGroup: boolean = true;
  btndisabledGroup: boolean = true;
  btnActive: boolean = false;
  RolesData: RoleDetails[] = [];
  UsersData: UserGroupData[] = [];
  PriviligeID: number;
  selectedTypeID: number;
  isDisabledUsers = true;
  selectedTypeItems: any = [];
  public isChecked = false;
  public isUserChecked = false;
  IsUserIsRequired: boolean = false;
  showGroupDashboardLoader: boolean = false;
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  offSet: string = "+05:30";
  constructor(private router: Router, private av_router: ActivatedRoute, private formbuilder: FormBuilder, private dataservice: AdminUserManagementService, private commonfeaturesservice: CommonFeaturesService, private notifications: NotificationAlertsService, private GV: GlobalVeriables) {


    if (this.av_router.snapshot.params["id"]) {
      this.groupid = this.av_router.snapshot.params["id"];
      this.updatebtn = true;
      this.savebtn = false;
    }
    else {
      this.groupid = 0;
      this.savebtn = true;
      this.updatebtn = false;
    }
  };

  GroupDyForm = this.formbuilder.group({
    userFormDepartment: ['', [Validators.required]],
    GroupFormGroupName: ['', [Validators.required, Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    GroupFormDescription: ['', Validators.pattern("^[ a-zA-Z0-9_-]*$")],
    GroupFormType: ['', Validators.required],
    GroupFormUsers: [''],

  });
  ngOnInit() {
    this.UserDepartments();

    this.CompanyID = +localStorage.getItem("usercompanyID");
    this.GV.UserId = +localStorage.getItem("userId");
    this.GroupDialog = true;
    this.GetAllTyes();
    if (this.groupid > 0) {
      this.isDisabledUsers = true;
      this.dataservice.getUsersGroup(8, '', this.groupid).subscribe(dt => {
        this.UsersData = dt;
        if (dt.length > 0)
          this.isDisabledUsers = false;
        else
          this.isDisabledUsers = true;
        if (this.isUserChecked = this.selectedItems.length === this.UsersData.length) {
          this.isUserChecked ? "Deselect All" : "Select All";
        }
      });
      this.EditGroup(this.groupid);

      this.dynGroupHeader = 'Edit Group';
    }
    else {
      this.isDisabledUsers = false;
      this.dynGroupHeader = 'Add Group';

    }
  }

  UserDepartments() {
    this.dataservice.getUserDepartments().subscribe(response => {
      this.departmentsData = response;
    });
  }

  handleFilter(value) {
    /**/
  }

  GetAllTyes() {
    this.dataservice.getUMGetRoles().subscribe(response => {
      this.RolesData = response.slice();
    });
  }

  public get toggleAllText() {
    return this.isChecked ? "Deselect All" : "Select All";
  }

  public isItemSelected(item) {
    return this.selectedTypeItems.some((x) => x.id === item.id);
  }

  public onClick() {
    this.isChecked = !this.isChecked;
    this.selectedTypeItems = this.isChecked ? this.RolesData.slice() : [];
    var selectedUsersList = [];
    if (this.isChecked) {
      
      for (let typeItems of this.selectedTypeItems.length) {
        selectedUsersList.push(typeItems.id);
      }
      if (selectedUsersList != undefined && selectedUsersList != null) {
        this.dataservice.getUsersGroup(3, selectedUsersList, this.groupid).subscribe(response => {
          this.UsersData = response;
          if (response.length > 0)
            this.isDisabledUsers = false;
          else {
            this.selectedItems = [];
            this.isDisabledUsers = true;
          }
          if (this.isUserChecked = this.selectedItems.length === this.UsersData.length) {
            this.isUserChecked ? "Deselect All" : "Select All";
          }
        });
      }
    }
    else {
      this.selectedItems = [];
      this.isDisabledUsers = true;
    }

  }

  ////users selection////
  public onValueChange() {
    this.isUserChecked = this.selectedItems.length === this.UsersData.length;
  }
  public get toggleUserAllText() {
    return this.isUserChecked ? "Deselect All" : "Select All";
  }

  public isUserItemSelected(item) {
    return this.selectedItems.some((x) => x.id === item.id);
  }

  public onUserClick() {
    this.isUserChecked = !this.isUserChecked;
    this.selectedItems = this.isUserChecked ? this.UsersData.slice() : [];
  }
  UsersOnChange(selectedRoleId: any, dynamicField: any) {
    this.isChecked = this.selectedTypeItems.length === this.RolesData.length;
    var selectedUsersList = [];
    for (let selectRoleId of selectedRoleId) {
      selectedUsersList.push(selectRoleId.id);
    }
    if (selectedUsersList != undefined && selectedUsersList != null) {
      this.dataservice.getUsersGroup(3, selectedUsersList, this.groupid).subscribe(response => {
        this.UsersData = response;
        if (response.length > 0)
          this.isDisabledUsers = false;
        else {
          this.selectedItems = [];
          this.isDisabledUsers = true;
        }
        if (this.isUserChecked = this.selectedItems.length === this.UsersData.length) {
          this.isUserChecked ? "Deselect All" : "Select All";
        }

      });
    }
    else {
      this.selectedItems = [];
      this.isDisabledUsers = true;
    }
  }
  saveOrUpdategroupData(groupid) {
    if (this.GroupDyForm.invalid) {
      this.submitted = true;
      return;
    }
    this.showGroupDashboardLoader = true;
    this.objGroupDt.operation = 6;
    this.objGroupDt.Id = parseInt(groupid);
    this.objGroupDt.currentUserId = this.GV.UserId;
    this.objGroupDt.groupName = this.GroupDyForm.controls['GroupFormGroupName'].value;
    this.objGroupDt.description = this.GroupDyForm.controls['GroupFormDescription'].value;
    this.objGroupDt.masterModule = this.GroupDyForm.controls['userFormDepartment'].value;
    ////type start////
    var typedata = this.GroupDyForm.controls['GroupFormType'].value;
    var getTypeDt = [];
    for (let data of typedata) {
      getTypeDt.push(data.id);
    }
    this.objGroupDt.type = getTypeDt.toString();
    ////type end////
    var ele = this.GroupDyForm.controls['GroupFormUsers'].value;
    var splitingdata = [];
    var splitingwithName = [];
    for (let eledata of ele) {
      splitingdata.push(eledata.id);
      splitingwithName.push(eledata.userName);
    }

    this.objGroupDt.userIds = splitingdata.toString();
    this.objGroupDt.users = splitingwithName.toString();
    this.dataservice.saveGroupsData(this.objGroupDt).subscribe(response => {
      if (response != undefined && response.length > 0) {
        if (response[0] == "0" && response[0] != undefined) {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "GCS", this.CompanyID).subscribe((dataRCActionsMessages) => {
            this.notifications.show(dataRCActionsMessages[0].displayMessage, dataRCActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
          if (this.objGroupDt.userIds != '' && this.objGroupDt.userIds != null)
            this.dataservice.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForUserAddOrUpdateOrDeactivateGroup, 1, 0, 0, parseInt(response[1]), 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
        }
        else if (response[0] == "1" && response[0] != undefined) {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "GUS", this.CompanyID).subscribe((dataGUActionsMessages) => {
            this.notifications.show(dataGUActionsMessages[0].displayMessage, dataGUActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
          if (this.objGroupDt.userIds != '' && this.objGroupDt.userIds != null)
            this.dataservice.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForUserAddOrUpdateOrDeactivateGroup, 2, 0, 0, this.objGroupDt.Id, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
        }
        else if (response[0] == "2" && response[0] != undefined) {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "GAE", this.CompanyID).subscribe((dataGAActionsMessages) => {
            this.notifications.show(dataGAActionsMessages[0].displayMessage, dataGAActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
      }
      else
        this.showGroupDashboardLoader = false;
      this.showGroupDashboardLoader = false;
      this.GroupDialog = false;
      this.router.navigate(['/Groups']);
    },
      error => {
        this.showGroupDashboardLoader = false;
        this.notifications.show(error.statusText, 4);
      }
    );
  }

  EditGroup(groupid) {
    debugger;
    this.GroupDialog = true;
    this.savebtn = false;
    this.updatebtn = true;
    this.UserDepartments();

    this.dataservice.getSelectedRolesData(7, '', groupid).subscribe(response => {
      if (response.length > 0) {
        this.selectedTypeItems = response;
        if (this.isChecked = this.selectedTypeItems.length === this.RolesData.length) {
          this.isChecked ? "Deselect All" : "Select All";
        }
      }
      else
        this.selectedTypeItems = [];
    });

    this.dataservice.getGroupsData(2, parseInt(groupid)).subscribe(data => {
      this.GroupDyForm.controls['GroupFormGroupName'].setValue(data[0].groupName);
      this.GroupDyForm.controls['GroupFormDescription'].setValue(data[0].description);
      this.GroupDyForm.controls['userFormDepartment'].setValue(parseInt(data[0].masterModuleName));
    });

    this.dataservice.getUsersGroup(5, '', groupid).subscribe(response => {
      if (response.length > 0) {
        this.selectedItems = response;
        this.isDisabledUsers = false;
        if (this.isUserChecked = this.selectedItems.length === this.UsersData.length) {
          this.isUserChecked ? "Deselect All" : "Select All";
        }
      }
      else {
        this.selectedItems = [];
        this.isDisabledUsers = true;
      }
    });
  }

  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }


}
