import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicFieldGridComponent } from '../../../ArtworkManagement/dynamic-fields/dynamic-field-grid/dynamic-field-grid.component';
//import { LanguageService } from '../services/language.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { AdminUserManagementService, DateConvertions, GetAuditAllFieldName, NotificationEmailTriggerEvent } from '../../../services/adminusermanagement.service';
import {  AduitForm, Office, OfficeAudit } from '../../../services/AMFormsDataDetails';
import { AuditDashboardForm, PDfExpAuditSave } from '../../../services/BPM/workflowtasksDetails';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { LanguageService } from '../../../services/language.service';
import { ModuleService } from '../../../services/module.service';
import { NotificationAlertsService } from '../../../services/notification.service';
@Component({
  selector: 'app-setup-outofoffice',
  templateUrl: './setup-outofoffice.component.html',

})
export class SetUpOUtOfOfficeComponent implements OnInit{
  userid: number;
  ManagePasswordDlg: boolean = true;
  ShowReAssignWindow: boolean = false;
  officeDyForm: FormGroup;
  createGroup: FormGroup;
  groupUsers = [];
  users = [];
  submitted = false;
  isProxy = null;
  officeData: Office = new Office();
  officesSaveData: Office = new Office();
  auditOffice: OfficeAudit = new OfficeAudit();
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  offSet: string = "+05:30";
  savedOfficeData: any;
  showDashboardLoader = false;
  reassignpopup: string = "none";
  dynHeadingName: string;
  inputFormFields: string = "";
  public languageDataDefault: any;
  gridLength: any;
  ProxyUser: any;
  refreshGrid: number = 0;
  AuditResponseData: AuditDashboardForm[] = [];
  AuditLogId: boolean = false;
  Audituserdata: AduitForm[] = []; ''
  htmlToAdd: any;
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  btnEditAction: boolean = true;
  btnDeleteAction: boolean = true;
  AuditexcelBtn: boolean = false;
  ObjAuditExpPdf = new PDfExpAuditSave();
  progress: number;
  message: string;
  FileName: string;
  showLoader: boolean = false;
  openSetupoutofficewindow:boolean=false;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  constructor(private formbuilder: FormBuilder, private moduleService: ModuleService,
    private dataservice: AdminUserManagementService, private GV: GlobalVeriables, private notifications: NotificationAlertsService, private commonfeaturesservice: CommonFeaturesService, private router: Router,
    private languageService: LanguageService, private DateTimeService: DateConvertions, private DisplayAllFields: GetAuditAllFieldName, private _cd: ChangeDetectorRef) { }
  
  

  ngOnInit() {
    localStorage.setItem("TasksFlag", "2");
    this.dynHeadingName = "";//template.templateName;
    this.dynHeadingName = this.languageService.GetActionMessageByLanguage("","");
    this.inputFormFields = "2955,2956,2958";//template.inputFormFields;

    this.languageDataDefault = this.languageService.dataByLanguage;

    this.reassignpopup = "block";
    this.openSetupoutofficewindow = true;
    this.userid = this.GV.UserId;
    this.officeDyForm = this.formbuilder.group({
      Message: ['', Validators.required],
      proxys: ['', Validators.required],
      TurnOffice: [''],
      tasks:['']
    });
    this.showDashboardLoader = true;
  
    
    // get the out office data to show in edit
    this.dataservice.getOfficedata(12, this.userid, 0, '').subscribe(response => {
      if (response != null) {
      this.officeData.State = response.state;
      this.officeData.Message = response.message;
      this.officeData.IsProxy = response.isProxy;
      this.officeData.Proxy = response.proxy;
        var proxy = this.officeData.Proxy != null ? this.officeData.Proxy:''
        localStorage.setItem("proxyUserId", proxy);
        var res = this.officeData.State == null || this.officeData.State == 'False' ? 'Off' : 'On';
        var isProxy = this.officeData.IsProxy != null ? this.officeData.IsProxy == 'False' ? 'Proxy' : 'Escalate' : null;
        this.officeDyForm.controls['Message'].setValue(this.officeData.Message);
        this.officeDyForm.controls['TurnOffice'].setValue(res);
        this.officeDyForm.controls['proxys'].setValue(isProxy);
        if (res == 'On') {
         
            localStorage.setItem('proxyUserId', '');
            localStorage.setItem('SelectedTasks', "");
        
        }
      }
      else {
        this.officeDyForm.controls['Message'].setValue(null);
        this.officeDyForm.controls['TurnOffice'].setValue('Off');
        this.officeDyForm.controls['proxys'].setValue(null);
       
      }
      if (localStorage.getItem('TasksFlag') == '1')
         this.officeDyForm.controls['tasks'].setValue('Assigned Tasks');
        else
         this.officeDyForm.controls['tasks'].setValue('Pending Tasks');

     
     
      this.showDashboardLoader = false;
    }
      ,
      error => {
        
        this.showDashboardLoader = false;
        this.notifications.show(error.statusText, 4);
      }
    );   

   
  
    
   
  }

  onChange(event: any) {
   if(this.officeDyForm.value.tasks=='Assigned Tasks')
      localStorage.setItem("TasksFlag", "1");
   else
     localStorage.setItem("TasksFlag", "2");
    this.refreshGrid ++;
    this._cd.detectChanges();
  }
  closereassignModalDialog() {
    this.reassignpopup = 'none'; //set none css after close dialog
    this.ShowReAssignWindow = false;
    this.moduleService.passSelectedModuleIDForDashboard(1);

    this.router.navigate(['/Projects']);
  }
 // on or off button change event
  onOfficeChange(event: any) {
    if (this.officeDyForm.value.TurnOffice == 'off')
      localStorage.setItem('proxyUserId', this.officeData.Proxy);

    else {
      if (this.officeData!=null)
        localStorage.setItem('proxyUserId', '');
      localStorage.setItem('SelectedTasks', "");
    }
  }
  // save out of office data and messages and mail notifications
  Save() {

    if (this.officeDyForm.invalid) {
      this.submitted = true;
      return;
    }
    if (this.officeDyForm.value.tasks == 'Assigned Tasks') {
      return;
    }
    this.gridLength = parseInt(localStorage.getItem('GridTotal'));
    this.ProxyUser = parseInt(localStorage.getItem('proxyUserId'));
    if (this.officeDyForm.value.TurnOffice == 'On') {
      if (Number.isNaN(this.gridLength) || this.gridLength < 0) {
        this.submitted = true;
        this.notifications.show("You can not setup out of office because you don't have tasks assgined to you", 4);
        return;
      }
      else if (Number.isNaN(this.ProxyUser)) {
        this.submitted = true;
        this.commonfeaturesservice.GetAllActionsMessages(1, 0, 13, 999, "OFFPE", 1).subscribe((dataUActionsMessages2) => {
          this.notifications.show(dataUActionsMessages2[0].displayMessage, dataUActionsMessages2[0].messageType);
        });
        return;
      }
    }
    this.refreshGrid = 0;

    if (this.officeData != null && this.officeDyForm.value.TurnOffice=='Off') {
      var res = this.officeData.State == null || this.officeData.State == 'False' ? 'Off' : 'On';
      var isProxy = this.officeData.IsProxy != null ? this.officeData.IsProxy == 'False' ? 'Proxy' : 'Escalate' : null;
     
      if (res == this.officeDyForm.value.TurnOffice && isProxy == this.officeDyForm.value.proxys && this.officeData.Message == this.officeDyForm.value.Message ) {
        this.commonfeaturesservice.GetAllActionsMessages(1, 0, 13, 999, "OFFNC", 1).subscribe((dataUActionsMessages2) => {
          this.notifications.show(dataUActionsMessages2[0].displayMessage, dataUActionsMessages2[0].messageType);
        });
        this.refreshGrid = 0;
        return;
      }
    }
    this.officesSaveData.Message = this.officeDyForm.value.Message;
    this.officesSaveData.State = this.officeDyForm.value.TurnOffice;
    this.officesSaveData.IsProxy = this.officeDyForm.value.proxys;
    this.officesSaveData.Proxy = this.ProxyUser.toString();

   
    this.showDashboardLoader = true;
    this.auditOffice.NewOutOfOffice = <Office>this.officesSaveData;
    this.auditOffice.OldOutOfOffice = <Office>this.officeData;
    this.auditOffice.CurrentUserId = this.userid;
   
    this.dataservice.saveOfficeData(this.auditOffice).subscribe(response => {
      //console.log(response);
      this.showDashboardLoader = false;
      this.savedOfficeData = response;
      //if (res == this.officeDyForm.value.TurnOffice || isProxy == this.officeDyForm.value.proxys || this.officeData.proxy == this.ProxyUser.toString())
      //  this.refreshGrid = true;
      //else
      //  this.refreshGrid = false;
    
     // if (this.savedOfficeData.isMessageChanged || this.savedOfficeData.isStateChanged || this.savedOfficeData.isProxyChanges || this.savedOfficeData.isAssignuserChanges) {
        this.commonfeaturesservice.GetAllActionsMessages(1, 0, 13, 999, "OFFUS", 1).subscribe((dataUActionsMessages) => {
          this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
        });
     
      if (this.savedOfficeData.state == 'On') {
       
          if (this.savedOfficeData.isProxy.toString() == 'Proxy')

              this.dataservice.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationForOutOfOffice, 1, 0, this.userid, '', 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
            else
              this.dataservice.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationForOutOfOffice, 2, 0, this.userid, '', 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
        
      }
      var outOfofficeState = this.savedOfficeData.state == 'On' ? true : false;
      this.GV.setoutOfofficeState(outOfofficeState);
      this.officeData.Message = this.officeDyForm.value.Message;
      this.officeData.IsProxy = this.officeDyForm.value.proxys;
      this.officeData.State = this.officeDyForm.value.TurnOffice;
      this.officeData.Proxy = this.ProxyUser.toString();
      if (this.savedOfficeData.state == 'On') {
        localStorage.setItem('proxyUserId', '');
        localStorage.setItem('SelectedTasks', "");
      }
     
    
      this.refreshGrid++;
      this._cd.detectChanges();
     
    },
      error => {
        //console.log(error);
        this.showDashboardLoader = false;
        this.notifications.show(error.statusText, 4);
      }    );

    //console.log(this.officeDyForm.value);
  }
  //cancel the office window and redirecto to projects screen
  auditclose() {
    this.ManagePasswordDlg = false;
    this.moduleService.passSelectedModuleIDForDashboard(1);
    this.openSetupoutofficewindow=false;
    this.router.navigate(['/Projects']);
  }
  //cancel the office audit window 
  auditWindowclose() {
    this.AuditLogId = false;
  }

  // to show the audit for office
  AuditLogData(keyID: any, TablenumID: any, CompanyID: any) {
    this.AuditLogId = true;
    this.IsAuditExportExcel = true;
    keyID = parseInt(localStorage.getItem('userId'));

    this.dataservice.AuditLogData(keyID, TablenumID, this.GV.UserId, CompanyID).subscribe((data) => {


      this.divHisdata = true;
      var AuditDisplay = "";
      if (data.length > 0) {
        this.AuditexcelBtn = true;
        for (var i = 0; i < data.length; i++) {
          var seraizedata = JSON.parse(data[i]["changes"]);

          AuditDisplay += "<div class=''>";
          AuditDisplay += "<table class='auditclass'>";
          AuditDisplay += "<tr class='auditclass margin-top-10' style='background: rgba(245, 247, 250, 0.5) !important;'>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>User: <b class='newtext-color'>" + data[i].userId + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Event date:<b class='newtext-color'> " + this.DateTimeService.convertdate(data[i].eventDate) + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Action type:<b class='newtext-color'> " + data[i].actionType + "</b></td>";
          AuditDisplay = AuditDisplay + "</tr>"
          AuditDisplay = AuditDisplay + "<tr class='auditclass'><td><b>Field name</b></td><td><b>Before change</b></td><td><b>After change</b></td></tr>";
          if (seraizedata.length > 0) {
            for (var j = 0; j < seraizedata.length; j++) {
             
              AuditDisplay = AuditDisplay + "<tr class='auditclass'>";
              AuditDisplay = AuditDisplay + "<td>" + this.DisplayAllFields.GetFieldName(seraizedata[j].FieldName, 1057) + "</div>";
              AuditDisplay = AuditDisplay + "<td>" + (seraizedata[j].ValueBefore == "(null)" ? "" : seraizedata[j].ValueBefore) + "</div>";
              AuditDisplay = AuditDisplay + "<td style='width:33%!important;'>" + (seraizedata[j].ValueAfter == "(null)" ? "" : seraizedata[j].ValueAfter) + "</div>";
              AuditDisplay = AuditDisplay + "</tr>";
            }
          }
          else
            AuditDisplay = AuditDisplay + "<tr class='newtext-color'><td></td><td class='center'>No updates to show!</td><td></td></tr>";

          AuditDisplay = AuditDisplay + "</table><br/>";
          AuditDisplay = AuditDisplay + "</div>";
        }
      }
      else {
        AuditDisplay = AuditDisplay + "No audit data for this record.";
        // this.notifications.show("No audit data for this record.");
        this.AuditexcelBtn = false;
      }
      this.htmlToAdd = AuditDisplay;
    });
  }

  ExportAuditToPdfFile(htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;;
    this.ObjAuditExpPdf.TableEnum = '59';
    this.showLoader = true;
    this.dataservice.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      //console.log("download result ", event.filePath);
      this.showLoader = false;
    },
      error => {
        //console.log(error);
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );


  }
  //downloading the audit dat to pdf file
  DownLoadExportPdfFile(Filepath: string, FileName: string) {
    this.FileName = FileName;
    this.dataservice.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
      }
    },
      error => {
        // console.log(error);
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }
  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    //a.download = this.FileName.substring(1).slice(0, -1);
    a.download = this.FileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

}
