import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/internal/Observable';
import { ModuleService } from 'src/app/services/module.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { breadCrumbs } from '../../../interfaces/ItemplateFields';

@Component({
  selector: 'app-dynamic-field-nested-grid',
  templateUrl: './dynamic-field-nested-grid.component.html',
  styleUrls: ['./dynamic-field-nested-grid.component.css']
})
export class DynamicFieldNestedGridComponent implements OnInit {
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  @Input() public template: any;
  @Input() public recordID: any;
  showLoader: boolean = false;
  MainDataSource: any;
  breadcrumbs: Array<breadCrumbs>;
  allcheckboxChecked: boolean = false;
  department: any;

  constructor(private moduleService: ModuleService, private GV: GlobalVeriables,) { }
  ngOnInit() {
    this.BindGridMainRecords();


    this.moduleService.breadCrumbs.subscribe(breadcrumbs => {



      var templateID = this.template.templateID;
      var displayControlsID = this.template.displayControlsID;
      var depeendentTemplateID = this.template.isDependent;
      var parentTemplateID = this.template.hasParent;


      this.breadcrumbs = breadcrumbs.filter(a => a.templateId == depeendentTemplateID || a.templateId == parentTemplateID);//|| (a.templateId == templateID && a.displayControlsID == displayControlsID)

      //this.breadcrumbs.filter(a => a.templateId == templateID && a.displayControlsID == displayControlsID)[0].displayName = "darshan";


    });


    this.moduleService.breadCrumbName.subscribe(breadcrumb => {
      if (!this.GV.GetIsRedirection())
      if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {



        var displayData = this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID);

        if (displayData == null || displayData == undefined || displayData.length == 0) {

        } else {

          this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;

        }
      }


    });
  }

  BindGridMainRecords() {
    this.showLoader = true;
    this.moduleService.BindProjectAssigments(this.recordID).subscribe(response => {
      this.MainDataSource = response;
      this.showLoader = false;
      this.hideCheckBoxes(this.MainDataSource);
    });
  }

  hideCheckBoxes(item:any) {
    setTimeout(function () {
      for (let i = 0; i < item.length; i++) {
        if (item[i].taskList.filter(ta => ta.workFlowStatusId == 274).length == 0 && item[i].taskList.filter(ta => ta.workFlowStatusId == 275).length == 0
          && item[i].taskList.filter(ta => ta.workFlowStatusId == 279).length == 0) {
          var elementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + item[i].departmentID);
          if (elementAll != null) {
            elementAll.checked = false;
            elementAll.hidden = true;
          }
        }
      }
    }, 10)
  }

  public expandedDetailKeys: any[] = [1];

  public expandDetailsBy = (dataItem: any): any => {
    return dataItem.departmentID;
  };
  ParentAssingnUsers(event: boolean) {
    if (event) {
      this.BindGridMainRecords();
      this.allcheckboxChecked = false;
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });

    }
  }
  onDeptCheckAll(DeptId: number) {

    var elementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + DeptId);
    var isChecked = elementAll.checked;
    this.allcheckboxChecked = elementAll.checked;
    this.department = DeptId;
    var checkBoxCheckIDs = []
    this.MainDataSource.forEach(function (deptSource) {
      if (deptSource.departmentID == DeptId) {
        deptSource.taskList.forEach(function (value) {
          var element = <HTMLInputElement>document.getElementById("chkDeptradio" + DeptId + value.processDataID);
          if (element != null) {
            if (isChecked) {
              element.checked = true;
              checkBoxCheckIDs.push(parseInt(value.processDataID));
            } else {
              element.checked = false;
            }
          }
        });
      }
      else {
        var defelementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + deptSource.departmentID);
        defelementAll.checked = false;
        deptSource.taskList.forEach(function (value) {
          var element = <HTMLInputElement>document.getElementById("chkDeptradio" + deptSource.departmentID + value.processDataID);
          if (element != null)
            element.checked = false;
        });
      }
    })
  }
  getShortName(strText) {
    var strTxt = "";
    if (strText.length >= 50)
      strTxt = strText.slice(0, 50).concat('...');
    else
      strTxt = strText;
    return strTxt;
  }
}
