import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from '../../../services/adminusermanagement.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { RoleDetails, UserFormData, DepartmentDetails, UserGroupsDetails, CountriesDetails, UserDomains, TimeZonesData } from '../../../services/AMFormsDataDetails';
import { ActivatedRoute } from '@angular/router';
import { NotificationAlertsService } from '../../../services/notification.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { CustomValidators } from '../../../ArtworkManagement/Custom-Validator/CustomValidators' //'../ArtworkManagement/Custom-Validator/CustomValidators';
import { MustMatch } from 'src/app/ArtworkManagement/dynamic-fields/mustmatch.validator';
import { LoginService } from 'src/app/services/login.service';
import { response } from 'express';
import { ModuleService } from '../../../services/module.service';


@Component({
  selector: 'app-managepassword',
  templateUrl: './managepassword.component.html',
  styleUrls: ['./managepassword.component.css']
})
export class ManagepasswordComponent implements OnInit {
  
  Esignpassword:boolean=false;
  submitted:boolean=false;
  notequl:boolean=false;
  notmatch:boolean=false;
  ManagePasswordDlg: boolean = true;
  lblErrorMsgYear: any;
  logo: string;
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  offSet: string = "+05:30"
  clpassword:string;
  cepassword:string;
  shwelMPDyForm: boolean = true;
  shweMPDyForm: boolean = false;
  forgotPasswordID: boolean = false;
  userLNewPasswordmustnotMatch:boolean=false;
  usereNewPasswordmustnotMatch:boolean=false;

  public lMPDyForm = new FormGroup(
    {      userLCurrentPassword: new FormControl('', Validators.required),
        userLNewPassword: new FormControl('', Validators.compose([Validators.required, CustomValidators.CustompatternValidator(this.GV.PwdMinimumCharacters,this.GV.SpecialCharacters,this.GV.UpperCaseCharacters,this.GV.NumericCharacters,this.GV.LowerCaseCharacters)])),
        userLConfirmPassword: new FormControl('', [Validators.required]),
        
    },
    //CustomValidators.mustnotMatch("userLCurrentPassword", "userLNewPassword"),
    CustomValidators.mustMatch("userLNewPassword", "userLConfirmPassword") // insert here
  );

  public eMPDyForm = new FormGroup(
    {      usereCurrentPassword: new FormControl('', Validators.required),
        usereNewPassword: new FormControl('', Validators.compose([Validators.required, CustomValidators.CustompatternValidator(this.GV.PwdMinimumCharacters,this.GV.SpecialCharacters,this.GV.UpperCaseCharacters,this.GV.NumericCharacters,this.GV.LowerCaseCharacters)])),
        usereConfirmPassword: new FormControl('', [Validators.required]),
        
    },
   // CustomValidators.mustnotMatch("usereCurrentPassword", "usereNewPassword"),
    CustomValidators.mustMatch("usereNewPassword", "usereConfirmPassword") // insert here
  );

  public eMPDyForm1 = new FormGroup(
    {     // usereCurrentPassword: new FormControl('', Validators.compose([Validators.required, CustomValidators.patternValidator()])),
        usereNewPassword: new FormControl('', Validators.compose([Validators.required, CustomValidators.CustompatternValidator(this.GV.PwdMinimumCharacters,this.GV.SpecialCharacters,this.GV.UpperCaseCharacters,this.GV.NumericCharacters,this.GV.LowerCaseCharacters)])),
        usereConfirmPassword: new FormControl('', [Validators.required]),
        
    },
    
    CustomValidators.mustMatch("usereNewPassword", "usereConfirmPassword") // insert here
  );
  UserDyForm=new FormGroup(
    {      usrEmailUserName: new FormControl('', 
        [Validators.required])
     
    });

  // lMPDyForm = this.formbuilder.group({
  //   userLCurrentPassword: ['', [Validators.required, Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
  //   userLNewPassword: ['',Validators.pattern("^[ a-zA-Z0-9_-]*$")],
  //   /* GroupFormType: ['', Validators.required],*/
  //   userLConfirmPassword: ['', Validators.required],
  // //  GroupFormUsers: [''],//, RxwebValidators.required({ conditionalExpression: (x =0, y=0) => this.groupid > 0 ? true : false })]// ['', Validators.required],

  // });

  // eMPDyForm = this.formbuilder.group({
  //   usereCurrentPassword: ['', Validators.required],
  //   usereNewPassword: ['',Validators.required],
  //   /* GroupFormType: ['', Validators.required],*/
  //   usereConfirmPassword: ['', Validators.required],
    
  // //  GroupFormUsers: [''],//, RxwebValidators.required({ conditionalExpression: (x =0, y=0) => this.groupid > 0 ? true : false })]// ['', Validators.required],

  // });


    
  constructor(private router: Router,
    private notifyService: NotificationAlertsService,
    private formbuilder: FormBuilder,
    private GV: GlobalVeriables,
    private loginservice: LoginService,
    private commonfeaturesservice: CommonFeaturesService,
    private amService: AdminUserManagementService,
    private moduleService: ModuleService
  ) { }

  ngOnInit(): void {
    this.Esignpassword=false;
    var currentTime = new Date()
    var year = currentTime.getFullYear();    // returns the year (four digits)
    this.lblErrorMsgYear = year;
     this.GetUserSettingsData();
    this.commonfeaturesservice.GetDefaultMasterModuleData().subscribe((dataDefaultMasterModuleData) => {
      if (dataDefaultMasterModuleData[0].logo != null) {
        var str_array = dataDefaultMasterModuleData[0].logo.split(',');
        this.logo = str_array[0];
      }

    });
  
  }
 
 

  public onTabSelect(e: SelectEvent) {
    this.shweMPDyForm=false;
    this.shwelMPDyForm = false;
    this.submitted=false;
    if (e.title == 'Login Password') {

      
      this.shwelMPDyForm = true;
      this.shweMPDyForm = false;
      this.eMPDyForm.reset();
      this.eMPDyForm1.reset();
    }
    else if(e.title=='E-Signature Password') {
      this.loginservice.GetEsign(this.GV.UserId).subscribe(response => {

        this.Esignpassword=response;
        
       })
      
      this.shweMPDyForm = true;
      this.shwelMPDyForm = false;
      this.lMPDyForm.reset();
    }

   
  }

  get f() {
    return this.lMPDyForm.controls;
  }
  get g() {
    return this.eMPDyForm.controls;
  }

  get h() {
    return this.eMPDyForm1.controls;
  }
  UserlogOut() {
    //clear state of templates
    this.router.navigate(['']);
    this.loginservice.setHeaderShow(false);
    localStorage.setItem("userLogin", "false");    
    window.location.reload();
    //this.router.dispose();
  }
  GetUserSettingsData(){
    this.loginservice.GetUserSettingsData().subscribe((userdata) => {

            this.GV.PwdMinimumCharacters=userdata.pwdMinCharacters;
            this.GV.SpecialCharacters=userdata.reqSpecialCharacters;
            this.GV.UpperCaseCharacters=userdata.reqUppercaseCharacters;
            this.GV.NumericCharacters=userdata.reqNumericCharacters;
            this.GV.LowerCaseCharacters=userdata.reqLowercaseCharacters;
           

            this.lMPDyForm = new FormGroup(
              {      userLCurrentPassword: new FormControl('', Validators.required),
                  userLNewPassword: new FormControl('', Validators.compose([Validators.required, CustomValidators.CustompatternValidator(this.GV.PwdMinimumCharacters,this.GV.SpecialCharacters,this.GV.UpperCaseCharacters,this.GV.NumericCharacters,this.GV.LowerCaseCharacters)])),
                  userLConfirmPassword: new FormControl('', [Validators.required]),
                  
              },
              //CustomValidators.mustnotMatch("userLCurrentPassword", "userLNewPassword"),
              CustomValidators.mustMatch("userLNewPassword", "userLConfirmPassword") // insert here
            );
          
           this.eMPDyForm = new FormGroup(
              {      usereCurrentPassword: new FormControl('', Validators.required),
                  usereNewPassword: new FormControl('', Validators.compose([Validators.required, CustomValidators.CustompatternValidator(this.GV.PwdMinimumCharacters,this.GV.SpecialCharacters,this.GV.UpperCaseCharacters,this.GV.NumericCharacters,this.GV.LowerCaseCharacters)])),
                  usereConfirmPassword: new FormControl('', [Validators.required]),
                  
              },
             // CustomValidators.mustnotMatch("usereCurrentPassword", "usereNewPassword"),
              CustomValidators.mustMatch("usereNewPassword", "usereConfirmPassword") // insert here
            );
          
             this.eMPDyForm1 = new FormGroup(
              {     // usereCurrentPassword: new FormControl('', Validators.compose([Validators.required, CustomValidators.patternValidator()])),
                  usereNewPassword: new FormControl('', Validators.compose([Validators.required, CustomValidators.CustompatternValidator(this.GV.PwdMinimumCharacters,this.GV.SpecialCharacters,this.GV.UpperCaseCharacters,this.GV.NumericCharacters,this.GV.LowerCaseCharacters)])),
                  usereConfirmPassword: new FormControl('', [Validators.required]),
                  
              },
              
              CustomValidators.mustMatch("usereNewPassword", "usereConfirmPassword") // insert here
            );

          });
        }
 
Save()
{
  
  if(this.shwelMPDyForm)
  {
    // if(this.lMPDyForm.controls['userLCurrentPassword'].value!='')
    // {
    //   this.loginservice.Getuserdata(this.GV.UserId).subscribe((data)=>{
    //     this.clpassword=data.lpassword;
    //     this.cepassword=data.epassword;
    //     if(this.lMPDyForm.controls['userLCurrentPassword'].value!=this.clpassword)
    //     {
    //         this.notmatch=true;
    //     }
    //     else
    //     {
    //       this.notmatch=false;
    //     }
        
       
    //    });
    // }
  
   
    if(this.lMPDyForm.invalid)
    {
      
      this.userLNewPasswordmustnotMatch=false;
   this.submitted=true;
   return;
    }
  //  else if(this.lMPDyForm.controls['userLCurrentPassword'].value == this.lMPDyForm.controls['userLNewPassword'].value)
  //     {
  //       if(this.lMPDyForm.controls['userLCurrentPassword'].value!='' && this.lMPDyForm.controls['userLNewPassword'].value!='')
  //       {
  //         this.userLNewPasswordmustnotMatch=true;
  //       }  
  //       return false; 
  //     }
    this.userLNewPasswordmustnotMatch=false;
    var currentpassword=this.lMPDyForm.controls['userLCurrentPassword'].value;
    var password=this.lMPDyForm.controls['userLNewPassword'].value;
    this.loginservice.getpasswordstatus(this.GV.UserId,password,'L').subscribe(response=>{

      if(response)
      {
        this.notifyService.show("Password and E-signature Passwords are same please enter different password!",4);
      }
      else
      {
        this.loginservice.GetuserCheck(this.GV.UserId,currentpassword,"L").subscribe(response=>
          {
            if(response)
            {
              this.loginservice.updatepassword(currentpassword,password,this.GV.UserId,true).subscribe(response=>
                {
                  if(response)
                  {
                    this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationForUpdatePassword, 1, 0, 0, this.GV.UserId, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
                    this.notifyService.show("Password is updated sucessfully!",1); 
                    this.UserlogOut();
                  }
                  else
                  {
                    this.notifyService.show("Old Password and new password sholud not match!",4);
                  }
                  
                });
                
            }
            else
            {
             this.notifyService.show("Current Password is incorrect",4);
            }
          });
      }
    });
    
    

  }
  else if(this.shweMPDyForm)
  {

    if(this.Esignpassword)
    {
      if(this.eMPDyForm.invalid)
      {
        this.usereNewPasswordmustnotMatch=false;
        this.submitted=true;
        return;
      }
      // else if(this.eMPDyForm.controls['usereCurrentPassword'].value == this.eMPDyForm.controls['usereNewPassword'].value)
      // {
      //   if(this.eMPDyForm.controls['usereCurrentPassword'].value!='' && this.eMPDyForm.controls['usereNewPassword'].value!='')
      //   {
      //     this.usereNewPasswordmustnotMatch=true;
      //    //status
      //   }  
      //   return false; 
      // }
      this.usereNewPasswordmustnotMatch=false;
      var currentpassword=this.eMPDyForm.controls['usereCurrentPassword'].value;
      var password=this.eMPDyForm.controls['usereNewPassword'].value;
      this.loginservice.getpasswordstatus(this.GV.UserId,password,'E').subscribe(response=>{

        if(response)
        {
          this.notifyService.show("Password and E-signature Passwords are same please enter different password!",4);
        }
        else
        {
          this.loginservice.GetuserCheck(this.GV.UserId,currentpassword,"E").subscribe(response=>{
            if(response)
            {
             this.loginservice.SetEsignPassword(currentpassword,password,this.GV.UserId,true).subscribe(response=>
               {
                 if(response)
                 {
                  this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationForUpdateEPassword, 1, 0, 0, this.GV.UserId, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
                  this.notifyService.show("E-SignPassword is updated successfully!",1);
                   this.ManagePasswordDlg = false;
                   this.moduleService.passSelectedModuleIDForDashboard(1);

                   this.router.navigate(['/Projects']);
                 }
                 else
                 {
                   this.notifyService.show("Old password and new password sholud not match",4);
                 }
                
               });
            }
     
            else
            {
             this.notifyService.show("Current password is incorrect",4);
            }
            
       
           })
        }
      });
      
     

    
    }
    else
    {
      if(this.eMPDyForm1.invalid)
      {
       
        this.submitted=true;
        return;
      }
      var password=this.eMPDyForm1.controls['usereNewPassword'].value;
      this.loginservice.getpasswordstatus(this.GV.UserId,password,'E').subscribe(response=>{

        if(response)
        {
          this.notifyService.show("Password and E-signature Passwords are same please enter different password!",4);
        }
        else
        {
          this.loginservice.SetEsignPassword('NA',password,this.GV.UserId,true).subscribe(response=>
            {
              if(response)
              {
                this.notifyService.show("E-signature Password is set successfully!",1);
                this.ManagePasswordDlg = false;
                this.moduleService.passSelectedModuleIDForDashboard(1);

                this.router.navigate(['/Projects']);
              }
              else
              {
                this.notifyService.show("Password is already Used!",4);
              }
    
    
             
            });
        }
      });
      
    }
   
  }
 
}

cancelUserData() {
  //this.forgotPasswordID = true;
  var type = "E";
  this.loginservice.getcustomerid(this.GV.UserName, type).subscribe(response => {
    var userid = response;
    if (userid > 0) {
      this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForgotLoginandESignPasswordUser, 2, 0, 0, userid, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
      this.notifyService.show("The link has been sent, please check your email to reset your password.", 1);
    }
    else {
      this.notifyService.show("Incorrect username or email.", 4);
    }
  });
  // this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForgotPasswordUser, 1, 0, 0, this.UserID, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
  this.forgotPasswordID = false;
  this.eMPDyForm.reset();
  this.eMPDyForm1.reset();
  this.UserDyForm.reset();
  this.UserDyForm.reset();
}

countriesClose() {
  this.forgotPasswordID = false;
  this.UserDyForm.reset();
}

submitFWP() {
  if(this.UserDyForm.invalid)
  {
    this.submitted=true;
    return;
  }
  var usernameemail = this.UserDyForm.controls['usrEmailUserName'].value;
  var type = "0";
  this.loginservice.getcustomerid(usernameemail, type).subscribe(response =>
    
    {
      var userid=response;
      if(userid>0)
      {
        this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForgotLoginandESignPasswordUser, 2, 0, 0, userid, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
        this.notifyService.show("The link has been sent, please check your email to reset your password.", 1);
    }
      else {
        this.notifyService.show("Incorrect username or email.", 4);
      }
    });
 // this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForgotPasswordUser, 1, 0, 0, this.UserID, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
  this.forgotPasswordID = false;
  this.eMPDyForm.reset();
  this.eMPDyForm1.reset();
  this.UserDyForm.reset();
}
  auditclose() {
    this.ManagePasswordDlg = false;
    this.moduleService.passSelectedModuleIDForDashboard(1);

    this.router.navigate(['/Projects']);
  }
}
