import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getBaseUrl } from '../../main';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  dataByLanguage:DataByLanguage[]=[];

  private oGetLanguageList = getBaseUrl() + "api/adminmanagement/GetLanguagesList";
  private oGetDataByLanguageID = getBaseUrl() + "api/adminmanagement/GetDataByLanguageID";

  constructor(private httpClient: HttpClient) { }

  public GetAllLanguages(): Observable<any>{
    return this.httpClient.get<any>(this.oGetLanguageList);
  }

  public GetDataByLanguageID(languageId:number): Observable<DataByLanguage[]>{
    let params = new HttpParams();
    params = params.set('LanguageId', languageId);
    return this.httpClient.get<DataByLanguage[]>(this.oGetDataByLanguageID ,{params:params})
    .pipe(tap(response=>this.dataByLanguage=response));
  }
  public GetDataBySelectedLanguageId(languageId:number): Observable<DataByLanguage[]>{
    let params = new HttpParams();
    params = params.set('LanguageId', languageId);
    return this.httpClient.get<DataByLanguage[]>(this.oGetDataByLanguageID ,{params:params});
    
  }
  public TranslateDefaultItem(dynamicFormFieldList, languageDataDefault, projectid = 0){
    if(dynamicFormFieldList && dynamicFormFieldList[0].dynamicFields.length>0){
      dynamicFormFieldList.forEach(function(fGroup,gIndex){
        if(fGroup && fGroup.dynamicFields && fGroup.dynamicFields.length>0){
          fGroup.dynamicFields.forEach(function(part,index){
            if (part.defaultItem) {

              if (projectid==0) {
                if (languageDataDefault.find(x => x.FieldIdList.includes(part.fieldId)))
                  part.defaultItem.key = languageDataDefault.find(x => x.FieldIdList.includes(part.fieldId)).DefaultValue;
              } else {
                if (part.controlTypeID!=12) {
                  // TODO document why this block is empty
                  if (languageDataDefault.find(x => x.FieldIdList.includes(part.fieldId)))
                    part.defaultItem.key = languageDataDefault.find(x => x.FieldIdList.includes(part.fieldId)).DefaultValue;
                }
              }
              
              // else
              //   part.defaultItem.key= 'Translation_Error';
            }
          });
        }
      });
    }
  }
  public GetActionMessageByLanguage(aliasKey, OrgValue){
    let tData=this.dataByLanguage.find(x=>x.LanguageKey==aliasKey);
    if(tData)
      return tData.Message;
    else
      return OrgValue;
  }
}

export class DataByLanguage
{
  RID: number
  ID: number
  Language:number
  FormFieldID: number
  Message: string
  LanguageKey : string
  Type: number
  TemplateID:number
  ModuleID:number
  HelpText:string
  FormGroupId:number
  DefaultValue:string
  WorkFlowTaskID:number
  CustomValidation:string
  MasterDataID:number
  FieldIdList=[];
  DisplayControlID:number;
}
