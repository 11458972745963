import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { templateSelectedRecord } from '../../../dynamic-form-view/dynamic-form-view.component';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { breadCrumbs } from '../../../interfaces/ItemplateFields';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { LanguageService } from '../../../services/language.service';
import { ModuleService } from '../../../services/module.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { DynamicFieldControlService } from '../../dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';

@Component({
  selector: 'app-dynamic-field-scheduler',
  templateUrl: './dynamic-field-scheduler.component.html',
  styleUrls: ['./dynamic-field-scheduler.component.css']
})

export class DynamicFieldSchedulerComponent implements OnInit {

  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  showLoader: boolean = false;
  public skip = 0;
  pageSize = 10;
  records: any;
  events: Array<SchedulerEventData> = [];
  filteredData: Array<SchedulerEventData> = [];
  breadcrumbs: Array<breadCrumbs>;
  eventDataPopup: any;
  public toggleText = "Hide";
  public show = false;
  timeoutid: any;
  callSts: boolean= false;
  constructor(private dataService: DynamicFieldService,
    private commonfeaturesservice: CommonFeaturesService,
    private qcs: DynamicFieldControlService,
    private http: HttpClient,
    private moduleService: ModuleService,
    private notifyService: NotificationAlertsService,
    public intl: IntlService,
    private GV: GlobalVeriables,

    private languageService: LanguageService
  ) { }

  ngOnInit(): void {

    this.loadTemplate();
    this.moduleService.breadCrumbs.subscribe(breadcrumbs => {



      var templateID = this.template.templateID;
      var displayControlsID = this.template.displayControlsID;
      var depeendentTemplateID = this.template.isDependent;
      var parentTemplateID = this.template.hasParent;


      this.breadcrumbs = breadcrumbs.filter(a => a.templateId == depeendentTemplateID || a.templateId == parentTemplateID);//|| (a.templateId == templateID && a.displayControlsID == displayControlsID)

      //this.breadcrumbs.filter(a => a.templateId == templateID && a.displayControlsID == displayControlsID)[0].displayName = "darshan";


    });


    this.moduleService.breadCrumbName.subscribe(breadcrumb => {
      if (!this.GV.GetIsRedirection())
      if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {


        this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;

      }


    });
  }

  showTooltip(e) {
    // This method is used only for Agenda view.
    const element = e.target as HTMLElement;
    const tdHovered =
      element.nodeName === "TD" &&
      element.firstElementChild.className === "k-task";
    const titleHovered = element.className === "k-task";

    const showTooltip = tdHovered || titleHovered;
  }

  closePopup() {
    this.show = false;
    this.toggleText = this.show ? "Hidе" : "Show";
  }
  
  onTaskClick(objScheduler: any) {
    let startDate = new Date(objScheduler.start);
    startDate.setHours(0, 0, 0, 0);
    
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);
    let copyEvents =  Object.assign([], this.events);
    var filtered = copyEvents.filter(item => (startDate < new Date(item.start) && endDate > new Date(item.start)));
    this.eventDataPopup = filtered;
    this.show = !this.show;
    this.toggleText = this.show ? "Hidе" : "Show";
  }

  loadTemplate() {
    this.showLoader = true;



    //Binding data for list view for
    this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 0, this.template.searchFilters, 'Default', this.skip, this.pageSize, "", this.template.chkFields, null)

      .subscribe(data => {
        this.records = JSON.parse(data.record);
        var events = [];

        var records = this.records;
        for (var i = 0; i < records.length; i++) {
          var eachEvent = new SchedulerEventData();

          if (i==0) {
            if (records[i].CalenderDueDate != null && records[i].CalenderDueDate != "") {
              eachEvent.id = records[i].ID;
              eachEvent.title = records[i].TaskName;
              eachEvent.projectID = records[i].ProjectID;
              eachEvent.workFlowStatus = records[i].WorkFlowStatus;
              eachEvent.workFlowStatusID = records[i].WorkFlowStatusID;
              eachEvent.start = records[i].ActualStartDate == '' ? new Date(records[i].CalenderDueDate) : new Date(records[i].ActualStartDate);
              eachEvent.end = records[i].ActualCompleteDate == '' ? new Date(records[i].CalenderDueDate) : new Date(records[i].ActualCompleteDate);
              eachEvent.CalenderDueDate = records[i].CalenderDueDate;
              events.push(eachEvent);

            }
          } else if (i == records.length - 1) {
            if (records[i].CalenderDueDate != null && records[i].CalenderDueDate != "") {
              eachEvent.id = records[i].ID;
              eachEvent.projectID = records[i].ProjectID;
              eachEvent.workFlowStatus = records[i].WorkFlowStatus;
              eachEvent.workFlowStatusID = records[i].WorkFlowStatusID;
              eachEvent.title = records[i].TaskName;
              eachEvent.start = records[i].ActualStartDate == '' ? new Date(records[i].CalenderDueDate) : new Date(records[i].ActualStartDate);
              eachEvent.end = records[i].ActualCompleteDate == '' ? new Date(records[i].CalenderDueDate) : new Date(records[i].ActualCompleteDate);
              eachEvent.CalenderDueDate = records[i].CalenderDueDate;
              events.push(eachEvent);

            }
          } else {
            if (records[i].CalenderDueDate != null && records[i].CalenderDueDate != "") {
              eachEvent.id = records[i].ID;
              eachEvent.title = records[i].TaskName;
              eachEvent.projectID = records[i].ProjectID;
              eachEvent.workFlowStatus = records[i].WorkFlowStatus;
              eachEvent.workFlowStatusID = records[i].WorkFlowStatusID;
              eachEvent.start = records[i - 1].ActualStartDate == '' ? new Date(records[i - 1].CalenderDueDate) : new Date(records[i - 1].ActualStartDate);
              eachEvent.end = records[i].ActualCompleteDate == '' ? new Date(records[i].CalenderDueDate) : new Date(records[i].ActualCompleteDate);
              eachEvent.CalenderDueDate = records[i].CalenderDueDate;
              events.push(eachEvent);

            }
          }
          
        }
        this.events = Object.assign([], events);
        this.filteredData=[];
        this.filteredData = Object.assign([], this.events);
        this.callSts = true;
        let a = {
          action: {
            view: {name:"week"}
}
        }
        if (this.callSts == true)
        this.handleNavigate(a);
      });

  }

  //Click Week 
  public handleNavigate(event: any): void {

    if (this.callSts == false) {
      this.loadTemplate();
    }
    //defined for calender Data
    this.filteredData=[];

    if (event.action.view.name == "week") {
      
      const groupedData = {};
      let groupedDataHourGroup = {};
      var copyEvents = Object.assign([], this.events);
      //Group by using Date
      copyEvents.forEach(obj => {
        const date = new Date(obj.start).toLocaleDateString();

        if (!groupedData[date]) {
          groupedData[date] = [];
        }
        groupedData[date].push(obj);
      });
      let dateKeys = new Array();
      dateKeys = Object.keys(groupedData);
      //Group by Date and time
      for (var i = 0; i < dateKeys.length; i++) {
        const groupedDataHour = {};
        let hour = 0;
        groupedData[dateKeys[i]].forEach(obj => {
          hour = new Date(obj.start).getHours();
          if (!groupedDataHour[hour]) {
            groupedDataHour[hour] = [];
          }
          groupedDataHour[hour].push(obj);
        });
        let groupedDataHourKeys = new Array();
        groupedDataHourKeys = Object.keys(groupedDataHour);
        for (var k = 0; k < groupedDataHourKeys.length; k++) {
          for (let j = 0; j < groupedDataHour[groupedDataHourKeys[k]].length; j++) {
            if (!groupedDataHourGroup[dateKeys[i]]) {
              groupedDataHourGroup[dateKeys[i]] = {};
            }
            if (!groupedDataHourGroup[dateKeys[i]][groupedDataHourKeys[k]]) {
              groupedDataHourGroup[dateKeys[i]][groupedDataHourKeys[k]] = [];
            }
            groupedDataHourGroup[dateKeys[i]][groupedDataHourKeys[k]].push(groupedDataHour[groupedDataHourKeys[k]][j]);
          }
        }
      }
      //Assign finel data 
      for (var l = 0; l < dateKeys.length; l++) {
        let groupedDataHourKeys = new Array();
        groupedDataHourKeys = Object.keys(groupedDataHourGroup[dateKeys[l]]);
        for (var m = 0; m < groupedDataHourKeys.length; m++) {
          for (var n = 0; n < 1; n++) {
            if(groupedDataHourGroup[dateKeys[l]][groupedDataHourKeys[m]].length>1)
              groupedDataHourGroup[dateKeys[l]][groupedDataHourKeys[m]][n].title = groupedDataHourGroup[dateKeys[l]][groupedDataHourKeys[m]][n].title + ' + ' + (groupedDataHourGroup[dateKeys[l]][groupedDataHourKeys[m]].length - 1)
            this.filteredData.push(groupedDataHourGroup[dateKeys[l]][groupedDataHourKeys[m]][n]);
          }
        }
      }
    } else {
      this.filteredData = Object.assign([], this.events);
    }
    this.callSts = false;
  }

  getShortName(strText) {
    var strTxt = "";
    if (strText.length >= 50)
      strTxt = strText.slice(0, 50).concat('...');
    else
      strTxt = strText;
    return strTxt;
  }
}


export class SchedulerEventData {
  id: number;
  title: string;
  start: Date;
  end: Date;
  projectID: number;
  workFlowStatus: string;
  workFlowStatusID: number;
  CalenderDueDate: Date;
}
