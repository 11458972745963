import {
  AbstractControl,
  ValidatorFn,
  FormControl,
  FormGroup
} from '@angular/forms';

export class CustomValidators {
  constructor() {}
 static patternValidator(): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } => {  
      if (!control.value) {  
        return null;  
      }  
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');  
      const valid = regex.test(control.value);  
      return valid ? null : { invalidPassword: true };  
    };  
  }  

  


 static CompareOldWithNew(field_name,input): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {

      //const input = control.value;
      const isnotequalValues = field_name != input;
      return  isnotequalValues ? null :{ isnotequalValues:true} ;

    };
  }
  static onlyChar(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value == '') return null;

      let re = new RegExp('^[a-zA-Z ]*$');
      if (re.test(control.value)) {
        return null;
      } else {
        return { onlyChar: true };
      }
    };
  }

  static mustnotMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && matchingControl.errors.mustMatch) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value === matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }

  static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }

  static CustompatternValidator(PwdMinimumCharacters : number , Req_SpecialCharacters : boolean , Req_UpperCaseCharacters : boolean ,
    Req_NumericCharacters : boolean ,Req_LowerCaseCharacters : boolean ): ValidatorFn {  
      debugger;
    return (control: AbstractControl): { [key: string]: any } => {  
      if (!control.value) {  
        return null;  
      }  
      debugger;
      if(PwdMinimumCharacters >0)
      {
       
      if(control.value.length<PwdMinimumCharacters) 
      {
        const validLCC = false; 
        if(validLCC === false){  
        return validLCC ? null : { invalidMinChar: true };}            
      }
      
      }
      if(Req_UpperCaseCharacters === true)
      {
        const regex = new RegExp('^(?=.*?[A-Z])');  
        const valid = regex.test(control.value); 
        if(valid === false){ 
        return valid ? null : { invalidUpperCase: true }; }
      }        
      if(Req_SpecialCharacters === true)
      {
      const regexSC = new RegExp('^(?=.*?[!@#\$%\^\&*\)\(+=._-])');  
      const validSC = regexSC.test(control.value); 
      if(validSC === false){  
      return validSC ? null : { invalidSpecialCharacters: true }; } 
      }
      
      if(Req_NumericCharacters === true)
      {
        const regexNC = new RegExp('^(?=.*?[0-9])');  
        const validNC = regexNC.test(control.value); 
        if(validNC === false){  
        return validNC ? null : { invalidNumeric: true };}   
      }
      if(Req_LowerCaseCharacters === true)
      {
        const regexLCC = new RegExp('^(?=.*?[a-z])');  
        const validLCC = regexLCC.test(control.value); 
        if(validLCC === false){  
        return validLCC ? null : { invalidLowerCase: true };}  
      }
      
      
    };  
  } 
}