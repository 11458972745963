

export class templateData {

  moduleID: number = 0;
  templateID: number = 0;
  parentTemplateID: number = 0;
  parentRecordID: number = 0;
  dependentTemplateID: number = 0;
  dependentRecordID: number = 0;
  tabTemplateID: number = 0;
  initialLoad: boolean = true;

}


export class templateDataRecord {

  ID: number = 0;
  TemplateName: number = 0;
  TemplateDescription: number = 0;
  TemplateAliasName: number = 0;
  moduleID: number = 0;
  templateID: number = 0;
  DisplayTemplateID: number = 0;
  DisplayControlsID: number = 0;
  EnableSearchOption: number = 0;
  EnableFiltersSearch: number = 0;
  ExternalIntegration: number = 0;
  DefaultHeader: number = 0;
  IsFilter: number = 0;
  IsSearch: number = 0;
  IsPaging: number = 0;
  IsSorting: number = 0;
  IsExportPDF: number = 0;
  IsExportExcel: number = 0;
  IsActionEdit: number = 0;
  IsActionView: number = 0;
  IsActionDelete: number = 0;
  ShowInActiveRecords: number = 0;
  Classess: number = 0;
  IsDependent: number = 0;
  HasParent: number = 0;
  RecordID: number = 0;
  ParentTemplateID: number = 0;
  ParentRecordID: number = 0;
  DependentTemplateID: number = 0;
  DependentRecordID: number = 0;
  SelectedTemplateId: number = 0;
  ControlClass: number = 0;
  selectedRecordID: number = 0;
  ActionEvent: number = 0;
  InputFormFields: number = 0;
}

export class cascadingField {

  dynamicField: any;
  selectedValues: any;


}


export class breadCrumbs {

  id: number;
  name: string;
  templateName: string;
  templateId: number;
  moduleId: number;
  displayName: string;
  defaultHeader: string;
  isDependent: number;
  hasParent: number;
  displayControlsID: number;
  displayControlsTabID: number;
  selectedRecordID: number;
  highlightRecordID: number;
  breadCrumOrder: number;


}
export class automation {
  inputData: string;
  Country: string;
  Product: string;
  Access_key: string;
  secret_key: string;
  bucket_name: string;
  folder_name: string;
  document_name: any=[];
  filePath : any=[];
  PackagingType: string;
  ModificationInfo: ModificationInfo[];
  }
  
  
  
  export class ModificationInfo {
  MappingName: string;
  "Old Value": string;
  "New Value": string;
  }

