import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//import { AppConfiguration } from "read-appsettings-json";


export function getBaseUrl() {
  //return AppConfiguration.Setting().Application.ServiceUrl;
  return "https://artworksandboxapi.freyrlabel.com/"; //AWS Demo instance
  //return "https://artwork360api.freyrlabel.com/"; //AWS QA public
  //return "https://artwork360devapi.freyrlabel.com/";// AWS Development
  //return "https://artwork360uatapi.freyrlabel.com/";// AWS UAT Instance
  //return "https://as3pocapi.freyrlabel.com/";//alb
  //return "http://localhost:44324/";
  //return  "https://artwork360penetrationapi.freyrlabel.com/"; //AWS Penetration instance
  //return "http://localhost:888/";  //AWS Performance instance
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));

document.addEventListener("DOMContentLoaded", function (event) {
  const showNavbar = (toggleId, navId, bodyId, headerId) => {
    const toggle = document.getElementById(toggleId),
      nav = document.getElementById(navId),
      bodypd = document.getElementById(bodyId),
      headerpd = document.getElementById(headerId)


    // Validate that all variables exist
    if (toggle && nav && bodypd && headerpd) {
      toggle.addEventListener('click', () => {
        // show navbar
        nav.classList.toggle('show')
        // change icon
        toggle.classList.toggle('bx-x')
        // add padding to body
        bodypd.classList.toggle('body-pd')
        // add padding to header
        headerpd.classList.toggle('body-pd')
      })
    }
  }


  showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header')


  /*===== LINK ACTIVE =====*/
  const linkColor = document.querySelectorAll('.nav_link')


  function colorLink() {
    if (linkColor) {
      linkColor.forEach(l => l.classList.remove('active'))
      this.classList.add('active')
    }
  }
  linkColor.forEach(l => l.addEventListener('click', colorLink))


  // Your code to run since DOM is loaded and ready
});












