import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationAlertsService } from '../services/notification.service';
import { catchError } from 'rxjs/operators';
import { error } from 'jquery';
import { LanguageService } from '../services/language.service';
import { LoginService } from '../services/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private notifyService: NotificationAlertsService, private languageService: LanguageService, private _loginService: LoginService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    return next.handle(request).pipe(
      catchError(error => {
        if (error) {
          switch (error.status) {
            case 401:
              this.HandleError(error)
              break;
            case 400:
              if (error.error.errors) {
                const modalStateErrors = [];
                for (const key in error.error.errors) {
                  if (error.error.errors[key]) {
                    modalStateErrors.push(error.error.errors[key])
                  }
                }
                throw modalStateErrors
              }
              else {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);
              }
              break;
            case 500:
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);//this.notifyService.show(error.statusText, 4);
              break;
            case 200:
              break;
            case 415:
              break;
            default:
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);//this.notifyService.show(error.statusText, 4);
              break;
          }
        }
        //console.log(error);
        //this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);
        return throwError(error);
      })
    );
  }
  private HandleError(err : HttpErrorResponse): Observable<any>
      {
        var tokensaving=localStorage.getItem('token');
      

        if(tokensaving !='stoken')
        {
         
        if(err.status === 401)
        {

          //this._loginService.isValid = false;
          if(localStorage["token"])
          {

            this._loginService.isValid = false;
            alert("Session expired. Please login again.");
          }
          localStorage.removeItem("token");
          this.router.navigate(['Login']);
          this._loginService.setHeaderShow(false);
          localStorage.setItem("userLogin", "false");  
          //window.location.reload();
        }
        return throwError(error);
      }
    }
}
