import { Injectable } from '@angular/core';
import { DynamicFieldBase } from '../dynamic-fields/dynamic-field-base';
import { HttpClient, HttpEventType, HttpParams, HttpRequest } from '@angular/common/http';
// import {TextboxDynamicField} from '../dynamic-fields/dynamic-field-textbox'
// import {TextAreaDynamicField} from '../dynamic-fields/dynamic-field-textarea'
// import {RadioDynamicField} from '../dynamic-fields/dynamic-field-radio'
// import {CheckboxDynamicField} from '../dynamic-fields/dynamic-field-checkbox'
// import {AutoCompleteDynamicField} from '../dynamic-fields/dynamic-field-autocomplete'
// import {SelectDynamicField} from '../dynamic-fields/dynamic-field-select'
// import {MultiSelectDynamicField} from '../dynamic-fields/dynamic-field-multiselect'
// import {DateTimeDynamicField} from '../dynamic-fields/dynamic-field-datetime'
// import {ComboBoxDynamicField} from '../dynamic-fields/dynamic-field-combobox'
// import {RichTextDynamicField} from '../dynamic-fields/dynamic-field-richtextbox'
// import {FileUploadDynamicField} from '../dynamic-fields/dynamic-field-fileupload'
// import {LabelDynamicField} from '../dynamic-fields/dynamic-field-label'
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs';
import { getBaseUrl } from '../../../main';
import { FileInfo } from '@progress/kendo-angular-upload';
import { GlobalVeriables } from 'src/app/Global/GlobalVaribales';
//import { gridProperties } from '../dynamic-fields/dynamic-field-grid/dynamic-field-grid.component';
@Injectable({
  providedIn: 'root'
})
export class DynamicFieldService {
  private REST_API_SERVER = getBaseUrl();


  private clearMultiSelectCheckAll = new BehaviorSubject<any>("");
  _multiSelectClear = this.clearMultiSelectCheckAll.asObservable();

  constructor(private httpClient: HttpClient, private GV:GlobalVeriables) { }
  getDynamicFormFields(templateID, editID, ParentFiledID, Isfromproject, inputFormFields,selectedTemplateID,IsFromAssignedUsers) {

    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes

    let params = new HttpParams();


    params = params.append('templateID', templateID);
    params = params.append('editID', editID);
    params = params.append('ParentFieldID', ParentFiledID);
    params = params.append('offset', offset);
    params = params.append('selectedTemplateID', selectedTemplateID);

    params = params.append('inputFormFields', inputFormFields);
    params = params.append('Isfromproject', Isfromproject);
    params = params.append('IsFromAssignedUsers', IsFromAssignedUsers);
    params=params.append('UserID',this.GV.UserId)
    


    return this.httpClient.get<any>(this.REST_API_SERVER + 'api/dynamic/GetAllDynamicFormFields', { params: params });
  }



  public clearDDLCheckAll(inputValue: any) {
    this.clearMultiSelectCheckAll.next(inputValue);
  }
  

  GetAllDynamicSearchFormFields(templateID, editID, ParentFiledID, Isfromproject, inputFormFields, selectedTemplateID, IsFromAssignedUsers,ControlType) {

    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes

    let params = new HttpParams();


    params = params.append('templateID', templateID);
    params = params.append('editID', editID);
    params = params.append('ParentFieldID', ParentFiledID);
    params = params.append('offset', offset);
    params = params.append('selectedTemplateID', selectedTemplateID);

    params = params.append('inputFormFields', inputFormFields);
    params = params.append('Isfromproject', Isfromproject);
    params = params.append('IsFromAssignedUsers', IsFromAssignedUsers);
    params = params.append('ControlType', ControlType);

    

    return this.httpClient.get<any>(this.REST_API_SERVER + 'api/dynamic/GetAllDynamicSearchFormFields', { params: params });
  }


  getEditTaskStatus(RecordID:number)
  {
    let params = new HttpParams();
    params = params.set('ProcessID', RecordID.toString());
    return this.httpClient.get<any>(this.REST_API_SERVER+'api/dynamic/GetEdittaskstatus',{ params: params })
  }
  getDynamicFormFieldsByParentID(templateID, ParentFiledID) {
    let params = new HttpParams();
    params = params.append('templateID', templateID);
    params = params.append('ParentFieldID', ParentFiledID);
    return this.httpClient.get<any>(this.REST_API_SERVER + 'api/dynamic/GetAllDynamicFormFieldsByParentID', { params: params });
  }
  DeleteDynamicFormFieldsdata(RoleID) {
    return this.httpClient.delete(this.REST_API_SERVER + 'api/dynamic/DeleteDynamicFormFieldData/' + RoleID);
  }
  saveDynamicFormFieldsdata(DynamicFormdataobj) {


    return this.httpClient.post(this.REST_API_SERVER + 'api/dynamic/SaveDynamicFormFieldData', DynamicFormdataobj, { responseType: 'text' });
  }
  saveDynamicReferenceFilesData(DynamicFormdataobj) {


    return this.httpClient.post(this.REST_API_SERVER + 'api/dynamic/SaveDynamicReferenceFilesData', DynamicFormdataobj, { responseType: 'text' });
  }
  saveReassigneUserData(DynamicFormdataobj) {
    return this.httpClient.post(this.REST_API_SERVER + 'api/dynamic/SaveReassingUsersTasks', DynamicFormdataobj, { responseType: 'text' });
  }


  addorUpdateDynamicFormFieldsdata(DynamicFormdataobj) {


    return this.httpClient.post(this.REST_API_SERVER + 'api/dynamic/AddUpdateDynamicFormFieldData', DynamicFormdataobj, { responseType: 'text' });
  }
  getDynamicFormFieldsdatabyId(roleID) {
    return this.httpClient.get<any>(this.REST_API_SERVER + 'api/dynamic/GetDynamicFormFieldData/' + roleID);
  }
  updateDynamicFormFieldsdata(roleobj) {
    return this.httpClient.put(this.REST_API_SERVER + 'api/dynamic/UpdateDynamicFormFieldData', roleobj);
  }

  uploadDocument() {
    //return this.httpClient.post(this.REST_API_SERVER + 'api/dynamic/uploadDocument');
    // return this.httpClient.post<any>(this.REST_API_SERVER + 'api/dynamic/uploadDocument', '');
    // return this.httpClient.post(this.REST_API_SERVER + 'api/dynamic/DynACU');
    return this.httpClient.post<any>(this.REST_API_SERVER + 'api/dynamic/DynACU', '');

  }

  RemoveDocument() {

    //return this.httpClient.get(this.REST_API_SERVER + 'api/dynamic/RemoveDocument');
    return this.httpClient.get(this.REST_API_SERVER + 'api/dynamic/DynACUR?loginUserId=' + this.GV.UserId);
  }


  checkUndefinedNullEmpty(item: any) {
    return item === undefined || item === null || item === "";
  }

  private myBehaviorSubject = new BehaviorSubject<string>('');


 setValue(value: string) {
   this.myBehaviorSubject.next(value);
 }

 getValue() {
   return this.myBehaviorSubject.asObservable();
 }

}
