import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EmailNotificationsData, ModulesData, StaticModulesData, TemplatesByModuleData, TemplatesFormFieldsData, WorkFlowData, WorkflowTasksActionsData, WorkflowTasksData, WorkflowTasksmasterData, WorkflowTasksmasterDataNames } from './workflowtasksDetails';
import { getBaseUrl } from '../../../main';
import { AuditTrailsDTOData } from '../AMFormsDataDetails';
import { LegendItemComponent } from '@progress/kendo-angular-charts';

@Injectable({
  providedIn: 'root'
})
export class WorkflowTasksManagementService {
  
  private oGetAllWorkflows = getBaseUrl() + "api/workflowtasks/GetAllWorkflows";
  private oGetStandardWorkflowTasks = getBaseUrl() + "api/workflowtasks/GetStandardWorkflowTasks";
  private oGetStandardWorkflowTasksActions = getBaseUrl() + "api/workflowtasks/GetStandardWorkflowTasksActions";

  private oGetAllWorkflowTasksmasterDataNames = getBaseUrl() + "api/workflowtasks/GetAllWorkflowTasksmasterDataNames";
  private oGetAllWorkflowTasksmasterData = getBaseUrl() + "api/workflowtasks/GetAllWorkflowTasksmasterData";
  private DeleteWorkflowMasterDataAPI = getBaseUrl() + "api/workflowtasks/DeleteWorkflowMasterData";

  private getPriorityNameDropdown = getBaseUrl() + "api/workflowtasks/getPriorityNameDropdown";

  private oGetAllModules = getBaseUrl() + "api/workflowtasks/GetAllModules";
  private oGetAllTemplatesByModule = getBaseUrl() + "api/workflowtasks/GetAllTemplatesByModule";
  private oGetAllTemplatesFormFields = getBaseUrl() + "api/workflowtasks/GetAllTemplatesFormFields";
  private oUpdateFormFieldsProperties = getBaseUrl() + "api/workflowtasks/UpdateFormFieldsProperties";

  private oGetAllEmailNotificationsData = getBaseUrl() + "api/workflowtasks/GetAllEmailNotificationsData";
  private oGetHtmlTemplate= getBaseUrl() + "api/workflowtasks/GetHtmlTemplate";
  private oGetStaticModulesData = getBaseUrl() + "api/workflowtasks/GetStaticModulesData";
  private GetUsersByGroupId = getBaseUrl() + "api/workflowtasks/GetUsersByGroupId"
  private updateWorkFlowTask = getBaseUrl() + "api/workflowtasks/UpdateWorkFlowTaskProperties";
  private updateWorkFlowMasterData = getBaseUrl() + "api/workflowtasks/UpdateWorkFlowMasterDataProperties";
  private updateWorkFlowTaskAction = getBaseUrl() + "api/workflowtasks/UpdateWorkFlowTaskActionProperties";

  public formOrderListByTemplate:any[];

  constructor(private httpClient: HttpClient) { }

  public GetStaticModulesData(CompanyID: number) {
    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<StaticModulesData[]>(this.oGetStaticModulesData, { params: params });
  }

  public GetAllModules(CompanyID: number) {
    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<ModulesData[]>(this.oGetAllModules, { params: params });
  }

  public GetAllTemplatesByModule(CompanyID: number, ModuleID: number) {
    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('ModuleID', ModuleID.toString());

    return this.httpClient.get<TemplatesByModuleData[]>(this.oGetAllTemplatesByModule, { params: params });
  }

  public GetAllEmailNotificationsData(ModuleID: number, CompanyID: number, EmailNotificationsID: number) {
    let params = new HttpParams();
    params = params.set('ModuleID', ModuleID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('EmailNotificationsID', EmailNotificationsID.toString());

    return this.httpClient.get<EmailNotificationsData[]>(this.oGetAllEmailNotificationsData, { params: params });
  }

  public GetAllTemplatesFormFields(CompanyID: number, FormFieldIDs:number, ActionID: number, DataType: number) {
    
    let params = new HttpParams();
    params = params.set('CompanyID',CompanyID);
    params = params.set('FormFieldIDs', FormFieldIDs.toString());
    params = params.set('ActionID', ActionID);
    params = params.set('DataType', DataType);
    return this.httpClient.get<TemplatesFormFieldsData[]>(this.oGetAllTemplatesFormFields, { params: params });
  }


  public GetAllWorkflowTasksmasterDataNames(CompanyID: number) {

    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<WorkflowTasksmasterDataNames[]>(this.oGetAllWorkflowTasksmasterDataNames, { params: params });
  }

  public GetAllWorkflowTasksmasterData(CompanyID: number, MastedataTypeID: number,id:number) {
   
    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('MastedataTypeID', MastedataTypeID);
    params = params.set('id', id);

    return this.httpClient.get<WorkflowTasksmasterData[]>(this.oGetAllWorkflowTasksmasterData, { params: params });
  }


  public GetAllWorkflows(CompanyID: number) {

    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<WorkFlowData[]>(this.oGetAllWorkflows, { params: params });
  }

  public GetStandardWorkflowTasks(CompanyID: number, WorkflowID: number, TaskIDs: number) {
    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('WorkflowID', WorkflowID.toString());
    params = params.set('TaskIDs', TaskIDs.toString());
    return this.httpClient.get<WorkflowTasksData[]>(this.oGetStandardWorkflowTasks, { params: params });
  }

  public GetStandardWorkflowTasksActions(CompanyID: number, WorkflowID: number, TaskIDs: number) {
    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('WorkflowID', WorkflowID.toString());
    params = params.set('TaskIDs', TaskIDs.toString());
    return this.httpClient.get<WorkflowTasksActionsData[]>(this.oGetStandardWorkflowTasksActions, { params: params });
  }

  // get the all users based on group id
  public getUsersByGroupId(groupId: number, companyId: number) {
    let params = new HttpParams();
    params = params.set('GroupId', groupId.toString());
    params = params.set('CompanyId', companyId.toString());

    return this.httpClient.get<any>(this.GetUsersByGroupId, { params: params });
  }

  public UpdateFormFieldsProperties(formFieldData : TemplatesFormFieldsData){
let body={
  FormFieldId:parseInt(formFieldData.FormFieldId),
  TemplateID:parseInt(formFieldData.TemplateID),
  //  columnSpan:formFieldData.columnSpan,
  defaultValue:formFieldData.defaultValue,
  // dependentFieldId:formFieldData.dependentFieldId.toString(),
  displayName:formFieldData.displayName,
  formOrder:parseInt(formFieldData.formOrder),
  groupId:formFieldData.groupId.toString(),
  helptext:formFieldData.helptext,
  isDependent:formFieldData.isDependent,
  isMandatory:formFieldData.isMandatory
  // controlType:'',
  // noOfRows:formFieldData.noOfRows

}
    return  this.httpClient.post<any>(this.oUpdateFormFieldsProperties, body);
  }

  public GetHtmlTemplate(templatePath: string){
    let params= new HttpParams();
    params=params.set('TemplatePath', templatePath); 
    return this.httpClient.get(this.oGetHtmlTemplate,{params: params});
  }

  public GetUTCOffset() {

    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes;

    //let params = new HttpParams();
    //params = params.append('offset', offset);

    return this.httpClient.get<any>(offset, {});
  }


  //public getUMGetAllPrivleges() {
   
  //  return this.httpClient.get(this.UMGetAllPrivleges);
  //}

  public GetDisplayFieldData(templateID: number, parentRecordID:number) {

    
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

   var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset= '+'+ hours + ':' + minutes
    

    let params = new HttpParams();
    params = params.set('templateID', templateID.toString());
    params = params.set('parentRecordID', parentRecordID.toString());
    
    params = params.append('offset', offset);

  }

  public UpdateWorkFlowTaskData(taskData: WorkflowTasksData) {
    return this.httpClient.post<any>(this.updateWorkFlowTask,taskData);
  }

  public UpdateWorkFlowTaskActionData(taskData: WorkflowTasksActionsData) {

    return this.httpClient.post<any>(this.updateWorkFlowTaskAction, taskData);
  }
 
  public Updateworkflowmasterdata(taskData: WorkflowTasksmasterData) {
        return this.httpClient.post<any>(this.updateWorkFlowMasterData,taskData);
      }

      public DeleteWorkflowMasterData(CompanyID: number, MastedataTypeID: number,id:number) {
        let body=
        {
          CompanyID:CompanyID,
          masterdatatypeid:MastedataTypeID,
          id:id
        }
            return this.httpClient.post<any>(this.DeleteWorkflowMasterDataAPI, body);
        
        }
      
  
}

class roleData {
  id: string
  roleDescription: string
  roleName: string
}

export class NotificationEmailTriggerEvent {
  ProjectInitiation:number = 1;
  ProjectStarted: number = 2;
  PreviousArtworkAvailableinDAL: number = 3;
  AnnotatedfilesrequiredfromExternalDrive: number = 4;
  NotificationGraphicArtist: number = 5;
  NotificationtoProofReader: number = 6;
  NotificationtoRA: number = 7;
  NotificationtoMKT: number = 8;
  NotificationtoPackagingApprover: number = 9;
  NotificationtoCustomerApprover: number = 10;
  NotificationtoQAReviewer: number = 11;
  NotificationtoQAApprover: number = 12;
  NotificationtoGraphicArtist: number = 13;
  NotificationtoInitiator: number = 29;
}

export class GetAuditAllFieldName {
  public GetFieldName(filedname: string, TableEnum: any) {
    var returnFieldName = filedname
    switch (TableEnum) {
      case 23:
        switch (filedname) {
          case "firstName": returnFieldName = "First Name"; break;
          case "lastname": returnFieldName = "Last Name"; break;
          case "username": returnFieldName = "User Name"; break;
          case "emailID": returnFieldName = "Email"; break;
          case "PhoneNumber": returnFieldName = "Phone Number"; break;
          case "Address": returnFieldName = "Address"; break;
          case "AdminRoleSelected": returnFieldName = "Admin Role Selected"; break;
          case "roleName": returnFieldName = "Role"; break;
          //case "Regions": returnFieldName = "Region"; break;
          case "countryName": returnFieldName = "Countries"; break;
          case "IsEsignature": returnFieldName = "Esignature"; break;
          case "IsActive": returnFieldName = "Status"; break;
          case null: returnFieldName = "Status"; break;
          case "Reason": returnFieldName = "Reason"; break;
          //case "departmentName": returnFieldName = "Departement"; break;
          case "gropuName": returnFieldName = "Groups"; break;
          case "domainName": returnFieldName = "Domain"; break;
          case "groupowner": returnFieldName = "Group Owner"; break;
        }
        break;
      case 24:
        switch (filedname) {
          case "roleName": returnFieldName = "Role"; break;
          case "GroupName": returnFieldName = "Name"; break;
          case "RoleDescription": returnFieldName = "Description"; break;
          case "RoleGroups": returnFieldName = "Select Role(s)"; break;
          case "EditAccess": returnFieldName = "Edit Access"; break;
          case "ReadOnlyAccess": returnFieldName = "ReadOnly Access"; break;
          case "NoAccess": returnFieldName = "No Access"; break;
          case "IsAdmin": returnFieldName = "Is Admin"; break;
          case "privileges": returnFieldName = "Privileges"; break;
        }
        break;
      case 22:
        switch (filedname) {
          case "GroupName": returnFieldName = "Group Name"; break;
          case "Description": returnFieldName = "Description"; break;
          case "Users": returnFieldName = "Users"; break;
          case "Type": returnFieldName = "Type"; break;
        }
        break;
      case 4:
        switch (filedname) {
          case "StatusName": returnFieldName = "Status"; break;
          case "Description": returnFieldName = "Description"; break;
        }
        break;
      case 29:
        switch (filedname) {
          case "Comments": returnFieldName = "Comments"; break;
          case "NotificationPreferences": returnFieldName = "NotificationPreferences"; break;
          case "Tasks": returnFieldName = "Bypass/Unbypass Tasks"; break;
          case "ByPassLevel": returnFieldName = "ByPass Level"; break;
          case "ListOfTasks": returnFieldName =  "List Of Tasks"; break;
          case "SuspendndResumeCycleTasks": returnFieldName =  "Suspend/Resume Cycle Tasks"; break;
        }
        break;
      default:
        switch (filedname) {
        }
        break;

    }
    return returnFieldName;
  }
}
  // Convert UTC to Local TimeZone
export class DateConvertions {
  public convertdate(date: any) {
    //loader = $rootScope.loading;
    const TZoffset = this.setUTCTimeZone();
    var parseDate = new Date(Date.parse(date));
    var aDate = new Date(parseDate.getTime() + this.setUTCTimeZoneInMinutes() * 60000);//UTC Time COnversion to local timezone
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var month = aDate.getMonth();
    var day = aDate.getDate() < 10 ? "0" + aDate.getDate() : aDate.getDate();
    var Year = aDate.getFullYear();
    var dates = day + " " + months[month] + " " + Year;
    var hours = aDate.getHours();
    hours = hours < 10 ? 0 + hours : hours;
    var minutes = aDate.getMinutes() < 10 ? "0" + aDate.getMinutes() : aDate.getMinutes();
    var seconds = aDate.getSeconds() < 10 ? "0" + aDate.getSeconds() : aDate.getSeconds();
    //var am_pm = aDate.getHours() >= 12 ? "PM" : "AM";
    //var time = hours + ":" + minutes + ":" + seconds + " " + am_pm;  
    var time = hours + ":" + minutes + ":" + seconds;
    var utctime = dates + " " + time;
    return dates + " " + time;


  }

  public setUTCTimeZone() {
    var hours;
    var setHrs = '';
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();
    const Offset = -(addTimeZoneOffSet);
    //negative offset for method
    //var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = "+" + (hours);
    
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    return hours + ':' + minutes
  }

  public setUTCTimeZoneInMinutes() {
    var currDate = new Date();
    var addTimeZoneOffSet = currDate.getTimezoneOffset();
    const Offset = -(addTimeZoneOffSet);

    return Offset


  };



}
