import { Component, Input, OnInit,NgModule, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from '../ArtworkManagement/Custom-Validator/CustomValidators';
import { LoginService } from '../services/login.service';
import { NotificationAlertsService } from '../services/notification.service';
//import { CustomValidators } from '../ArtworkManagement/Custom-Validator/CustomValidators';

import { CommonModule, DOCUMENT } from '@angular/common';
import { GlobalVeriables } from '../Global/GlobalVaribales';

// @NgModule({
//   imports: [
//     CommonModule,
//   ],
//   declarations: [],
//   providers: [],
//   exports: [],
// })
@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html'
  // styleUrls: ['./setpassword.component.css']
})
export class SetpasswordComponent implements OnInit 
{
  IsSetpassword:boolean=false;
  Islogin:boolean=false;
  status: string;
  Passwordwindow: boolean = false;
  PwdMinimumCharacters:number=0;
  SpecialCharacters:boolean=false;
  UpperCaseCharacters:boolean=false;
  NumericCharacters:boolean=false;
  LowerCaseCharacters:boolean=false;
  //registerForm: FormGroup;
  types:string ;
  key:string;
  token:boolean=false;
  @Input() public template: any;
  // @Input() public templateID: any;
  submitted:boolean=false;
  lblErrorMsgYear: any;
  constructor(@Inject(DOCUMENT) private document: Document,private notifyService: NotificationAlertsService,private formBuilder: FormBuilder,private av_router: ActivatedRoute,private _loginService: LoginService,private router:Router,private GV: GlobalVeriables) {

  //  this.GetUserSettingsData();
   }
  
  // registerForm = this.formBuilder.group({
  //   password: ['', Validators.required] ,
  //   confirmPassword: ['', Validators.required]
  // },
  // //CustomValidators.mustMatch('password', 'confirmpassword'),
  // )

  public registerForm = new FormGroup(
    {      password: new FormControl('', Validators.compose([Validators.required, CustomValidators.CustompatternValidator(this.GV.PwdMinimumCharacters,this.GV.SpecialCharacters,this.GV.UpperCaseCharacters,this.GV.NumericCharacters,this.GV.LowerCaseCharacters)])),
      confirmPassword: new FormControl('', [Validators.required])
    },

    CustomValidators.mustMatch("password", "confirmPassword") // insert here
  );
 
  
  // loginForm = this.formBuilder.group({
  //   userName: ['', Validators.required],
  //   password: ['', Validators.required],
  // })

  ngOnInit(){
    this.IsSetpassword=true;
    this.Islogin=true;

    var currentTime = new Date()
    var year = currentTime.getFullYear();    // returns the year (four digits)
    this.lblErrorMsgYear = year;
    // this.registerForm = this.formBuilder.group({
    //   password: ['', Validators.required] ,
    //   confirmPassword: ['', Validators.required]
    // },
    // CustomValidators.mustMatch('password', 'confirmpassword'),
    // )
     this.GetUserSettingsData();
  
    let Status = this.av_router.snapshot.paramMap.get("Status");
    this.status=Status;
    // this._loginService.Gettype(this.status).subscribe(res=>{
         
    //   this.status=res;
    // });
    this.key = this.av_router.snapshot.paramMap.get("key");
    if(this.key!=null)
    {
      this._loginService.Getid(this.key).subscribe(res=>{
        this.key=res;
        var Type = this.av_router.snapshot.paramMap.get("Type");
       
     this.types=Type;
    
    
    this.submitted=false;
    if(Type=="Epassword")
    {
     this.Islogin=false; 
    }
    if (Type == "Epassword") {
      this._loginService.userToken(this.key, Type).subscribe(response => {

        this.token = response;
        if (this.token) {
          this.Passwordwindow = true;
        }
      });
          
    }
    else {
      this._loginService.userToken(this.key, Type).subscribe(response => {

        this.token = response;
        if (this.token) {
          this._loginService.setclearOutsideUrl(true);
          this.Passwordwindow = true;
        }
      });
    }
      });
    }
     
   
   
   
    
        //var test =btoa(this.key);
    //alert(test);
  //var  test=atob(this.key);
      //alert(test);
   
   
   
   
  }
  GetUserSettingsData(){
    this._loginService.GetUserSettingsData().subscribe((userdata) => {

            this.GV.PwdMinimumCharacters=userdata.pwdMinCharacters;
            this.GV.SpecialCharacters=userdata.reqSpecialCharacters;
            this.GV.UpperCaseCharacters=userdata.reqUppercaseCharacters;
            this.GV.NumericCharacters=userdata.reqNumericCharacters;
            this.GV.LowerCaseCharacters=userdata.reqLowercaseCharacters;
            this.registerForm=new FormGroup(
              {      password: new FormControl('', Validators.compose([Validators.required, CustomValidators.CustompatternValidator(this.GV.PwdMinimumCharacters,this.GV.SpecialCharacters,this.GV.UpperCaseCharacters,this.GV.NumericCharacters,this.GV.LowerCaseCharacters)])),
                confirmPassword: new FormControl('', [Validators.required])
              },
          
              CustomValidators.mustMatch("password", "confirmPassword") // insert here
            );
          });
        }
  closeDialog() {
    this.Passwordwindow = false;
    this.UserlogOut();
    //this.router.navigate(['Login']);
  }
  UserlogOut() {
    //clear state of templates
    this._loginService.setHeaderShow(false);
    localStorage.setItem("userLogin", "false");
    this.router.navigate(['Login']);
   
    //window.location.reload();
    //this.router.dispose();
  }
  get f() {
    return this.registerForm.controls;
  }
  Save()
  {
    this.submitted = false;
  
    if (this.registerForm.invalid) {
      this.submitted = true;
      return false;
    }
   
    
     var password=this.registerForm.controls['password'].value;
    // password=btoa(password);
    // debugger;

    if(this.Islogin)
    {
      this._loginService.getpasswordstatus(this.key,password,'L').subscribe(response=>{

        if(response)
        {
          this.notifyService.show("Password and E-signature Passwords are same please enter different password!",4);
        }
        else
        {
          this._loginService.updatepassword('NA',password,this.key,this.Islogin).subscribe(response=>
            {
              if(response)
              {
                if(this.Islogin==true)
                {
                this.token=false;
                this.registerForm.reset();
                if(this.status=="reset")
                {
                  this.Islogin=true;
                }
                else
                {
                  this.Islogin=false;
                }
               
                
              }
              
              else
              {
                this.token=false;
                this.Islogin==false;
                this.registerForm.reset();
              }
                this.notifyService.show("Password is successfully Updated", 1);
                if(this.status=="reset")
                {
                  this.closeDialog();
                  this.Passwordwindow = false;
                   this.UserlogOut();
                  
                //  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['Login']));
                }
               // 
               
              }
             else
             {
              this.notifyService.show("Password is Already Used!", 4);
             }
             
              
              
            });
        }
      });
      
    
      }

      else
      {
        this._loginService.getpasswordstatus(this.key,password,'E').subscribe(response=>{

          if(response)
          {
            this.notifyService.show("Password and E-signature Passwords are same please enter different password!",4);
          }
          else
          {
            this._loginService.SetEsignPassword('NA',password,this.key,this.Islogin).subscribe(response=>
              {
                if(response)
                {
                  if(this.Islogin==true)
                  {
                    this.registerForm.reset();
                    this.Islogin=false;
                  }
                  
                  else
                  this.Islogin==false;
                  this.registerForm.reset();
                  this.notifyService.show("E-signature password is successfully Updated", 1);
                  this.closeDialog();
                  this.Passwordwindow = false;
                  this.UserlogOut();
                   
                
                  

                }
                else
                {
                  this.notifyService.show("Password is  already used!", 4);
                }
                
                
              });
          }
        });
       
      }
    
    //alert(this.loginForm.invalid);
  }
 

}
