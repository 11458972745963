import { Component, Input, OnInit } from '@angular/core';
import { templateData } from '../../../interfaces/ItemplateFields';
import { ModuleService } from '../../../services/module.service';
import { ReportsService } from '../../../services/reports.service';

@Component({
  selector: 'app-dynamic-field-custom-chart',
  templateUrl: './dynamic-field-custom-chart.component.html',
  styleUrls: ['./dynamic-field-custom-chart.component.css']
})
export class DynamicFieldCustomChartComponent implements OnInit {

  displaytemplates: any;
  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  @Input() public componentType: any;
  EnableFiltersSearch: boolean = false;
  SearchName:any;
  GMTLoadDateTime: string = '';

  showFilter:boolean=false;
  GMTReportCreatedTime: string = '';
  showdatetime: boolean = false;
  showSearchExpansion: boolean = true;
  ShowGraphView: boolean = false;
  ShowDataView: boolean = false;
  SelectGraphtab: boolean = true;
  SelectDatatab: boolean = false;
  EnableSearchOption: any;
  ShowFilterSearchInPopUp: boolean = false;
  EnableFilterSearchSaving: any;
  ShowSearchPopUp: boolean = false;
  ShowSearchDirect: boolean = false;
  IsSearchedApplied:any;
  reportName:any;
  reportID:any;
constructor(private moduleService: ModuleService, private reportservice: ReportsService,) { }

  ngOnInit(): void {
    this.moduleService.showFilter.subscribe(res => {
      this.showFilter = res;
    })

    this.reportservice.GMTReportCreatedTime.subscribe(response => {
      if (response) {
        this.reportservice.GetUTCDate().subscribe((dateresult) => {
          this.GMTReportCreatedTime = dateresult;
        });
      }
    });

    this.moduleService.searchTabFilters.subscribe(searchData => {
      if (searchData.templateId == 0) {
        this.showdatetime = false;

      } else {
        this.showSearchExpansion = false;

        var tempDisplayTemplates = this.displaytemplates;
        this.displaytemplates = null;
        setTimeout(() => {
     
          tempDisplayTemplates.forEach(function (searchfilters, index) {
            tempDisplayTemplates[index].searchFilters = searchData.searchFieldValues;//searchData.searchFields;
            tempDisplayTemplates[index].searchFilterValues = searchData.searchFields;//searchData.searchFieldValues;
            tempDisplayTemplates[index].searchName = searchData.searchName;
            tempDisplayTemplates[index].highlightRecordID = null;
            tempDisplayTemplates[index].selectedRecordID = null;
          }, tempDisplayTemplates);
          this.displaytemplates = tempDisplayTemplates;
        }, 100);
        this.showdatetime = true;
      }

    });

    this.reportservice.GetUTCDate().subscribe((dateresult) => {
      this.GMTLoadDateTime = dateresult;
      this.GMTReportCreatedTime = dateresult;
    });
   
   
    var tempData = new templateData();
    tempData.moduleID = parseInt(this.template.moduleID);

    
    this.showdatetime = this.template.targetTemplateId == "89" ? true : false;
    tempData.templateID = (this.template.targetTemplateId == null || this.template.targetTemplateId == "0") ? this.templateID : this.template.targetTemplateId;
    tempData.tabTemplateID = (this.template.targetTemplateId == null || this.template.targetTemplateId == "0") ? this.templateID : this.template.targetTemplateId;
    tempData.parentTemplateID = parseInt(this.template.parentTemplateID);
    tempData.parentRecordID = parseInt(this.template.parentRecordID);
    tempData.dependentTemplateID = parseInt(this.template.dependentTemplateID);
    tempData.dependentRecordID = parseInt(this.template.dependentRecordID);
    this.moduleService.GetTemplatesByTemplateID(tempData).subscribe(data => {
 

      this.reportName=data[0].templateName;
      this.reportID = data[0].templateID;

      this.SearchName = data[0].searchName;
      if (data[0].searchFilters == "" || data[0].searchFilters == [] || data[0].searchFilters == undefined) {
        this.IsSearchedApplied = "";
       } else {
        this.IsSearchedApplied =data[0].searchFilterValues;
        }
      localStorage.setItem("selectedSearchReportValues", this.IsSearchedApplied);

        this.template.searchDefaultID=data[0].searchDefaultID
        this.template.searchName=data[0].searchName;
        this.template.defaultSearchCheck=data[0].defaultSearchCheck;
      var recordID = this.template.recordID;
      var recordType = this.template.recordType;

      var searchFilters = data[0].searchFilters;
      var searchFilterValues = data[0].searchFilterValues; 
      var defaultSearchCheck = data[0].defaultSearchCheck;


      let displaycontrolids = [];
      var ChartControl = null;

      data.forEach(function (part, index) {


        if (this[index].displayControlsID == '32') {
 
          ChartControl = this[index];
        } else {
          this[index].recordID = recordID;
          this[index].selectedRecordID = recordID;
          this[index].recordType = recordType;
          this[index].searchFilters = searchFilters;
          this[index].searchFilterValues = searchFilterValues;
          this[index].defaultSearch = defaultSearchCheck;
          data.map(function (dt) {
            displaycontrolids.push(dt.displayControlsID);
          })
        }
      }, data);



      this.EnableSearchOption = ChartControl.enableSearchOption == "True" ? true : false;
      this.EnableFiltersSearch = ChartControl.enableFiltersSearch == "True" ? true : false;
      this.ShowFilterSearchInPopUp = ChartControl.showFilterSearchInPopUp == "True" ? true : false;
      this.EnableFilterSearchSaving = ChartControl.enableFilterSearchSaving == "True" ? true : false;

      if (this.ShowFilterSearchInPopUp) {
        this.ShowSearchPopUp = true;
        this.ShowSearchDirect = false;
      } else {
        this.ShowSearchPopUp = false;
        this.ShowSearchDirect = true;
      }

      if (displaycontrolids.includes('35') || displaycontrolids.includes('33')) {
        this.ShowGraphView = true;
        this.SelectGraphtab = true;
        this.SelectDatatab = false;
      }
      else {
        this.ShowGraphView = false;
        this.SelectGraphtab = false;
      }
      if (displaycontrolids.includes('13') || displaycontrolids.includes('37')) {
        this.ShowDataView = true;
        this.SelectGraphtab = false;
        this.SelectDatatab = true;
      }
      else {
        this.ShowDataView = false;
        this.SelectDatatab = false;
      }
      this.displaytemplates = data.sort((a, b) => {
        return a.tabOrder - b.tabOrder;
      });;


    });
  }


  public onExpand() {
    this.showSearchExpansion = true;
  }

  onSearchClick(searchFilters: any) {
    /**/
  }


  toggleFilter() {

    this.showFilter = !this.showFilter;
    this.IsSearchedApplied = localStorage.getItem("selectedSearchReportValues");
    }


}






