import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-signature',
  templateUrl: './e-signature.component.html',
  styleUrls: ['./e-signature.component.css']
})
export class ESignatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  submiteSignature(userid: any) {

  }

  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }

}
