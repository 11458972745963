import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// custom validator to check that two fields match



export function MustMatch(controlName: string, matchingControlName: string,matchingControlRequiredType:boolean, ControlType: number) {

  return (formGroup: FormGroup) => {
    const matchingControl = formGroup.controls[controlName];
    const control = formGroup.controls[matchingControlName];
    if (matchingControl !== undefined) {
      if (matchingControl.errors != null && Object.keys(matchingControl.errors).length === 0)
        matchingControl.setErrors(null);
    }
    //if (matchingControl.errors && !matchingControl.errors.mustMatch) {

    //  return;
    //}

    // set error on matchingControl if validation fails
    if (control !== undefined && control.value == matchingControl.value && ControlType === 1  && control.value !=="" && matchingControl.value !=="") {
      matchingControl.setErrors({ mustMatch: true });
    } else if (ControlType === 1 && control !== undefined && matchingControl !== undefined && control.value !== matchingControl.value) {
      matchingControl.setErrors(null);
      if (matchingControlRequiredType && (matchingControl.value ===null|| matchingControl.value ==="" || matchingControl.value ===undefined) ) {
        matchingControl.setErrors({ required: true });
      }
    }
    if (ControlType == 2) {
      if (control !== undefined && control.value == matchingControl.value && control.value === "272") {
        matchingControl.setErrors({ mustMatch: true });
      }
      else if (control !== undefined && matchingControl !== undefined && control.value !== matchingControl.value) {
        matchingControl.setErrors(null);
        if (matchingControlRequiredType&& (matchingControl.value ==null|| matchingControl.value =="" || matchingControl.value ==undefined)) {
          matchingControl.setErrors({ required: true });
        }
    }
  }

    //if (control !== undefined && control.value == matchingControl.value && control.value === "272" && matchingControl.value === "272" && ControlType === 2) {

    //  matchingControl.setErrors({ mustMatch: true });
    //}


  }


}
