import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { TemplatesFormFieldsData } from '../../../services/BPM/workflowtasksDetails';
import { WorkflowTasksManagementService } from '../../../services/BPM/workflowtasksmanagement.service';
import { CommonFeaturesService, ControlTypes, RqstFormGroups } from '../../../services/commonfeatures.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';


@Component({
  selector: 'app-dynamicforms-manage',
  templateUrl: './dynamicforms-manage.component.html',
  styleUrls: ['./dynamicforms-manage.component.css']
})
export class DynamicformsManageComponent implements OnInit {
  formFieldId: any;
  public templateId;
  companyid: number;
  Actionid: number;
  Datatype: number;
  valuecheckcontrol: any;
  MetadataLookUpId: any;
  metadataLookUpId: any;
  MasterDataID: number;
  ResponseTemplatesFormFieldsData: any;
  public formOrderList: any[] = [];
  public allControlTypes: ControlTypes[] = [];
  public allRqstFormGroups: RqstFormGroups[] = [];
  public isDependent: boolean;
  public isMandatory: boolean;
  public dependentFileId;
  submitted = false;
  Metadatadropdown: any;
  public displayName;
  public currentFormOrder;
  public isFormOrderExists: boolean = false;
  public templateFormFieldUpdateObj = new TemplatesFormFieldsData();
  WorkflowTaskActionsWindow: boolean = false;
  metadataDropDown: boolean = true;

  constructor(private router: Router, private av_router: ActivatedRoute,
    private formbuilder: FormBuilder, private dataservice: WorkflowTasksManagementService,
    private commonfeaturesservice: CommonFeaturesService,
    private notifications: NotificationAlertsService, private GV: GlobalVeriables,
    private adminservice: AdminUserManagementService) {
    
    this.formFieldId = this.av_router.snapshot.params["FormFieldId"];
    this.templateId = this.av_router.snapshot.params["TemplateID"];
    this.MetadataLookUpId = (this.av_router.snapshot.params["metadataLookUpId"] == "" ? 0 : this.av_router.snapshot.params["metadataLookUpId"]);

  }

  EditFieldForm = this.formbuilder.group({
    DisplayName: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]*$")]],
    ControlType: ['', [Validators.required]],
    FormOrder: ['', [Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    IsMandatory: ['', [Validators.required]],
    DefaultValue: [''],
    ColSpan: ['', [Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    RowSpan: ['', [Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    IsDependent: ['', [Validators.required]],
    DependentFile: ['', Validators.required],
    GroupName: ['', [Validators.required]],
    MetadataLookUpName: ['', [Validators.required]],
    HelpText: [''],

  });
  
  ngOnInit() {
    

    //get all control types   
    this.commonfeaturesservice.GetAllControlTypes().subscribe(data => {
     
      this.allControlTypes = data;
    })


    //get all request form groups    
    this.commonfeaturesservice.GetAllRequestFormGroups().subscribe(data => {
      this.allRqstFormGroups = data;
    })

    //get all request from metadatalookup
    this.adminservice.getUMManageAllMasterData(1, this.MetadataLookUpId, 1, 1).subscribe(data => {
      
      this.Metadatadropdown = data;
    });


    this.GetFormFieldsByTemplateId(1, this.Actionid, this.formFieldId, this.Datatype);
    this.formOrderList = this.dataservice.formOrderListByTemplate;
  }

  public GetFormFieldsByTemplateId(CompanyId: any, FormFieldIDs: any, ActionID: any, DataType: any = 1) {
    this.companyid = CompanyId;
    this.formFieldId = this.formFieldId;
    this.Actionid = this.templateId;
    this.Datatype = DataType;
    this.dataservice.GetAllTemplatesFormFields(CompanyId, this.formFieldId, ActionID, DataType).subscribe(data => {
      this.ResponseTemplatesFormFieldsData = data;
      if (data) {
        if (data[0].isDependent === 'No')
          this.isDependent = false;
        else
          this.isDependent = true;

        if (data) {
          if (data[0].isMandatory === 'No')
            this.isMandatory = false;
          else
            this.isMandatory = true;

          this.dependentFileId = parseInt(data[0].dependentFieldId);
          this.displayName = data[0].displayName;
          this.currentFormOrder = data[0].formOrder;
          this.EditFieldForm.controls['DisplayName'].setValue(data[0].displayName);
          this.EditFieldForm.controls['ControlType'].setValue(parseInt(data[0].controlType));
          this.EditFieldForm.controls['FormOrder'].setValue(parseInt(data[0].formOrder));
          this.EditFieldForm.controls['IsMandatory'].setValue(data[0].isMandatory);
          this.EditFieldForm.controls['DefaultValue'].setValue(data[0].defaultValue);
          this.EditFieldForm.controls['ColSpan'].setValue(data[0].columnSpan);
          this.EditFieldForm.controls['RowSpan'].setValue(data[0].noOfRows);
          this.EditFieldForm.controls['IsDependent'].setValue(data[0].isDependent);
          this.EditFieldForm.controls['DependentFile'].setValue(this.dependentFileId);
          this.EditFieldForm.controls['GroupName'].setValue(parseInt(data[0].groupId));
          this.EditFieldForm.controls['MetadataLookUpName'].setValue(parseInt(data[0].metadataLookUpId));
          this.EditFieldForm.controls['HelpText'].setValue(data[0].helptext);
          this.valuecheckcontrol = parseInt(data[0].controlType);

        }
        if (this.valuecheckcontrol == 2 || this.valuecheckcontrol == 4 || this.valuecheckcontrol == 21) {
          this.metadataDropDown = true;
        }
        else {
          this.metadataDropDown = false;
        }
      }
    });
  }
  switchDependentFIle(checked:boolean){
    if(checked){
      this.EditFieldForm.controls['DependentFile'].setValue(this.dependentFileId);
      this.isDependent=true;
    }else{
      this.EditFieldForm.controls['DependentFile'].setValue(null);
      this.isDependent=false;
    }
  }

  UpdateFieldProperties(formFieldId: any) {

    if (this.EditFieldForm.invalid) {
      this.submitted = true;
      return;
    }
    this.templateFormFieldUpdateObj.TemplateID = this.templateId;
    this.templateFormFieldUpdateObj.FormFieldId = this.formFieldId;
    this.templateFormFieldUpdateObj.displayName = this.EditFieldForm.controls['DisplayName'].value;
    this.templateFormFieldUpdateObj.formOrder = this.EditFieldForm.controls['FormOrder'].value;
    this.templateFormFieldUpdateObj.isMandatory = this.EditFieldForm.controls['IsMandatory'].value;
    this.templateFormFieldUpdateObj.defaultValue = this.EditFieldForm.controls['DefaultValue'].value;
    this.templateFormFieldUpdateObj.isDependent = this.EditFieldForm.controls['IsDependent'].value;
    this.templateFormFieldUpdateObj.groupId = this.EditFieldForm.controls['GroupName'].value;
    this.templateFormFieldUpdateObj.MetadataLookUpId = this.EditFieldForm.controls['MetadataLookUpName'].value;
    this.templateFormFieldUpdateObj.helptext = this.EditFieldForm.controls['HelpText'].value;
    this.dataservice.UpdateFormFieldsProperties(this.templateFormFieldUpdateObj).subscribe();
    
    this.notifications.show("Dynamic Forms Information Updated Successfully");


    this.router.navigate(['/DynamicForms/', this.templateId]);
  }

  onFormOrderKeypressEvent(event: any) {
    this.isFormOrderExists = false;
  }

  cancelUserData() {

  }
}


