export class WorkFlowData {
  workFlowName: string;
  workFlowID: string;
}

export class WorkflowTasksmasterDataNames {
  name: string;
  Id: string;
}

export class ModulesData {
  moduleID: string;

  moduleName: string;
  ParentModuleID: string;

  ParentModuleName: string;
  DefaultHeader: string;
  TNavigationText: string;

  MasterModuleID: string;
  MasterModuleName: string;
}

export class TemplatesByModuleData {
  templateID: string;
  templateName: string;
  templateType: string;
  TemplateHeading: string;
  HasNested: string;
  IsPublished: string;
  MaximumOuterDisplayField: string;
  TemplateClass: string;
  TemplateOrder: string;
  IsDependent: string;
  HasParent: string;
  ModuleID: string;
  DisplayTemplateID: string;
  DisplayControlsID: string;

  EnableSearchOption: string;
  ExternalIntegration: string;
  DefaultHeader: string;
  IsFilter: string;
  EnableMultiSelect: string;

  IsPaging: string;
  IsSorting: string;
  IsExportPDF: string;
  IsExportExcel: string;
  ShowInActiveRecords: string;

  ShowProgressBar: string;
  NoOfRecordsMsg: string;
  ModuleClass: string;
}

export class TemplatesFormFieldsData {
  FormFieldId: string;
  TemplateID: string;
  FieldName: string;
  displayName: string;
  controlType: string;
  ControlTypeName: string;
  formOrder: string;
  isMandatory: string;
  defaultValue: string;
  MetadataLookUpId: string;
  metadataLookUpId:any;
  MetadataLookUpIdFilters: string;
  AutoGenerateMetadataId: string;
  SqlSource: string;

  columnSpan: string;
  noOfRows: string;
  isDependent: string;
  dependentFieldId: string;
  groupId: string;

  isNumericOnly: string;
  helptext: string;
  Classes: string;
  IsDisabledField: string;
  IsDisplayField: string;

  DisplayOrder: string;
  NoOfRecordsMsg: string;
  ModuleClass: string;

  IsMultipleFilesUpload: string;
  howInFinalizedReport: string;
  IsInitiateField: string;
  IsWorkfloField: string;
  WorkflowMandatory: string;
  WorkFlowTaskFieldId: string;
  UseThisInNotification: string;
  IsDependancyLookupField: string;
  ValidationRule: string;
  MinimumLength: string;
  MaximunLength: string;
  IsUnique: string;
  IsCarryForward: string;
  CarryFieldId: string;
  CarryFieldTemplateId: string;
  CustomValidateMessage: string;

  groupName: string;

  actionName: string;
  inputFormFields: string;
  actionID: string;
}


export class WorkflowTasksmasterData {

  ID:number;
  Priority: number;
ProcessTemplateId:number;
RequestSubCategoryID:number;
WorkflowID:number;
WFTID:number;
Type:number;
  PriorityName: string;
  Workflow: string;
  TaskName: string;
  TypeOfPriority: string;
  PriorityDuration: string;
  DynamicWorkflow: string;
  ProcessTemplate: string;
 masterdatatypeid:number;
  RequestSubCategory: string;
  TypeOfTask: string;
typeOfPriority:string;
}

export class WorkflowTasksData {
  TaskId: number;
  PreviousTask: string;
  CurrentTask: string;
  NextTask: string;
  TaskAliasName: string;
  IsCycle: boolean;
  RejectedParentTask: number;
  ParentComponentFileTask: string;
  TypeOfTask: string;
  TaskLogoRClass: string;
  ProcessFlow: number;
  StepNumber: number;
  SequenceID: number;
  WorkflowTaskId: string;
  DepartmentName: string;
  GroupName: string;
  UserName: string;
  WorkflowID: number;
  workflowName: string;
  IsCyclevalue: boolean;
  IsTaskEditable: boolean;
  IsTaskByPass: boolean;
  IsSetTaskOwner: boolean;
  IsAutoCompleteTask: boolean;
  IsAddApproversAction: boolean;
  IsSuspendCycle: boolean;
  IsFinalTask: boolean;
  EnableTaskEditFields: boolean;
  EnableProjectSummaryFields: boolean;
  EnableSubprocessEditFields: boolean;
  EnableEditSubscribeFields: boolean;
  EnableSubprocessIDis: boolean;
  RoleId: any;
  GroupId: any;
  TaskOwner: any;
  RejectWorkFlowTaskID: number;
  RejectedParentTaskid:any;

}

export class WorkflowTasksActionsData {

  ActionID: string;
  ActionName: string;
  ActionEvent: string;
  WorkFlowTaskID: string;
  TaskName: string;
  EmailNotificationsMasterID: string;
  EmailTemplate: string;
  IseSignatureEnabled: string;
  ActionTypeID: string;
  ActionType: string;
  StepNumber: string;
  IntegrationType: string;
  ActionClass: string;
  workflowName: string;

}

export class AuditDashboardForm
{
  FieldName: string;
  valueBefore: string;
  valueAfter: string;
}

export class AduitForm
{
  userId: string;
  eventDate: string;
  actionType: string;
  changes: string;
  FieldName: string;
  ValueBefore: string;
  ValueAfter: string;
}


export class PDfExpAuditSave {
  htmlcontent: string;
  offset: string;
  TableEnum: string;
  FilePath: string;
  Message: string;
  IsSuccess: boolean;
  AuditFile: string;
}

export class EmailNotificationsData {
  EmailNotificationsID: string;
  ModuleID: string;
  TemplateName: string;
  MasterTemplateView: string;
  Subject: string;
  MailBody: string;
  EmailType: string;
  FromEmail: string;
  ToUsers: string;
  CCUsers: string;
  BCCUsers: string;
  ExternalUsers: string;
  ActionURLID: string;

  ActionURL: string;

  EmailNotDataID: string;
  FormFieldID: string;
  DisplayName: string;
  FieldNameHolder: string;
  FieldName: string;
  FieldOrder: string;
  IsStandardColumnID: string;
  IsStandardColumn: string;
  SQLSource: string;
  FieldUIClassess: string;
}

export class StaticModulesData {
  ID: string;
  Name: string;
  RoutingName: string;
  ParentID: string;
  Alias: string;
  PrimayClass: string;
  Class: string;
  ActionEvent: string;
}

export class UserGroupsDetails
{
  //GroupName: string;
 // ID: string;
  GroupDesc: string;
  //CompanyID: string;
  Name: string;
}
