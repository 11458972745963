import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationAlertsService } from '../../../services/notification.service';
import { WorkflowTasksmasterData, WorkflowTasksmasterDataNames } from '../../../services/BPM/workflowtasksDetails';
import { WorkflowTasksManagementService } from '../../../services/BPM/workflowtasksmanagement.service';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormControl } from '@angular/forms';
import { numberSymbols } from '@progress/kendo-angular-intl';
import { AdminUserManagementService } from 'src/app/services/adminusermanagement.service';

@Component({
  selector: 'app-workflowtasksmasterdata-list',
  templateUrl: './workflowtasksmasterdata-list.component.html',
  styleUrls: ['./workflowtasksmasterdata-list.component.css']
})
export class WorkflowtasksmasterdataListComponent implements OnInit {

  ResponseWorkflowTasksmasterData: WorkflowTasksmasterData[] = [];
  ResponseWorkflowTasksmasterDataNames: WorkflowTasksmasterDataNames[] = [];
  WorkflowID: number = 0;
  wftid:number;
  ResponseWorkflowTasksmasterDataitem:any;
  companyId:number;
  processFlow:any;
  
  updatedtWorkflowmasterdata = {} as WorkflowTasksmasterData;
  dynamicworkflow:any;
  selecctedProcessFlow:any;
  selectedDepartment:any;
  timelinedata:any;
  submitted = false;
  getpriorityname:any
  dynamicWorkflow:any;
  gridata:any;
  workflowdata:any;
  id:number=0;
  process:any;
  subcategory:any;
  masterdata:any;
  masterDataName:any;
  mastedatatypeId:number;
  WorkFlowName: any;
  tasknamedata:any;
  WorkFlowTaskName: any;
  WorkflowTaskmasterdataWindow: boolean = false;
  WorkflowtasksmasterdataID: number = 0;
  WorkflowTasksmasterDataNames: string;
  processTemplate:any=[]
  requestSubCategory:any=[]
  priorityName:any=[]
  workflow:any=[]
  taskName:any=[]
  typeOfTask:any=[]
  typeOfPriority:any=[]
  getByID:number;
  opened:boolean=false;



  constructor(private http: HttpClient, private av_router: ActivatedRoute, private router: Router, private dataservice: WorkflowTasksManagementService
    , private notifications: NotificationAlertsService, private formbuilder: FormBuilder, private admnService:AdminUserManagementService) {


  }

  WorkFlowMasterDataEditDyForm = this.formbuilder.group({
    DynamicWorkflow: ['', [Validators.required]],
    ProcessTemplate:['',[Validators.required]],
    RequestSubCategory:['',[Validators.required]],
    TaskName:['',],
    ProcessFlow: ['',],
    PriorityName:['',],
    PriorityDuration:['',],
    WorkFlow:['',],
    TypeOfPriority:['',],
    TaskNamee:['',],

    
  });

  ngOnInit() {
    this.loadWorkFlowData(1);
     this.getProcessFlow();
     this.GetPriorityDropDown();
     this.GetProcessTemplateDropDown();
     this.GetRequestSubCategoryDropDown();
     this.GetWorkflowDropDown();
     this.GetTaskNameDropDown();
     this.GetTimelineInDropDown();
  }

  GetPriorityDropDown()
  {
    this.admnService.getUMManageAllMasterData(1, 9,1, 1).subscribe(data =>{
      this.masterDataName=data
    
     });
  }

  GetProcessTemplateDropDown()
  {
    this.admnService.getUMManageAllMasterData(1, 1,1, 1).subscribe(data =>{
      this.process=data

     });
  }

  GetRequestSubCategoryDropDown()
  {
    this.admnService.getUMManageAllMasterData(1, 4,1, 1).subscribe(data =>{
      this.subcategory=data
     
     });
  }

  GetTimelineInDropDown()
  {
    this.timelinedata = [{type : 0, name: 'Days' },
    { type: 1, name: 'Hours' },
    { type: 2, name: 'Minutes' }
    ]
  }

  GetWorkflowDropDown()
  {
    this.dataservice.GetAllWorkflows(1).subscribe(data =>{
      this.workflowdata=data

     });
  }

  GetTaskNameDropDown()
  {
    this.dataservice.GetAllWorkflowTasksmasterData(1,32,0).subscribe(data =>{
      this.taskName=data;
      
     });
  }

  closeDialog()
  {
    this.opened=false;
  }
  openDialog(CompanyID:any=1,WorkflowtasksmasterdataID:any,id:number)
  {
    this.opened=true;
    this.companyId=CompanyID;
    this.WorkflowtasksmasterdataID= this.WorkflowtasksmasterdataID;
    this.id=id;
  }
  getProcessFlow() {
    this.processFlow = [{ id: 1, name: 'Project Level' },
    { id: 2, name: 'Sub Process Level' },
    { id: 3, name: 'Workflow Level' }
    ];
  }


  loadWorkFlowData(CompanyID: number) {
    // showLoader :true
    this.dataservice.GetAllWorkflowTasksmasterDataNames(CompanyID).subscribe(data => {
      //console.log("data", data);
      this.ResponseWorkflowTasksmasterDataNames = data;
      // this.WorkflowTasksmasterDataNames = this.ResponseWorkflowTasksmasterDataNames[0].name;

     if (this.WorkflowtasksmasterdataID==30||this.WorkflowtasksmasterdataID==31||this.WorkflowtasksmasterdataID==32)//dataItem.dataItem.id;
      this.dataservice.GetAllWorkflowTasksmasterData(1, this.WorkflowtasksmasterdataID,0).subscribe(data => {
        //console.log("data", data);
        this.ResponseWorkflowTasksmasterData = data;
        this.WorkflowTasksmasterDataNames = this.ResponseWorkflowTasksmasterDataNames[0].name;

      });

    });
  }


  public handleSelection(dataItem): void {
    this.WorkFlowMasterDataEditDyForm.controls["DynamicWorkflow"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["ProcessTemplate"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["RequestSubCategory"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["TaskName"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["ProcessFlow"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["PriorityName"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["PriorityDuration"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["WorkFlow"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["TypeOfPriority"].clearValidators();
    this.WorkFlowMasterDataEditDyForm.controls["TaskNamee"].clearValidators();

    this.WorkflowtasksmasterdataID = dataItem.dataItem.id;
    if (dataItem.dataItem.id == 30){
      this.WorkFlowMasterDataEditDyForm.controls["DynamicWorkflow"].addValidators(Validators.required);
      this.WorkFlowMasterDataEditDyForm.controls["ProcessTemplate"].addValidators(Validators.required);
      this.WorkFlowMasterDataEditDyForm.controls["RequestSubCategory"].addValidators(Validators.required);

      this.loadWorkFlowData(1);
    }
    else if (dataItem.dataItem.id == 31){
      this.WorkFlowMasterDataEditDyForm.controls["TaskName"].addValidators(Validators.required);
      this.WorkFlowMasterDataEditDyForm.controls["ProcessFlow"].addValidators(Validators.required);

      this.loadWorkFlowData(1);
    }
    if (dataItem.dataItem.id == 32){
      this.WorkFlowMasterDataEditDyForm.controls["PriorityName"].addValidators(Validators.required);
      this.WorkFlowMasterDataEditDyForm.controls["PriorityDuration"].addValidators(Validators.required);
      this.WorkFlowMasterDataEditDyForm.controls["WorkFlow"].addValidators(Validators.required);
      this.WorkFlowMasterDataEditDyForm.controls["TypeOfPriority"].addValidators(Validators.required);
      this.WorkFlowMasterDataEditDyForm.controls["TaskNamee"].addValidators(Validators.required);
      this.loadWorkFlowData(1);
    }
    // this.WorkflowtasksmasterdataID = dataItem.dataItem.id;
    this.WorkflowTasksmasterDataNames = dataItem.dataItem.name;

    this.dataservice.GetAllWorkflowTasksmasterData(1, this.WorkflowtasksmasterdataID,0).subscribe(data => {
      //console.log("data", data);
      this.ResponseWorkflowTasksmasterData = data;
      //this.WorkFlowName = data[0].WorkflowName;
    });
  }

 public GetWorkflowTasksActionsData(CompanyID:any=1,WorkflowtasksmasterdataID:any,id:number){
 
   this.companyId=CompanyID;
   this.WorkflowtasksmasterdataID= this.WorkflowtasksmasterdataID;
   this.id=id;
   this.dataservice.GetAllWorkflowTasksmasterData(CompanyID,  this.WorkflowtasksmasterdataID,id).subscribe(data => {

    this.ResponseWorkflowTasksmasterDataitem=data[0];
    this.getByID= this.id;
    this.WorkFlowMasterDataEditDyForm.controls['DynamicWorkflow'].setValue(this.ResponseWorkflowTasksmasterDataitem.dynamicWorkflow);
    this.WorkFlowMasterDataEditDyForm.controls['ProcessTemplate'].setValue(this.ResponseWorkflowTasksmasterDataitem.processTemplateId);
    this.WorkFlowMasterDataEditDyForm.controls['RequestSubCategory'].setValue(this.ResponseWorkflowTasksmasterDataitem.requestSubCategoryID);
    this.WorkFlowMasterDataEditDyForm.controls['TaskName'].setValue(this.ResponseWorkflowTasksmasterDataitem.taskName);
    this.WorkFlowMasterDataEditDyForm.controls['ProcessFlow'].setValue(this.ResponseWorkflowTasksmasterDataitem.typeOfTask);
    this.WorkFlowMasterDataEditDyForm.controls['PriorityName'].setValue(this.ResponseWorkflowTasksmasterDataitem.priority);
    this.WorkFlowMasterDataEditDyForm.controls['WorkFlow'].setValue(this.ResponseWorkflowTasksmasterDataitem.workflowID);
    this.WorkFlowMasterDataEditDyForm.controls['TaskNamee'].setValue(this.ResponseWorkflowTasksmasterDataitem.wftid);
    this.WorkFlowMasterDataEditDyForm.controls['TypeOfPriority'].setValue(this.ResponseWorkflowTasksmasterDataitem.typeOfPriority);
    this.WorkFlowMasterDataEditDyForm.controls['PriorityDuration'].setValue(this.ResponseWorkflowTasksmasterDataitem.priorityDuration);
    var flow = this.ResponseWorkflowTasksmasterDataitem.typeOfTask;
      if (flow) {
        let selected = this.processFlow.find(function getProcess(processdata) {
          return processdata.name === flow;
        });
        this.selecctedProcessFlow = selected.id;

      }
      else {
        this.selecctedProcessFlow = flow;
      }
    this.WorkflowTaskmasterdataWindow = true;
   });
   
  }

  updatetWorkflowmasterdata(WorkflowtasksmasterdataID:any,id:number){  
     
    if (this.WorkFlowMasterDataEditDyForm.invalid) {
      this.submitted = true;
      return;
    }
    this.updatedtWorkflowmasterdata.masterdatatypeid=WorkflowtasksmasterdataID;
    this.updatedtWorkflowmasterdata.ID=id;
    this.updatedtWorkflowmasterdata.DynamicWorkflow = (this.WorkFlowMasterDataEditDyForm.controls['DynamicWorkflow'].value == null ? "":this.WorkFlowMasterDataEditDyForm.controls['DynamicWorkflow'].value.toString());
    this.updatedtWorkflowmasterdata.ProcessTemplateId =(this.WorkFlowMasterDataEditDyForm.controls['ProcessTemplate'].value==null?0: this.WorkFlowMasterDataEditDyForm.controls['ProcessTemplate'].value);
    this.updatedtWorkflowmasterdata.RequestSubCategoryID = (this.WorkFlowMasterDataEditDyForm.controls['RequestSubCategory'].value==null ? 0:this.WorkFlowMasterDataEditDyForm.controls['RequestSubCategory'].value);
    this.updatedtWorkflowmasterdata.TaskName = (this.WorkFlowMasterDataEditDyForm.controls['TaskName'].value==null ? "":this.WorkFlowMasterDataEditDyForm.controls['TaskName'].value.toString());
    this.updatedtWorkflowmasterdata.TypeOfTask = (this.WorkFlowMasterDataEditDyForm.controls['ProcessFlow'].value==null ? "":this.WorkFlowMasterDataEditDyForm.controls['ProcessFlow'].value.toString());
    this.updatedtWorkflowmasterdata.Priority = (this.WorkFlowMasterDataEditDyForm.controls['PriorityName'].value.toString()==null ? 0:this.WorkFlowMasterDataEditDyForm.controls['PriorityName'].value);
    this.updatedtWorkflowmasterdata.WorkflowID = (this.WorkFlowMasterDataEditDyForm.controls['WorkFlow'].value==null ? 0:this.WorkFlowMasterDataEditDyForm.controls['WorkFlow'].value);
    this.updatedtWorkflowmasterdata.WFTID = (this.WorkFlowMasterDataEditDyForm.controls['TaskNamee'].value==null ? 0:this.WorkFlowMasterDataEditDyForm.controls['TaskNamee'].value);
    this.updatedtWorkflowmasterdata.typeOfPriority = (this.WorkFlowMasterDataEditDyForm.controls['TypeOfPriority'].value==null ? "":this.WorkFlowMasterDataEditDyForm.controls['TypeOfPriority'].value);
    this.updatedtWorkflowmasterdata.PriorityDuration = (this.WorkFlowMasterDataEditDyForm.controls['PriorityDuration'].value==null ? "":this.WorkFlowMasterDataEditDyForm.controls['PriorityDuration'].value);
    
     this.dataservice.Updateworkflowmasterdata(this.updatedtWorkflowmasterdata).subscribe();
     
     this.loadWorkFlowData(1);
     this.notifications.show("Workflow Master Data Information Updated Successfully",1);
     this.WorkflowTaskmasterdataWindow = false;

     this.loadWorkFlowData(1);
  }

  DeleteWorkflowTasksActionsData()
  {
    // this.companyId=CompanyID;
    // this.WorkflowtasksmasterdataID= this.WorkflowtasksmasterdataID;
    // this.id=id;
    this.dataservice.DeleteWorkflowMasterData(1, this.WorkflowtasksmasterdataID,this.id).subscribe();
      
      this.opened=false;
      this.notifications.show("Workflow Master Data Information Deleted Successfully",4);
      this.loadWorkFlowData(1);

    
  }

  public onTabSelect(e: SelectEvent) {
    this.masterdata=e.title;
    if (e.title == 'Standard Workflows') {
      this.dataservice.GetAllWorkflowTasksmasterData(this.companyId, this.WorkflowtasksmasterdataID,this.id).subscribe(data => {
        this.ResponseWorkflowTasksmasterData = data;
        this.gridata = data;
      });
    }
  }

  countriesClose(){
    this.WorkflowTaskmasterdataWindow=false;
  }

}
