import { Component, EventEmitter, Output } from '@angular/core';
import { ModuleService } from '../services/module.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { getLocaleFirstDayOfWeek } from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {


  masterModuleID: number;
  masterModules: any;
  showMasterModules: boolean = false;
  selectedMasterModule: any;
 

  constructor(private moduleService: ModuleService, private http: HttpClient, private router: Router) { }

  ngOnInit() {

   


    this.moduleService.getMasterModules().subscribe(data => {

      this.masterModules = data;

      if (this.masterModules.length>0) {


        this.selectedMasterModule = data[0];
      this.masterModuleID = data[0].id;

      this.moduleService.passMasterModulueID(this.masterModuleID);
 }

    })

  }


  onShowMasterModules() {

    this.showMasterModules = true;


  }

  onMasterModuleClick(module) {
   
   
    this.masterModuleID = module.id;

    this.selectedMasterModule = module;
    

    this.moduleService.passMasterModulueID(this.masterModuleID);
   
    this.showMasterModules = false;

  }
  
}

