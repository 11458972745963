import { Component, Input, OnInit } from '@angular/core';
import { escapeSelector } from 'jquery';
import { Subscription } from 'rxjs';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { breadCrumbs, templateData } from '../../../interfaces/ItemplateFields';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { ModuleService } from '../../../services/module.service';

@Component({
  selector: 'app-dynamic-field-splitter',
  templateUrl: './dynamic-field-splitter.component.html',
  styleUrls: ['./dynamic-field-splitter.component.css']
})
export class DynamicFieldSplitterComponent implements OnInit {
  displaytemplates: any;
  alldisplaytemplates: any;
  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  @Input() public componentType: any;

  dependentSplitterUnSub: Subscription;

  breadcrumbs: Array<breadCrumbs>;

  selectedTab: any;
  @Input() public ID: any;

  constructor(private moduleService: ModuleService, private amService: AdminUserManagementService, private GV: GlobalVeriables,) { }

  ngOnInit() {

    debugger;
    this.moduleService.searchTabFilters.subscribe(searchData => {
      if (searchData.templateId == 0) {

      } else {



        //alert(searchData.searchFields);
        var tempDisplayTemplates = this.alldisplaytemplates;
        this.alldisplaytemplates = null;
        setTimeout(() => {

          if (tempDisplayTemplates !== null && tempDisplayTemplates !== "" && tempDisplayTemplates.length > 0) {
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].searchFilters = searchData.searchFields;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].defaultSearch = searchData.defaultSearch;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].searchFilterValues = searchData.searchFieldValues;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].searchName = searchData.searchName;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].chkFields = searchData.chkFields;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].chkFieldValues = searchData.chkFieldValues;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].highlightRecordID = null;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].selectedRecordID = null;
          }


          this.alldisplaytemplates = tempDisplayTemplates;//.filter(a => a.hasParent == 0 && a.isDependent == 0 && a.displayControlsID != 0 && a.moduleID == this.moduleID);;

          this.displaytemplates = this.alldisplaytemplates;


          this.selectedTab = tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].id;

        }, 100);
      }

    });

    var tempData = new templateData();
    tempData.moduleID = parseInt(this.template.moduleID);
    tempData.templateID = this.templateID;
    tempData.tabTemplateID = this.templateID;
    tempData.parentTemplateID = parseInt(this.template.parentTemplateID);
    tempData.parentRecordID = parseInt(this.template.parentRecordID);
    tempData.dependentTemplateID = parseInt(this.template.dependentTemplateID);
    tempData.dependentRecordID = parseInt(this.template.dependentRecordID);


    this.moduleService.GetTemplatesByTemplateIDForSplitter(tempData).subscribe(data => {



      var recordID = this.template.recordID;

      var recordType = this.template.recordType;
      var collectionId = this.template.collectionId;
      var searchFilters = this.template.searchFilters;
      var searchFilterValues = this.template.searchFilterValues;

      var defaultSearchCheck = this.template.defaultSearchCheck;
      var chkFields = this.template.chkFields;
      var chkFieldValues = this.template.chkFieldValues;
      var chkDisplayControl = this.template.chkDisplayControl;




      data.forEach(function (part, index) {
        this[index].recordID = recordID;
        this[index].selectedRecordID = recordID;
        this[index].recordType = recordType;
        this[index].collectionId = collectionId;
        this[index].searchFilters = searchFilters;
        this[index].defaultSearch = defaultSearchCheck;
        this[index].searchFilterValues = searchFilterValues;

        if (this[index].displayControlsID == chkDisplayControl) {
          this[index].chkFields = chkFields;
          this[index].chkFieldValues = chkFieldValues;
        }
        //tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilters = searchData.searchFields;
        //tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilterValues = searchData.searchFieldValues;

      }, data);

      debugger;
      this.alldisplaytemplates = data;
      data[0].splitterClass = "100";
      this.displaytemplates = [data[0]];

      

 

    });
    this.moduleService.splitterTemplate.subscribe(dalFileDat => {

      debugger;
      let allTemplates = this.alldisplaytemplates;
      allTemplates[0].splitterClass = "leftside-splitter";
      allTemplates[1].splitterClass = "rightside-splitter";


      allTemplates[1].recordID = dalFileDat["ID"];
      allTemplates[1].selectedRecordID = dalFileDat["ID"];

      allTemplates[1].parentRecordID = dalFileDat["ProjectID"];


      //debugger;
      if (this.displaytemplates.length == this.alldisplaytemplates.length) {



        this.displaytemplates.splice(1, 1);

        this.displaytemplates.push(allTemplates[1]);

        this.displaytemplates[0].highlightRecordID = dalFileDat["ID"];
        var tempDisplayTemplates = this.displaytemplates;

        tempDisplayTemplates.forEach(function (part, index) {

          tempDisplayTemplates[index].recordType = dalFileDat["ProjectID"];
          tempDisplayTemplates[index].collectionId = dalFileDat["ProjectID"];
        }, tempDisplayTemplates);


        this.displaytemplates = null;


        setTimeout(() => {

          this.displaytemplates = tempDisplayTemplates;

          debugger;
 

        }, 10);



      } else {


        this.displaytemplates.push(allTemplates[1]);

      }







    });


    this.dependentSplitterUnSub= this.moduleService.dependentSplitter.subscribe(dataDependent => {
 
 
       
      if (dataDependent["recordID"] != 0 && dataDependent["recordID"] != undefined && dataDependent["recordID"] != null) {
        
      
      this.displaytemplates[0].highlightRecordID = dataDependent["recordID"];

      var tempTemplates = this.displaytemplates;




      this.displaytemplates = null;

      setTimeout(() => {
        this.displaytemplates = tempTemplates;

      }, 10);


    }


    });

    this.moduleService.breadCrumbs.subscribe(breadcrumbs => {



      var templateID = this.template.templateID;
      var displayControlsID = this.template.displayControlsID;
      var depeendentTemplateID = this.template.isDependent;
      var parentTemplateID = this.template.hasParent;


      this.breadcrumbs = breadcrumbs.filter(a => a.templateId == depeendentTemplateID || a.templateId == parentTemplateID);//|| (a.templateId == templateID && a.displayControlsID == displayControlsID)

      //this.breadcrumbs.filter(a => a.templateId == templateID && a.displayControlsID == displayControlsID)[0].displayName = "darshan";


    });

    this.moduleService.breadCrumbName.subscribe(breadcrumb => {

      if (!this.GV.GetIsRedirection()) 
      if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {


        this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;

        }
      

    });
  }


  getShortName(strText) {
    var strTxt = "";
    if (strText.length >= 50)
      strTxt = strText.slice(0, 50).concat('...');
    else
      strTxt = strText;
    return strTxt;
  }
  onTabClick(selectedTab) {
    this.selectedTab = selectedTab;

  }

  checkUserPrivilege(actionEventID, LevelID) {
    return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);

  }

  ngOnDestroy() {
    this.dependentSplitterUnSub.unsubscribe();
  
  }
}
