import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { cascadingField } from '../../../interfaces/ItemplateFields';
import { ModuleService } from '../../../services/module.service';

@Component({
  selector: 'app-dynamic-multi-select-check-box',
  templateUrl: './dynamic-multi-select-check-box.component.html',
  styleUrls: ['./dynamic-multi-select-check-box.component.css']
})
export class DynamicMultiSelectCheckBoxComponent implements OnInit {

  @Input() public DynamicField: any;
  @Input() public dynamicForm: FormGroup;
  
   
  public listItems: any;
  public data: any;
  public value: any = [];
  public cascadingField: cascadingField;
  selectAllValue;

  constructor(private dynFieldservice: DynamicFieldService, private moduleService: ModuleService) {
    this.dynFieldservice._multiSelectClear.subscribe(response => {
      if (response != "")
        this.onClick(response);
    })
  }

 

  ngOnInit() {
     
    this.data = this.listItems = this.DynamicField.options;


    if (this.DynamicField.value == null || this.DynamicField.value == undefined || this.DynamicField.value=="") {
      this.value = [];

    } else {
      this.value =   JSON.parse(this.DynamicField.value);
      this.isChecked = this.value.length === this.listItems.length;

    }

  }

  

  public isChecked = false;

  public get isIndet() {
    return (
      this.value.length !== 0 && this.value.length !== this.listItems.length
    );
  }

  public get toggleAllText() {
    if(this.isChecked){
      this.selectAllValue="Deselect All";
      return 'STC_DSALL';
    }else{
      this.selectAllValue="Select All";
      return 'STC_SALL';
    }
  }

  public isItemSelected(item) {
     


    return this.value.some((x) => x.value === item.value);
  }

  public loadSelected(item) {
     

    return this.value.some((x) => x.value === item.value);
  }

  public onClick(inputValue: any) {
    if (inputValue === "FromReset") {
      this.isChecked = false;
      this.value = [];
    }
    else {
      this.isChecked = !this.isChecked;
      this.value = this.isChecked ? this.listItems.map(obj => obj.value) : [];
    }
  }

  public valueChange(dynamicField, event) {
   
  

    this.isChecked = this.value.length === this.listItems.length;

   var objcascadingField = new cascadingField();

   objcascadingField.dynamicField = dynamicField;
    objcascadingField.selectedValues = event;

    this.moduleService.passupdateCascading(objcascadingField)

  }

  public filterChange(filter: any): void {
    this.listItems = this.data.filter(
      (s) => s.key.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
}
