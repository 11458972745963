import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../../../services/module.service';
import { AduitForm, AuditDashboardForm, MasterData, MasterDataInput } from '../../../services/AMFormsDataDetails';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
@Component({
  selector: 'app-add-masterdata',
  templateUrl: './add-masterdata.component.html',
  styleUrls: ['./add-masterdata.component.css']
})
export class AddMasterdataComponent implements OnInit {

  roleDialog: boolean;
  PriviligeData: MasterData[] = [];
  objMasterDataInput = new MasterDataInput();
  showLoader = false;
  submitted = false;
  ddlmsprivilegeName = new FormControl([20]);
  OperationType: number = 1;
  selectedItems: any;
  privDataOpearation: number;
  savebtn: boolean = false;
  updatebtn: boolean = false;
  masterDataID: number = 0;;
  MasteDataTypeID: number;
  MasteDataType: string;
  dynMasterDataHeader: string = null;
  CompanyID: number = 0;

  constructor(private moduleService: ModuleService, private http: HttpClient, private av_router: ActivatedRoute, private formBuilder: FormBuilder,private router: Router, private amService: AdminUserManagementService
    , private commonfeaturesservice: CommonFeaturesService, private notifications: NotificationAlertsService, private GV: GlobalVeriables) {

    if (this.av_router.snapshot.params["masterDataID"] || this.av_router.snapshot.params["MasteDataTypeID"]) {
      this.masterDataID = this.av_router.snapshot.params["masterDataID"];
      this.MasteDataTypeID = this.av_router.snapshot.params["MasteDataTypeID"];
      //this.MasteDataType = this.av_router.snapshot.params["MasteDataType"];

      this.updatebtn = true;
      this.savebtn = false;

      if (this.masterDataID == undefined)
        this.masterDataID = 0;
    }
    else {
      this.masterDataID = 0;
      this.savebtn = true;
      this.updatebtn = false;
    }
  };
  masterDataForm = this.formBuilder.group({
    txtName: ['', [Validators.required, Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    txtDescription: ['', [Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    //ddlmsprivilegeName: ['', Validators.required],
  })

  ngOnInit() {
    this.CompanyID= +localStorage.getItem("usercompanyID");
    this.MasteDataType = (this.MasteDataTypeID == 1 ? 'Process templates' : this.MasteDataTypeID == 4 ? 'Request sub categories' : this.MasteDataTypeID == 5 ? 'Molecule names' : this.MasteDataTypeID == 6 ?
      'Brand names' : this.MasteDataTypeID == 8 ? 'Markets' : this.MasteDataTypeID == 7 ? 'Packaging sites ' :
        this.MasteDataTypeID == 9 ? 'Priorities' : this.MasteDataTypeID == 15 ? 'Component types ' : this.MasteDataTypeID == 16 ? 'Customers' : this.MasteDataTypeID == 17 ? 'Dosage forms' : this.MasteDataTypeID == 18 ? 'Disposition status' : null);

    if (this.masterDataID != undefined && this.masterDataID != 0) {
      this.dynMasterDataHeader = 'Edit ' + this.MasteDataType;
      this.EditRoleData(this.masterDataID);
    }
    else {
      this.updatebtn = false;
      this.savebtn = true;
      this.dynMasterDataHeader = 'Add ' + this.MasteDataType;

    }
  }


  saveMasteData(masterDataID: any, MasteDataTypeID :any) {
    if (this.masterDataForm.invalid) {
      this.submitted = true;
      return;
    }

    //this.showLoader = true;
    this.objMasterDataInput.MasterDataID = parseInt(masterDataID);
    this.objMasterDataInput.MasterDataName = this.masterDataForm.controls['txtName'].value;
    this.objMasterDataInput.MasterDataDescsiption = this.masterDataForm.controls['txtDescription'].value;
    this.objMasterDataInput.MasterDataTypeID = parseInt(MasteDataTypeID);
    this.objMasterDataInput.CompanyID = this.CompanyID;
    this.objMasterDataInput.userID = this.GV.UserId;

    //this.objPrivilige.RoleID = 0;
    // this.objPrivilige.privileges = this.priviligeForm.controls['ddlmsprivilegeName'].value;
    this.amService.osaveAllMasterData(this.objMasterDataInput).subscribe(response => {
      if (response != undefined) {
        if (response == "1") {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDCS", this.CompanyID).subscribe((dataMCActionsMessages) => {
            this.notifications.show(this.objMasterDataInput.MasterDataName + dataMCActionsMessages[0].displayMessage, dataMCActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        else if (response == "2") {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDUS", this.CompanyID).subscribe((dataMUActionsMessages) => {
            this.notifications.show(this.objMasterDataInput.MasterDataName + dataMUActionsMessages[0].displayMessage, dataMUActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        else {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDAE", this.CompanyID).subscribe((dataMEActionsMessages) => {
            this.notifications.show(this.objMasterDataInput.MasterDataName + dataMEActionsMessages[0].displayMessage, dataMEActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
      }
      
      this.roleDialog = false;
      this.router.navigate(['/MasterData/' + MasteDataTypeID]);
      this.router.navigate(['/MasterData/' + MasteDataTypeID]);
    });
  }

  EditRoleData(masterDataID) {
    this.roleDialog = true;
    this.savebtn = false;
    this.updatebtn = true;

    this.amService.getUMManageAllMasterData(2, this.MasteDataTypeID,parseInt(masterDataID), this.CompanyID).subscribe(data => {
      //console.log('data', data);
      this.masterDataForm.controls['txtName'].setValue(data[0].masterDataName);
      this.masterDataForm.controls['txtDescription'].setValue(data[0].masterDataDescsiption);
    });
  }

  CancelMasteData(MasteDataTypeID) {
    this.router.navigate(['MasterData/' + MasteDataTypeID]);
  }
  onKeypressEvent(event: any){
    if (event.which === 32 && !event.target.value)
    event.preventDefault();
 }
}
