import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoleDetails, UserFormData, DepartmentDetails, UserGroupsDetails, CountriesDetails, UserDomains, TimeZonesData, Languages, Companies, BusinessUnits, dropDownOptions } from '../../../services/AMFormsDataDetails';
import { ActivatedRoute } from '@angular/router';
import { NotificationAlertsService } from '../../../services/notification.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-add-user',
  styles: ["h4 { font-size: 1em; font-weight: bold; padding: 0 8px; }"],
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})

export class AddUserComponent implements OnInit {
  isDisabled = true;
  UserDialogbx: boolean;
  userid: number;
  areaModel: any;
  OperationType: number = 1;
  UserID: number = 0;
  CompanyID: number = 0;
  submitted = false;
  validated = true;
  UsersDt = new UserFormData();
  selectedItems: any = [];
  public isChecked = false;
  CountriesData: CountriesDetails[] = [];
  departmentsData: DepartmentDetails[] = [];
  RolesData: RoleDetails[] = [];
  GroupsData: UserGroupsDetails[] = [];
  DomainsData: UserDomains[] = [];
  usrTimeZonesData: TimeZonesData[] = [];
  languages: Languages[] = [];
  companies: Companies[] = [];
  businessUnits: BusinessUnits[] = [];
  isTimeFormatChecked: boolean;
  savebtn: boolean = false;
  updatebtn: boolean = false;
  dynUserHeader: string = null;
  dataparsing: string;
  btnAddUser: boolean = true;
  btndisabledUSer: boolean = true;
  btnActive: boolean = false;
  countriesDataOpearation: number;
  contrydata: any;
  isTxtusrName = false;
  isTxtEmail = false;
  isRoles = false;
  selectedGroupItems: any = [];
  public isGroupChecked = false;
  isDisabledGroups: boolean = true;
  isDisabledBusinessUnit: boolean = true;
  selectedRoleid: any;
  roleidget: number;
  showDashboardLoader: boolean = false;
  selectedRegion: any = [];
  public isMarketChecked = false;
  selectedMarketItems: any = [];
  GaatesMarketsData: dropDownOptions[] = [];
  GaatesRegions: dropDownOptions[] = [];
  IsGAATESEnabled: boolean = false;
  isEnableEsign: boolean = true;
  isSSOUserChecked: boolean;
  isTxtssouser = false;

  constructor(private router: Router, private av_router: ActivatedRoute, private formbuilder: FormBuilder, private dataservice: AdminUserManagementService, private commonfeaturesservice: CommonFeaturesService, private notifications: NotificationAlertsService, private GV: GlobalVeriables) {
    this.usrTimeZonesData = this.usrTimeZonesData.slice();
    this.roleidget = this.av_router.snapshot.params["roleid"];

    if (this.av_router.snapshot.params["id"]) {
      this.userid = this.av_router.snapshot.params["id"];
      this.updatebtn = true;
      this.savebtn = false;
    }
    else {
      this.userid = 0;
      this.savebtn = true;
      this.updatebtn = false;
    }
  };

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    //  operator: 'startsWith'
    operator: 'contains'
  };

  

  UserDyForm = this.formbuilder.group({
    userFormUserName: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_-]*$")]],
    userFormEmail: ['', [Validators.required, Validators.email]],
    userFormFirstName: ['', [Validators.required, Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    userFormLastname: ['', [Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    userFormDomain: ['', [Validators.required]],
    userTimeZones: ['', [Validators.required]],
    userFormGroups: ['', [Validators.required]],
    userRolesFormData: ['', [Validators.required]],
    userFormDepartment: [''],
    userFormCountries: ['', [Validators.required]],
    userFormGroupOwner: [''],
    userTimeFormat: [false],
    ssouserformat: [false],
    userFormGAATESRegion: ['', [Validators.required]],
    userFormGaatesMarket: ['', [Validators.required]],
    userlanguageData: ['', [Validators.required]],
    userFormCompany: ['', [Validators.required]],
    userFormBusinessUnit: ['', [Validators.required]],
    userFormBusinessPhone: [''],
    userEsign: [true]

  });




  ngOnInit() {
    this.GV.UserId = +localStorage.getItem("userId");
    this.CompanyID= +localStorage.getItem("usercompanyID");
    this.UserDialogbx = true;
    this.UserCountries();
    this.UserDepartments();
    this.UserRoles();
    this.UserDomains();
    this.GetTimeZonesData();
    this.getLangauges();
    this.getCompanies();

    //if (this.IsGAATESEnabled) 
    //  this.GAATESRegion();

    if (this.userid > 0) {

      this.dataservice.getUMUserGroups(5, this.userid, 1, this.roleidget).subscribe(response => {
        this.GroupsData = response;

        if (response.length > 0) 
          this.isDisabledGroups = false;
        else 
          this.selectedGroupItems = [];

        if (this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length) 
          this.isGroupChecked ? "Deselect All" : "Select All";

        if (response != undefined && response != null && response.length > 0) {
          this.isDisabled = false;
          this.UserDyForm.controls['userFormGroupOwner'].setValue("");
        }
        else {
          this.UserDyForm.controls['userFormGroupOwner'].setValue("");
          this.isDisabled = true;
        }
      },
        error => {
          this.notifications.show( error.statusText, 4);
        }
      );
      this.EditUser(this.userid);
      this.dynUserHeader = 'Edit User';
    }
    else 
      this.dynUserHeader = 'Add User';
  }

  public get isIndet() {
    return (
      this.selectedItems.length !== 0 && this.selectedItems.length !== this.CountriesData.length
    );
  }

  public get toggleAllText() {
    return this.isChecked ? "Deselect All" : "Select All";
  }

  public isItemSelected(item) {
    return this.selectedItems.some((x) => x.countryID === item.countryID);
  }

  public onClick() {
    this.isChecked = !this.isChecked;
    this.selectedItems = this.isChecked ? this.CountriesData.slice() : [];
  }

  public onValueChange() {
    this.isChecked = this.selectedItems.length === this.CountriesData.length;
  }

  public get isMarketIndet() {
    return (
      this.selectedMarketItems.length !== 0 && this.selectedMarketItems.length !== this.GaatesMarketsData.length
    );
  }

  public get toggleMarketAllText() {
    return this.isMarketChecked ? "Deselect All" : "Select All";
  }

  public isMarketItemSelected(item) {
    return this.selectedMarketItems.some((x) => x.id === item.id);
  }

  public onMarketClick() {
    this.isMarketChecked = !this.isMarketChecked;
    this.selectedMarketItems = this.isMarketChecked ? this.GaatesMarketsData.slice() : [];
  }

  public onMaketsValueChange() {
    this.isMarketChecked = this.selectedMarketItems.length === this.GaatesMarketsData.length;
  }

  public onGaatesRegionChange(event, data: any) {

    if (event == null || event == "" || event == undefined) {
      this.GaatesMarketsData = [];
      return;
    } else {

      this.dataservice.getGAATESMarkets(event).subscribe(response => {
        this.GaatesMarketsData = response;

        if (data.length > 0) {

          this.selectedMarketItems = this.GaatesMarketsData.filter(function (item) {
            return data.indexOf(item["id"].toString()) > -1
          });

          if (data.length > 0) {
            if (this.isMarketChecked = this.selectedMarketItems.length === this.GaatesMarketsData.length) 
              this.isMarketChecked ? "Deselect All" : "Select All";
          }
          else 
            this.selectedMarketItems = [];
        }
        else 
          this.selectedMarketItems = [];
      });
    }
  }

  saveOrUpdateUserData(userid: any) {
   try {
     
     if (this.IsGAATESEnabled) {
       this.UserDyForm.controls['userTimeZones'].setErrors(null);
       this.UserDyForm.controls['userFormDomain'].setErrors(null);
       this.UserDyForm.controls['userFormGroupOwner'].setErrors(null);
       this.UserDyForm.controls['userFormDepartment'].setErrors(null);
       this.UserDyForm.controls['userFormGroups'].setErrors(null);
       this.UserDyForm.controls['userTimeZones'].setValue(1);
       this.UserDyForm.controls['userFormDomain'].setValue(1);
       this.UserDyForm.controls['userFormGroupOwner'].setValue(1);
       this.UserDyForm.controls['userFormDepartment'].setValue(1);
       this.UserDyForm.controls['userFormGroups'].setValue(1);
       this.UserDyForm.controls['userFormCountries'].setErrors(null);
       this.UserDyForm.controls['userFormCountries'].setValue(1);

       if (this.UserDyForm.invalid) {
         this.submitted = true;
         return;
       }
     } else {
       this.UserDyForm.controls['userFormGAATESRegion'].setErrors(null);
       this.UserDyForm.controls['userFormGAATESRegion'].setValue(1);
       this.UserDyForm.controls['userFormGaatesMarket'].setErrors(null);
       this.UserDyForm.controls['userFormGaatesMarket'].setValue(1);

       if (this.UserDyForm.invalid) {
         this.submitted = true;
         return;
       }
     }

     if (this.IsGAATESEnabled) {
       this.UsersDt.Region = (this.UserDyForm.controls['userFormGAATESRegion'].value == undefined || this.UserDyForm.controls['userFormGAATESRegion'].value == null) ? '' :
         this.UserDyForm.controls['userFormGAATESRegion'].value.toString();
       this.UsersDt.Market = (this.UserDyForm.controls['userFormGaatesMarket'].value == undefined || this.UserDyForm.controls['userFormGaatesMarket'].value == null) ? '' :
         this.UserDyForm.controls['userFormGaatesMarket'].value.map(a => a.id).toString();
     } else {
       this.UsersDt.Region = "";
       this.UsersDt.Market = "";
     }
    this.showDashboardLoader = true;
    this.UsersDt.userid = userid.toString();
    this.UsersDt.currentUserId = this.GV.UserId;
    this.UsersDt.username = this.UserDyForm.controls['userFormUserName'].value;
     this.UsersDt.businessPhone = this.UserDyForm.controls['userFormBusinessPhone'].value == null ? '' : this.UserDyForm.controls['userFormBusinessPhone'].value;
    this.UsersDt.emailID = this.UserDyForm.controls['userFormEmail'].value;
    this.UsersDt.firstName = this.UserDyForm.controls['userFormFirstName'].value;
    this.UsersDt.lastname = this.UserDyForm.controls['userFormLastname'].value;
    this.UsersDt.language = (this.UserDyForm.controls['userlanguageData'].value == undefined || this.UserDyForm.controls['userlanguageData'].value == null) ? '' : this.UserDyForm.controls['userlanguageData'].value.toString();
    this.UsersDt.timeFormat = this.UserDyForm.controls['userTimeFormat'].value;
    this.UsersDt.ssouser = this.UserDyForm.controls['ssouserformat'].value;
    this.UsersDt.domain = (this.UserDyForm.controls['userFormDomain'].value == undefined || this.UserDyForm.controls['userFormDomain'].value == null) ? '' : this.UserDyForm.controls['userFormDomain'].value.toString();
    this.UsersDt.timezone = (this.UserDyForm.controls['userTimeZones'].value == undefined || this.UserDyForm.controls['userTimeZones'].value == null) ? '' : this.UserDyForm.controls['userTimeZones'].value.toString();
    this.UsersDt.department = (this.UserDyForm.controls['userFormDepartment'].value == undefined || this.UserDyForm.controls['userFormDepartment'].value == null) ? '' : this.UserDyForm.controls['userFormDepartment'].value.toString();
    this.UsersDt.roles = (this.UserDyForm.controls['userRolesFormData'].value == undefined || this.UserDyForm.controls['userRolesFormData'].value == null) ? '' : this.UserDyForm.controls['userRolesFormData'].value.toString();
    this.UsersDt.company = (this.UserDyForm.controls['userFormCompany'].value == undefined || this.UserDyForm.controls['userFormCompany'].value == null) ? '' : this.UserDyForm.controls['userFormCompany'].value.toString();
    this.UsersDt.businessUnit = (this.UserDyForm.controls['userFormBusinessUnit'].value == undefined || this.UserDyForm.controls['userFormBusinessUnit'].value == null) ? '' : this.UserDyForm.controls['userFormBusinessUnit'].value.toString();
     this.UsersDt.groupowner = this.UserDyForm.controls['userFormGroupOwner'].value.toString();
     this.UsersDt.enableEsign = this.UserDyForm.controls['userEsign'].value;
    ////groups///
    let grpdata = this.UserDyForm.controls['userFormGroups'].value;
     let getGroupDt = [];
     for (let i = 0; i < grpdata.length; i++) {
      getGroupDt.push(grpdata[i].id);
     }

    this.UsersDt.groupname = getGroupDt.toString();
    ////countries////
    let ele = this.UserDyForm.controls['userFormCountries'].value;
    let splitingdata = [];
     let splitingwithName = [];
     for (let j = 0; j < ele.length; j++) {
       splitingdata.push(ele[j].countryID);
       splitingwithName.push(ele[j].countryName)
     }

    this.UsersDt.countries = splitingdata.toString();
     this.UsersDt.countryName = splitingwithName.toString();
     //this.UsersDt.enableSSOUser = localStorage.getItem("ssoLogin");
    if (userid.toString() == "0") 
      this.UsersDt.lockoutEnabled = "1";

    this.dataservice.saveFormUsersFieldsdata(this.UsersDt).subscribe(
      (data) => {
       
        if (data != undefined) {
          if (data == "0") {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "UCS", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
            this.showDashboardLoader = false;
            this.UserDialogbx = false;
            this.router.navigate(['/Users']);
          }
          else if (data == "1") 
          {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1,0, "UUS", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
            this.showDashboardLoader = false;
            this.UserDialogbx = false;
            this.router.navigate(['/Users']);
          }
          else if (data == "2")
          {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1,0, "UAE", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
            this.showDashboardLoader = false;
            this.UserDialogbx = false;
          }
          else if (data == "3")
          {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "EAE", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
            this.showDashboardLoader = false;
            this.UserDialogbx = false;
          }
          else if (data == "4")
          {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "PEVD", this.CompanyID).subscribe((dataUActionsMessages) => {
              this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
            });
            this.showDashboardLoader = false;
            this.UserDialogbx = false;
          }
          else
          {
            this.UserDialogbx = false;
            this.showDashboardLoader = false;
            this.notifications.show(data, 4)
          }
        }
      },
      error => {
        this.UserDialogbx = false;
        this.showDashboardLoader = false;
        this.notifications.show(error.statusText, 4);
      }
    );
  } catch (error) {
    this.UserDialogbx = false;
    this.showDashboardLoader = false;
   this.notifications.show(error.statusText, 4);
  }
  finally
  {
     this.showDashboardLoader = false;
  }
  }

  //Getting Countries data//
  UserCountries() {
    this.dataservice.getUMGetCountries().subscribe(response => {
      this.CountriesData = response;
    });
  }

  //Getting Roles data//
  UserRoles() {
    this.dataservice.getUMGetRoles().subscribe(response => {
      this.RolesData = response;
    });
  }

  //Getting Departmenst data//
  UserDepartments() {
    this.dataservice.getUserDepartments().subscribe(response => {
      this.departmentsData = response;
    });
  }

  //Getting Groups data//
  RolesChange(roleid: any, userid: any) {
    this.selectedGroupItems = [];
    if (roleid == 0)
      this.isDisabledGroups = true;

    this.dataservice.getUMUserGroups(5, userid, 1, roleid).subscribe(response => {
      this.GroupsData = response;

      if (response.length > 0) {
        this.isDisabledGroups = false;

        if (this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length) {
          this.isGroupChecked ? "Deselect All" : "Select All";
        }
      }
      else {
        this.selectedGroupItems = [];
        this.isGroupChecked = false;
      }

      if (response != undefined && response != null && response.length > 0) {
        this.isDisabled = false;
        this.UserDyForm.controls['userFormGroupOwner'].setValue("");
      }
      else
      {
        this.UserDyForm.controls['userFormGroupOwner'].setValue("");
        this.isDisabled = true;
      }
    });
  }

  //UserGroups() {
  //  this.dataservice.getUMUserGroups(5, this.GV.UserId, 1, roleid).subscribe(response => {
  //    this.GroupsData = response;
  //  });
  //}

  CompanyChange(companyId: any) {
    this.businessUnits = [];
    this.isDisabledBusinessUnit = true;
    this.dataservice.getBusinessUnits(companyId).subscribe(response => {
      if (response.length > 0) {
        this.businessUnits = response;
        this.isDisabledBusinessUnit = false;
      }
    });
  }

  public get isGroupIndet() {
    return (
      this.selectedGroupItems.length !== 0 && this.selectedGroupItems.length !== this.GroupsData.length
    );
  }

  public get GrouptoggleAllText() {
    return this.isGroupChecked ? "Deselect All" : "Select All";
  }

  public GroupisItemSelected(item) {
    return this.selectedGroupItems.some((x) => x.id === item.id);
  }

  public GrouponClick() {
    this.isGroupChecked = !this.isGroupChecked;
    this.selectedGroupItems = this.isGroupChecked ? this.GroupsData.slice() : [];

    if (this.isGroupChecked) 
      this.isDisabled = false;
    else {
      this.UserDyForm.controls['userFormGroupOwner'].setValue("");
      this.isDisabled = true;
    }
  }

  public GrouponValueChange() {
    this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length;
  }

  //Getting Domains Data//
  UserDomains() {
    this.dataservice.getUserDomains().subscribe(response => {
      this.DomainsData = response;
    });
  }

  GetTimeZonesData() {

    this.dataservice.LoadTimeZonesDahsboard().subscribe(response => {
      this.usrTimeZonesData = response;
      this.usrTimeZonesData = this.usrTimeZonesData.slice();
    });
  }

  getLangauges() {
    this.dataservice.getlanguages().subscribe(response => {
      this.languages = response;
    });
  }

  getCompanies() {
    this.dataservice.getCompanies().subscribe(response => {
      this.companies = response;
    });
  }

  close() {
    this.UserDialogbx = false;
    this.router.navigate(['/Users']);
  }

  cancelUserData() {
    this.UserDyForm.reset();
    this.UserDialogbx = false;
    this.router.navigate(['/Users']);
  }

  EditUser(userid: number) {
    this.UserDialogbx = true;
    this.savebtn = false;
    this.updatebtn = true;
    this.OperationType = 2;
    this.countriesDataOpearation = 4;
    this.isDisabledBusinessUnit = false;
   
    this.dataservice.getUMUserGroups(6, userid, 1, this.roleidget).subscribe(data => {
      
      if (data.length > 0) {
        this.selectedGroupItems = data;
        if (this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length) {
          this.isGroupChecked ? "Deselect All" : "Select All";
        }
      }
      else 
        this.selectedGroupItems = [];
    });

    this.dataservice.getUMGetUsers(this.OperationType, userid, this.CompanyID).subscribe(data => {
      this.isTimeFormatChecked = data[0].timeFormat;
      this.isSSOUserChecked = data[0].ssouser;
      this.isEnableEsign = data[0].enableEsign;
      this.UserDyForm.controls['userFormUserName'].setValue(data[0].username);
      this.UserDyForm.controls['userFormEmail'].setValue(data[0].emailID);
      this.UserDyForm.controls['userFormFirstName'].setValue(data[0].firstName);
      this.UserDyForm.controls['userFormLastname'].setValue(data[0].lastname);
      this.UserDyForm.controls['userFormDomain'].setValue(parseInt(data[0].domain));
      this.UserDyForm.controls['userTimeZones'].setValue(parseInt(data[0].timeZoneID));
      this.UserDyForm.controls['userFormDepartment'].setValue(parseInt(data[0].department));
      this.UserDyForm.controls['userRolesFormData'].setValue(parseInt(data[0].roles));
      this.UserDyForm.controls['userTimeFormat'].setValue(data[0].timeFormat);
      this.UserDyForm.controls['ssouserformat'].setValue(data[0].ssouser);
      this.UserDyForm.controls['userlanguageData'].setValue(parseInt(data[0].language));
      this.UserDyForm.controls['userEsign'].setValue(data[0].enableEsign);
      this.UserDyForm.controls['userFormCompany'].setValue(parseInt(data[0].companyId));
      this.dataservice.getBusinessUnits(parseInt(data[0].companyId)).subscribe(response => {
        if (response.length > 0) {
          this.businessUnits = response;
        }
      });

      if (this.IsGAATESEnabled) {
        this.UserDyForm.controls['userFormGAATESRegion'].setValue(parseInt(data[0].region));
        this.onGaatesRegionChange(data[0].region, data[0].market.split(','));
      }
      this.UserDyForm.controls['userFormBusinessUnit'].setValue(parseInt(data[0].businessUnitId));
      this.UserDyForm.controls['userFormBusinessPhone'].setValue(data[0].businessPhone);
      this.isTxtusrName = true;
      this.isTxtssouser = true;
      this.isTxtEmail = true;
      this.isRoles = true;
      this.selectedRoleid = data[0].groupname;
      if (data[0].groupowner == "True" || (data[0].groupname != '' && data[0].groupname != 0 && data[0].groupname.length > 0)) {
        if (data[0].groupowner == "True") {
          this.isDisabled = false;
          this.UserDyForm.controls['userFormGroupOwner'].setValue(data[0].groupowner);
        }
      }
      else {
        this.UserDyForm.controls['userFormGroupOwner'].setValue("");
        this.isDisabled = true;
      }
    });

   
    this.dataservice.getCountriesData(this.countriesDataOpearation, userid, this.CompanyID).subscribe(data => {
     
      if (data.length > 0) {
        this.selectedItems = data;
        if (this.isChecked = this.selectedItems.length === this.CountriesData.length) 
          this.isChecked ? "Deselect All" : "Select All";
      }
      else 
        this.selectedItems = [];
    });
  }

  //groups change event for gropuowner
  groupOnchange(event: any, data: any) {
    this.isGroupChecked = this.selectedGroupItems.length === this.GroupsData.length;
    let selectedGroupsList = [];

    for (let i = 0; i < event.length; i++) {
      selectedGroupsList.push(event[i].id);
    }
    if (selectedGroupsList != undefined && selectedGroupsList != null && selectedGroupsList.length >0) {
      if (event != null) 
        this.isDisabled = false;
    }
    else {
      this.UserDyForm.controls['userFormGroupOwner'].setValue("");
      this.isDisabled = true;
    }
  }

  onKeypressEvent(event: any){
    if (event.which === 32 && !event.target.value)
    event.preventDefault();
  }

  //Getting Regions Data//
  GAATESRegion() {
    this.dataservice.getGAATESRegions().subscribe(response => {
      this.GaatesRegions = response;
    });
  }
}
