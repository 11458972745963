import {Component, OnInit, ChangeDetectorRef, Input, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ChartComponent, LegendLabelsContentArgs } from "@progress/kendo-angular-charts";
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { IntlService } from "@progress/kendo-angular-intl";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DynamicFormGroupBase } from '../dynamic-field-base';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { ReportsService } from '../../../services/reports.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { ModuleService } from '../../../services/module.service';
import { templateData } from '../../../interfaces/ItemplateFields';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { saveAs } from "@progress/kendo-file-saver";
import {
  GroupDescriptor,
  DataResult,
  process,
  groupBy,
} from "@progress/kendo-data-query";
import { error } from '@angular/compiler/src/util';
import { LanguageService } from 'src/app/services/language.service';
import { templateSelectedRecord } from '../dynamic-field-list/dynamic-field-list.component';
@Component({
  selector: 'app-dynamic-field-chart',
  templateUrl: './dynamic-field-chart.component.html',
  styleUrls: ['./dynamic-field-chart.component.css'],
})
export class DynamicFieldChartComponent implements OnInit, OnDestroy {
  @Input() public template: any;
  public dynamicForm: FormGroup;
  DynamicFields: DynamicFormGroupBase<any>;
  @ViewChild("chart1")
  chart1: ChartComponent;
  @ViewChild("chartPie")
  chartPie: ChartComponent;

  actionEvent: string;
  selectedYearItems = [];
  selectedMonthItems = [];
  value: any;
  charttype: any = { type: 'column' };
  chartStakedtype: string='group';
  invalidSubmitAttempt: boolean = false;
  SearchFilterData: any
  public chartTypeItems: any[] = [
    { key: "Column", value: "column" },
    { key: "Bar", value: "bar" },
  ];
  public GroupStackedItems: any[] = [
    { key: "Group", value: "group" },
    { key: "Stack", value: "stack" },
  ];
  public Stacked: boolean = false;
  public categories: any;
  public showSeries: boolean = false;
  public model = [];
  public modelres: any;// = [];
  public TChartPiedata: any;
  public TChartdata: any;
  showLoader: boolean = false;
  GMTLoadDateTime: string = '';
  GMTReportCreatedTime: string = '';
  showdatetime: boolean = false;
  NoData: boolean = false;
  moduleID: any;
  ////////////////////////////////////// grid  variables
  columns: any;
  displayActions: any;
  records: any;
  filteredRecords: any=[];
  FilteredGridData: DataResult;
  EnableSearchOption: any;
  IsFilter: any;
  IsPaging: any;
  IsSorting: any;
  checkBoxCheckIDs: any = [];

  IsExportPDF: any;
  EnableMultiSelect: any;
  ShowMultiSelect: boolean = false;

  EnableselectedValue: any = 0;
  loadChart: boolean = false;
  IsExportExcel: any;
  public mySelection: number[] = [0];
  showgrid: boolean = false;
  public fData: any;
  loadfilesave: boolean = false;
  loadPieChartfilesave: boolean = false;
  txtFilenamevalue: string = "chart";
  txtPieChartFilenamevalue: string = "pie chart";
  TotalPrj: number = 0;
  chartannotateshow = false;
  searchfiltershow = false;
  anchornewshow = false;
  anchorpiechartshow = false;
  columnsWithAlias=[];

  @ViewChild("annotateanchor") public annotateanchor: ElementRef;
  @ViewChild("annotatepopup", { read: ElementRef }) public annotatepopup: ElementRef;
  @ViewChild('anchornew') public anchornew: ElementRef;
  @ViewChild('popupnew', { read: ElementRef }) public popupnew: ElementRef;
  @ViewChild('anchorpiechart') public anchorpiechart: ElementRef;
  @ViewChild('popuppiechart', { read: ElementRef }) public popuppiechart: ElementRef;

  //@ViewChild("searchfilter") public searchfilter: ElementRef;
  //@ViewChild("searchpopup", { read: ElementRef }) public searchpopup: ElementRef;
  //////////////////////////////////////////////////////
  constructor(private moduleService: ModuleService, private router: Router, private intl: IntlService, private GV: GlobalVeriables, private reportservice: ReportsService, private notifyService: NotificationAlertsService,
    private changeRef: ChangeDetectorRef,private languageService : LanguageService) {
    this.labelContent = this.labelContent.bind(this);
    this.PielabelContent = this.PielabelContent.bind(this);
  }

  //@HostListener("keydown", ["$event"])
  //public keydown(event: any): void {
  //  if (event.keyCode === 27) {
  //    this.onTogglechartannotate(false);
  //  }
  //}

  //@HostListener("document:click", ["$event"])
  //public documentClick(event: any): void {
  //  if (!this.contains(event.target)) {
  //    this.onTogglechartannotate(false);
  //  }
  //  //if (!this.contain1(event.target)) {
  //  //  this.onTogglesearchfilter(false);
  //  //}
  //}

  public onTogglechartannotate(chartannotateshow?: boolean): void {
    //this.chartannotateshow = !this.chartannotateshow;
    this.chartannotateshow = chartannotateshow != undefined ? chartannotateshow : !this.chartannotateshow;
  }
  public onTogglesearchfilter(searchfiltershow?: boolean): void {
    //this.chartannotateshow = !this.chartannotateshow;
    this.searchfiltershow = searchfiltershow != undefined ? searchfiltershow : !this.searchfiltershow;
  }

  public onToggleanchornew(anchornewshow?: boolean): void {

    //this.chartannotateshow = !this.chartannotateshow;
    this.anchornewshow = anchornewshow != undefined ? anchornewshow : !this.anchornewshow;
    if (this.anchornewshow){
      this.exportChart('chart');
    }
   
  }


  public onToggleanchorpiechart(anchorpiechartshow?: boolean): void {

    //this.chartannotateshow = !this.chartannotateshow;
    this.anchorpiechartshow = anchorpiechartshow != undefined ? anchorpiechartshow : !this.anchorpiechartshow;
    if (this.anchorpiechartshow) {
      this.exportChart('piechart');
    }

  }



  public closechartannotate(): void {
    this.chartannotateshow = false;
  }
  public closesearchfilter(): void {
    this.searchfiltershow = false;
  }

  private contains(target: any): boolean {
    return (
      this.annotateanchor.nativeElement.contains(target) ||
      (this.annotatepopup ? this.annotatepopup.nativeElement.contains(target) : false)
    );
  }

  //private contain1(target: any): boolean {
  //  return (
  //    this.searchfilter.nativeElement.contains(target) ||
  //    (this.searchpopup ? this.searchpopup.nativeElement.contain(target) : false)
  //  );
  //}


  public labelContent(args: LegendLabelsContentArgs): string {
    if (args.dataItem.TotalProjects != 0) {
      let status= this.GetTranslatedStatus(args.dataItem.Status);
      return `${status}: ${this.intl.formatNumber(
        args.dataItem.TotalProjects, "0"
      )}`;
    }
    else
      return '';
  }
  public PielabelContent(args: LegendLabelsContentArgs): string {
    let status= this.GetTranslatedStatus(args.dataItem.Status);
    return `${status}: ${this.intl.formatNumber(
      args.dataItem.TotalPercentage, "p2"
    )}`;
  }
  subscription1$: Subscription;
  public groups: GroupDescriptor[] = [];//{ field: "Status" }
  ngOnInit() {
    this.NoData = false;
    var temp = this.template;
    this.dynamicForm = new FormGroup({
      ddlcharttypevalue: new FormControl(''),
      ddlchartgrptypevalue: new FormControl(''),
      txtFilename: new FormControl(''),
      txtPieChartFilename: new FormControl(''),
      RbChartType: new FormControl(''),
      RbChartGroupType: new FormControl('')
    });
    this.reportservice.GetUTCDate().subscribe((dateresult) => {
      this.GMTLoadDateTime = dateresult
    });

    this.charttype = "column";
    this.chartStakedtype = "group";
    this.dynamicForm.controls['ddlcharttypevalue'].setValue(this.charttype);
    this.dynamicForm.controls['ddlchartgrptypevalue'].setValue(this.chartStakedtype);
    this.dynamicForm.controls['RbChartType'].setValue(this.charttype);
    this.dynamicForm.controls['RbChartGroupType'].setValue(this.chartStakedtype);
    

  
    this.loadgroupGridData();
  }
  ngOnDestroy() {
  }
  colorFn(e) {
    //console.log(e);
    if (e.category == 'InTime' || e.dataItem.Status == 'InTime')
      return '#6ad49b';
    if (e.category == 'Late' || e.dataItem.Status == 'Late')
      return '#f1416c';
  }
  LegendNames(e) {
    if (e.category == 'InTime' || e.dataItem.Status == 'InTime')
      return '#6ad49b';
    if (e.category == 'Late' || e.dataItem.Status == 'Late')
      return '#f1416c';
  } 
  LoadFilterData(action: any, SearchFilterData: any) {
    this.showLoader = true;
    if (SearchFilterData == null || SearchFilterData == '') {
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW',"Something went wrong. Please try again "), 4);
      return;
    }
    else {
      this.actionEvent = action;
      var inputData = JSON.stringify(SearchFilterData);
      var Params: any = [];
      var ParamsIds = JSON.parse(SearchFilterData);
      for (let i = 0; i < ParamsIds.length; i++) {
        Params.push({ key: ParamsIds[i].key, value: ParamsIds[i].options });
      }
      Params.push({
        key: "startyearvalue", value: JSON.stringify(ParamsIds.ProjectStartYear == "" ? [] : ParamsIds.ProjectStartYear)
      });
      Params.push({
        key: "startmonthvalue", value: JSON.stringify(ParamsIds.ProjectStartMonth == "" ? [] : ParamsIds.ProjectStartMonth)
      });
      var jresult = JSON.stringify(Params);
      this.reportservice.AllActiveProjectDetails("AAPR", jresult, this.GV.UserId).subscribe((dataresult) => {
        var chatlist = dataresult.Table;
        if (dataresult == null) {
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW',"Something went wrong. Please try again ") , 4);
          return;
        }//debugger
        if(dataresult.Table3){
          this.columnsWithAlias=dataresult.Table3;
        }
        this.LoadData(dataresult.Table, dataresult.Table1, dataresult.Table2);
      }, error => { this.showLoader = false;});
    }

  }
  LoadData(filteredRecords:any=[],tChartdata:any=[], tChartPiedata:any=[],) {
    this.filteredRecords = filteredRecords;
    this.modelres = [];
    this.categories = [];
    this.showdatetime = false;
    this.TChartPiedata = [];
    this.TChartdata = [];
    this.FilteredGridData = this.filteredRecords;
    if (this.filteredRecords.length > 0) {
      this.columns = Object.keys(this.filteredRecords[0]);
      // this.columns.forEach(element => {
      //   let match=this.columnsWithAlias.find(x=>x.name==element);
      // });
      //this.displayActions = data.displayActions;
      var chartdata = tChartdata;
      this.TChartdata = tChartdata;
      this.TChartPiedata = tChartPiedata;
      this.TotalPrj = this.TChartPiedata.filter(x => x).reduce((sum, item) => sum + item.TotalProjects, 0);
      //this.TChartPiedata = [...this.TChartPiedata].reverse();
      //var StatusList = [...new Set(chartdata.map(v => v.status))];
      var StatusList = chartdata.map(v => v.Status).filter((item, i, ar) => ar.indexOf(item) === i) //another method to get distinct values from array object

      this.categories = StatusList;
      var model12 = [];
      StatusList.forEach(function (value) {
        var fData1 = chartdata.filter(
          x => x.Status === value);
        model12.push({ data: fData1 });
        //console.log(value);
      });
      this.modelres = model12;
      this.showdatetime = true;
      this.loadChart = true;
      this.loadPieChartfilesave = false;
      this.loadfilesave = false;
      this.NoData = false;
      this.LoadGridData();
      this.reportservice.GetUTCDate().subscribe((dateresult) => {
        this.GMTReportCreatedTime = dateresult
      });
    }
    else {
      this.NoData = true;
    }
    this.showgrid = true;
    this.showLoader = false;
  }

  LoadGridData() {
    this.groups = [];
    this.FilteredGridData = this.filteredRecords;
    this.EnableSearchOption = true;
    this.IsFilter = false;
    this.IsPaging = true;
    this.IsSorting = true;
    this.IsExportPDF = true;
    this.IsExportExcel = true;
    this.EnableMultiSelect = false;
  }

  onSearchClick(searchFilters: any) {
    this.loadChart = false;
    this.charttype = "column";
    this.chartStakedtype = "group";
    this.Stacked = false;
    this.dynamicForm.controls['RbChartType'].setValue(this.charttype);
    this.dynamicForm.controls['RbChartGroupType'].setValue(this.chartStakedtype);
    this.SearchFilterData = searchFilters.searchFields;//JSON.parse(searchFilters);
    this.LoadFilterData('AAPR', this.SearchFilterData);
    this.NoData = false;
  }

  chartChange(selectedvalue: any) {
    this.charttype = selectedvalue;
    this.loadChart = false;
    this.LoadData(this.filteredRecords, this.TChartdata, this.TChartPiedata);
    //this.LoadFilterData('AAPR', this.SearchFilterData);
  }
  chartGrpChange(selectedvalue: any) {
    this.chartStakedtype = selectedvalue;
    if (selectedvalue == 'stack') 
      this.Stacked = true;
    else
      this.Stacked = false;

    this.loadChart = false;
    this.LoadData(this.filteredRecords, this.TChartdata, this.TChartPiedata);
    //this.LoadFilterData('AAPR', this.SearchFilterData);
  }
  BackAction() {
    //this.moduleID = 4;
    //var templData = new templateData();
    //templData.moduleID = this.moduleID;
    //this.moduleService.passTemplateData(templData);

    //this.router.navigate([module.routingName]);
    //this.router.navigate(["Reports"], { replaceUrl: true });
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(["Reports"]));
  }
  exportChart(selecvalue: any) {
    if (selecvalue == 'piechart')
      this.loadPieChartfilesave = true;
    else
      this.loadfilesave = true;
  }
  exportfileChart(selecvalue: any) {
    if (selecvalue == 'piechart') {
      this.chartPie.exportImage().then((dataURI) => {
        saveAs(dataURI, this.txtPieChartFilenamevalue != '' ? this.txtPieChartFilenamevalue : 'piechart' + ".png");
        this.loadPieChartfilesave = false;
        this.txtPieChartFilenamevalue = "pie chart";
      });
      

    }
    else {
      this.chart1.exportImage().then((dataURI) => {
        saveAs(dataURI, this.txtFilenamevalue != '' ? this.txtFilenamevalue : 'chart' + ".png");

        this.loadfilesave = false;
        this.txtFilenamevalue = "chart";
      });
     
    }
  }
  exportfilecancelChart(selecvalue: any) {
    if (selecvalue == 'piechart') {
      this.loadPieChartfilesave = false;
      this.txtPieChartFilenamevalue = "pie chart";
      this.anchorpiechartshow = false;
    }
    else {
      this.loadfilesave = false;
      this.txtFilenamevalue = "chart";
      this.anchornewshow = false;
    }
  }
  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
  }
  private loadgroupGridData(): void {
    this.FilteredGridData = process(this.filteredRecords, { group: this.groups });
  }
  EnableDisableFilter() {
    if (this.IsFilter == true)
      this.IsFilter = false;
    else
      this.IsFilter = true;
  }
  changeChartType(e) {
    //console.log(e.target.value);
    this.chartChange(e.target.value);
  }
  changeChartGroupType(e) {
    //console.log(e.target.value);
    this.chartGrpChange(e.target.value);
  }
  gridAction(dataItem, action) {
    var ID = dataItem.ID;
    this.moduleID = 1;
    var templData = new templateData();
    templData.moduleID = this.moduleID;
    this.moduleService.passTemplateData(templData);

    //this.moduleService.passSelectedRecordID(9504);

    //let selectedItem = new templateSelectedRecord();
    //selectedItem.templateId = "15";
    //selectedItem.selectedRecordId = 9504;
    //selectedItem.highlightRecordID = 9504;
    //selectedItem.moduleID = "1";
    //selectedItem.dependentRecordID = "0";
    var selectReport = new OpenReportType();
    selectReport.type = action;
    selectReport.dataItem = JSON.stringify(dataItem);
    localStorage.setItem("reportsSelectedData", JSON.stringify(selectReport));
    //this.moduleService.passDependencyTemplate(selectedItem);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(["Projects"]));
    //this.router.navigate(["Projects"], { replaceUrl: true });
  }
 
/*--Start Chart zoom js code--*/
  ZoominAction(){
    var zoomdiv = $(".div-area-zoom");
    zoomdiv.addClass("zoomdivone");
    var hide = $(".zoom_in");
    hide.addClass("znone");
 
   var disp = $(".zoom_out");
   disp.addClass("zdplay");
   }  
 
   ZoomoutAction(){
     var zoomdiv = $(".div-area-zoom");
     zoomdiv.removeClass("zoomdivone");
     var hide = $(".zoom_in");
     hide.removeClass("znone");
 
     var disp = $(".zoom_out");
     disp.removeClass("zdplay");
    }  

    ZoominAction2(){
      var zoomdiv = $(".div-area-zoom2");
      zoomdiv.addClass("zoomdivone2");
      var hide = $(".zoom_in");
      hide.addClass("znone");
   
     var disp = $(".zoom_out");
     disp.addClass("zdplay");
     }  
   
     ZoomoutAction2(){
       var zoomdiv = $(".div-area-zoom2");
       zoomdiv.removeClass("zoomdivone2");
       var hide = $(".zoom_in");
       hide.removeClass("znone");
   
       var disp = $(".zoom_out");
       disp.removeClass("zdplay");
      }  

    /*--End Chart zoom js code--*/

    GetTranslatedStatus(status : string){
      if(status=="Late"){
        let tData=this.languageService.GetActionMessageByLanguage("STC_LATE",status);
        return tData;
      }
      else if(status=="InTime"){
        let tData=this.languageService.GetActionMessageByLanguage("STC_INTM",status);
        return tData;
      }
      else{
        return status;
      }
    }

}
export class OpenReportType {
  type: string;
  dataItem: string;
}
