import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse, HttpBackend } from '@angular/common/http';
// import 'rxjs/add/operator/do';
import { Observable } from 'rxjs';
 import{ NotificationAlertsService  } from "../services/notification.service";
 import { Router, ResolveStart } from '@angular/router';
 import { GlobalVeriables } from '../Global/GlobalVaribales';
import { tap } from 'rxjs/operators';
import { templateSelectedRecord } from '../ArtworkManagement/dynamic-fields/dynamic-field-list/dynamic-field-list.component';
import { ModuleService } from '../services/module.service';
import { LoginService } from '../services/login.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
 @Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
  public message:string;
  public type:string;
  public data:any;
  LogOutConfrimopened:boolean=false;
  IsLoginSuccess:boolean=false;
  
  constructor(private moduleService: ModuleService ,private GV: GlobalVeriables,private _loginService: LoginService, private Notification:NotificationAlertsService,private router: Router,public Global:GlobalVeriables, private jwtHelper: JwtHelperService) {}
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const API_KEY = '123456';
    let token=localStorage.getItem('token');
  //  let token="eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJSYWp1IiwibmJmIjoxNjQ1MTc2MzIzLCJleHAiOjE2NDUxNzc1MjMsImlhdCI6MTY0NTE3NjMyM30.9F_5ewd73zK4l1j5qJ8XKM_ASX5Rnqa6Yvwruth4s1ly7KGekE5RXHzKUZ4CMTr7JEcsancN0mrkdf3qxkHxjg";
   // alert(token);
    if(token!=null)
    {
      const clonedReq=httpRequest.clone({
        headers:httpRequest.headers.set('Authorization','bearer ' + token)
      });
    

    return next.handle(clonedReq).pipe
    ( tap(
      succ=>{},
      err=>{
        if(err.status==401)
        {
          // alert("Token expired. Please login again.");
          // //localStorage.setItem("token", "");
          // this.moduleService.passDependencyTemplate(new templateSelectedRecord())
          // this.LogOutConfrimopened = false;
          // this._loginService.setHeaderShow(false);
          // this._loginService.userLogoff();
          // this.IsLoginSuccess = false;
          // localStorage.setItem("userLogin", "false");
          // window.location.reload();
          // this.router.dispose();
         
          // window.location.reload['/Projects'];

        }
      }
     
    )

    )

    }
    else
    {
      if (!JSON.parse(localStorage.getItem("ssoLogin"))) {
        this._loginService.userLogoff();
        this.IsLoginSuccess = false;
        localStorage.setItem("userLogin", "false");
        this.router.navigate(['Login']);
      }
          //window.location.reload();
          //this.router.dispose();
      //return next.handle(httpRequest.clone());
      return next.handle(httpRequest);
    }
    
    // return next.handle(httpRequest.clone({ setHeaders: { 
      
    //   Authorization: `Bearer ${token}` } }));
    //return next.handle(httpRequest);
  }
  // intercept2(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   debugger;
  //   this.Global.Loading=true;
  //   request = request.clone({
    
  //     // setHeaders: {
  //     //  // Authorization: `Bearer ${this.auth.getToken()}`
  //     // }

  //   });
  //   return next.handle(request);
  // }
  
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   // debugger;
  //    this.Global.Loading=true;
  //   return next.handle(request).do((event: HttpEvent<any>) => {
  //    // debugger;
  //   // if(event instanceof HttpBackend )
  //   // {debugger;
  //   //   this.Global.Loading=true;
  //   //   console.log(event);
  //   // }

  //     if (event instanceof HttpResponse) {
  //       this.Global.Loading=false;
  //       // do stuff with response if you want
  //       //debugger;
  //       //console.log(event);
  //       // if(event.body.IsSuccess!=null)
  //       // {
  //       this.data=event.body;
      
  //       if(this.data.Message!=""&& this.data.Message!=null)
  //       {
  //         this.Notification.show(this.data.Message,this.data.MessageType);
         
  //       }
  //      else if(this.data.returnMessage!=""&& this.data.returnMessage!=null)
  //       {
  //         this.Notification.show(this.data.returnMessage,1);
         
  //       }
        
  //       if(this.data.StatusCode===401)
  //       {
  //         window.location.href = '/Home/Index/';
  //       }

  //    // }
  //     }
  //   }, (err: any) => {
  //     //debugger;
  //     this.Global.Loading=false;
  //     if (err instanceof HttpErrorResponse) {
  //       if(err.status === 200&&err.url!="")
  //       {
  //         window.location.href = err.url;
  //       }
  //       if (err.status === 401) {
  //         window.location.href = '/Home/Index/';
  //         // redirect to the login route
  //         // or show a modal
  //       }
  //       if (err.status === 301) {
  //         window.location.href = '/Home/Index/';
  //         // redirect to the login route
  //         // or show a modal
  //       }
  //     }
  //   });
  // }
}
