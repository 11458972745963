import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalVeriables } from "../../Global/GlobalVaribales";
import { AdminUserManagementService, DateConvertions, GetAuditAllFieldName } from "../../services/adminusermanagement.service";
import { AduitForm, AuditDashboardForm, PDfExpAuditSave, UserSettings } from "../../services/AMFormsDataDetails";
import { NotificationAlertsService } from "../../services/notification.service";
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { CommonFeaturesService } from "../../services/commonfeatures.service";
import * as internal from "assert";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css'],
  providers: [GetAuditAllFieldName, NotificationAlertsService, DateConvertions]
})

export class UserSettingsComponent implements OnInit {
  showDashboardLoader: boolean = false;
  submitted: boolean = false;
  userSettingsObj = new UserSettings();
  AuditResponseData: AuditDashboardForm[] = [];
  AuditLogId: boolean = false;
  Audituserdata: AduitForm[] = [];''
  htmlToAdd: any;
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  btnEditAction: boolean = true;
  btnDeleteAction: boolean = true;
  AuditexcelBtn: boolean = false;
  userid: number = parseInt(localStorage.getItem('userId'));
  ObjAuditExpPdf = new PDfExpAuditSave();
  progress: number;
  message: string;
  FileName: string;
  showLoader: boolean = false;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  UsersType: string;
  pdfDataList : PDFDownloadData[] = [];
  pdfData : PDFDownloadData;
  gridData: any;
  timeStamp : Date = new Date();
  Id:number;

  constructor(private formbuilder: FormBuilder, private dataservice: AdminUserManagementService, private notifications: NotificationAlertsService, private DisplayAllFields: GetAuditAllFieldName, private GV: GlobalVeriables, private DateTimeService: DateConvertions, private router: Router, private commonfeaturesservice: CommonFeaturesService) {

  }

  SettingsDyForm = this.formbuilder.group({
    PwdExpiryDays: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    PwdFailedAttempts: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    PwdHistoryLimit: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    EsignPwdExpiryDays: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    MaxEsignPwdFailedAttempts: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    EsignPwdhistoryLimit: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    PwdMinCharacters: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    //EmailNotiDays: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    ReqSpecialCharacters: [false],
    ReqUppercaseCharacters: [false],
    ReqNumericCharacters: [false],
    ReqLowercaseCharacters: [false]
  });

  ngOnInit() {
    this.dataservice.GetUserSettingsData().subscribe(data => {
      this.Id=data.id;
      this.SettingsDyForm.controls['PwdExpiryDays'].setValue(data.pwdExpiryDays == null ? '' : data.pwdExpiryDays);
      this.SettingsDyForm.controls['PwdFailedAttempts'].setValue(data.pwdFailedAttempts == null ? '' : data.pwdFailedAttempts);
      this.SettingsDyForm.controls['PwdHistoryLimit'].setValue(data.pwdHistoryLimit == null ? '' : data.pwdHistoryLimit);
      this.SettingsDyForm.controls['EsignPwdExpiryDays'].setValue(data.esignPwdExpiryDays == null ? '' : data.esignPwdhistoryLimit);
      this.SettingsDyForm.controls['MaxEsignPwdFailedAttempts'].setValue(data.maxEsignPwdFailedAttempts == null ? '' : data.maxEsignPwdFailedAttempts);
      this.SettingsDyForm.controls['EsignPwdhistoryLimit'].setValue(data.esignPwdhistoryLimit == null ? '' : data.esignPwdhistoryLimit);
      this.SettingsDyForm.controls['PwdMinCharacters'].setValue(data.pwdMinCharacters == null ? '' : data.pwdMinCharacters);
      //this.SettingsDyForm.controls['EmailNotiDays'].setValue(data.emailNotiDays == null ? '' : data.emailNotiDays);
      this.SettingsDyForm.controls['ReqSpecialCharacters'].setValue(data.reqSpecialCharacters == null ? false : data.reqSpecialCharacters);
      this.SettingsDyForm.controls['ReqUppercaseCharacters'].setValue(data.reqUppercaseCharacters == null ? false : data.reqUppercaseCharacters);
      this.SettingsDyForm.controls['ReqNumericCharacters'].setValue(data.reqNumericCharacters == null ? false : data.reqNumericCharacters);
      this.SettingsDyForm.controls['ReqLowercaseCharacters'].setValue(data.reqLowercaseCharacters == null ? false : data.reqLowercaseCharacters);

      this.UsersType = "Audit Log";
    });
  }

  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }

  saveSystemSettingsData() {
    try {

      if (this.SettingsDyForm.invalid) {
        /* debugger;*/
        this.submitted = true;
        return;
      }
      this.showDashboardLoader = true;
      this.userSettingsObj.PwdExpiryDays = parseInt(this.SettingsDyForm.controls['PwdExpiryDays'].value);
      this.userSettingsObj.PwdFailedAttempts = parseInt(this.SettingsDyForm.controls['PwdFailedAttempts'].value);
      this.userSettingsObj.PwdHistoryLimit = parseInt(this.SettingsDyForm.controls['PwdHistoryLimit'].value);
      this.userSettingsObj.EsignPwdExpiryDays = parseInt(this.SettingsDyForm.controls['EsignPwdExpiryDays'].value);
      this.userSettingsObj.MaxEsignPwdFailedAttempts = parseInt(this.SettingsDyForm.controls['MaxEsignPwdFailedAttempts'].value);
      this.userSettingsObj.EsignPwdhistoryLimit = parseInt(this.SettingsDyForm.controls['EsignPwdhistoryLimit'].value);
      this.userSettingsObj.PwdMinCharacters = parseInt(this.SettingsDyForm.controls['PwdMinCharacters'].value);
      //this.userSettingsObj.EmailNotiDays = parseInt(this.SettingsDyForm.controls['EmailNotiDays'].value);
      this.userSettingsObj.ReqSpecialCharacters = this.SettingsDyForm.controls['ReqSpecialCharacters'].value;
      this.userSettingsObj.ReqUppercaseCharacters = this.SettingsDyForm.controls['ReqUppercaseCharacters'].value;
      this.userSettingsObj.ReqNumericCharacters = this.SettingsDyForm.controls['ReqNumericCharacters'].value;
      this.userSettingsObj.ReqLowercaseCharacters = this.SettingsDyForm.controls['ReqLowercaseCharacters'].value;
      this.userSettingsObj.CreatedBy = parseInt(localStorage.getItem('userId'));
      this.userSettingsObj.ModifiedBy = parseInt(localStorage.getItem('userId'));

      this.dataservice.SaveUserSettingsData(this.userSettingsObj).subscribe(
        (data) => {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "USUS", 1).subscribe((dataUActionsMessages) => {
            this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
          });
          this.showDashboardLoader = false;
        },
        error => {
          this.showDashboardLoader = false;
          //console.log(error);
          this.notifications.show(error.statusText, 4);

        });
    }
    catch (error) {
      this.showDashboardLoader = false;
      this.notifications.show(error.statusText, 4);

    }
    finally {
      //this.UserDialogbx = false;
      this.showDashboardLoader = false;

    }

  }
  cancelSystemSettingsData() {
    this.SettingsDyForm.reset();
  }
  auditclose() {
    this.AuditLogId = false;
    this.pdfDataList = [];
    this.router.navigate(['/UserSettings']);
  }
  AuditLogData(keyID: any, TablenumID: any, CompanyID: any) {
    this.AuditLogId = true;
    this.IsAuditExportExcel = true;

    this.dataservice.AuditLogData(keyID, TablenumID, this.GV.UserId, CompanyID).subscribe((data) => {

      //console.log(data);
      this.divHisdata = true;
      var AuditDisplay = "";
      if (data.length > 0) {
        this.AuditexcelBtn = true;
        for (var i = 0; i < data.length; i++) {
          this.pdfData = new PDFDownloadData;
          this.pdfData.actionType = data[i].actionType;
          this.pdfData.eventDate = this.DateTimeService.convertdate(data[i].eventDate);
          this.pdfData.userId = data[i].userId;
          var seraizedata = JSON.parse(data[i]["changes"]);
          if(seraizedata){
            for (var j = 0; j < seraizedata.length; j++) {
              seraizedata[j].FieldName = this.DisplayAllFields.GetFieldName(seraizedata[j].FieldName, 1056);
            }
          }
          this.pdfData.listdata = seraizedata;
          this.pdfDataList.push(this.pdfData);
          
        }
        this.gridData = this.pdfDataList;
      }
    });
  }

  ExportAuditToPdfFile(htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;;
    this.ObjAuditExpPdf.TableEnum = '59';
    this.showLoader = true;
    this.dataservice.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      //console.log("download result ", event.filePath);
      this.showLoader = false;
    },
      error => {
        //console.log(error);
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );


  }

  DownLoadExportPdfFile(Filepath: string, FileName: string) {
    this.FileName = FileName;
    this.dataservice.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
      }
    },
      error => {
        // console.log(error);
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }
  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    //a.download = this.FileName.substring(1).slice(0, -1);
    a.download = this.FileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

}
export class PDFDownloadData{
userId : string;
eventDate: string;
actionType: string;
listdata: PDFFieldNames[] = [];

}
export class PDFFieldNames{
  FieldName: string;
  ValueBefore: string;
  ValueAfter: string;
}
